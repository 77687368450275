import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Translate from 'lib/Translate';
import { TailSpin } from 'react-loader-spinner';
import { BreadCrumb, Button, Colors } from '@dm/bigfish';
import { PDFDocument } from 'pdf-lib';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';

import NavActions from 'lib/NavActions';
import { useLocation } from 'react-router-dom';
import { Category, PackageCategory } from '@dm/types';
import SignDocument from './componentsPackages/SignaturePad';

export interface SignProps {
        packagesSetsetPropertySignLink: string;
        isPackageSigned: boolean;
        documentTemplateLoading: boolean;
        documentTemplateError: string;
        documentTemplate: string;
        packagesSetSignCompleted: boolean;
        packagesSetSignCompletedAttempting: boolean;
        packagesSetSignCompletedError:string;
        setTemplateDownload: (documentTemplate: number, propertyId: string) => void;
        setIsPackagesSigned: (state: boolean) => void;
        getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
        setIsPackagesSignedComplete: (state: boolean) => void;
        clearProperty: () => void;
}

interface NavParams {
    propertyIndividualId: string;
    packageCategory: PackageCategory;
}
const PackagesSign = (props : SignProps) : JSX.Element => {
    const { setTemplateDownload,
        packagesSetsetPropertySignLink,
        isPackageSigned,
        documentTemplateLoading,
        documentTemplateError,
        documentTemplate,
        packagesSetSignCompleted,
        packagesSetSignCompletedAttempting,
        packagesSetSignCompletedError,
        setIsPackagesSigned,
        getUploadUrl,
        setIsPackagesSignedComplete,
        clearProperty } = props;
    const [imageUrl, setImageUrl] = useState('');
    const [imageUrlDefault, setImageUrlDefault] = useState('');
    const [signed, SetSigned] = useState(false);
    const [packageName, SetPackageName] = useState('');

    const idAndChoosedPackages = useLocation();

    const { propertyIndividualId, packageCategory } = idAndChoosedPackages.state as NavParams;

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Sign.DocumentSign'),
                onClick: () => { return false; },
            },
        ],
    );
    useEffect(() => {
        setImageUrlDefault('');
        setIsPackagesSigned(false);
        if (packageCategory === PackageCategory.VacantPossession) {
            setTemplateDownload(Category.VacantPossession, propertyIndividualId);
            SetPackageName(`${Translate.t('Property.PropertyVacantPossesion')} ${Translate.t('Sign.Agreement')}`);
        }
        if (packageCategory === PackageCategory.DefectInspection) {
            setTemplateDownload(Category.DefectInspection, propertyIndividualId);
            SetPackageName(`${Translate.t('Property.PropertyDefectInspection')} ${Translate.t('Sign.Agreement')}`);
        }
        if (packageCategory === PackageCategory.LeaseSale) {
            setTemplateDownload(Category.LeaseSale, propertyIndividualId);
            SetPackageName(`${Translate.t('Property.PropertyLeaseSale')} ${Translate.t('Sign.Agreement')}`);
        }
        if (packageCategory === PackageCategory.VacantManagement) {
            setTemplateDownload(Category.VacantManagement, propertyIndividualId);
            SetPackageName(`Vacant Management ${Translate.t('Sign.Agreement')}`);
        }
        if (packageCategory === PackageCategory.PropertyManagement) {
            setTemplateDownload(Category.PropertyManagement, propertyIndividualId);
            SetPackageName(`Property Management ${Translate.t('Sign.Agreement')}`);
        }
    }, []);

    useEffect(() => {
        defaultDocumentFunction();
    }, [documentTemplate]);

    useEffect(() => {
        if (isPackageSigned === true) {
            embedPdfPages();
            setIsPackagesSigned(false);
            SetSigned(true);
        }
    }, [isPackageSigned]);

    async function defaultDocumentFunction() {
        if (packageCategory === PackageCategory.LeaseSale) {
            const base64Data = 'JVBERi0xLjcKJYGBgYEKCjUgMCBvYmoKPDwKL0ZpbHRlciAvRmxhdGVEZWNvZGUKL0xlbm'
            + 'd0aCAxMDQKPj4Kc3RyZWFtCniccwrhMlAAwaJ0Ln2P1Jyy1JLM5ERdc0MjCwUjE4WQNC4Q'
            + '6cNlCFZkqGCqYGSqEJLLZWNuYGZiZmbkYuZsZmlmZGRgZmluDCQNzc3NTM2NzdzMXMxMjQ'
            + 'ztFEKyuEK0uFxDuAAOERdVCmVuZHN0cmVhbQplbmRvYmoKCjYgMCBvYmoKPDwKL0ZpbHRl'
            + 'ciAvRmxhdGVEZWNvZGUKL1R5cGUgL09ialN0bQovTiA0Ci9GaXJzdCAyMAovTGVuZ3RoID'
            + 'IxNQo+PgpzdHJlYW0KeJxVj9GqwjAMhu/zFHkBzTo3nCCCiiKIHPEICuJF3cKoSCu2E8/b'
            + '20wPIr1p8v9/8kVhgilmGfawX2CGaVrgcAi0/bsy0lrX7IGWpvJ4iJYEN3gEmrrGBlQwGs'
            + 'HHO9VBX1wNrxAqMX87RBD5xpJuddqwd82tjAHxzV1U5LPgy52DKXWnr1Lheg+j/c/pzGVr'
            + 'iqV0VlwZPXGPCJjElw/ybkwUmeoWgxesDXGhHJC/D/iikp1Av80ptKU0FdBEe25pPihAM1'
            + 'u6ytgaaWfs2Hrz35CJT1+EWmAKZW5kc3RyZWFtCmVuZG9iagoKNyAwIG9iago8PAovU2l6'
            + 'ZSA4Ci9Sb290IDIgMCBSCi9GaWx0ZXIgL0ZsYXRlRGVjb2RlCi9UeXBlIC9YUmVmCi9MZW'
            + '5ndGggMzgKL1cgWyAxIDIgMiBdCi9JbmRleCBbIDAgOCBdCj4+CnN0cmVhbQp4nBXEwREA'
            + 'EBAEsCwz3vrvRmOOyyOoGhZdutHN2MT55fIAVocD+AplbmRzdHJlYW0KZW5kb2JqCgpzdG'
            + 'FydHhyZWYKNTEwCiUlRU9G';
            const base64Response = await fetch(`data:image/jpeg;base64,${base64Data}`);
            const blob = await base64Response.blob();
            const file = new Blob([blob], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            setImageUrlDefault(fileURL);
            SetSigned(false);
        } else {
            const base64Data = documentTemplate;
            const base64Response = await fetch(`data:image/jpeg;base64,${base64Data}`);
            const blob = await base64Response.blob();
            const file = new Blob([blob], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            setImageUrlDefault(fileURL);
            SetSigned(false);
        }
    }

    async function embedPdfPages() {
        if (packageCategory === PackageCategory.LeaseSale) {
            const jpgUrl = packagesSetsetPropertySignLink;
            const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());

            const pdfFromS3 = 'JVBERi0xLjcKJYGBgYEKCjUgMCBvYmoKPDwKL0ZpbHRlciAvRmxhdGVEZWNvZGUKL0xlbm'
            + 'd0aCAxMDQKPj4Kc3RyZWFtCniccwrhMlAAwaJ0Ln2P1Jyy1JLM5ERdc0MjCwUjE4WQNC4Q'
            + '6cNlCFZkqGCqYGSqEJLLZWNuYGZiZmbkYuZsZmlmZGRgZmluDCQNzc3NTM2NzdzMXMxMjQ'
            + 'ztFEKyuEK0uFxDuAAOERdVCmVuZHN0cmVhbQplbmRvYmoKCjYgMCBvYmoKPDwKL0ZpbHRl'
            + 'ciAvRmxhdGVEZWNvZGUKL1R5cGUgL09ialN0bQovTiA0Ci9GaXJzdCAyMAovTGVuZ3RoID'
            + 'IxNQo+PgpzdHJlYW0KeJxVj9GqwjAMhu/zFHkBzTo3nCCCiiKIHPEICuJF3cKoSCu2E8/b'
            + '20wPIr1p8v9/8kVhgilmGfawX2CGaVrgcAi0/bsy0lrX7IGWpvJ4iJYEN3gEmrrGBlQwGs'
            + 'HHO9VBX1wNrxAqMX87RBD5xpJuddqwd82tjAHxzV1U5LPgy52DKXWnr1Lheg+j/c/pzGVr'
            + 'iqV0VlwZPXGPCJjElw/ybkwUmeoWgxesDXGhHJC/D/iikp1Av80ptKU0FdBEe25pPihAM1'
            + 'u6ytgaaWfs2Hrz35CJT1+EWmAKZW5kc3RyZWFtCmVuZG9iagoKNyAwIG9iago8PAovU2l6'
            + 'ZSA4Ci9Sb290IDIgMCBSCi9GaWx0ZXIgL0ZsYXRlRGVjb2RlCi9UeXBlIC9YUmVmCi9MZW'
            + '5ndGggMzgKL1cgWyAxIDIgMiBdCi9JbmRleCBbIDAgOCBdCj4+CnN0cmVhbQp4nBXEwREA'
            + 'EBAEsCwz3vrvRmOOyyOoGhZdutHN2MT55fIAVocD+AplbmRzdHJlYW0KZW5kb2JqCgpzdG'
            + 'FydHhyZWYKNTEwCiUlRU9G';

            const pdfDoc = await PDFDocument.create();
            // Embed the JPG image bytes and PNG image bytes
            const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
            const jpgDims = jpgImage.scale(0.3);

            const dataUri = `data:application/pdf;base64,${pdfFromS3}`;

            // const ContractPDFPdfBytesLoaded = await PDFDocument.load(pdfFromS3);
            const ContractPDFPdfBytesLoaded = await PDFDocument.load(dataUri);

            // Embed first page of the mainpdf and clip the sign image
            const embedPDFPdfBytesLoaded = await pdfDoc.embedPage(ContractPDFPdfBytesLoaded.getPages()[0], {
                left: 0,
                bottom: 800,
                right: 650,
                top: -85,
            });

            const embedPdfBytesLoadedDimention = embedPDFPdfBytesLoaded;

            // Add a blank page to the document
            const page = pdfDoc.addPage();

            // Draw the JPG image in the center of the page
            page.drawImage(jpgImage, {
                x: page.getWidth() / 2 - 200 - jpgDims.width / 2,
                y: page.getHeight() - page.getHeight() + 120 - jpgDims.height / 2,
                width: jpgDims.width,
                height: jpgDims.height,

            });
            page.drawPage(embedPDFPdfBytesLoaded, {
                ...embedPdfBytesLoadedDimention,
                x: page.getWidth() / 2 - embedPdfBytesLoadedDimention.width / 2,
                y: page.getHeight() / 2 - embedPdfBytesLoadedDimention.height / 2 - 50,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            const file = new Blob([pdfBytes], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            setImageUrl(fileURL);

            const b64 = file;

            fileSelectHandler(b64);
        } else if (packageCategory === PackageCategory.PropertyManagement || packageCategory === PackageCategory.VacantManagement) {
            const jpgUrl = packagesSetsetPropertySignLink;
            const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());

            const pdfFromS3 = documentTemplate;

            const pdfDoc = await PDFDocument.create();
            // Embed the JPG image bytes and PNG image bytes
            const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
            const jpgDims = jpgImage.scale(0.3);
            // Load the main PDF into a PDFDocument
            const ContractPDFPdfBytesLoaded = await PDFDocument.load(pdfFromS3);

            // Embed first page of the mainpdf and clip the sign image
            const embedPDFPdfBytesLoaded = await pdfDoc.embedPage(ContractPDFPdfBytesLoaded.getPages()[11], {
                left: 0,
                bottom: 800,
                right: 650,
                top: -85,
            });

            const embedPdfBytesLoadedDimention = embedPDFPdfBytesLoaded;

            // Embed previous pages into the new document
            const copiedPages = await pdfDoc.copyPages(ContractPDFPdfBytesLoaded, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
            const [page1, page2, page3, page4, page5, page6, page7, page8, page9, page10, page11] = copiedPages;

            pdfDoc.addPage(page1);
            pdfDoc.addPage(page2);
            pdfDoc.addPage(page3);
            pdfDoc.addPage(page4);
            pdfDoc.addPage(page5);
            pdfDoc.addPage(page6);
            pdfDoc.addPage(page7);
            pdfDoc.addPage(page8);
            pdfDoc.addPage(page9);
            pdfDoc.addPage(page10);
            pdfDoc.addPage(page11);
            const page = pdfDoc.addPage();

            // Draw the JPG image in the center of the page
            page.drawImage(jpgImage, {
                x: page.getWidth() / 2 - 215 - jpgDims.width / 2,
                y: page.getHeight() - page.getHeight() + 230 - jpgDims.height / 2,
                width: jpgDims.width,
                height: jpgDims.height,

            });
            page.drawPage(embedPDFPdfBytesLoaded, {
                ...embedPdfBytesLoadedDimention,
                x: page.getWidth() / 2 - embedPdfBytesLoadedDimention.width / 2,
                y: page.getHeight() / 2 - embedPdfBytesLoadedDimention.height / 2 - 50,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            const file = new Blob([pdfBytes], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            setImageUrl(fileURL);

            const b64 = file;

            fileSelectHandler(b64);
        } else if (packageCategory === PackageCategory.VacantPossession || packageCategory === PackageCategory.DefectInspection) {
            const jpgUrl = packagesSetsetPropertySignLink;
            const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());

            const pdfFromS3 = documentTemplate;

            const pdfDoc = await PDFDocument.create();
            // Embed the JPG image bytes and PNG image bytes
            const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
            const jpgDims = jpgImage.scale(0.3);
            // Load the main PDF into a PDFDocument
            const ContractPDFPdfBytesLoaded = await PDFDocument.load(pdfFromS3);

            // Embed first page of the mainpdf and clip the sign image
            const embedPDFPdfBytesLoaded = await pdfDoc.embedPage(ContractPDFPdfBytesLoaded.getPages()[0], {
                left: 0,
                bottom: 700,
                right: 650,
                top: -85,
            });

            const embedPdfBytesLoadedDimention = embedPDFPdfBytesLoaded;

            // Add a blank page to the document
            const page = pdfDoc.addPage();

            // Draw the JPG image in the center of the page
            page.drawImage(jpgImage, {
                x: page.getWidth() / 2 - 200 - jpgDims.width / 2,
                y: page.getHeight() - page.getHeight() + 180 - jpgDims.height / 2,
                width: jpgDims.width,
                height: jpgDims.height,

            });
            page.drawPage(embedPDFPdfBytesLoaded, {
                ...embedPdfBytesLoadedDimention,
                x: page.getWidth() / 2 - embedPdfBytesLoadedDimention.width / 2,
                y: page.getHeight() / 2 - embedPdfBytesLoadedDimention.height / 2 - 50,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            const file = new Blob([pdfBytes], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            setImageUrl(fileURL);

            const b64 = file;

            fileSelectHandler(b64);
        }
    }

    const fileSelectHandler = (document: any) => {
        const documentExtension = '.pdf';
        if (packageCategory === PackageCategory.LeaseSale) {
            getUploadUrl(Category.LeaseSale, propertyIndividualId, Category.LeaseSale.toString(), documentExtension, document);
        }
        if (packageCategory === PackageCategory.DefectInspection) {
            getUploadUrl(Category.PendingSignature, propertyIndividualId, Category.DefectInspection.toString(), documentExtension, document);
        }
        if (packageCategory === PackageCategory.VacantPossession) {
            getUploadUrl(Category.PendingSignature, propertyIndividualId, Category.VacantPossession.toString(), documentExtension, document);
        }

        if (packageCategory === PackageCategory.PropertyManagement) {
            getUploadUrl(Category.PendingSignature, propertyIndividualId, Category.PropertyManagement.toString(), documentExtension, document);
        }
        if (packageCategory === PackageCategory.VacantManagement) {
            getUploadUrl(Category.PendingSignature, propertyIndividualId, Category.VacantManagement.toString(), documentExtension, document);
        }
    };

    const goToHome = () => {
        clearProperty();
        NavActions.navResetToHome();
        setIsPackagesSignedComplete(false);
    };

    return (
        <>
            <BreadCrumb onBackPressed={() => NavActions.navToHome()} data={breadCrumb} />
            <ModulesContainer>
                <ModulesMainContainer>
                    <ModalPadding>
                        <CenteringContainer>
                            <TitleSpacingContainer>
                                <h2>{packageName}</h2>
                            </TitleSpacingContainer>
                        </CenteringContainer>
                        <Container>
                            <div>
                                {(packageCategory === PackageCategory.LeaseSale) && (
                                    <CenteringContainer>
                                        {signed ? (
                                            <CarouselPhoto src={imageUrl} title='pdf' />
                                        )
                                            : (
                                                <CarouselPhoto src={imageUrlDefault} title='pdf' />
                                            )}
                                    </CenteringContainer>
                                )}
                                {(documentTemplate !== undefined) && (documentTemplate !== '') ? (
                                    <CenteringContainer>
                                        {signed ? ([
                                            (packageCategory === PackageCategory.PropertyManagement || PackageCategory.VacantManagement) ? (
                                                <CarouselPhoto src={`${imageUrl}#page=12`} title='pdf' />
                                            ) : (<CarouselPhoto src={imageUrl} title='pdf' />
                                            ),
                                        ]
                                        )
                                            : (
                                                <CarouselPhoto src={imageUrlDefault} title='pdf' />
                                            )}
                                    </CenteringContainer>
                                ) : (

                                    <CenteringContainer>
                                        {documentTemplateError === '' ? (
                                            <LoaderContainer color={Colors.primary} height='250' width='250' />

                                        ) : (
                                            <CenteringContainer>
                                                {((packageCategory !== PackageCategory.PropertyManagement) && (packageCategory !== PackageCategory.LeaseSale) && (packageCategory !== PackageCategory.VacantManagement))
                                                && <h4>{Translate.t('GeneralUI.SomethingWrong')}</h4>}
                                            </CenteringContainer>
                                        )}
                                    </CenteringContainer>

                                )}

                                <CenteringContainer>
                                    <SignDocument />

                                </CenteringContainer>
                                <CenteringContainer>
                                    <div>
                                        {packagesSetSignCompleted && (
                                            <Button
                                                label={Translate.t('GeneralUI.Submit')}
                                                onClick={goToHome}
                                            />
                                        )}

                                        {packagesSetSignCompletedError !== '' && (
                                            <div>
                                                <WarningContainer>
                                                    {Translate.t('GeneralUI.SomethingWrong')}
                                                </WarningContainer>
                                                <Button
                                                    label={Translate.t('VerifySignUp.VerifySignUpLabel')}
                                                    onClick={() => NavActions.navResetToHome()}
                                                />

                                            </div>
                                        )}

                                    </div>

                                </CenteringContainer>

                            </div>

                        </Container>
                    </ModalPadding>
                </ModulesMainContainer>
            </ModulesContainer>

        </>

    );
};

const CarouselPhoto = styled.iframe`
  width: 600px; 
  height: 800px;
  @media (max-width: 1450px) {
        width: 350px;
        height: 400px;
  } 
  object-fit: cover;
`;

const CenteringContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
`;

const TitleSpacingContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const WarningContainer = styled.h4`
    display: flex;
    color: red;
`;

const LoaderContainer = styled(TailSpin)`
    display: flex;
    justify-self: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
`;

const ModulesContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const ModulesMainContainer = styled.div`
    width: 45%;
    margin-right: 16px;
    >:not(:last-child) {
        margin-bottom: 40px;
    }
    background-color: white;
`;

const IframeContainer = styled.iframe`
    width: 600px;
    height: 800px;
    object-fit: cover;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
`;

const ModalPadding = styled.div`
    padding: 20px;
`;

export interface RoundInputsampleProps {
    placeholder?: string;
    value?: string;
    onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onEnterPressed?: () => void;
    style?: React.CSSProperties;
}

const mapStateToProps = (state: RootState) => ({
    packagesSetsetPropertySignLink: Selectors.packagesSetsetPropertySignLink(state),
    isPackageSigned: Selectors.packagesSetIsPackageSigned(state),
    downloadTemplateLink: Selectors.packagesSetIsPackageSigned(state),

    documentTemplateLoading: Selectors.getPackagesGetPackagesDownloadSignDocumentsAttempt(state),
    documentTemplateError: Selectors.getPackagesgetPackagesDownloadSignDocumentsFailure(state),
    documentTemplate: Selectors.getPackagesgetPackagesDownloadSignDocuments(state),

    packagesSetSignCompletedAttempting: Selectors.packagesUploadPackagesSignedDocumentAttempting(state),
    packagesSetSignCompletedError: Selectors.packagesUploadPackagesSignedDocumentError(state),
    packagesSetSignCompleted: Selectors.packagesSetSignCompleted(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTemplateDownload: (documentTemplate: number, propertyId: string) => dispatch(Actions.getPackagesDownloadDocumentTemplateAttempt({ documentTemplate, propertyId })),
    setIsPackagesSigned: (state: boolean) => dispatch(Actions.setIsPackagesSigned(state)),
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPackagesSignedUploadUrlAttempt({ category, id, name, extension, document })),
    setIsPackagesSignedComplete: (state: boolean) => dispatch(Actions.setSignCompleted(state)),
    clearProperty: () => dispatch(Actions.clearProperty()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesSign);
