import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';

import { RequestResetPasswordPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchRequestResetPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authRequestResetPasswordAttempt', handleRequestResetPassword, api);
}

function* handleRequestResetPassword(api: AuthGateway, data: RequestResetPasswordPayload) {
    const { email } = data.payload;

    const response = yield* call([api, api.requestResetPassword], { email });

    if (response.status === GatewayResponseStatus.Success) {
        NavActions.navToLogin();
        toast.success('Recovery link has been sent to your email');

        if (!response.data) {
            yield put(Actions.authRequestResetPasswordFailure('Something went wrong. Please try again.'));
        }
    } else {
        toast.error('Something went wrong. Please try again.');

        yield put(Actions.authRequestResetPasswordFailure(response.message));
    }
}
