const dev = {
    baseUrl: process.env.REACT_APP_BASE_URL,
};

const prod = {
    baseUrl: process.env.REACT_APP_BASE_URL,
};

const dormeoBankDetails = {
    bankName: process.env.REACT_APP_BANK_NAME,
    bankNumber: process.env.REACT_APP_BANK_NUMBER,
    accountName: process.env.REACT_APP_ACCOUNTNAME,
    swiftCode: process.env.REACT_APP_SWIFTCODE,
    mapApiKey: process.env.REACT_APP_MAPAPI_KEY,
    stripeSecretKey: process.env.REACT_APP_STRIPESECRET_KEY,
    stripePublicKey: process.env.REACT_APP_STRIPEPUBLIC_KEY,
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

const useMockApi = false;

export default {
    ...config,
    useMockApi,
    dormeoBankDetails,
};
