import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

import NoImageAvailable from '../../assets/images/no_image_available.png';

export interface IImageCarousel {
  image: string;
  desc?: string;
}

export interface ImageCarouselProps {
  data: IImageCarousel[];
  style?: React.CSSProperties;
}

const CarouselGroupMini = styled.div<{disabled?: boolean}>`
  position: relative;
  height: 65vh;
  width: 80%;
  object-fit: cover;
  box-shadow: 0 1.5rem 4rem rgba(black, .4);
  border-radius: 2px;
  border-right: 5px solid white;
  border-left: 5px solid white;
  transition: all .2s;
`;

const CarouselPhotoDesc = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  opacity: 0.75;
  background-color: #F2B47E;
  color: white;
  padding: 8px 20px;
  box-sizing: border-box;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
`;

const CarouselMainContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const CarouselPhoto = styled.iframe`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarouselPhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageCarousel = ({ data, style, ...otherProps } : ImageCarouselProps) : JSX.Element => {
    const [currentArray, setCurrentArray] = useState<IImageCarousel[] | []>([]);
    const [currentArrayEmpty, setCurrentArrayEmpty] = useState<IImageCarousel[] | []>([]);
    const [noimage, setNoimage] = useState(false);

    const { length } = data;

    useEffect(() => {
        if (data[0].image.length && data[0].image !== undefined) {
            setCurrentArray([data[0]]);
        } else {
            setNoimage(true);
            setCurrentArray(currentArrayEmpty);
        }
    }, []);

    return (

        <CarouselMainContainer>
            {!noimage && (
                <CarouselGroupMini>
                    {data[0].image.includes('pdf') ? (
                        <CarouselPhoto src={`${data[0].image}#toolbar=0`} title='image' />
                    ) : <CarouselPhotoImage src={data[0].image} title='image' />}

                    {data && <CarouselPhotoDesc>{data[0].desc}</CarouselPhotoDesc>}
                </CarouselGroupMini>
            )}

            {noimage && (
                <CarouselGroupMini disabled>
                    <CarouselPhoto src={NoImageAvailable} />
                </CarouselGroupMini>
            )}
        </CarouselMainContainer>

    );
};
