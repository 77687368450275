import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IOwnerUser } from '@dm/types';
import { IUpdateOwnerUser } from 'entities/user';

export interface GetUserParams {
    authToken: string;
}

export interface UpdateUserParams {
    data: IUpdateOwnerUser,
    authToken: string;
}

export abstract class IUserGateway extends Gateway {
    abstract GetUserInfo(params: GetUserParams): GatewayResponse<IOwnerUser>;

    abstract SetUserInfo(params: UpdateUserParams): GatewayResponse<string | null>;
}
