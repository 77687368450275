import { ViewAllPMTransactionResponse } from '@dm/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropReduxState } from './types';

const initialState: PropReduxState = {
    selectedLanguage: '',
    selectedTabView: '',
    welcomeModal: false,
    welcomeModalSuccess: false,
    isUploadFileModalOpen: false,
    isFileViewModalOpen: false,
    fileList: [],
    isPMTransactionModalOpen: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.selectedLanguage = action.payload;
        },
        setTabView: (state, action: PayloadAction<string>) => {
            state.selectedTabView = action.payload;
        },
        setWelcomeModal: (state, action: PayloadAction<boolean>) => {
            state.welcomeModal = action.payload;
        },
        setWelcomeModalSuccess: (state, action: PayloadAction<boolean>) => {
            state.welcomeModalSuccess = action.payload;
        },
        setUploadFileModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUploadFileModalOpen = action.payload;
        },
        setFileViewModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isFileViewModalOpen = action.payload;
        },
        setFileList: (state, action: PayloadAction<ViewAllPMTransactionResponse['fileUrl']>) => {
            state.fileList = action.payload;
        },
        setPMTransactionModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isPMTransactionModalOpen = action.payload;
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
