import React, { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';

import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Translate from 'i18n-js';
import icons from 'assets/icons';
import Modal from 'components/Modal';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import { Button, Colors } from '@dm/bigfish';
import { Oval } from 'react-loader-spinner';
import { Category } from '@dm/types';

export interface UploadFileModalProps {
    propertyId: string;
    transactionId: string;
    isUploadFileModalOpen: boolean;
    setIsUploadFileModalOpen: (value: boolean) => void;

    attempLoadingUpload: boolean;
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
}

const UploadFileModal = (props: UploadFileModalProps): JSX.Element => {
    const {
        propertyId,
        transactionId,
        isUploadFileModalOpen,
        setIsUploadFileModalOpen,
        attempLoadingUpload,
        getUploadUrl,
    } = props;

    const [uploadedFile, setUploadedFile] = useState<File>();

    const onUploadFile = () => {
        if (uploadedFile !== undefined) {
            const { type } = uploadedFile;
            let documentExtension = '';

            switch (type) {
                case 'application/pdf': documentExtension = '.pdf'; break;
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }
            getUploadUrl(Category.PropertyManagementTransaction, propertyId, transactionId, documentExtension, uploadedFile);
            setUploadedFile(undefined);
        }
        return false;
    };

    const renderFileUpload = () => {
        const hiddenFileInput = useRef<HTMLInputElement>(null);

        const handleClick = () => {
            hiddenFileInput.current?.click();
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const fileUploaded = event.target.files?.[0];
            if (fileUploaded !== undefined) {
                if (uploadedFile === undefined) {
                    setUploadedFile(fileUploaded);
                }
                const { type } = fileUploaded;
                let documentExtension = '';

                switch (type) {
                    case 'application/pdf': documentExtension = '.pdf'; break;
                    case 'image/jpg': documentExtension = '.jpg'; break;
                    case 'image/jpeg': documentExtension = '.jpeg'; break;
                    case 'image/png': documentExtension = '.png'; break;
                    default:
                }

                if ((documentExtension === ('.png')) || (documentExtension === ('.jpeg')) || (documentExtension === ('.jpg')) || (documentExtension === ('.pdf'))) {
                    toast.success('File uploaded!');
                } else {
                    setUploadedFile(undefined);
                    toast.error(Translate.t('Packages.PaymentUpload.Filetypenotsupported'));
                }
            }
        };

        return (
            <>
                <UploadDocumentsContainer
                    onClick={handleClick}
                >
                    {
                        attempLoadingUpload
                            ? (
                                <LoadingContainer>
                                    <Oval
                                        height={100}
                                        width={100}
                                        color={Colors.secondary}
                                        secondaryColor={Colors.primary}
                                    />
                                    <p>Uploading documents...</p>
                                </LoadingContainer>
                            ) : (
                                <>
                                    <UploadImage src={uploadedFile ? icons.UploadFileCheck : icons.UploadFile} height={80} width={80} />
                                    {
                                        uploadedFile ? (
                                            <p>{uploadedFile.name}</p>
                                        ) : (
                                            <p>Upload Document</p>
                                        )
                                    }
                                </>
                            )
                    }

                </UploadDocumentsContainer>
                <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
            </>
        );
    };

    return (
        <Modal
            width='500px'
            height='380px'
            show={isUploadFileModalOpen}
            closeCursor
            onClickClose={() => [setUploadedFile(undefined), setIsUploadFileModalOpen(false)]}
        >
            <div style={{ height: '300px' }}>
                <Title>Upload file</Title>
                {renderFileUpload()}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'space-evenly' }}>
                    <Button
                        label='Upload'
                        disabled={uploadedFile === undefined}
                        style={{ cursor: 'pointer' }}
                        onClick={() => onUploadFile()}
                    />
                    <Button
                        label='Cancel'
                        onClick={() => [setUploadedFile(undefined), setIsUploadFileModalOpen(false)]}
                    />
                </div>
            </div>
        </Modal>
    );
};

const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
`;

const UploadImage = styled(SVG)<{disabled?:boolean}>`
    padding: 10px;
    width: 100%;
    height: 100%;

    &:active:not([disabled]){
        transform: translateY(4px);
    }
`;

const UploadDocumentsContainer = styled.button`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px dotted #a3a3a3;
    border-radius: 20px;
    background-color: ${Colors.primaryLightest};
    margin-top: 20px;

    &:disabled{
        background-color: rgba(0, 0, 0, 0.192);
    }

    p{
        margin-bottom: 10px;
    }

    &:hover:not([disabled]){
        background-color: ${Colors.primaryLight};
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    isUploadFileModalOpen: Selectors.getUiUploadFileModalOpen(state),
    attempLoadingUpload: Selectors.packagesgetPackagesUploadUrlAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsUploadFileModalOpen: (value: boolean) => dispatch(Actions.setUploadFileModalOpen(value)),
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPackagesUploadUrlAttempt({ category, id, name, extension, document })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileModal);
