import React from 'react';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';

import Translate from 'lib/Translate';

import Icons from '../assets/icons';

const CarelineContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 20px;

  background: #FFCFA6;

  box-sizing: border-box;
  border-radius: 12px;

  max-width: 264px;
`;

const CarelineDescription = styled(SVG)`
  color: #FFCFA6;
  height: 40px;
  width: 40px;
`;

const CarelineText = styled.h4`
  color: #fff;

  font-size: 14px;
  line-height: 16px;
  font-weight: 700;

  margin-left: 12px;

  text-transform: capitalize;
`;

const CarelineButton = (): JSX.Element => {
    return (
        <CarelineContainer>
            <CarelineDescription src={Icons.CarelineIcon} />
            <CarelineText>{Translate.t('Ui.UiCareline')}</CarelineText>
        </CarelineContainer>
    );
};

export default CarelineButton;
