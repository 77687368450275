import React, { FunctionComponent, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Button, Colors, Fonts } from '@dm/bigfish';

import { IFile } from '@dm/types';
import FileSaver, { saveAs } from 'file-saver';
import media from 'lib/Breakpoints';

interface PackagesListCardProps {
    id: number;
    propertyName: string;
    documents: IFile[];
    setModalOpen: (state: boolean) => void;
    setSelectedDocuments: (data: IFile[]) => void;
}

const PackagesListCard: FunctionComponent<PackagesListCardProps> = (props: PackagesListCardProps) => {
    const {
        id,
        propertyName,
        documents,
        setModalOpen,
        setSelectedDocuments,
    } = props;

    const [documentList, setDocumentList] = useState<IFile[]>([]);

    const idClickHandler = () => {
        setModalOpen(true);
        documentList.push(documents[id]);
        setSelectedDocuments(documentList);
    };

    const saveFile = () => {
        const urlLink = documents[id].url;
        const documentName = documents[id].name;

        saveAs(
            urlLink,
            documentName,
        );
    };

    return (
        <>
            <td>
                {propertyName}
            </td>

            <td>
                {(documents[id].createdAt !== undefined) ? (
                    <div>
                        {(dayjs(documents[id].createdAt).format('DD/MM/YYYY'))}
                    </div>
                ) : 'no date'}
            </td>

            <td id='actions'>
                <StyledButton
                    label={Translate.t('Packages.ViewDocuments.ButtonDownloadLabel')}
                    onClick={saveFile}
                />

                <StyledButtonView
                    label={Translate.t('Packages.ViewDocuments.ButtonViewLabel')}
                    onClick={idClickHandler}
                />
            </td>
        </>

    );
};

const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: 5px;
    font-size: 16px;
`;

const StyledButtonView = styled(Button)`
    width: 100%;
    font-size: 16px;

    ${media.sm`
        display: none;
    `}
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpen: (state: boolean) => dispatch(Actions.setPackagesDocumentVacantPossessionView(state)),
});

export default connect(null, mapDispatchToProps)(PackagesListCard);
