import { InitState } from 'redux/slices/prop';

import { IServerProperty, IPropertyIndividual, IPropertyPaginator, IAllPropertyDetailDashboards, IRentalAnalytics, IWalletAnalytics } from 'entities/property';

const getPropertyAttempt = (state: InitState): boolean => state.actions.getProperty || false;
const getPropertyFailure = (state: InitState): string => state.error.getProperty || '';
const property = (state: InitState): IServerProperty[] => state.property || [];

const getPropertyPaginatorAttempt = (state: InitState): boolean => state.actions.getPropertyPaginator || false;
const getPropertyPaginatorFailure = (state: InitState): string => state.error.getPropertyPaginator || '';
const propertyPaginator = (state: InitState): IPropertyPaginator => state.propertyPaginator || {};

const displayedProperty = (state: InitState): IPropertyPaginator['data'] => state.displayedProperty || [];
const currentPropertyIndex = (state: InitState): number => state.currentPropertyIndex || 0;
const menuIsOpen = (state: InitState): boolean => state.menuIsOpen || false;

const getAllPropertyDetailsAttempt = (state: InitState): boolean => state.actions.getAllPropertyDetails || false;
const getAllPropertyDetailsFailure = (state: InitState): string => state.error.getAllPropertyDetails || '';
const allPropertyDetails = (state: InitState): IAllPropertyDetailDashboards => state.allPropertyDetails || {};

const getRentalAnalyticsAttempt = (state: InitState): boolean => state.actions.getRentalAnalytics || false;
const getRentalAnalyticsFailure = (state: InitState): string => state.error.getRentalAnalytics || '';
const rentalAnalytics = (state: InitState): IRentalAnalytics => state.allRentalAnalytics || {};

const getWalletAnalyticsAttempt = (state: InitState): boolean => state.actions.getWalletAnalytics || false;
const getWalletAnalyticsFailure = (state: InitState): string => state.error.getWalletAnalytics || '';
const walletAnalytics = (state: InitState): IWalletAnalytics[] => state.allWalletAnalytics || [];

const getPropertyIndividualAttempt = (state: InitState): boolean => state.actions.getPropertyIndividual || false;
const getPropertyPropertyIndividualFailure = (state: InitState): string => state.error.getPropertyIndividual || '';
const PropertyIndividual = (state: InitState): IPropertyIndividual => state.propertyIndividual || {};

const getPropertyImageDataAttempt = (state: InitState): boolean => state.actions.getPropertyImage || false;
const getPropertyImageDataFailure = (state: InitState): string => state.error.getPropertyImage || '';
const PropertyImageData = (state: InitState): IPropertyIndividual => state.propertyImageUpdatedData || {};

export default {
    getPropertyAttempt,
    getPropertyFailure,
    property,

    getPropertyPaginatorAttempt,
    getPropertyPaginatorFailure,
    propertyPaginator,

    displayedProperty,
    currentPropertyIndex,
    menuIsOpen,

    getAllPropertyDetailsAttempt,
    getAllPropertyDetailsFailure,
    allPropertyDetails,

    getRentalAnalyticsAttempt,
    getRentalAnalyticsFailure,
    rentalAnalytics,

    getWalletAnalyticsAttempt,
    getWalletAnalyticsFailure,
    walletAnalytics,

    getPropertyIndividualAttempt,
    getPropertyPropertyIndividualFailure,
    PropertyIndividual,

    getPropertyImageDataAttempt,
    getPropertyImageDataFailure,
    PropertyImageData,
};
