import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Translate from 'lib/Translate';
import { CardCollapsible, Button, Colors } from '@dm/bigfish';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { Oval } from 'react-loader-spinner';

import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesPoscode, CoordinatesState, CoordinatesCountry, CoordinatesCity, IPropertyLocation, IPropertyIndividual, IEditPropertyDetails } from 'entities/property';
import Icons from '../../../assets/icons';
import Map from './Map';

export interface LocationProps {
    loading: boolean;
    coordinates: Coordinates;
    address1st: CoordinatesAddress1;
    address2nd: CoordinatesAddress2;
    poscodes: CoordinatesPoscode;
    states: CoordinatesState;
    countryLocation: CoordinatesCountry;
    cityLocation: CoordinatesCity;
    propertyId: string;
    propertyInfo: IPropertyIndividual;
    termsSuccess: string;
    locationCollapsibleStatus: boolean;
    locationUpdate: boolean;
    PropertyDetailCollapsibleStatus: boolean;
    setPropertySetPropertyLocationSubmitted: boolean;
    getPropertyLocationCloseCollapsibleAfterSubmitted: string;
    setLocation(data: IPropertyLocation): void;
    setOpenLocationCollapsible: (state: boolean) => void;
    setOpenPropertyDetailsCollapsible: (state: boolean) => void;
    getPropertyIndividual: (id: string) => void;
    setPropertyLocationSubmitted: (state: boolean) => void;
    setLocationUpdate: (State: boolean) => void;
    setPropertyLocationCloseCollapsibleAfterSubmitted: (State: string) => void;
    editPropertyDetailsAttempt: boolean;
    editPropertyDetails: (data: IEditPropertyDetails) => void;
}
const Location = (props: LocationProps) : JSX.Element => {
    const { setLocation,
        coordinates,
        address1st,
        address2nd,
        poscodes,
        states,
        countryLocation,
        cityLocation,
        loading,
        propertyId,
        locationCollapsibleStatus,
        PropertyDetailCollapsibleStatus,
        getPropertyLocationCloseCollapsibleAfterSubmitted,
        propertyInfo,
        locationUpdate,
        setPropertySetPropertyLocationSubmitted,
        setOpenLocationCollapsible,
        setOpenPropertyDetailsCollapsible,
        getPropertyIndividual,
        setPropertyLocationSubmitted,
        setPropertyLocationCloseCollapsibleAfterSubmitted,
        setLocationUpdate,
        editPropertyDetailsAttempt,
        editPropertyDetails } = props;
    const [postcode, setPostcode] = useState('');
    const [edited, setEdited] = useState(false);
    const ownerRef = useRef<any>(null);

    const { latitude, longitude } = coordinates;

    const { address1 } = address1st;
    const { address2 } = address2nd;
    const { poscode } = poscodes;
    const { state } = states;
    const { country } = countryLocation;
    const { city } = cityLocation;
    useEffect(() => {
        setPropertyLocationSubmitted(false);
        setPropertyLocationCloseCollapsibleAfterSubmitted('');
    }, []);

    useEffect(() => {
        if (getPropertyLocationCloseCollapsibleAfterSubmitted === 'Close') {
            onCloseCollapsibleOwner();
            setPropertyLocationSubmitted(true);
        }
        if (getPropertyLocationCloseCollapsibleAfterSubmitted === 'Open') {
            onOpenCollapsibleOwner();
        }
    }, [getPropertyLocationCloseCollapsibleAfterSubmitted]);

    useEffect(() => {
        if (propertyId) {
            if ([propertyInfo].length && propertyInfo.location !== undefined) {
                setPropertyLocationSubmitted(true);
            }
        }

        if ((locationCollapsibleStatus) && locationCollapsibleStatus === true) {
            onOpenCollapsibleOwner();
        }
    }, [locationCollapsibleStatus, setPropertySetPropertyLocationSubmitted, propertyInfo]);

    const onOpenCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.openCollapsible();
        }
    };

    const onCloseCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.closeCollapsible();
        }
    };

    const onSubmitHandler = () => {
        setPostcode(poscode);
        const location = {
            long: longitude,
            lat: latitude,
            address1,
            address2,
            postcode,
            city,
            state,
            country,
        };
        editPropertyDetails({
            propertyId,
            location,
        });
        setOpenLocationCollapsible(false);
        setEdited(!edited);
        setLocationUpdate(!locationUpdate);
        setPropertyLocationCloseCollapsibleAfterSubmitted('Open');
    };

    return (
        <LoacationMainContainer>
            <CardCollapsible ref={ownerRef} preText={Translate.t('Property.Location.CollapsiblePretext')} title={Translate.t('Property.Location.CollapsibleTitle')} icon={setPropertySetPropertyLocationSubmitted ? Icons.VerifiedIcon : Icons.CautionCircle} focused={setPropertySetPropertyLocationSubmitted}>
                <MainContainer>
                    {editPropertyDetailsAttempt ? (
                        <Loading>
                            <Oval
                                height={250}
                                width={250}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </Loading>
                    ) : (
                        <div>
                            <p>{Translate.t('Property.Location.TitleMessage')}</p>
                            <Map setEdited={setEdited} edited={edited} />
                            <ButtonContainer>
                                <ConfirmPropertyButton loading={editPropertyDetailsAttempt} label={Translate.t('Property.PropertyCreate.PropertyConfirmButton')} onClick={onSubmitHandler} />
                            </ButtonContainer>
                        </div>
                    )}
                </MainContainer>

            </CardCollapsible>
        </LoacationMainContainer>

    );
};

const Loading = styled.div`
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const LoacationMainContainer = styled.div`
    margin-bottom: 20px;
`;

const ConfirmPropertyButton = styled(Button)`
    align-items: flex-start;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: static;
    width: 66px;
    height: 32px;
    left: 0px;
    border-radius: 8px;

    background-color: #F2B47E;
    color: white;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetPropertySetLocationAttempting(state),
    error: Selectors.getPropertyGetPropertySetLocationError(state),
    coordinates: Selectors.getPropertyGetCoordinates(state),
    address1st: Selectors.getPropertyGetAddress1(state),
    address2nd: Selectors.getPropertyGetAddress2(state),
    poscodes: Selectors.getPropertyGetPoscode(state),
    states: Selectors.getPropertyGetState(state),
    countryLocation: Selectors.getPropertyGetCountry(state),
    cityLocation: Selectors.getPropertyGetCity(state),
    termsSuccess: Selectors.getPropertyGetPropertySetTerms(state),
    locationCollapsibleStatus: Selectors.setIsPropertyOpenLocationCollapsible(state),
    propertyInfo: Selectors.getPropPropertyIndividual(state),
    PropertyDetailCollapsibleStatus: Selectors.setIsPropertyOpenDetailsCollapsible(state),
    setPropertySetPropertyLocationSubmitted: Selectors.setPropertySetPropertyLocationSubmitted(state),
    getPropertyLocationCloseCollapsibleAfterSubmitted: Selectors.getPropertyLocationCloseCollapsibleAfterSubmitted(state),
    editPropertyDetailsAttempt: Selectors.getPropertyEditPropertyDetailsAttempting(state),
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setLocation: (data: IPropertyLocation) =>
        dispatch(Actions.propertySetLocationAttempt(data)),
    setOpenPropertyDetailsCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenDetailsCollapsible(state)),
    setOpenLocationCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenLocationCollapsible(state)),
    getPropertyIndividual: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
    setPropertyLocationSubmitted: (state: boolean) =>
        dispatch(Actions.setPropertyLocationSubmitted(state)),
    setPropertyLocationCloseCollapsibleAfterSubmitted: (state: string) =>
        dispatch(Actions.setPropertyLocationCloseCollapsibleAfterSubmitted(state)),
    editPropertyDetails: (data: IEditPropertyDetails) => dispatch(Actions.editPropertyDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
