import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import validator from 'validator';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Translate from 'lib/Translate';

import { RootState, AppDispatch } from 'redux/store';

import NavActions from 'lib/NavActions';

import { RoundInput, Button, BreadCrumb, Checkbox } from '@dm/bigfish';

import media from 'lib/Breakpoints';
import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

interface LoginProps {
    loading: boolean;
    error: string;
    isLogin: string,
    login(email: string, password: string, rememberMe?: boolean): void;
}

const Login = (props: LoginProps): JSX.Element => {
    const { login, loading, error, isLogin } = props;
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<boolean | null>(null);
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Login.LoginBcLogin'),
                onClick: () => { NavActions.navToLogin(); },
            },
            {
                label: Translate.t('Login.LoginBcOwnerLogin'),
                onClick: () => { return false; },
            },
        ],
    );

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onChangeTextEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(!validator.isEmail(e.target.value));
    };

    const onChangeTextPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onSubmitLoginHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (!emailError) login(email, password, rememberMe);
    };

    const onSubmitLoginHandlerOnEnter = () => {
        if (!emailError) login(email, password, rememberMe);
    };

    useEffect(() => {
        if (isLogin) NavActions.navToHome();
    }, [isLogin]);

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Form onSubmit={onSubmitLoginHandler}>
                    <FormTitle>{Translate.t('Login.LoginOwnerLogin')}</FormTitle>
                    {' '}
                    <InputGroup label={Translate.t('Login.LoginEmail')} placeholder={Translate.t('Login.LoginPlaceholder')} value={email} onChangeText={onChangeTextEmail} onEnterPressed={onSubmitLoginHandlerOnEnter} inputError={emailError || false} errorText={Translate.t('Login.LoginErrorEmail')} required />
                    <InputGroup type='password' label={Translate.t('Login.LoginPassword')} placeholder={Translate.t('Login.LoginPlaceholderPassword')} value={password} onEnterPressed={onSubmitLoginHandlerOnEnter} onChangeText={onChangeTextPassword} required />
                    <Checkbox label={Translate.t('Login.LoginRememberMe')} checked={rememberMe} onClickCheckbox={() => setRememberMe(!rememberMe)} />
                    <Text style={{ marginTop: '16px' }}>
                        {Translate.t('Login.LoginHaveYou')}
                        {' '}
                        <TextLink onClick={() => { NavActions.navToRequestResetPassword(); }}>
                            {Translate.t('Login.LoginForgotPassword')}
                        </TextLink>
                    </Text>
                    <SubmitButtonContainer>
                        <SubmitButton loading={loading} label={Translate.t('Login.LoginLabel')} />
                    </SubmitButtonContainer>
                </Form>
                <Text style={{ marginTop: '20px', textAlign: 'center' }}>
                    {Translate.t('Login.LoginNewTo')}
                    {' '}
                    <TextLink onClick={() => { NavActions.navToRegister(); }}>
                        {Translate.t('Login.LoginJoinNow')}
                    </TextLink>
                </Text>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>

    );
};

const Form = styled.form`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;

    max-width: 554px;

    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    ${media.sm`
        margin-top: 40px;
        box-shadow: 0px 0px 0px;
    `}
`;

const FormTitle = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    margin-bottom: 28px;
`;

const InputGroup = styled(RoundInput)`
    margin-bottom: 16px;
`;

const Text = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    color: #696969;
`;

const TextLink = styled.a`
    font-size: inherit;
    line-height: inherit;
    color: #2F80ED;

    cursor: pointer;

    &:hover{
        color: #2f81edd1;
    }

    user-select: none;
`;

const SubmitButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;

    ${media.sm`
        justify-content: center;
    `}
`;
const SubmitButton = styled(Button)`
    display: flex;
    width: fit-content;
    padding-inline: 20px;
    margin-top: 20px;

    ${media.sm`
        width: 100%;
        justify-content: center;
    `}
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthLoginAttempting(state),
    error: Selectors.getAuthLoginError(state),
    isLogin: Selectors.getAuthAuthToken(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    login: (email: string, password: string, rememberMe?: boolean) =>
        dispatch(Actions.authLoginAttempt({ email, password, rememberMe })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
