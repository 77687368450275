import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IEditPropertyDetails, IPropertyAmenities, IPropertyIndividual } from 'entities/property';
import { CardCollapsible, Checkbox, Button } from '@dm/bigfish';
import Translate from 'lib/Translate';

import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Icons from '../../../assets/icons';

interface PropertyAmenitiesProps {
    loading: boolean;
    setAmenities(data: IPropertyAmenities): void;
    propertyId: string;
    amenitiesData: IPropertyIndividual;
    propertyImagesCollapsibleStatus: boolean;
    setOpenAmenitiesCollapsible: (state: boolean) => void;
    propertyAmenitiesCollapsibleStatus: boolean;
    setOpenImagesCollapsible: (state: boolean) => void;
    setPropertyAmenitiesSubmitted: (state: boolean) => void;
    editPropertyDetailsAttempt: boolean;
    editPropertyDetails: (data: IEditPropertyDetails) => void;
}

const ItemTitle = styled.h5`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  > li:not(:first-child){
    margin-top: 16px;
  } 
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
  row-gap: 8px;
  column-gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`;

const MainContainer = styled.form`
  width: 100%;
  padding: 20px;
`;

const PropertyMainContainer = styled.form`
  margin-bottom: 20px;
`;

const PropertyAmenities = (props: PropertyAmenitiesProps) : JSX.Element => {
    const {
        loading,
        setAmenities,
        propertyId,
        amenitiesData,
        propertyImagesCollapsibleStatus,
        setOpenAmenitiesCollapsible,
        propertyAmenitiesCollapsibleStatus,
        setOpenImagesCollapsible,
        setPropertyAmenitiesSubmitted,
        editPropertyDetailsAttempt,
        editPropertyDetails,
    } = props;

    const [television, setTelevision] = useState(false);
    const [curtain, setCurtain] = useState(false);
    const [mattress, setMattress] = useState(false);
    const [washingMachine, setWashingMachine] = useState(false);
    const [hoodHub, setHoodHub] = useState(false);
    const [airConditioner, setAirConditioner] = useState(false);
    const [waterHeater, setWaterHeater] = useState(false);
    const [diningTable, setDiningTable] = useState(false);
    const [wardrobe, setWardrobe] = useState(false);
    const [kitchenCabinet, setKitchenCabinet] = useState(false);
    const [oven, setOven] = useState(false);
    const [refrigerator, setRefrigerator] = useState(false);
    const [sofa, setSofa] = useState(false);
    const [microwave, setMicrowave] = useState(false);
    const [bedFrame, setBedFrame] = useState(false);
    const [internet, setInternet] = useState(false);
    const [ownerStatus, setOwnerStatus] = useState(false);
    const ownerRef = useRef<any>(null);

    const [bBQ, setBBQ] = useState(false);
    const [coveredParking, setCoveredParking] = useState(false);
    const [gymnasium, setGymnasium] = useState(false);
    const [basketballCourt, setBasketballCourt] = useState(false);
    const [restaurant, setRestaurant] = useState(false);
    const [airConditioner2, setAirConditioner2] = useState(false);
    const [dobiLaundromat, setDobiLaundromat] = useState(false);
    const [nursery, setNursery] = useState(false);
    const [playground, setPlayground] = useState(false);
    const [sauna, setSauna] = useState(false);
    const [swimmingPool, setSwimmingPool] = useState(false);
    const [tennisCourt, setTennisCourt] = useState(false);
    const [security24hour, setSecurity24hour] = useState(false);
    const [miniMart, setMiniMart] = useState(false);
    const [squashCourt, setSquashCourt] = useState(false);
    const [badmintonCourt, setBadmintonCourt] = useState(false);
    const [elevator, setElevator] = useState(false);

    const onCloseCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.closeCollapsible();
        }
    };

    useEffect(() => {
        setPropertyAmenitiesSubmitted(false);

        if (propertyId) {
            if (([amenitiesData.amenities?.facilities].length || [amenitiesData.amenities?.furnishing].length) && amenitiesData.amenities !== undefined) {
                setTelevision(amenitiesData.amenities?.furnishing[0]?.available ?? false);
                setCurtain(amenitiesData.amenities?.furnishing[1]?.available ?? false);
                setMattress(amenitiesData.amenities?.furnishing[2]?.available ?? false);
                setWashingMachine(amenitiesData.amenities?.furnishing[3]?.available ?? false);
                setHoodHub(amenitiesData.amenities?.furnishing[4]?.available ?? false);
                setAirConditioner(amenitiesData.amenities?.furnishing[5]?.available ?? false);
                setWaterHeater(amenitiesData.amenities?.furnishing[6]?.available ?? false);
                setDiningTable(amenitiesData.amenities?.furnishing[7]?.available ?? false);
                setWardrobe(amenitiesData.amenities?.furnishing[8]?.available ?? false);
                setKitchenCabinet(amenitiesData.amenities?.furnishing[9]?.available ?? false);
                setOven(amenitiesData.amenities?.furnishing[10]?.available ?? false);
                setRefrigerator(amenitiesData.amenities?.furnishing[11]?.available ?? false);
                setSofa(amenitiesData.amenities?.furnishing[12]?.available ?? false);
                setMicrowave(amenitiesData.amenities?.furnishing[13]?.available ?? false);
                setBedFrame(amenitiesData.amenities?.furnishing[14]?.available ?? false);
                setInternet(amenitiesData.amenities?.furnishing[15]?.available ?? false);

                setBBQ(amenitiesData.amenities?.facilities[0]?.available ?? false);
                setCoveredParking(amenitiesData.amenities?.facilities[1]?.available ?? false);
                setGymnasium(amenitiesData.amenities?.facilities[2]?.available ?? false);
                setBasketballCourt(amenitiesData.amenities?.facilities[3]?.available ?? false);
                setRestaurant(amenitiesData.amenities?.facilities[4]?.available ?? false);
                setAirConditioner2(amenitiesData.amenities?.facilities[5]?.available ?? false);
                setDobiLaundromat(amenitiesData.amenities?.facilities[6]?.available ?? false);
                setNursery(amenitiesData.amenities?.facilities[7]?.available ?? false);
                setPlayground(amenitiesData.amenities?.facilities[8]?.available ?? false);
                setSauna(amenitiesData.amenities?.facilities[9]?.available ?? false);
                setSwimmingPool(amenitiesData.amenities?.facilities[10]?.available ?? false);
                setTennisCourt(amenitiesData.amenities?.facilities[11]?.available ?? false);
                setSecurity24hour(amenitiesData.amenities?.facilities[12]?.available ?? false);
                setMiniMart(amenitiesData.amenities?.facilities[13]?.available ?? false);
                setSquashCourt(amenitiesData.amenities?.facilities[14]?.available ?? false);
                setBadmintonCourt(amenitiesData.amenities?.facilities[15]?.available ?? false);
                setElevator(amenitiesData.amenities?.facilities[16]?.available ?? false);
            }

            if (amenitiesData.amenities) {
                if (amenitiesData.amenities.facilities) {
                    for (let i = 0; i < amenitiesData.amenities.facilities.length; i += 1) {
                        if (amenitiesData.amenities.facilities[i].available) {
                            setOwnerStatus(true);
                            onCloseCollapsibleOwner();
                        }
                    }
                }

                if (amenitiesData.amenities.furnishing) {
                    for (let i = 0; i < amenitiesData.amenities.furnishing.length; i += 1) {
                        if (amenitiesData.amenities.furnishing[i].available) {
                            setOwnerStatus(true);
                            onCloseCollapsibleOwner();
                        }
                    }
                }
            }
            setPropertyAmenitiesSubmitted(true);
        }
    }, [amenitiesData.amenities]);

    useEffect(() => {
        if ((propertyAmenitiesCollapsibleStatus) && propertyAmenitiesCollapsibleStatus === true) {
            onOpenCollapsibleOwner();
        }
    }, [propertyAmenitiesCollapsibleStatus]);

    const onOpenCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.openCollapsible();
        }
    };

    const onSubmitConfirmHandler = () => {
        const data = {
            amenities: {
                furnishing: [{
                    name: 'television ',
                    available: television,
                },
                {
                    name: 'curtain',
                    available: curtain,
                },
                {
                    name: 'mattress',
                    available: mattress,
                },
                {
                    name: 'washingMachine',
                    available: washingMachine,
                },
                {
                    name: 'hoodHub',
                    available: hoodHub,
                },
                {
                    name: 'airConditioner',
                    available: airConditioner,
                },
                {
                    name: 'waterHeater',
                    available: waterHeater,
                },
                {
                    name: 'diningTable',
                    available: diningTable,
                },
                {
                    name: 'wardrobe',
                    available: wardrobe,
                },
                {
                    name: 'kitchenCabinet',
                    available: kitchenCabinet,
                },
                {
                    name: 'oven',
                    available: oven,
                },
                {
                    name: 'refrigerator',
                    available: refrigerator,
                },
                {
                    name: 'sofa',
                    available: sofa,
                },
                {
                    name: 'microwave',
                    available: microwave,
                },
                {
                    name: 'bedFrame',
                    available: bedFrame,
                },
                {
                    name: 'internet',
                    available: internet,
                }],
                facilities: [{
                    name: 'bbq',
                    available: bBQ,
                },
                {
                    name: 'coveredParking',
                    available: coveredParking,
                },
                {
                    name: 'gymnasium',
                    available: gymnasium,
                },
                {
                    name: 'basketballCourt',
                    available: basketballCourt,
                },
                {
                    name: 'restaurant',
                    available: restaurant,
                },
                {
                    name: 'airConditioner2',
                    available: airConditioner2,
                },
                {
                    name: 'dobiLaundromat',
                    available: dobiLaundromat,
                },
                {
                    name: 'nursery',
                    available: nursery,
                },
                {
                    name: 'playground',
                    available: playground,
                },
                {
                    name: 'sauna',
                    available: sauna,
                },
                {
                    name: 'swimmingPool',
                    available: swimmingPool,
                },
                {
                    name: 'tennisCourt',
                    available: tennisCourt,
                },
                {
                    name: 'security24hour',
                    available: security24hour,
                },
                {
                    name: 'miniMart',
                    available: miniMart,
                },
                {
                    name: 'squashCourt',
                    available: squashCourt,
                },
                {
                    name: 'badmintonCourt',
                    available: badmintonCourt,
                },
                {
                    name: 'elevator',
                    available: elevator,
                }],
            },

        };

        editPropertyDetails({
            propertyId,
            amenities: data.amenities,
        });
        setOwnerStatus(true);
        onCloseCollapsibleOwner();

        setOpenAmenitiesCollapsible(false);
        setOpenImagesCollapsible(!propertyImagesCollapsibleStatus);
    };

    const onSubmitCancelHandler = () => {
        setTelevision(false);
        setCurtain(false);
        setMattress(false);
        setWashingMachine(false);
        setHoodHub(false);
        setAirConditioner(false);
        setWaterHeater(false);
        setDiningTable(false);
        setWardrobe(false);
        setKitchenCabinet(false);
        setOven(false);
        setRefrigerator(false);
        setSofa(false);
        setMicrowave(false);
        setBedFrame(false);
        setInternet(false);
        setBBQ(false);
        setCoveredParking(false);
        setGymnasium(false);
        setBasketballCourt(false);
        setRestaurant(false);
        setAirConditioner2(false);
        setDobiLaundromat(false);
        setNursery(false);
        setPlayground(false);
        setSauna(false);
        setSwimmingPool(false);
        setTennisCourt(false);
        setSecurity24hour(false);
        setMiniMart(false);
        setSquashCourt(false);
        setBadmintonCourt(false);
        setElevator(false);

        setPropertyAmenitiesSubmitted(false);
    };

    return (
        <PropertyMainContainer>
            <CardCollapsible ref={ownerRef} preText={Translate.t('Property.PropertyAmenities.CollapsiblePretext')} title={Translate.t('Property.PropertyAmenities.CollapsibleTitle')} icon={ownerStatus ? Icons.VerifiedIcon : Icons.CautionCircle} focused={ownerStatus}>
                <MainContainer>
                    <>
                        <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFurnishing')}</ItemTitle>
                        <DetailsContainer>
                            <Checkbox label={Translate.t('Property.PropertyAmenities.TelevisionLabel')} checked={television} onClickCheckbox={() => setTelevision(!television)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.CurtainLabel')} checked={curtain} onClickCheckbox={() => setCurtain(!curtain)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.MattressLabel')} checked={mattress} onClickCheckbox={() => setMattress(!mattress)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.WashingMachineLabel')} checked={washingMachine} onClickCheckbox={() => setWashingMachine(!washingMachine)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.HoodHubLabel')} checked={hoodHub} onClickCheckbox={() => setHoodHub(!hoodHub)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner} onClickCheckbox={() => setAirConditioner(!airConditioner)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.WaterHeaterLabel')} checked={waterHeater} onClickCheckbox={() => setWaterHeater(!waterHeater)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.DiningTableLabel')} checked={diningTable} onClickCheckbox={() => setDiningTable(!diningTable)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.WardrobeLabel')} checked={wardrobe} onClickCheckbox={() => setWardrobe(!wardrobe)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.KitchenCabinetLabel')} checked={kitchenCabinet} onClickCheckbox={() => setKitchenCabinet(!kitchenCabinet)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.OvenLabel')} checked={oven} onClickCheckbox={() => setOven(!oven)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.RefrigeratorLabel')} checked={refrigerator} onClickCheckbox={() => setRefrigerator(!refrigerator)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.SofaLabel')} checked={sofa} onClickCheckbox={() => setSofa(!sofa)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.MicrowaveLabel')} checked={microwave} onClickCheckbox={() => setMicrowave(!microwave)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.BedframeLabel')} checked={bedFrame} onClickCheckbox={() => setBedFrame(!bedFrame)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.InternetLabel')} checked={internet} onClickCheckbox={() => setInternet(!internet)} />
                        </DetailsContainer>
                        <ItemTitle>{Translate.t('Property.PropertyAmenities.ItemTitleFacilities')}</ItemTitle>
                        <DetailsContainer>
                            <Checkbox label='BBQ' checked={bBQ} onClickCheckbox={() => setBBQ(!bBQ)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.CoveredParkingLabel')} checked={coveredParking} onClickCheckbox={() => setCoveredParking(!coveredParking)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.GymnasiumLabel')} checked={gymnasium} onClickCheckbox={() => setGymnasium(!gymnasium)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.BasketballCourtLabel')} checked={basketballCourt} onClickCheckbox={() => setBasketballCourt(!basketballCourt)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.RestaurantLabel')} checked={restaurant} onClickCheckbox={() => setRestaurant(!restaurant)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.AirConditionerLabel')} checked={airConditioner2} onClickCheckbox={() => setAirConditioner2(!airConditioner2)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.DobiLabel')} checked={dobiLaundromat} onClickCheckbox={() => setDobiLaundromat(!dobiLaundromat)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.NurseryLabel')} checked={nursery} onClickCheckbox={() => setNursery(!nursery)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.PlaygroundLabel')} checked={playground} onClickCheckbox={() => setPlayground(!playground)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.SaunaLabel')} checked={sauna} onClickCheckbox={() => setSauna(!sauna)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.SwimmingPoolLabel')} checked={swimmingPool} onClickCheckbox={() => setSwimmingPool(!swimmingPool)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.TennisCourtLabel')} checked={tennisCourt} onClickCheckbox={() => setTennisCourt(!tennisCourt)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.SecurityLabel')} checked={security24hour} onClickCheckbox={() => setSecurity24hour(!security24hour)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.MiniMartLabel')} checked={miniMart} onClickCheckbox={() => setMiniMart(!miniMart)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.SquashCourtLabel')} checked={squashCourt} onClickCheckbox={() => setSquashCourt(!squashCourt)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.BadmintonCourtLabel')} checked={badmintonCourt} onClickCheckbox={() => setBadmintonCourt(!badmintonCourt)} />
                            <Checkbox label={Translate.t('Property.PropertyAmenities.ElevatorLabel')} checked={elevator} onClickCheckbox={() => setElevator(!elevator)} />
                        </DetailsContainer>
                    </>

                    <ButtonContainer>
                        <ConfirmPropertyButton
                            type='button'
                            onClick={onSubmitConfirmHandler}
                            loading={editPropertyDetailsAttempt}
                            label={Translate.t('Property.PropertyConfirmButton')}
                        />
                        <ClearPropertyButton
                            type='button'
                            onClick={onSubmitCancelHandler}
                        >
                            {Translate.t('Property.PropertyClearButton')}
                        </ClearPropertyButton>
                    </ButtonContainer>

                </MainContainer>

            </CardCollapsible>
        </PropertyMainContainer>

    );
};
const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetPropertyAmenitiesAttempting(state),
    error: Selectors.getPropertyGetPropertyAmenitiesError(state),
    propertyDetailsStatus: Selectors.getPropertyGetPropertyDetailsSuccess(state),
    propertyImagesCollapsibleStatus: Selectors.setIsPropertyOpenImagesCollapsible(state),
    propertyAmenitiesCollapsibleStatus: Selectors.setIsPropertyOpenAmenitiesCollapsible(state),
    amenitiesData: Selectors.getPropPropertyIndividual(state),
    editPropertyDetailsAttempt: Selectors.getPropertyEditPropertyDetailsAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setAmenities: (data: IPropertyAmenities) =>
        dispatch(Actions.propertySetPropertyAmenitiesAttempt(data)),
    setCollapsibleStatus: (state: boolean) =>
        dispatch(Actions.propertyDetailsStatus(state)),
    setOpenAmenitiesCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenAmenitiesCollapsible(state)),
    setOpenImagesCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenImagesCollapsible(state)),
    setPropertyAmenitiesSubmitted: (state: boolean) =>
        dispatch(Actions.setPropertyAmenitiesSubmitted(state)),
    editPropertyDetails: (data: IEditPropertyDetails) => dispatch(Actions.editPropertyDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAmenities);

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ConfirmPropertyButton = styled(Button)`
    align-items: flex-start;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: static;
    width: 66px; 
    height: 32px;
    left: 0px;
    border-radius: 8px;
    background-color: #F2B47E;
    color: white;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
`;

const ClearPropertyButton = styled.button`
    align-items: flex-start;
    padding: 8px;
    position: static;
    width: 50px; 
    height: 32px;
    left: 10px;
    border-radius: 8px;
    margin-right: 10px;
    background-color: #ffffff;
    color: black;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
`;
