import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import { SetPropertyWalletActionPayload } from 'redux/slices/property/types';
import Utils from 'lib/Utils';

export default function* watchGetPropertyWalletDetails(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertyPropertyWalletAttempt', handleGetPropertyWalletDetails, api);
}

function* handleGetPropertyWalletDetails(api: PropertyGateway, data: SetPropertyWalletActionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId } = data.payload;

        const response = yield* call([api, api.getPropertyWalletDetails], {
            propertyId,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
            // perform operation here
            if (!response.data) {
                yield put(Actions.propertyPropertyWalletFailure('Something went wrong. Please try again.'));
                return;
            }
            yield put(Actions.propertyPropertyWalletSuccess(response.data));
        } else {
            // handle error
            yield put(Actions.propertyPropertyWalletFailure(response.message));
        }
    }
}
