import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDefectInspection, ILeaseSale, IPackagePrice, IPropertyPackagePurchaseTermsEnum, IPropertyPackageStatusEnum, IVacantPossession, PropertyManagementTransactionResponse, PaymentGatewayResponse, IPropertyManagement, IVacantManagement, ViewTransactionResponse, IPropertyDefectInspectionStage } from '@dm/types';
import { GetPaymentInfoRTO, IGoogleDriveContent, PackagesiD } from 'entities/packages';
import { PropReduxState, PackagesPricingPayload, SetIPackagesPayment, SetIPackagesSortPayload, UploadPackagesDocumentActionPayload, GetPackagesUploadUrlActionPayload, GetSignDocumentTemplateActionPayload, GetPackagesPaymentCreditCardActionPayload, SetCardPaymentStatusUpdatePayload, SetIPackagesPropertyManagementSortPayload, UpdatePropertyManagementTransactionStatusActionPayload, SetITopUpPayment, GetSubscriptionTransactionPayload, setSubscriptionTransactionCancelPayload, setSubscriptionTransactionRenewPayload, GetGoogleDriveContentActionPayload, CreatePMTransactionActionPayload } from './types';

const initialState: PropReduxState = {
    actions: {
        getPackagesPricing: false,
        setPackagesPayments: false,
        getPackagesUploadUrl: false,
        uploadPackagesDocument: false,
        getpackagesDataSort: false,
        getpackagesLeaseAndSaleDataSort: false,
        getpackagesDefectInspectionDataSort: false,
        getSignDocumentTemplate: false,
        getPackagesSignedUploadUrl: false,
        uploadSignedPackagesDocument: false,
        getPackagesPaymentCreditCard: false,
        getpackagesPropertyManagementDataSort: false,
        setPropertyManagementTransaction: false,
        setPropertyManagementTransactionGateway: false,
        setTopUpPayments: false,
        getPackagesDataPropertyManagementSort: false,
        getPackagesIvacantManagementDataSort: false,
        getSubscriptionTransactions: false,
        setSubscriptionTransactionsCancel: false,
        setSubscriptionTransactionsRenew: false,
        getGoogleDriveContent: false,
        createPMTransaction: false,
    },
    PackagesPricing: {
        vacantPossession: 0,
        defectInspection: 0,
        designRenovation: 0,
        propertyManagement: 0,
        leaseSale: 0,
        vacantManagement: 0,
    },

    packagesId: {
        id: '',
    },

    IPackagesPayment: {
        paymentId: '',
        clientSecret: '',
    },

    IPackagesVacantPossessionSort: {
        status: IPropertyPackageStatusEnum.Pending,
        term: IPropertyPackagePurchaseTermsEnum.OneYear,
        recentEvents: [],
        price: 0,
        vacantPossessionDocuments: [],
        googleDrivePath: '',
        targetPathRootFolder: '',
    },

    IPackagesDefectInspectionDataDetailsSort: {
        status: IPropertyPackageStatusEnum.Pending,
        term: IPropertyPackagePurchaseTermsEnum.OneYear,
        recentEvents: [],
        price: 0,
        defectInspectionDocuments: [],
        googleDrivePath: '',
        targetPathRootFolder: '',
        progressLogs: [],
        stage: IPropertyDefectInspectionStage.PurchasedPackage,
    },

    IPackagesLeaseSaleDataDetailsSort: {
        status: IPropertyPackageStatusEnum.Pending,
        term: IPropertyPackagePurchaseTermsEnum.OneYear,
        recentEvents: [],
        price: 0,
        leaseSaleDocuments: [],
    },

    paymentDocumentModal: false,
    confirmPaymentMethodModal: false,
    documentUploadStatus: false,
    vacantPossesionModal: false,
    packagesDocumentVacantPossessionView: false,
    leaseSaleModal: false,
    buyAll: false,
    allCancel: false,
    uploadedDocument: false,
    PackagesDocumentUploadURL: '',
    documentUploadId: '',

    packagesDocumentTemplate: '',
    isPackageSigned: false,
    signCompleted: false,
    PackagesSignedDocumentUploadURL: '',
    packagesEmailModal: false,
    PackagesPaymentCreditCardData: null,
    IPackagesPropertyManagementDataDetailsSort: null,
    PropertyManagementTransactionUpdatedData: '',
    PropertyManagementTransactionUpdatedDataGateway: null,
    packagesPropertyManagementStatusUpdateModal: false,
    ITopUpPayment: null,
    TopUpPaymentModal: false,
    IPackagesPropertyManagementDataSortSuccess: null,
    IPackagesIvacantManagementDataDetailsSort: null,
    IPackagesSubscriptionTransactionData: null,
    IPackagesSubscriptionTransactionCancelData: '',
    IPackagesSubscriptionTransactionRenewData: '',
    TransactionApproveOrRenewLoaderModal: false,
    googleDriveContent: [],
    transactionId: '',
    error: {
        getPackagesPricing: '',
        setPackagesPayments: '',
        getPackagesUploadUrl: '',
        uploadPackagesDocument: '',
        getpackagesDataSort: '',
        getpackagesLeaseAndSaleDataSort: '',
        getpackagesDefectInspectionDataSort: '',
        getSignDocumentTemplate: '',
        getPackagesSignedUploadUrl: '',
        uploadSignedPackagesDocument: '',
        getPackagesPaymentCreditCard: '',
        getpackagesPropertyManagementDataSort: '',
        setPropertyManagementTransaction: '',
        setPropertyManagementTransactionGateway: '',
        setTopUpPayments: '',
        getPackagesDataPropertyManagementSort: '',
        getPackagesIvacantManagementDataSort: '',
        getSubscriptionTransactions: '',
        setSubscriptionTransactionsCancel: '',
        setSubscriptionTransactionsRenew: '',
        getGoogleDriveContent: '',
        createPMTransaction: '',
    },
};

const packagesSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {
        packagesGetPricingAttempt: (state, _action: PackagesPricingPayload) => {
            state.actions.getPackagesPricing = true;
            state.error.getPackagesPricing = '';
        },
        packagesGetPricingSuccess: (state, action: PayloadAction<IPackagePrice>) => {
            state.actions.getPackagesPricing = false;
            state.PackagesPricing = action.payload;
        },
        packagesGetPricingFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesPricing = false;
            if (action.payload) {
                state.error.getPackagesPricing = action.payload;
            }
        },

        packagesSetId: (state, action: PayloadAction<PackagesiD>) => {
            state.packagesId = action.payload;
        },

        packagesSetPackagesPaymentAttempt: (state, _action: SetIPackagesPayment) => {
            state.actions.setPackagesPayments = true;
            state.error.setPackagesPayments = '';
        },
        packagesSetPackagesPaymentSuccess: (state, action: PayloadAction<PaymentGatewayResponse>) => {
            state.actions.setPackagesPayments = false;
            state.IPackagesPayment = action.payload;
        },
        packagesSetPackagesPaymentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPackagesPayments = false;
            if (action.payload) {
                state.error.setPackagesPayments = action.payload;
            }
        },

        setPaymentDocumentModalOpen: (state, action: PayloadAction<boolean>) => {
            state.paymentDocumentModal = action.payload;
        },
        setConfirmPaymentMethodModalOpen: (state, action: PayloadAction<boolean>) => {
            state.confirmPaymentMethodModal = action.payload;
        },

        getPackagesUploadUrlAttempt: (state, _action: GetPackagesUploadUrlActionPayload) => {
            state.actions.getPackagesUploadUrl = true;
            state.error.getPackagesUploadUrl = '';
        },
        getPackagesUploadUrlSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getPackagesUploadUrl = false;
            state.error.getPackagesUploadUrl = '';

            state.PackagesDocumentUploadURL = action.payload;
        },
        getPackagesUploadUrlFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesUploadUrl = false;
            if (action.payload) {
                state.error.getPackagesUploadUrl = action.payload;
            }
        },

        uploadPackagesDocumentAttempt: (state, _action: UploadPackagesDocumentActionPayload) => {
            state.actions.uploadPackagesDocument = true;
            state.error.uploadPackagesDocument = '';
        },
        uploadPackagesDocumentSuccess: (state) => {
            state.actions.uploadPackagesDocument = false;
            state.error.uploadPackagesDocument = '';
        },

        uploadPackagesDocumentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadPackagesDocument = false;
            if (action.payload) {
                state.error.uploadPackagesDocument = action.payload;
            }
        },

        setDocumentUploadStatus: (state, action: PayloadAction<boolean>) => {
            state.documentUploadStatus = action.payload;
        },

        setCreateDocumentUploadID: (state, action: PayloadAction<string>) => {
            state.documentUploadId = action.payload;
        },

        setVacantPossesionModal: (state, action: PayloadAction<boolean>) => {
            state.vacantPossesionModal = action.payload;
        },

        setPackagesDocumentVacantPossessionView: (state, action: PayloadAction<boolean>) => {
            state.packagesDocumentVacantPossessionView = action.payload;
        },

        packagesGetIVacantPossessionDataSortAttempt: (state, _action: SetIPackagesSortPayload) => {
            state.actions.getpackagesDataSort = true;
            state.error.getpackagesDataSort = '';
        },
        packagesGetIVacantPossessionDataSortSuccess: (state, action: PayloadAction<IVacantPossession >) => {
            state.actions.getpackagesDataSort = false;
            state.IPackagesVacantPossessionSort = action.payload;
        },
        packagesGetIVacantPossessionDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesDataSort = false;
            if (action.payload) {
                state.error.getpackagesDataSort = action.payload;
            }
        },

        setLeaseSaleModal: (state, action: PayloadAction<boolean>) => {
            state.leaseSaleModal = action.payload;
        },

        packagesGetILeaseAndSaleDataSortAttempt: (state, _action: SetIPackagesSortPayload) => {
            state.actions.getpackagesLeaseAndSaleDataSort = true;
            state.error.getpackagesLeaseAndSaleDataSort = '';
        },
        packagesGetILeaseAndSaleDataSortSuccess: (state, action: PayloadAction<ILeaseSale>) => {
            state.actions.getpackagesLeaseAndSaleDataSort = false;
            state.IPackagesLeaseSaleDataDetailsSort = action.payload;
        },
        packagesGetILeaseAndSaleDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesLeaseAndSaleDataSort = false;
            if (action.payload) {
                state.error.getpackagesLeaseAndSaleDataSort = action.payload;
            }
        },

        setPackagesBuyAll: (state, action: PayloadAction<boolean>) => {
            state.buyAll = action.payload;
        },

        setPackagesAllCancel: (state, action: PayloadAction<boolean>) => {
            state.allCancel = action.payload;
        },

        packagesGetDefectInspectionDataSortAttempt: (state, _action: SetIPackagesSortPayload) => {
            state.actions.getpackagesDefectInspectionDataSort = true;
            state.error.getpackagesDefectInspectionDataSort = '';
        },
        packagesGetDefectInspectionDataSortSuccess: (state, action: PayloadAction<IDefectInspection>) => {
            state.actions.getpackagesDefectInspectionDataSort = false;
            state.IPackagesDefectInspectionDataDetailsSort = action.payload;
        },
        packagesGetDefectInspectionDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesDefectInspectionDataSort = false;
            if (action.payload) {
                state.error.getpackagesDefectInspectionDataSort = action.payload;
            }
        },

        getPackagesDownloadDocumentTemplateAttempt: (state, _action: GetSignDocumentTemplateActionPayload) => {
            state.actions.getSignDocumentTemplate = true;
            state.error.getSignDocumentTemplate = '';
        },
        getPackagesDownloadDocumentTemplateSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getSignDocumentTemplate = false;
            state.error.getSignDocumentTemplate = '';

            state.packagesDocumentTemplate = action.payload;
        },
        getPackagesDownloadDocumentTemplateFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSignDocumentTemplate = false;
            if (action.payload) {
                state.error.getSignDocumentTemplate = action.payload;
            }
        },

        setIsPackagesSigned: (state, action: PayloadAction<boolean>) => {
            state.isPackageSigned = action.payload;
        },

        setSignCompleted: (state, action: PayloadAction<boolean>) => {
            state.signCompleted = action.payload;
        },

        getPackagesSignedUploadUrlAttempt: (state, _action: GetPackagesUploadUrlActionPayload) => {
            state.actions.getPackagesSignedUploadUrl = true;
            state.error.getPackagesSignedUploadUrl = '';
        },
        getPackagesSignedUploadUrlSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getPackagesSignedUploadUrl = false;
            state.error.getPackagesSignedUploadUrl = '';

            state.PackagesSignedDocumentUploadURL = action.payload;
        },
        getPackagesSignedUploadUrlFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesSignedUploadUrl = false;
            if (action.payload) {
                state.error.getPackagesSignedUploadUrl = action.payload;
            }
        },

        uploadSignedPackagesDocumentAttempt: (state, _action: UploadPackagesDocumentActionPayload) => {
            state.actions.uploadSignedPackagesDocument = true;
            state.error.uploadSignedPackagesDocument = '';
        },
        uploadSignedPackagesDocumentSuccess: (state) => {
            state.actions.uploadSignedPackagesDocument = false;
            state.error.uploadSignedPackagesDocument = '';
        },

        uploadSignedPackagesDocumentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadSignedPackagesDocument = false;
            if (action.payload) {
                state.error.uploadSignedPackagesDocument = action.payload;
            }
        },
        setPackagesEmailModalOpen: (state, action: PayloadAction<boolean>) => {
            state.packagesEmailModal = action.payload;
        },

        setPackagesUploadedDocuments: (state, action: PayloadAction<boolean>) => {
            state.uploadedDocument = action.payload;
        },

        getPackagesPaymentCreditCardAttempt: (state, _action: GetPackagesPaymentCreditCardActionPayload) => {
            state.actions.getPackagesPaymentCreditCard = true;
            state.error.getPackagesPaymentCreditCard = '';
        },
        getPackagesPaymentCreditCardSuccess: (state, action: PayloadAction<GetPaymentInfoRTO | null>) => {
            state.actions.getPackagesPaymentCreditCard = false;
            state.error.getPackagesPaymentCreditCard = '';

            state.PackagesPaymentCreditCardData = action.payload;
        },
        getPackagesPaymentCreditCardFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesPaymentCreditCard = false;
            if (action.payload) {
                state.error.getPackagesPaymentCreditCard = action.payload;
            }
        },

        packagesGetIPropertyManagementDataSortAttempt: (state, _action: SetIPackagesPropertyManagementSortPayload) => {
            state.actions.getpackagesPropertyManagementDataSort = true;
            state.error.getpackagesPropertyManagementDataSort = '';
        },
        packagesGetIPropertyManagementDataSortSuccess: (state, action: PayloadAction<PropertyManagementTransactionResponse[] | null>) => {
            state.actions.getpackagesPropertyManagementDataSort = false;
            state.IPackagesPropertyManagementDataDetailsSort = action.payload;
        },
        packagesGetIPropertyManagementDataSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getpackagesPropertyManagementDataSort = false;
            if (action.payload) {
                state.error.getpackagesPropertyManagementDataSort = action.payload;
            }
        },

        packagesSetPropertyManagementPackageTransactionAttempt: (state, _action: UpdatePropertyManagementTransactionStatusActionPayload) => {
            state.actions.setPropertyManagementTransaction = true;
            state.error.setPropertyManagementTransaction = '';
        },
        packagesSetPropertyManagementPackageTransactionSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setPropertyManagementTransaction = false;
            state.PropertyManagementTransactionUpdatedData = action.payload;
        },
        packagesSetPropertyManagementPackageTransactionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPropertyManagementTransaction = false;
            if (action.payload) {
                state.error.setPropertyManagementTransaction = action.payload;
            }
        },

        packagesSetPropertyManagementPackageTransactionGatewayAttempt: (state, _action: UpdatePropertyManagementTransactionStatusActionPayload) => {
            state.actions.setPropertyManagementTransactionGateway = true;
            state.error.setPropertyManagementTransactionGateway = '';
        },
        packagesSetPropertyManagementPackageTransactionSuccessGateway: (state, action: PayloadAction<PaymentGatewayResponse | null>) => {
            state.actions.setPropertyManagementTransactionGateway = false;
            state.PropertyManagementTransactionUpdatedDataGateway = action.payload;
        },
        packagesSetPropertyManagementPackageTransactionGatewayFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPropertyManagementTransactionGateway = false;
            if (action.payload) {
                state.error.setPropertyManagementTransactionGateway = action.payload;
            }
        },

        setPackagesPropertyManagementStatusUpdateModalOpen: (state, action: PayloadAction<boolean>) => {
            state.packagesPropertyManagementStatusUpdateModal = action.payload;
        },

        packagesSetTopUpPaymentAttempt: (state, _action: SetITopUpPayment) => {
            state.actions.setTopUpPayments = true;
            state.error.setTopUpPayments = '';
        },
        packagesSetTopUpPaymentSuccess: (state, action: PayloadAction<PaymentGatewayResponse | null>) => {
            state.actions.setTopUpPayments = false;
            state.ITopUpPayment = action.payload;
        },
        packagesSetTopUpPaymentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setTopUpPayments = false;
            if (action.payload) {
                state.error.setTopUpPayments = action.payload;
            }
        },

        setTopUpPaymentModalOpen: (state, action: PayloadAction<boolean>) => {
            state.TopUpPaymentModal = action.payload;
        },

        packagesGetIPropertyManagementDataSortUploadedDocumentAttempt: (state, _action: SetIPackagesSortPayload) => {
            state.actions.getPackagesDataPropertyManagementSort = true;
            state.error.getPackagesDataPropertyManagementSort = '';
        },
        packagesGetIPropertyManagementDataSortUploadedDocumentSuccess: (state, action: PayloadAction<IPropertyManagement | null >) => {
            state.actions.getPackagesDataPropertyManagementSort = false;
            state.IPackagesPropertyManagementDataSortSuccess = action.payload;
        },
        packagesGetIPropertyManagementDataSortUploadedDcoumentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesDataPropertyManagementSort = false;
            if (action.payload) {
                state.error.getPackagesDataPropertyManagementSort = action.payload;
            }
        },

        packagesGetIVacantManagementSortAttempt: (state, _action: SetIPackagesSortPayload) => {
            state.actions.getPackagesIvacantManagementDataSort = true;
            state.error.getPackagesIvacantManagementDataSort = '';
        },
        packagesGetIVacantManagementSortSuccess: (state, action: PayloadAction<IVacantManagement | null>) => {
            state.actions.getPackagesIvacantManagementDataSort = false;
            state.IPackagesIvacantManagementDataDetailsSort = action.payload;
        },
        packagesGetIVacantManagementSortFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPackagesIvacantManagementDataSort = false;
            if (action.payload) {
                state.error.getPackagesIvacantManagementDataSort = action.payload;
            }
        },

        packagesGetSubscriptionTransactionsAttempt: (state) => {
            state.actions.getSubscriptionTransactions = true;
            state.error.getSubscriptionTransactions = '';
        },
        packagesGetSubscriptionTransactionsSuccess: (state, action: PayloadAction<ViewTransactionResponse[] | null>) => {
            state.actions.getSubscriptionTransactions = false;
            state.IPackagesSubscriptionTransactionData = action.payload;
        },
        packagesGetSubscriptionTransactionsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSubscriptionTransactions = false;
            if (action.payload) {
                state.error.getSubscriptionTransactions = action.payload;
            }
        },

        packagesSetSubscriptionTransactionsCancelAttempt: (state, _action: setSubscriptionTransactionCancelPayload) => {
            state.actions.setSubscriptionTransactionsCancel = true;
            state.error.setSubscriptionTransactionsCancel = '';
        },
        packagesSetSubscriptionTransactionsCancelSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setSubscriptionTransactionsCancel = false;
            state.IPackagesSubscriptionTransactionCancelData = action.payload;
        },
        packagesSetSubscriptionTransactionsCancelFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setSubscriptionTransactionsCancel = false;
            if (action.payload) {
                state.error.setSubscriptionTransactionsCancel = action.payload;
            }
        },

        packagesSetSubscriptionTransactionsRenewAttempt: (state, _action: setSubscriptionTransactionRenewPayload) => {
            state.actions.setSubscriptionTransactionsRenew = true;
            state.error.setSubscriptionTransactionsRenew = '';
        },
        packagesSetSubscriptionTransactionsRenewSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setSubscriptionTransactionsRenew = false;
            state.IPackagesSubscriptionTransactionRenewData = action.payload;
        },
        packagesSetSubscriptionTransactionsRenewFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setSubscriptionTransactionsRenew = false;
            if (action.payload) {
                state.error.setSubscriptionTransactionsRenew = action.payload;
            }
        },

        setTransactionApproveOrRenewLoaderModalOpen: (state, action: PayloadAction<boolean>) => {
            state.TransactionApproveOrRenewLoaderModal = action.payload;
        },

        getGoogleDriveContentAttempt: (state, _action: GetGoogleDriveContentActionPayload) => {
            state.actions.getGoogleDriveContent = true;
            state.error.getGoogleDriveContent = '';
        },
        getGoogleDriveContentSuccess: (state, action: PayloadAction<IGoogleDriveContent[]>) => {
            state.actions.getGoogleDriveContent = false;
            if (action.payload) {
                state.googleDriveContent = action.payload;
            }
        },
        getGoogleDriveContentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getGoogleDriveContent = false;
            if (action.payload) {
                state.error.getGoogleDriveContent = action.payload;
            }
        },
        // ? Property Management Transaction
        // * Create
        createPMTransactionAttempt: (state, _action: CreatePMTransactionActionPayload) => {
            state.actions.createPMTransaction = true;
            state.error.createPMTransaction = '';
        },
        createPMTransactionSuccess: (state, action: PayloadAction<{id: string}>) => {
            state.actions.createPMTransaction = false;
            if (action.payload) {
                state.transactionId = action.payload.id;
            }
        },
        createPMTransactionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createPMTransaction = false;
            if (action.payload) {
                state.error.createPMTransaction = action.payload;
            }
        },

    },
});

export type InitState = typeof initialState;

export default {
    actions: packagesSlice.actions,
    reducers: packagesSlice.reducer,
};
