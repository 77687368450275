import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { toast } from 'react-toastify';
import validator from 'validator';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/Translate';

import { RoundInput, Button, BreadCrumb } from '@dm/bigfish';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

interface SignUpProps {
    loading: boolean;
    signUp(email: string, password: string, role: number): void;
}

const SignUp = ({ signUp, loading }: SignUpProps): JSX.Element => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<boolean | null>(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<boolean | null>(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<boolean | null>(null);

    const [breadCrumb] = useState([
        {
            label: Translate.t('SignUp.SignUpBcSignUp'),
            onClick: () => { NavActions.navToRegister(); },
        },
        {
            label: Translate.t('SignUp.SignUpBcOwnerSignUp'),
            onClick: () => { return false; },
        }]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onChangeTextEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(!validator.isEmail(e.target.value));
    };

    const onChangeTextPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        const validPasswordTest = validator.isStrongPassword(e.target.value, { minLength: 5, minLowercase: 1, minUppercase: 1, minSymbols: 1 });

        setPasswordError(!validPasswordTest);
    };

    const onChangeTextConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    useEffect(() => {
        setConfirmPasswordError(!validator.equals(password, confirmPassword));
    }, [confirmPassword]);

    const onSubmitSignupHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (!emailError && !passwordError && !confirmPasswordError) {
            signUp(email, password, 2);
        } else {
            toast.error('Please enter valid form');
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Form onSubmit={onSubmitSignupHandler}>
                    <FormTitle>{Translate.t('SignUp.SignUpOwnerSignup')}</FormTitle>
                    <InputGroup label={Translate.t('SignUp.SignUpEmail')} placeholder={Translate.t('SignUp.SignUpPlaceholderEmail')} value={email} onChangeText={onChangeTextEmail} inputError={emailError || false} errorText='Please insert a valid email address' required />
                    <InputGroup type='password' label={Translate.t('SignUp.SignUpPassword')} placeholder={Translate.t('SignUp.SignUpPlaceholderPassword')} value={password} onChangeText={onChangeTextPassword} inputError={passwordError || false} errorText='Please insert a valid password' required />
                    <InputGroup type='password' label={Translate.t('SignUp.SignUpConfirmPassword')} placeholder={Translate.t('SignUp.SignUpPlaceholderPassword')} value={confirmPassword} onChangeText={onChangeTextConfirmPassword} inputError={confirmPasswordError || false} errorText='Confirm password does not match with password' required />
                    <Text>
                        {Translate.t('SignUp.SignUpPasswordStrengthText')}
                    </Text>

                    <Text>
                        {Translate.t('SignUp.SignUpAlreadyUser')}
                        {' '}
                        <TextLink onClick={() => NavActions.navToLogin()}>{Translate.t('SignUp.SignUpLogin')}</TextLink>
                    </Text>
                    <SubmitButton>
                        <Button loading={loading} label={Translate.t('SignUp.SignUpLabel')} />
                    </SubmitButton>
                </Form>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>
    );
};

const Form = styled.form`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;

    max-width: 554px;

    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const FormTitle = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    margin-bottom: 28px;
`;

const InputGroup = styled(RoundInput)`
    margin-bottom: 16px;
`;

const Text = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    color: #696969;

    margin: 28px 0;
    text-align: center;
`;

const TextLink = styled.a`
    font-size: inherit;
    line-height: inherit;
    color: #2F80ED;

    cursor: pointer;

    &:hover{
        color: #2f81edd1;
    }

    user-select: none;
`;

const SubmitButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthSignUpAttempting(state),
    error: Selectors.getAuthSignUpError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    signUp: (email: string, password: string, role: number) =>
        dispatch(Actions.authSignUpAttempt({ email, password, role })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
