import { GatewayResponse, GatewayResponseStatus } from 'api/types/types';
import Gateway from 'api/types/Gateway';

import { LoginResponse } from '../AuthBase';

const simulateApi = (response: any) => {
    return new Promise(res => {
        setTimeout(() => {
            res(response);
        }, 1000);
    });
};

export default class AuthGateway extends Gateway {
    async login(): GatewayResponse<LoginResponse> {
        const response = await simulateApi({
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MWNhZGJlNGQ1MGI2ZDEyNmRlYzU1ODAiLCJpYXQiOjE2NDA2ODQ1ODIsImV4cCI6MTY0MDY4NjM4MiwidHlwZSI6ImFjY2VzcyJ9.iMyKlfKAh7CQ4DgDH7dbVVbQbN8wYKAXGlUX5XiPYpc',
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as LoginResponse,
        };
    }

    async signUp(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async requestResetPassword(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async verifySignUp(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async detailedSignUp(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async verifyForgotPasswordEmail(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async forgotPasswordNewPassword(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async unsubscribe(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }
}
