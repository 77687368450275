import { GatewayResponse, GatewayResponseStatus } from 'api/types/types';
import Gateway from 'api/types/Gateway';

import { IOwnerUser } from '@dm/types';

const simulateApi = (response: any) => {
    return new Promise(res => {
        setTimeout(() => {
            res(response);
        }, 1000);
    });
};

export default class UserGateway extends Gateway {
    async GetUserInfo(): GatewayResponse<IOwnerUser> {
        const response = await simulateApi({
            data: [
                {
                    userId: '620f5cb875a05c83cbca0f59',
                    firstName: 'Eren',
                    lastName: 'Jaeger',
                    email: 'anwar.khairi@internal.double.my',
                    phoneNumber: '+60192837465',
                    role: 2,
                    isFirstSignUp: false,
                    title: 'Mr. ',
                    nationality: 'Malaysian',
                    race: 0,
                    DOB: '2020-12-31T16:00:00.000Z',
                    gender: 'MALE',
                    religion: 'Wall titan',
                    familyStatus: 'Married',
                    countryOfResidence: 'kirigakure',
                    correspondingAddress: {
                        address1: 'Wall Maria',
                        address2: 'Eldia',
                        city: 'Trost District',
                        postcode: '00420',
                        state: 'Wall Maria',
                        country: 'Eldia',
                    },
                    financialInfo: {
                        bankType: 'Retail',
                        bankName: 'Maybank',
                        currency: 'RM',
                        bankAccNo: '168899004433',
                        swiftCode: 'MYHJSSJFDSAD1234',
                        branchAddress: {
                            address1: 'Wall Sina',
                            address2: 'Eldia',
                            city: 'Trost District',
                            postcode: '00420',
                            state: 'Wall sina',
                            country: 'Marleyyyyyyyyyyyyy',
                        },
                    },
                    companyDetails: {
                        companyName: 'DoubleDot',
                        designation: 'WebApp',
                        officeNo: '1',
                    },
                    emergencyContact: {
                        fullName: 'Mikasa',
                        relationship: 'Unrequited Love',
                        phoneNo: '+111111111111111',
                        email: '1234@email.com',
                    },
                    createdAt: '2022-02-18T08:45:44.220Z',
                    updatedAt: '2022-02-18T08:45:44.220Z',
                },

            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as IOwnerUser,
        };
    }

    async SetUserInfo(): GatewayResponse<string> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }
}
