import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesPoscode, CoordinatesState, CoordinatesCountry, CoordinatesCity, WalletBalanceParams } from 'entities/property';

import { SetDescriptionActionPayload, PropertyAmenitiesPayload, SetForApprovalActionPayload, SetDetails1ActionPayload, SetCreateImagePayload, SetCreatePropertyPayload, SetTermsActionPayload, SetLocationPayload, UploadPropertyDocumentActionPayload, GetPropertyUploadUrlActionPayload, SetImageDeleteActionPayload, SetPropertyWalletActionPayload, CreatePropertyPayload, EditPropertyDetailsPayload } from './types';

const initialState = {
    actions: {
        SetDescription: false,
        SetPropertyAmenities: false,
        propertydetails: false,
        setCreateProperty: false,
        setImagesProperty: false,
        setTermsProperty: false,
        setLocationProperty: false,
        setApprovalProperty: false,
        getPropertyUploadUrl: false,
        uploadPropertyDocument: false,
        setDeleteImage: false,
        setPropertyWallet: false,
        createProperty: false,
        editPropertyDetails: false,
    },
    description: '',
    terms: null,
    approval: '',
    SetDescription: '',
    SetLocation: '',
    setCreatePropertyId: '',
    setImagesPropertyId: '',
    coordinates: {
        latitude: 0,
        longitude: 0,
    },

    coordinatesaddress1: {
        address1: '',
    },

    coordinatesaddress2: {
        address2: '',
    },

    coordinatesposcode: {
        poscode: '',
    },

    coordinatesstate: {
        state: '',
    },

    coordinatescountry: {
        country: '',
    },

    coordinatescity: {
        city: '',
    },

    PropertyDetailsStatus: false,
    createPropertyModal: false,

    PropertyImageUploadURL: '',
    filePath: '',

    PropertyImageUploadComplete: false,

    deletedImage: '',

    signLink: '',

    propertyLocation: false,
    propertyDetails: false,
    propertyAmenities: false,
    propertyImages: false,
    propertyDescription: false,

    propertyTermsAndConditionSubmitted: false,
    propertyLocationSubmitted: false,
    propertyDetailsSubmitted: false,
    propertyAmenitiesSubmitted: false,
    propertyImagesSubmitted: false,
    propertyDescriptionSubmitted: false,
    walletData: { amount: 0 },
    ImageUploadFileName: '',
    propertyLocationMapCollapsibleClose: '',
    propertyId: '',
    error: {
        SetDescription: '',
        SetPropertyAmenities: '',
        propertydetails: '',
        setCreateProperty: '',
        setImagesProperty: '',
        setTermsProperty: '',
        setLocationProperty: '',
        setApprovalProperty: '',
        getPropertyUploadUrl: '',
        uploadPropertyDocument: '',
        setDeleteImage: '',
        setPropertyWallet: '',
        createProperty: '',
        editPropertyDetails: '',
    },
};

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        propertySetDescriptionAttempt: (state, _action: SetDescriptionActionPayload) => {
            state.actions.SetDescription = true;
            state.error.SetDescription = '';
        },
        propertySetDescriptionSuccess: (state, action: PayloadAction<string>) => {
            state.actions.SetDescription = false;
            state.description = action.payload;
        },
        propertySetDescriptionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.SetDescription = false;
            if (action.payload) {
                state.error.SetDescription = action.payload;
            }
        },

        propertySetPropertyAmenitiesAttempt: (state, _action: PropertyAmenitiesPayload) => {
            state.actions.SetPropertyAmenities = true;
            state.error.SetPropertyAmenities = '';
        },
        propertySetPropertyAmenitiesSuccess: (state) => {
            state.actions.SetPropertyAmenities = false;
        },
        propertySetPropertyAmenitiesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.SetPropertyAmenities = false;
            if (action.payload) {
                state.error.SetPropertyAmenities = action.payload;
            }
        },

        propertySetPropertyDetailsAttempt: (state, _action: SetDetails1ActionPayload) => {
            state.actions.propertydetails = true;
            state.error.propertydetails = '';
        },
        propertySetPropertyDetailsSuccess: (state) => {
            state.actions.propertydetails = false;
        },
        propertySetPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.propertydetails = false;
            if (action.payload) {
                state.error.propertydetails = action.payload;
            }
        },

        propertyDetailsStatus: (state, action: PayloadAction<boolean>) => {
            state.PropertyDetailsStatus = action.payload;
        },

        propertyCreateNewDraftAttempt: (state, _action: SetCreatePropertyPayload) => {
            state.actions.setCreateProperty = true;
            state.error.setCreateProperty = '';
        },
        propertyCreateNewDraftSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setCreateProperty = false;
            state.setCreatePropertyId = action.payload;
        },
        propertyCreateNewDraftFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setCreateProperty = false;
            if (action.payload) {
                state.error.setCreateProperty = action.payload;
            }
        },

        propertySetProperyImageAttempt: (state, _action: SetCreateImagePayload) => {
            state.actions.setImagesProperty = true;
            state.error.setImagesProperty = '';
        },
        propertySetProperyImageSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setImagesProperty = false;
            state.setImagesPropertyId = action.payload;
        },
        propertySetProperyImageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setImagesProperty = false;
            if (action.payload) {
                state.error.setImagesProperty = action.payload;
            }
        },

        propertyAcceptTermsAttempt: (state, _action: SetTermsActionPayload) => {
            state.actions.setTermsProperty = true;
            state.error.setTermsProperty = '';
        },
        propertyAcceptTermsSuccess: (state, action: PayloadAction<any | undefined>) => {
            state.actions.setTermsProperty = false;
            state.terms = action.payload;
        },
        propertyAcceptTermsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setTermsProperty = false;
            if (action.payload) {
                state.error.setTermsProperty = action.payload;
            }
        },

        propertySetApprovalAttempt: (state, _action: SetForApprovalActionPayload) => {
            state.actions.setApprovalProperty = true;
            state.error.setApprovalProperty = '';
        },
        propertySetApprovalSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setApprovalProperty = false;
            state.approval = action.payload;
        },
        propertySetApprovalFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setApprovalProperty = false;
            if (action.payload) {
                state.error.setApprovalProperty = action.payload;
            }
        },

        propertySetLocationAttempt: (state, _action: SetLocationPayload) => {
            state.actions.setLocationProperty = true;
            state.error.setLocationProperty = '';
        },
        propertySetLocationSuccess: (state, action: PayloadAction<any | undefined>) => {
            state.actions.setLocationProperty = false;
            state.SetLocation = action.payload;
        },
        propertySetLocationFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setLocationProperty = false;
            if (action.payload) {
                state.error.setLocationProperty = action.payload;
            }
        },

        propertySetCoordinates: (state, action: PayloadAction<Coordinates>) => {
            state.coordinates = action.payload;
        },
        propertyResetCoordinates: (state) => {
            state.coordinates = {
                latitude: 0,
                longitude: 0,
            };
        },

        propertySetCoordinatesAddress1: (state, action: PayloadAction<CoordinatesAddress1>) => {
            state.coordinatesaddress1 = action.payload;
        },

        propertySetCoordinatesAddress2: (state, action: PayloadAction<CoordinatesAddress2>) => {
            state.coordinatesaddress2 = action.payload;
        },

        propertySetCoordinatesPoscode: (state, action: PayloadAction<CoordinatesPoscode>) => {
            state.coordinatesposcode = action.payload;
        },

        propertySetCoordinatesState: (state, action: PayloadAction<CoordinatesState>) => {
            state.coordinatesstate = action.payload;
        },

        propertySetCoordinatesCountry: (state, action: PayloadAction<CoordinatesCountry>) => {
            state.coordinatescountry = action.payload;
        },

        propertySetCoordinatesCity: (state, action: PayloadAction<CoordinatesCity>) => {
            state.coordinatescity = action.payload;
        },

        getPropertyUploadUrlAttempt: (state, _action: GetPropertyUploadUrlActionPayload) => {
            state.actions.getPropertyUploadUrl = true;
            state.error.getPropertyUploadUrl = '';
        },

        getPropertyUploadUrlSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getPropertyUploadUrl = false;
            state.error.getPropertyUploadUrl = '';

            state.PropertyImageUploadURL = action.payload;
        },
        getPropertyUploadUrlFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyUploadUrl = false;
            if (action.payload) {
                state.error.getPropertyUploadUrl = action.payload;
            }
        },

        uploadPropertyImagesAttempt: (state, _action: UploadPropertyDocumentActionPayload) => {
            state.actions.uploadPropertyDocument = true;
            state.error.uploadPropertyDocument = '';
        },
        uploadPropertyImagesSuccess: (state) => {
            state.actions.uploadPropertyDocument = false;
            state.error.uploadPropertyDocument = '';
        },

        setPropertyImageUploadComplete: (state, action: PayloadAction<boolean>) => {
            state.PropertyImageUploadComplete = action.payload;
            state.propertyImages = action.payload;
        },

        propertySetDeleteImageAttempt: (state, _action: SetImageDeleteActionPayload) => {
            state.actions.setDeleteImage = true;
            state.error.setDeleteImage = '';
        },
        propertySetDeleteImageSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setDeleteImage = false;
            state.deletedImage = action.payload;
        },
        propertySetDeleteImageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setDeleteImage = false;
            if (action.payload) {
                state.error.setDeleteImage = action.payload;
            }
        },

        setPropertyOpenLocationCollapsible: (state, action: PayloadAction<boolean>) => {
            state.propertyLocation = action.payload;
        },

        setPropertyOpenDetailsCollapsible: (state, action: PayloadAction<boolean>) => {
            state.propertyDetails = action.payload;
        },

        setPropertyOpenAmenitiesCollapsible: (state, action: PayloadAction<boolean>) => {
            state.propertyAmenities = action.payload;
        },
        setPropertyOpenImagesCollapsible: (state, action: PayloadAction<boolean>) => {
            state.propertyImages = action.payload;
        },
        setPropertyOpenDescriptionCollapsible: (state, action: PayloadAction<boolean>) => {
            state.propertyDescription = action.payload;
        },

        propertyCreatePropertyModal: (state, action: PayloadAction<boolean>) => {
            state.createPropertyModal = action.payload;
        },

        setPropertyTermsAndConditionSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyTermsAndConditionSubmitted = action.payload;
        },
        setPropertyLocationSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyLocationSubmitted = action.payload;
        },
        setPropertyDetailsSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyDetailsSubmitted = action.payload;
        },
        setPropertyAmenitiesSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyAmenitiesSubmitted = action.payload;
        },
        setPropertyImagesSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyImagesSubmitted = action.payload;
        },
        setPropertyDescriptionSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyDescriptionSubmitted = action.payload;
        },

        setPropertySign: (state, action: PayloadAction<string>) => {
            state.signLink = action.payload;
        },

        propertyPropertyWalletAttempt: (state, _action: SetPropertyWalletActionPayload) => {
            state.actions.setPropertyWallet = true;
            state.error.setPropertyWallet = '';
        },
        propertyPropertyWalletSuccess: (state, action: PayloadAction<WalletBalanceParams>) => {
            state.actions.setPropertyWallet = false;
            state.walletData = action.payload;
        },
        propertyPropertyWalletFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPropertyWallet = false;
            if (action.payload) {
                state.error.setPropertyWallet = action.payload;
            }
        },

        setImageUploadFileName: (state, action: PayloadAction<string>) => {
            state.ImageUploadFileName = action.payload;
        },

        setPropertyLocationCloseCollapsibleAfterSubmitted: (state, action: PayloadAction<string>) => {
            state.propertyLocationMapCollapsibleClose = action.payload;
        },
        // ? property actions
        // ! create property
        createPropertyAttempt: (state, _action: CreatePropertyPayload) => {
            state.actions.createProperty = true;
            state.error.createProperty = '';
        },
        createPropertySuccess: (state, action: PayloadAction<string>) => {
            state.actions.createProperty = false;
            state.propertyId = action.payload;
        },
        createPropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createProperty = false;
            if (action.payload) {
                state.error.createProperty = action.payload;
            }
        },
        // ! edit property details
        editPropertyDetailsAttempt: (state, _action: EditPropertyDetailsPayload) => {
            state.actions.editPropertyDetails = true;
            state.error.editPropertyDetails = '';
        },
        editPropertyDetailsSuccess: (state) => {
            state.actions.editPropertyDetails = false;
        },
        editPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editPropertyDetails = false;
            if (action.payload) {
                state.error.editPropertyDetails = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: propertySlice.actions,
    reducers: propertySlice.reducer,
};
