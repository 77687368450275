import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetDescriptionActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchDesription(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetDescriptionAttempt', handleDesription, api);
}

function* handleDesription(api: PropertyGateway, data: SetDescriptionActionPayload) {
    const { id, description } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setDescription], { id, description, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success(Translate.t('GeneralUI.Saved'));
        yield put(Actions.propertySetDescriptionSuccess('true'));
        yield put(Actions.setPropertyDescriptionSubmitted(true));

        if (!response.data) {
            yield put(Actions.propertySetDescriptionFailure(Translate.t('GeneralUI.SomethingWrong')));
        }
    } else {
        yield put(Actions.propertySetDescriptionFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralUI.Failed'));
            } else {
                toast.error(Translate.t('GeneralUI.TryAgain'));
            }
        }
    }
}
