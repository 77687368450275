import React, { FunctionComponent, useState, useEffect } from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components';
import Translate from 'lib/Translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IFile } from '@dm/types';

import { IImageCarousel } from '@dm/bigfish';
import { ImageCarousel } from './DocumentCarousel';

interface DocumentImagesModalProps {
    isModalOpen: boolean;
    setModalOpen: (state: boolean) => void;
    documentData: IFile[];
}

const DocumentImagesModal: FunctionComponent<DocumentImagesModalProps> = (props: DocumentImagesModalProps) => {
    const { documentData, setModalOpen, isModalOpen } = props;

    const [imageUrls, setImageUrls] = useState<IImageCarousel[]>([]);

    useEffect(() => {
        if (documentData.length) {
            const parsedData: IImageCarousel[] = documentData.map(item => {
                const { url = '', name = '' } = item;

                return {
                    image: url,
                    desc: name,
                };
            });
            setImageUrls(parsedData);
        }
    }, [documentData]);

    const renderModalBody = () => {
        if (!imageUrls.length || !imageUrls[0].image) {
            return (
                <div>
                    {Translate.t('Packages.ViewDocuments.NoImages')}
                </div>
            );
        }

        return (
            <ImageCarousel
                data={imageUrls}
            />
        );
    };

    return (
        <Modal
            show={isModalOpen}
            closeCursor
            onClickClose={() => setModalOpen(false)}
        >
            <PaddingContainer>
                <TitleContainer>
                    {Translate.t('Packages.ViewDocuments.Documents')}

                </TitleContainer>
                <RenderBodyContainer>
                    {renderModalBody()}
                </RenderBodyContainer>

            </PaddingContainer>
        </Modal>
    );
};

const PaddingContainer = styled.div`
padding: 20px;
`;

const RenderBodyContainer = styled.div`
justify-self: center;
`;

const TitleContainer = styled.div`
margin-bottom: 20px;
font-size: 28px;
`;

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.setpackagesDocumentViewVacantPossesion(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpen: (state: boolean) => dispatch(Actions.setPackagesDocumentVacantPossessionView(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentImagesModal);
