import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';

import { SignUpActionPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchSignUp(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authSignUpAttempt', handleSignUp, api);
}

function* handleSignUp(api: AuthGateway, data: SignUpActionPayload) {
    const { email, password, role } = data.payload;

    const response = yield* call([api, api.signUp], { email, password, role });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Congratulations, your account has been successfully created');

        NavActions.navToVerificationEmailSent();

        if (!response.data) {
            yield put(Actions.authSignUpFailure('Something went wrong. Please try again.'));
        }
    } else {
        yield put(Actions.authSignUpFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'email has been used.') {
                toast.error('Registration failed! Email has been used');
            } else {
                toast.error('Registration failed! Please try again later');
            }
        }
    }
}
