import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import { Bars } from 'react-loader-spinner';
import Translate from 'lib/Translate';
import NavActions from 'lib/NavActions';
import { EventTrail, Paginator, Colors } from '@dm/bigfish';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IServerProperty } from 'entities/property';
import { PackagesiD } from 'entities/packages';
import PropertyModal from 'containers/property/components/PropertyModal';
import { IPropertyPackageStatusEnum, PackageCategory } from '@dm/types';
import PropertyImage from '../../../components/PropertyImage';
import ButtonInfo from '../../../components/ButtonInfo';
import SupportInfo from '../../../components/SupportInfo';
import EventTrailRecentEvents from '../../../components/EventTrailRecentEvents';
import Icons from '../../../assets/icons';
import { CardCollapsible } from '../../../components/CardCollapsible';

interface PropertyListProps {
    loading: boolean;
    error: string;
    propertyList: IServerProperty[];
    getProperty: (index: number) => void;
    clearProperty: () => void;
    setPackagesId: (params: PackagesiD) => void;
    isCreatePropertyModalOpen: boolean;
    setPropertyCreateModal: (state: boolean) => void;
}

const Property = (props : PropertyListProps): JSX.Element => {
    const { loading, error, propertyList, getProperty, clearProperty, setPackagesId, setPropertyCreateModal, isCreatePropertyModalOpen } = props;
    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);
    const [pendingActivities, setPendingActivities] = useState([]);
    const [pendingEvents, setPendingEvents] = useState([]);
    const [propertystatus, setPropertystatus] = useState(false);

    useEffect(() => {
        getProperty(1);
        return () => {
            clearProperty();
        };
    }, []);

    useEffect(() => {
        // Handle pagination
        if (propertyList.length) {
            const { maxIndex } = propertyList[0];
            setPaginationData(createPaginationData(maxIndex));
            setMaxPages(maxIndex);
        }
    }, [propertyList]);

    const CheckPropertyStatus = (status: number) => {
        switch (status) {
            case 0:
                return Translate.t('GeneralUI.CollapsibleStatusPendingApproval');
            case 1:
                return Translate.t('GeneralUI.CollapsibleStatusVacantVacant');
            case 2:
                return Translate.t('GeneralUI.CollapsibleStatusTenanted');
            case 3:
                return Translate.t('GeneralUI.CollapsibleStatusDeactivated');
            case 4:
                return Translate.t('GeneralUI.CollapsibleStatusDraftProperty');
            case 5:
                return Translate.t('GeneralUI.CollapsibleStatusApproved');
            default:
                return '';
        }
    };

    // the function below is not translated because it is not for display but to do checking what type of status the packages is
    // and to decide which type of button to display
    const checkPropertyPackagesStatus = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case 0:
                return '';
            case 1:
                return 'Pending';
            case 2:
                return 'pending verification';
            case 3:
                return 'Active';
            case 4:
                return 'PaymentRejected';
            case 5:
                return 'Disabled';
            case 6:
                return 'FreeTrial';
            case 7:
                return 'PendingSignature';
            default:
                return '';
        }
    };

    // this is done temporarily until sign feature implemented in feature for property management and vacant management
    const checkPropertyPackagesStatusPropertyManagementAndVacantManagement = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case 0:
                return '';
            case 1:
                return 'Pending';
            case 2:
                return 'pending verification';
            case 3:
                return 'Active';
            case 4:
                return 'PaymentRejected';
            case 5:
                return 'Disabled';
            case 6:
                return 'FreeTrial';
            case 7:
                return 'Active';
            default:
                return '';
        }
    };

    const winScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const createPaginationData = (maxIndex: number) => {
        const pagination = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pagination.push(i);
        }
        //! set the page limit for paginator
        return pagination.slice(currentMinPages, currentMaxPages);
    };

    const prevPaginationNumber = () => {
        const currentNumber = (paginationNumber === 1 ? paginationNumber : paginationNumber - 1);
        setPaginationNumber(currentNumber);
        //! logic for previous button
        if (currentNumber === currentMinPages && currentMinPages !== 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMinPages);
        }
        getProperty(currentNumber);
        winScrollToTop();
    };

    const nextPaginationNumber = () => {
        const currentNumber = (paginationNumber === maxPages ? paginationNumber : paginationNumber + 1);
        setPaginationNumber(currentNumber);

        //! logic for next button
        if (paginationNumber === currentMaxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }
        if (currentNumber !== paginationNumber) {
            getProperty(currentNumber);
            winScrollToTop();
        }
    };

    const firstPaginationNumber = () => {
        //! setting min and max pages to the first one
        setCurrentMinPages(0);
        setCurrentMaxPages(20);
        setPaginationNumber(1);
        getProperty(1);
        winScrollToTop();
    };

    const lastPaginationNumber = () => {
        //! setting min max pages to the maxPages
        setCurrentMinPages(maxPages - (maxPages % 20));
        setCurrentMaxPages(maxPages);
        setPaginationNumber(maxPages);
        getProperty(maxPages);
        winScrollToTop();
    };

    const onClickPaginationNumber = (pages: number) => {
        setPaginationNumber(pages);
        getProperty(pages);
        winScrollToTop();
    };

    const onClickCreatNewProperty = () => {
        setPropertyCreateModal(!isCreatePropertyModalOpen);
    };

    const toPackagesSelect = (_id: string) => {
        NavActions.navToPackages(_id);
        setPackagesId({
            id: _id,
        });
        return _id;
    };

    const toViewProperty = (_id: string) => {
        NavActions.navToViewPropertyDetails(_id);
    };

    const toCreatePropertyView = (_id: string) => {
        NavActions.navToCreateNewProperty(_id);
        setPackagesId({
            id: _id,
        });
        return _id;
    };

    const signOrRedirectToPackages = (status: number, _id: string, packageType: PackageCategory) => {
        if (status === IPropertyPackageStatusEnum.PendingSignature) {
            NavActions.navToPackagesSign(_id, packageType);
        } else {
            if (packageType === PackageCategory.VacantPossession) {
                NavActions.navToViewVacantPossessionPackages(_id);
            }
            if (packageType === PackageCategory.DefectInspection) {
                NavActions.navToViewDefectInspectionPackages(_id);
            }
            // {TEMP for future use}
            // if (packageType === PackageCategory.DesignRenovation) {

            // }
            if (packageType === PackageCategory.PropertyManagement) {
                NavActions.navToViewPropertyManagementPackages(_id);
            }
            if (packageType === PackageCategory.VacantManagement) {
                NavActions.navToViewVacantManagementPackages(_id);
            }
            if (packageType === PackageCategory.LeaseSale) {
                NavActions.navToViewLeaseAndSalePackages(_id);
            }
        }
    };
    return (
        <ModulesContainer>
            <ModulesMainContainer>
                {
                    !loading ? (
                        <>
                            {propertyList.map(({ index, data }) => {
                                if (+index === paginationNumber) {
                                    return (
                                        data.map((property, propertyIndex) => {
                                            return (

                                                <CardCollapsible key={Math.random()} onclick={() => toCreatePropertyView(property._id)} title={property.name.substring(0, 20)} subtitle={property.location ? `${property.location.city}, ${property.location.state}, ${property.location.country}` : ''} chipText={property.status ? CheckPropertyStatus(property.status) : ''}>
                                                    <ModulesPropertyContainer>
                                                        <PropertySubInfoContainer>
                                                            {(property.images && property.images.length) ? <PropertyImage src={property.images[0].url[0]} /> : <PropertyImage />}
                                                            {property.location && <span>{`${property.location.address1 && `${property.location.address1}, `} ${property.location.address2 && `${property.location.address2}, `} ${property.location.city}, ${property.location.state}, ${property.location.country}`}</span>}
                                                        </PropertySubInfoContainer>
                                                        <PropertyInfoContainer>
                                                            <EventTrailRecentEvents title={Translate.t('Property.PropertyRecentEvents')} primary buttonLabel={Translate.t('Property.PropertyRecentEvents')} data={(property.recentEvents && property.recentEvents[0]) ? property.recentEvents : pendingEvents} />
                                                            {(property.packages && property.packages.vacantPossession?.status) ? <ButtonInfo desc={Translate.t('Property.PropertyVacantPossesion')} status={checkPropertyPackagesStatus(property.packages.vacantPossession.status)} onclick={() => signOrRedirectToPackages(property!.packages!.vacantPossession!.status, property._id, PackageCategory.VacantPossession)} />
                                                                : <div>{(property.packages?.vacantPossession?.price) ? <ButtonInfo desc={Translate.t('Property.PropertyVacantPossesion')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewVacantPossessionPackages(property._id)} /> : ''}</div>}

                                                            {(property.packages && property.packages.defectInspection?.status) ? <ButtonInfo desc={Translate.t('Property.PropertyDefectInspection')} status={checkPropertyPackagesStatus(property.packages.defectInspection.status)} onclick={() => signOrRedirectToPackages(property!.packages!.defectInspection!.status, property._id, PackageCategory.DefectInspection)} />
                                                                : <div>{(property.packages?.defectInspection?.price) ? <ButtonInfo desc={Translate.t('Property.PropertyDefectInspection')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewDefectInspectionPackages(property._id)} /> : ''}</div>}

                                                            {/** THE FEATURES BELOW WILL BE ADDED IN FUTURE */}
                                                            {/* {(property.packages && property.packages.designRenovation?.status) ? <ButtonInfo desc={Translate.t('Property.PropertyDesignRenovation')} status={checkPropertyPackagesStatus(property.packages.designRenovation.status)} onclick={() => undefined} /> : (property.packages?.designRenovation?.price) && <ButtonInfo desc={Translate.t('Property.PropertyDesignRenovation')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => undefined} />} */}
                                                            {(property.packages && property.packages.propertyManagement?.status) ? <ButtonInfo desc={Translate.t('Property.PropertyManagement')} status={checkPropertyPackagesStatus(property.packages.propertyManagement.status)} onclick={() => signOrRedirectToPackages(property!.packages!.propertyManagement!.status, property._id, PackageCategory.PropertyManagement)} />
                                                                : <div>{(property.packages?.propertyManagement?.price) ? <ButtonInfo desc={Translate.t('Property.PropertyManagement')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewPropertyManagementPackages(property._id)} /> : ''}</div>}
                                                            {/* {(property.packages && property.packages.propertyManagement?.status) ? <ButtonInfo desc={Translate.t('Property.PropertyManagement')} status={checkPropertyPackagesStatusPropertyManagementAndVacantManagement(property.packages.propertyManagement.status)} onclick={() => signOrRedirectToPackagesPropertyManagementAndVacantManagement(property!.packages!.propertyManagement!.status, property._id, PackageCategory.PropertyManagement)} />
                                                                : <div>{(property.packages?.propertyManagement?.price) ? <ButtonInfo desc={Translate.t('Property.PropertyManagement')} status={checkPropertyPackagesStatusPropertyManagementAndVacantManagement(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewPropertyManagementPackages(property._id)} /> : ''}</div>} */}
                                                            {/** THE FEATURES BELOW WILL BE ADDED IN FUTURE */}
                                                            {(property.packages && property.packages.vacantManagement?.status) ? <ButtonInfo desc='vacant Management' status={checkPropertyPackagesStatus(property.packages.vacantManagement.status)} onclick={() => signOrRedirectToPackages(property!.packages!.vacantManagement!.status, property._id, PackageCategory.VacantManagement)} />
                                                                : <div>{(property.packages?.vacantManagement?.price) ? <ButtonInfo desc='vacant Management' status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewVacantManagementPackages(property._id)} /> : ''}</div>}
                                                            {/* {(property.packages && property.packages.vacantManagement?.status) ? <ButtonInfo desc='vacant Management' status={checkPropertyPackagesStatusPropertyManagementAndVacantManagement(property.packages.vacantManagement.status)} onclick={() => signOrRedirectToPackagesPropertyManagementAndVacantManagement(property!.packages!.vacantManagement!.status, property._id, PackageCategory.VacantManagement)} />
                                                                : <div>{(property.packages?.vacantManagement?.price) ? <ButtonInfo desc='vacant Management' status={checkPropertyPackagesStatusPropertyManagementAndVacantManagement(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewVacantManagementPackages(property._id)} /> : ''}</div>} */}

                                                            {(property.packages && property.packages.leaseSale?.status) ? <ButtonInfo desc={Translate.t('Property.PropertyLeaseSale')} status={checkPropertyPackagesStatus(property.packages.leaseSale.status)} onclick={() => signOrRedirectToPackages(property!.packages!.leaseSale!.status, property._id, PackageCategory.LeaseSale)} />
                                                                : <div>{(property.packages?.leaseSale?.price) ? <ButtonInfo desc={Translate.t('Property.PropertyLeaseSale')} status={checkPropertyPackagesStatus(IPropertyPackageStatusEnum.Pending)} onclick={() => NavActions.navToViewLeaseAndSalePackages(property._id)} /> : ''}</div>}

                                                        </PropertyInfoContainer>
                                                    </ModulesPropertyContainer>

                                                    {(property.packages && ((property.packages?.vacantPossession?.status !== IPropertyPackageStatusEnum.Active) && (property.packages?.defectInspection?.status !== IPropertyPackageStatusEnum.Active) && (property.packages?.designRenovation?.status !== IPropertyPackageStatusEnum.Active) && (property.packages?.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && (property.packages?.leaseSale?.status !== IPropertyPackageStatusEnum.Active))
                                                        ? (
                                                            (property.packages.vacantPossession?.price || property.packages.defectInspection?.price
                                                                || property.packages.designRenovation?.price
                                                                || property.packages.propertyManagement?.price || property.packages.leaseSale?.price)
                                                            && (
                                                                <ModulesFooter focused={!propertystatus} onClick={() => toPackagesSelect(property._id)}>
                                                                    <ServiceSetupTitle>{Translate.t('Property.ToPackagesSetup')}</ServiceSetupTitle>
                                                                    <RightArrow>&gt;</RightArrow>
                                                                </ModulesFooter>
                                                            )
                                                        )
                                                        : (
                                                            <ModulesFooter>
                                                                <ModuleFooterNav onClick={() => toViewProperty(property._id)}>
                                                                    {Translate.t('Property.PropertyPage')}
                                                                    <span>&gt;</span>
                                                                </ModuleFooterNav>
                                                            </ModulesFooter>
                                                        )
                                                    )}
                                                </CardCollapsible>
                                            );
                                        }));
                                }

                                return null;
                            })}

                            {(propertyList.length < 1 && !error) && <Container><PropertyInfoText>{Translate.t('Property.PropertyNoProperty')}</PropertyInfoText></Container>}

                            {(propertyList.length < 1 && error) && <Container><PropertyInfoText>{Translate.t('Property.PropertyError')}</PropertyInfoText></Container>}

                            {propertyList.length >= 1 && <StyledPaginator currentIndex={paginationNumber} data={paginationData} onNext={nextPaginationNumber} onPrev={prevPaginationNumber} onFirst={firstPaginationNumber} onLast={lastPaginationNumber} onClickIndex={onClickPaginationNumber} />}
                        </>
                    )
                        : (
                            <Container>
                                <Bars color={Colors.primary} height={260} width={260} />
                            </Container>
                        )
                }

            </ModulesMainContainer>
            <ModulesSideContainer>
                {isCreatePropertyModalOpen && (
                    <PropertyModal />
                )}
                <ModulesAddPropertyButton onClick={onClickCreatNewProperty}>
                    <ModulesAddIcon src={Icons.AddIcon} />
                    {Translate.t('Property.PropertyAddNewProperty')}
                </ModulesAddPropertyButton>
                <EventTrail title={Translate.t('Property.PropertyPendingActivites')} primary buttonLabel={Translate.t('Property.PropertyPendingActivitesNav')} data={pendingActivities} onClickButtonLabel={() => undefined} emptyText={Translate.t('Property.PropertyPendingActivitesInfo')} />
                <SupportInfo />
            </ModulesSideContainer>
        </ModulesContainer>
    );
};

const ModulesContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const ServiceSetupTitle = styled.div`
    line-height: 22px;
    font-weight: 700;
    font-size: 18px;
`;

const RightArrow = styled.div`
    margin: 8px;
    margin-top: 1px;
    width: 8px;
    height: 12px;
`;

const ModulesFooter = styled.div<{focused?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 48px;
    padding: 12px 20px;
    background-color: #FFF2E7;
    ${(props) => props.focused && css`
    background-color: #F2865E;;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    `}
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
`;

const ModuleFooterNav = styled.button`
    display: flex;
    align-items: center;
    color: #F2865E;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;

    span {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
    }
`;

const ModulesMainContainer = styled.div`
    width: 824px;
    margin-right: 16px;
    >:not(:last-child) {
        margin-bottom: 40px;
    }
`;

const ModulesPropertyContainer = styled.div`
    display: flex;
    padding: 20px;
    img {
        margin-right: 16px;
    }
    
`;

const PropertySubInfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    >:not(:last-child) {
        margin-bottom: 20px;
    }

    span {
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        font-style: italic;
        max-width: 224px;
        text-align: justify;
    }
`;

const PropertyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    >:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const PropertyInfoText = styled.h3`
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
`;

const ModulesAddPropertyButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 276px;
    width: 100%;
    background-color: #FFCFA6;
    color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 20px;
    border: none;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
    text-transform: capitalize;
    cursor: pointer;
`;

const ModulesAddIcon = styled(SVG)`
    width: 56px;
    height: 56px;
    margin-bottom: 56px;
`;

const ModulesSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 544px;
    >:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const StyledPaginator = styled(Paginator)`
    margin: 0 auto;
    margin-top: 60px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 480px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropPropertyAttempt(state),
    error: Selectors.getPropPropertyFailure(state),
    propertyList: Selectors.getPropProperty(state),
    isCreatePropertyModalOpen: Selectors.setPropertyCreatePropertyModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProperty: (index: number) =>
        dispatch(Actions.getPropertyAttempt({ index })),
    clearProperty: () => dispatch(Actions.clearProperty()),
    setPackagesId: (params: PackagesiD) => dispatch(Actions.packagesSetId(params)),
    setPropertyCreateModal: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
