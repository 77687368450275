import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';
import { toast } from 'react-toastify';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';

import { VerifyForgotPasswordEmailPayload } from 'redux/slices/auth/types';

import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';

export default function* watchVerifyForgotPasswordEmail(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authVerifyForgotPasswordEmailAttempt', handleVerifyForgotPasswordEmail, api);
}

function* handleVerifyForgotPasswordEmail(api: AuthGateway, data: VerifyForgotPasswordEmailPayload) {
    const { hash } = data.payload;

    const response = yield* call([api, api.verifyForgotPasswordEmail], { hash: encodeURIComponent(hash) });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.authVerifyForgotPasswordEmailFailure('Something went wrong. Please try again.'));
        }

        yield put(Actions.authVerifyForgotPasswordEmailSuccess());
    } else {
        yield put(Actions.authVerifyForgotPasswordEmailFailure(response.message));

        NavActions.navToLogin();
        toast.error('The provided ID does not look like a valid ID. Please try again');
    }
}
