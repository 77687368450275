import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    LoginActionPayload,
    SignUpActionPayload,
    RequestResetPasswordPayload,
    VerifySignUpPayload,
    DetailedSignUpPayload,
    VerifyForgotPasswordEmailPayload,
    ForgotPasswordNewPasswordPayload,
    UnsubscribePayload,
} from './types';

const initialState = {
    actions: {
        login: false,
        signUp: false,
        startup: true,
        logout: false,
        requestResetPassword: false,
        verifySignUp: false,
        detailedSignUp: false,
        verifyForgotPasswordEmail: false,
        forgotPasswordNewPassword: false,
        unsubscribe: false,
    },
    authToken: '',
    verifySignUp: false,
    isFirstSignUp: false,
    error: {
        login: '',
        signUp: '',
        startup: '',
        logout: '',
        requestResetPassword: '',
        verifySignUp: '',
        detailedSignUp: '',
        verifyForgotPasswordEmail: '',
        forgotPasswordNewPassword: '',
        unsubscribe: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLoginAttempt: (state, _action: LoginActionPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },
        authLogout: (state) => {
            state.actions.login = false;
            state.authToken = '';
        },
        authSignUpAttempt: (state, _action: SignUpActionPayload) => {
            state.actions.signUp = true;
            state.error.signUp = '';
        },
        authSignUpSuccess: (state) => {
            state.actions.signUp = false;
        },
        authSignUpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.signUp = false;
            if (action.payload) {
                state.error.signUp = action.payload;
            }
        },
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.startup = false;
            if (action.payload) state.error.startup = action.payload;
        },
        authRequestResetPasswordAttempt: (state, _action: RequestResetPasswordPayload) => {
            state.actions.requestResetPassword = true;
            state.error.requestResetPassword = '';
        },
        authRequestResetPasswordSuccess: (state) => {
            state.actions.requestResetPassword = false;
        },
        authRequestResetPasswordFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.requestResetPassword = false;
            if (action.payload) {
                state.error.requestResetPassword = action.payload;
            }
        },
        authVerifySignUpAttempt: (state, _action: VerifySignUpPayload) => {
            state.actions.verifySignUp = true;
            state.error.verifySignUp = '';
        },
        authVerifySignUpSuccess: (state, action: PayloadAction<boolean>) => {
            state.actions.verifySignUp = false;
            state.verifySignUp = action.payload;
        },
        authVerifySignUpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.verifySignUp = false;
            state.verifySignUp = false;
            if (action.payload) {
                state.error.verifySignUp = action.payload;
            }
        },
        clearVerifySignUp: (state) => {
            state.verifySignUp = false;
        },
        authDetailedSignUpAttempt: (state, _action: DetailedSignUpPayload) => {
            state.actions.detailedSignUp = true;
            state.error.detailedSignUp = '';
        },
        authDetailedSignUpSuccess: (state) => {
            state.actions.detailedSignUp = false;
        },
        authDetailedSignUpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.detailedSignUp = false;
            if (action.payload) {
                state.error.detailedSignUp = action.payload;
            }
        },
        setIsFirstSignUp: (state, action: PayloadAction<boolean>) => {
            state.isFirstSignUp = action.payload;
        },
        authVerifyForgotPasswordEmailAttempt: (state, _action: VerifyForgotPasswordEmailPayload) => {
            state.actions.verifyForgotPasswordEmail = true;
            state.error.verifyForgotPasswordEmail = '';
        },
        authVerifyForgotPasswordEmailSuccess: (state) => {
            state.actions.verifyForgotPasswordEmail = false;
        },
        authVerifyForgotPasswordEmailFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.verifyForgotPasswordEmail = false;
            if (action.payload) {
                state.error.verifyForgotPasswordEmail = action.payload;
            }
        },
        authForgotPasswordNewPasswordAttempt: (state, _action: ForgotPasswordNewPasswordPayload) => {
            state.actions.forgotPasswordNewPassword = true;
            state.error.forgotPasswordNewPassword = '';
        },
        authForgotPasswordNewPasswordSuccess: (state) => {
            state.actions.forgotPasswordNewPassword = false;
        },
        authForgotPasswordNewPasswordFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.forgotPasswordNewPassword = false;
            if (action.payload) {
                state.error.forgotPasswordNewPassword = action.payload;
            }
        },
        authUnsubscribeAttempt: (state, _action: UnsubscribePayload) => {
            state.actions.unsubscribe = true;
            state.error.unsubscribe = '';
        },
        authUnsubscribeSuccess: (state) => {
            state.actions.unsubscribe = false;
        },
        authUnsubscribeFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.unsubscribe = false;
            if (action.payload) {
                state.error.unsubscribe = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
