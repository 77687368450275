import { ApiResponse } from 'apisauce';

import { APIResponse, GatewayResponse } from 'api/types/types';

import { IServerProperty, IPropertyIndividual, WalletBalanceParams, IPropertyPaginator, IAllPropertyDetailDashboards, IRentalAnalytics, IWalletAnalytics } from 'entities/property';

import { GetPropertyParams, PropertySetPropertyIndividualParams, IPropertyGateway, PropertySetApprovaldAgreementsParams, PropertySetDescriptionParams, PropertySetPropertyAmenitiesParams, PropertyCreatePropertyParams, PropertySetTermsAndAgreementsParams, Details1Params, PropertySetPropertyLocationParams, PropertySetImageParams, UploadPropertyDocumentParams, GetPropertyUploadUrlParams, ImageDeleteParams, WalletDetailsParams, GetAllPropertyDetailsParams, GetRentalAnalyticsParams, CreatePropertyParams, EditPropertyDetails } from './PropertyBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PropertyGateway extends IPropertyGateway {
    async getProperty(params: GetPropertyParams): GatewayResponse<IServerProperty> {
        const data = {
            index: params.index,
        };

        const response: ApiResponse<IServerProperty> = await this.api.get('/owner/property?requireIndex=true', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyPaginator(params: GetPropertyParams): GatewayResponse<IPropertyPaginator> {
        const data = {
            index: params.index,
        };

        const response: ApiResponse<IPropertyPaginator> = await this.api.get('/owner/property?requireIndex=false', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getAllPropertyDetails(params: GetAllPropertyDetailsParams): GatewayResponse<IAllPropertyDetailDashboards> {
        const { propertyId } = params;

        const response: ApiResponse<IAllPropertyDetailDashboards> = await this.api.get(`/owner/dashboard/getDashboard?propertyId=${propertyId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getRentalAnalytics(params: GetRentalAnalyticsParams): GatewayResponse<IRentalAnalytics> {
        const { propertyId, dateFrom, dateTo } = params;

        const response: ApiResponse<IRentalAnalytics> = await this.api.get(`/owner/dashboard/getRentalAnalytics?propertyId=${propertyId}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getWalletAnalytics(params: GetRentalAnalyticsParams): GatewayResponse<IWalletAnalytics[]> {
        const { propertyId, dateFrom, dateTo } = params;

        const response: ApiResponse<IWalletAnalytics[]> = await this.api.get(`/owner/dashboard/getWalletAnalytics?propertyId=${propertyId}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyIndividual(params: PropertySetPropertyIndividualParams): GatewayResponse<IPropertyIndividual> {
        const data = {
            id: params.id,
        };

        const response: ApiResponse<IPropertyIndividual> = await this.api.get(`/property/getProperty?id=${data.id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setDescription(params: PropertySetDescriptionParams): GatewayResponse<null> {
        const data = {
            id: params.id,
            description: params.description,
        };
        const response: ApiResponse<null> = await this.api.put('/owner/property/propertyDescription', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async submitForApproval(params: PropertySetApprovaldAgreementsParams): GatewayResponse<string | null> {
        const data = {
            id: params.id,
        };
        const response: ApiResponse<string | null> = await this.api.put('/owner/property/submitForApproval', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setCreateProperty(params: PropertyCreatePropertyParams): GatewayResponse<{ id: string}> {
        const data = {
            name: params.name,
        };
        const response: ApiResponse<{id: string}> = await this.api.post('/owner/property', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setTermsAndAgreements(params: PropertySetTermsAndAgreementsParams): GatewayResponse<string | null> {
        const data = {
            id: params.id,
        };
        const response: ApiResponse<string | null> = await this.api.put(`/owner/property/acceptTerms?id=${data.id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyAmenities(params: PropertySetPropertyAmenitiesParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/owner/property/propertyAmenities', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyDetails(params: Details1Params): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/owner/property/propertyDetails', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async setProperyImage(params: PropertySetImageParams): GatewayResponse<string> {
        const data = {
            category: params.category,
            id: params.id,
            name: params.name,
        };
        const response: ApiResponse<string> = await this.api.get(`/upload/url?category=${data.category}&id=${data.id}&name=${data.name} `, {});
        return this.process(response);
    }

    async setPropertyLocation(params: PropertySetPropertyLocationParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/owner/property/setLocation', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyUploadUrl(params: GetPropertyUploadUrlParams): GatewayResponse<string> {
        const { category, id, name, extension } = params;
        const response: ApiResponse<string> = await this.api.get('/upload/url', { category, id, name, extension }, getHeaders(params.authToken));
        return this.process(response);
    }

    async uploadPropertyDocument(params: UploadPropertyDocumentParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put(params.url, params.doc);
        return this.process(response);
    }

    async setPropertyDeleteImage(params: ImageDeleteParams): GatewayResponse<string> {
        const { category, id, name, fileName } = params;
        const response: ApiResponse<string> = await this.api.delete('/upload', { category, name, id, fileName }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyWalletDetails(params: WalletDetailsParams): GatewayResponse<WalletBalanceParams | null> {
        const { propertyId } = params;
        const response: ApiResponse<WalletBalanceParams | null> = await this.api.get(`/property/getWallet?propertyId=${propertyId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async createProperty(params: CreatePropertyParams): GatewayResponse<string | null> {
        const data = {
            name: params.name,
        };
        const response: ApiResponse<string | null> = await this.api.post('/property/createUpdateProperty', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async editPropertyDetails(params: EditPropertyDetails): GatewayResponse<string | null> {
        const { propertyId, details, location, amenities, description, unitNo } = params;

        const response: ApiResponse<string | null> = await this.api.post('/property/createUpdateProperty', { propertyId, details, location, amenities, description, unitNo }, getHeaders(params.authToken));
        return this.process(response);
    }
}
