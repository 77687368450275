import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Utils from 'lib/Utils';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { GatewayResponseStatus } from 'api/types/types';
import PropertyGateway from 'api/Property';
import { SetImageDeleteActionPayload } from 'redux/slices/property/types';

export default function* watchGetPropertyDeleteImage(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetDeleteImageAttempt', handleGetUploadUrl, api);
}

function* handleGetUploadUrl(api: PropertyGateway, data: SetImageDeleteActionPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { category, id, name, fileName } = data.payload;

        const response = yield* call([api, api.setPropertyDeleteImage], { category, id, name, fileName, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.propertySetDeleteImageFailure(response.message));
            toast.error(response.message);
            return;
        } if (response.status === GatewayResponseStatus.Success) {
            yield put(Actions.propertySetDeleteImageSuccess(response.data));
            yield put(Actions.getPropertyImageDataAttempt({ id }));
            const imageData = yield* select(Selectors.getPropertyImageData);
            if (imageData.images.length) {
                put(Actions.getPropertyIndividualAttempt({ id }));
                window.location.reload();
            }
            toast.success(Translate.t('Property.PropertyImagesAdd.imageDeleteSuccess'));
        }
    }
}
