import { InitState } from 'redux/slices/auth';

const getLoginAttempting = (state: InitState): boolean => state.actions.login || false;
const getLoginError = (state: InitState): string => state.error.login || '';

const getSignUpAttempting = (state: InitState): boolean => state.actions.signUp || false;
const getSignUpError = (state: InitState): string => state.error.signUp || '';
const getLogout = (state: InitState): boolean => state.actions.logout || false;

const getAuthToken = (state: InitState): string => state.authToken || '';

const getStartupAttempting = (state: InitState): boolean => state.actions.startup || false;
const getStartupError = (state: InitState): string => state.error.startup || '';

const getRequestResetPasswordAttempting = (state: InitState): boolean => state.actions.requestResetPassword || false;
const getRequestResetPasswordError = (state: InitState): string => state.error.requestResetPassword || '';

const getVerifySignUpAttempt = (state: InitState): boolean => state.actions.verifySignUp || false;
const getVerifySignUpError = (state: InitState): string => state.error.verifySignUp || '';

const getVerifySignUp = (state: InitState): boolean => state.verifySignUp || false;

const getDetailedSignUpAttempt = (state: InitState): boolean => state.actions.detailedSignUp || false;
const getDetailedSignUpError = (state: InitState): string => state.error.detailedSignUp || '';

const getIsFirstSignUp = (state: InitState): boolean => state.isFirstSignUp || false;

const getVerifyForgotPasswordEmailAttempt = (state: InitState): boolean => state.actions.verifyForgotPasswordEmail || false;
const getVerifyForgotPasswordEmailError = (state: InitState): string => state.error.verifyForgotPasswordEmail || '';

const getForgotPasswordNewPasswordAttempt = (state: InitState): boolean => state.actions.forgotPasswordNewPassword || false;
const getForgotPasswordNewPasswordError = (state: InitState): string => state.error.forgotPasswordNewPassword || '';

const getUnsubscribeAttempt = (state: InitState): boolean => state.actions.unsubscribe || false;
const getUnsubscribeError = (state: InitState): string => state.error.unsubscribe || '';

export default {
    getLoginAttempting,
    getLoginError,

    getSignUpAttempting,
    getSignUpError,
    getLogout,

    getAuthToken,

    getStartupAttempting,
    getStartupError,

    getRequestResetPasswordAttempting,
    getRequestResetPasswordError,

    getVerifySignUpAttempt,
    getVerifySignUpError,
    getVerifySignUp,

    getDetailedSignUpAttempt,
    getDetailedSignUpError,

    getIsFirstSignUp,

    getVerifyForgotPasswordEmailAttempt,
    getVerifyForgotPasswordEmailError,

    getForgotPasswordNewPasswordAttempt,
    getForgotPasswordNewPasswordError,

    getUnsubscribeAttempt,
    getUnsubscribeError,
};
