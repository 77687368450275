import React, { useState, useEffect, useRef } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

import Modal from 'components/Modal';

import Translate from 'lib/Translate';
import { Category, PMTransactionTypeEnum } from '@dm/types';
import dayjs from 'dayjs';
import { ICreatePMTransaction } from 'entities/packages';
import { Colors } from '@dm/bigfish';
import icons from 'assets/icons';
import { IAllPropertyDetailDashboards } from 'entities/property';
import PropertyImage from 'assets/images/building.png';
import StyledComponent from './styles/NewTransactionModalStyle';

// ! interface zone
// * props interface
export interface CreateTransactionModalProps {
    propertyId: string;
    isPMTransactionModalOpen: boolean;
    setPMTransactionModalOpen: (state: boolean) => void;
    createPMTransactionAttempt: boolean;
    createPMTransactionFailure: string;
    createdTransactionId: string;
    createPMTransaction: (data: ICreatePMTransaction) => void;
    attempLoadingUpload: boolean;
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
    allPropertyDetails: IAllPropertyDetailDashboards;

}

// ! JSX function component
const CreateTransactionModal = (props: CreateTransactionModalProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        propertyId,
        isPMTransactionModalOpen,
        setPMTransactionModalOpen,
        createPMTransactionAttempt,
        createPMTransactionFailure,
        createdTransactionId,
        createPMTransaction,
        attempLoadingUpload,
        getUploadUrl,
        allPropertyDetails,
    } = props;

    const {
        MainContainer,
        ModalTitleContainer,
        ModalContentContainer,
        Title,
        SpanContainer,
        TransactionDetailsContainer,
        UpperTransactionDetailsContainer,
        SelectedPropertyContainer,
        PropertyListingRowButton,
        PropertyListingsColumnWithImage,
        NameContainer,
        DetailsContainer,
        DetailsContainerOverlay,
        TransactionForm,
        StyledInput,
        StyledDatePicker,
        StyledButton,
        UploadDocumentsContainer,
        DateTimePicker,
        StyledButtonWrapper,
        LoadingContainer,
        UploadImage,
    } = StyledComponent;

    // ! useState Zone
    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [uploadedFile, setUploadedFile] = useState<File>();

    const [transactionDate, setTransactionDate] = useState<Date>(new Date());

    // ! useEffect zone
    useEffect(() => {
        if (createdTransactionId !== null && uploadedFile !== undefined) {
            const { type } = uploadedFile;
            let documentExtension = '';

            switch (type) {
                case 'application/pdf': documentExtension = '.pdf'; break;
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }
            getUploadUrl(Category.PropertyManagementTransaction, propertyId, createdTransactionId, documentExtension, uploadedFile);
        }
    }, [createdTransactionId, uploadedFile]);

    // ! functions
    const onCloseModal = () => {
        setAmount('');
        setRemarks('');
        setPMTransactionModalOpen(false);
        setUploadedFile(undefined);
    };

    const onClickCheckInput = (
        newPropertyId: string,
        transactionAmount: number,
        transactionRemarks: string,
        transactionDateData: Date,
    ) => {
        const data: ICreatePMTransaction = {
            propertyId: newPropertyId,
            amount: transactionAmount,
            description: transactionRemarks,
            transactionDate: transactionDateData.toISOString(),
            transactionType: PMTransactionTypeEnum.transaction,
        };

        if (newPropertyId === '') {
            toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionSelectProperty'));
            return;
        }
        if (transactionAmount === 0) {
            toast.error('Enter transaction amount!');
            return;
        }
        if ((((transactionAmount < 0) || ((Number.isNaN(transactionAmount)))))) {
            if ((transactionAmount < 0)) {
                toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionMoreThan0'));
            } else {
                toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionOnlyNumbers'));
            }
            return;
        }

        if ((transactionAmount > 0) && (!(Number.isNaN(transactionAmount)))) {
            if (propertyId.length) {
                createPMTransaction(data);
            } else {
                toast.error(Translate.t('PropertyRpTransactions.PropertyRpTransactionSelectProperty'));
            }
        }
    };

    const onDatepickerChangeHanlder = (date: Date) => {
        setTransactionDate(date);
    };

    const renderFileUpload = () => {
        const hiddenFileInput = useRef<HTMLInputElement>(null);

        const handleClick = () => {
            hiddenFileInput.current?.click();
        };

        const onClearFile = () => {
            if (uploadedFile !== undefined) {
                setUploadedFile(undefined);
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const fileUploaded = event.target.files?.[0];
            if (fileUploaded !== undefined) {
                if (uploadedFile === undefined) {
                    setUploadedFile(fileUploaded);
                }
                const { type } = fileUploaded;
                let documentExtension = '';

                switch (type) {
                    case 'application/pdf': documentExtension = '.pdf'; break;
                    case 'image/jpg': documentExtension = '.jpg'; break;
                    case 'image/jpeg': documentExtension = '.jpeg'; break;
                    case 'image/png': documentExtension = '.png'; break;
                    default:
                }

                if ((documentExtension === ('.png')) || (documentExtension === ('.jpeg')) || (documentExtension === ('.jpg')) || (documentExtension === ('.pdf'))) {
                    toast.success('File uploaded!');
                } else {
                    setUploadedFile(undefined);
                    toast.error(Translate.t('Packages.PaymentUpload.Filetypenotsupported'));
                }
            }
        };

        return (
            <>
                {
                    uploadedFile
                    && (
                        <SpanContainer
                            style={{ right: 12, top: 50, width: '20px', height: '20px', position: 'absolute' }}
                            aria-hidden='true'
                            role='button'
                            onClick={() => onClearFile()}
                        >
                            &times;
                        </SpanContainer>
                    )
                }
                <UploadDocumentsContainer
                    onClick={handleClick}
                >
                    {
                        attempLoadingUpload
                            ? (
                                <LoadingContainer>
                                    <Oval
                                        height={100}
                                        width={100}
                                        color={Colors.secondary}
                                        secondaryColor={Colors.primary}
                                    />
                                    <p>Uploading documents...</p>
                                </LoadingContainer>
                            ) : (
                                <>
                                    <UploadImage src={uploadedFile ? icons.UploadFileCheck : icons.UploadFile} height={50} width={50} />
                                    {
                                        uploadedFile ? (
                                            <p>{uploadedFile.name}</p>
                                        ) : (
                                            <p>Upload Document</p>
                                        )
                                    }
                                </>
                            )
                    }
                </UploadDocumentsContainer>
                <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
            </>
        );
    };

    // ! render zone
    const renderSubmitButton = (
        newPropertyId: string,
        transactionAmount: string,
        transactionRemarks: string,
        transactionDateData: Date,
    ) => {
        if (createPMTransactionAttempt || attempLoadingUpload) {
            return (
                <StyledButton loading label='creating...' disabled />
            );
        }
        return (
            <StyledButton label='Create Transaction' onClick={() => onClickCheckInput(newPropertyId, Number(transactionAmount), transactionRemarks, transactionDateData)} />
        );
    };
    // * main render
    return (
        <Modal
            height='fit-content'
            width='800px'
            show={isPMTransactionModalOpen}
        >
            <MainContainer>
                <ModalTitleContainer>
                    <Title>
                        Create Property Management Transaction
                    </Title>
                    <SpanContainer onClick={() => onCloseModal()}>
                        &times;
                    </SpanContainer>
                </ModalTitleContainer>
                <ModalContentContainer>
                    <TransactionDetailsContainer>
                        <UpperTransactionDetailsContainer>
                            <SelectedPropertyContainer>
                                <PropertyListingRowButton
                                    style={{ marginBottom: 0, borderRadius: '20px' }}
                                    key={propertyId}
                                    checked
                                >
                                    <PropertyListingsColumnWithImage>
                                        <NameContainer>
                                            {allPropertyDetails.propertyDetails.name}
                                        </NameContainer>
                                        <img
                                            src={PropertyImage}
                                            alt='properties images'
                                        />
                                    </PropertyListingsColumnWithImage>
                                    <p>{allPropertyDetails.propertyDetails.unit}</p>
                                    <p>
                                        {allPropertyDetails.propertyDetails.location?.city ?? ''}
                                    </p>
                                </PropertyListingRowButton>
                            </SelectedPropertyContainer>
                        </UpperTransactionDetailsContainer>
                        <DetailsContainer>
                            <DetailsContainerOverlay propertySelected />
                            <h2>Transaction details</h2>
                            <TransactionForm>
                                Amount:
                                <StyledInput width='50%' value={amount} onChange={(e) => setAmount(e.target.value)} />
                                Remark:
                                <StyledInput width='50%' value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                                Date:
                                <StyledButtonWrapper>
                                    <div style={{ marginRight: '-1%', borderRadius: '12px', height: '75%', width: '100%' }}>
                                        <DateTimePicker
                                            className='customized-datepicker'
                                            onChange={(newDate: Date) => onDatepickerChangeHanlder(newDate)}
                                            value={(!transactionDate) ? 'Select Date' : dayjs(transactionDate).format('dddd,   DD-MM-YY   h:mm A')}
                                            selected={transactionDate}
                                            showTimeSelect
                                            dateFormat='MMMM d, yyyy HH:mm'
                                            shouldCloseOnSelect
                                        />
                                    </div>
                                </StyledButtonWrapper>
                            </TransactionForm>
                            {renderFileUpload()}
                            {renderSubmitButton(propertyId, amount, remarks, transactionDate)}
                        </DetailsContainer>
                    </TransactionDetailsContainer>
                </ModalContentContainer>
            </MainContainer>
        </Modal>
    );
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    isPMTransactionModalOpen: Selectors.getUiIsPMTransactionModalOpen(state),
    createPMTransactionAttempt: Selectors.packageGetCreatePMTransactionAttempting(state),
    createPMTransactionFailure: Selectors.packageGetCreatePMTransactionError(state),
    createdTransactionId: Selectors.packageGetCreatedPMTransactionId(state),
    attempLoadingUpload: Selectors.packagesgetPackagesUploadUrlAttempting(state),
    allPropertyDetails: Selectors.getAllPropertyDetails(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setPMTransactionModalOpen: (state: boolean) => dispatch(Actions.setPMTransactionModalOpen(state)),
    createPMTransaction: (data: ICreatePMTransaction) => dispatch(Actions.createPMTransactionAttempt(data)),
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPackagesUploadUrlAttempt({ category, id, name, extension, document })),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionModal);
