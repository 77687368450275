import React, { useState, useEffect, FunctionComponent } from 'react';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { Circles } from 'react-loader-spinner';
import { Colors } from '@dm/bigfish';
import Modal from 'components/Modal';

import InnerImageZoom from 'react-inner-image-zoom';
import styled from 'styled-components';
import { ViewAllPMTransactionResponse } from '@dm/types';
import media from 'lib/Breakpoints';

interface FileListModalProps {
    isModalOpen: boolean;
    fileList: ViewAllPMTransactionResponse['fileUrl'];
    setIsModalOpen: (state: boolean) => void;
}

const FileListModal: FunctionComponent<FileListModalProps> = (props: FileListModalProps) => {
    const {
        isModalOpen,
        fileList,
        setIsModalOpen,
    } = props;

    const [imageUrl, setImageUrl] = useState<string>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (fileList.length > 0) {
            setImageUrl(fileList[0].url);
        }
    }, [fileList]);

    useEffect(() => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }, [imageUrl]);

    const downloadFile = (url: string) => {
        window.location.href = url;
    };

    const renderFileList = () => {
        if (fileList.length > 0) {
            return (
                <>
                    {
                        fileList.map((item) => {
                            const fileUrl = item.url.split('/');
                            const fileName = fileUrl[fileUrl.length - 1].split('?')[0];
                            return (
                                <>
                                    <FileDownloadLink href={item.url} target='_blank' download={fileName}>
                                        {fileName}
                                    </FileDownloadLink>
                                    <FileButton isSelected={item.url === imageUrl} onClick={() => setImageUrl(item.url)}>{fileName}</FileButton>
                                </>
                            );
                        })
                    }
                </>
            );
        }

        return (
            <p>There is no documents available to view</p>
        );
    };

    const renderFileViewContainer = () => {
        if (isLoading) {
            return (
                <LoadingContainer>
                    <Circles color={Colors.primary} />
                </LoadingContainer>
            );
        }

        if (imageUrl) {
            const fileUrl = imageUrl.split('/');
            const fileName = fileUrl[fileUrl.length - 1].split('?')[0];
            const fileType = fileName.split('.')[1].toLowerCase();

            if (fileType === 'pdf') {
                return (
                    <iframe
                        src={imageUrl}
                        style={{ width: '700px', height: '600px' }}
                        id='FileViewContainer'
                        title='File view container'
                    />
                );
            }

            return (
                <InnerImageZoom
                    src={imageUrl}
                    zoomScale={1.5}
                />
            );
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                No Image
            </div>
        );
    };

    return (
        <Modal
            width='60%'
            height='fit-content'
            show={isModalOpen}
            closeCursor
            onClickClose={() => [setIsModalOpen(false), setImageUrl('')]}
        >
            <ModalTitle
                style={{

                }}
            >
                View Files
            </ModalTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', height: '90%' }}>
                <ButtonContainer>
                    {renderFileList()}
                </ButtonContainer>
                <FileViewContainer>
                    {renderFileViewContainer()}
                </FileViewContainer>
            </div>
        </Modal>
    );
};

const ModalTitle = styled.div`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    gap: 20px;

    ${media.sm`
        width: 100%;
    `}
`;

const FileViewContainer = styled.div`
    width: 68%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px dashed grey;
    border-radius: 10px;
    overflow: auto;

    ${media.sm`
        display: none;
    `}
`;

const FileDownloadLink = styled.a`
    text-decoration: none;
    color: ${Colors.black};
    display: none;
    background-color: ${Colors.primaryLight};
    padding: 10px;
    border-radius: 10px;

    ${media.sm`
        display: block;
    `}
`;

const FileButton = styled.div<{isSelected: boolean}>`
    border: 2px solid ${Colors.primaryLight};
    background-color: ${props => (props.isSelected ? Colors.primaryLight : Colors.white)};
    padding: 10px;
    border-radius: 10px;

    &:hover{
        background-color: ${Colors.primaryLight};
        cursor: pointer;
    }

    ${media.sm`
        display: none;
    `}
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 500px;
`;

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.getUiFileListModalOpen(state),
    fileList: Selectors.getUiGetFileList(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileListModal);
