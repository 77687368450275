import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetTermsActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchTermsProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertyAcceptTermsAttempt', handleTerms, api);
}

function* handleTerms(api: PropertyGateway, data: SetTermsActionPayload) {
    const { id } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);
    const response = yield* call([api, api.setTermsAndAgreements], { id, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success(Translate.t('GeneralUI.Saved'));
        yield put(Actions.propertyAcceptTermsSuccess(response.data));
        yield put(Actions.setPropertyTermsAndConditionSubmitted(true));

        if (!response.data) {
            yield put(Actions.propertyAcceptTermsFailure(Translate.t('GeneralUI.SomethingWrong')));
        }
    } else {
        yield put(Actions.propertyAcceptTermsFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralUI.Failed'));
            } else {
                toast.error(Translate.t('GeneralUI.TryAgain'));
            }
        }
    }
}
