import { Colors, Fonts } from '@dm/bigfish';
import styled, { keyframes } from 'styled-components';
import SVG from 'react-inlinesvg';

const slideInTop = keyframes`
    0% {
    transform: translateY(-20px);
    opacity: 0;
    }
    100% {
        transform: translateY(10px);
        opacity: 1;
    }
`;

const slideOutTop = keyframes`
    0% {
    transform: translateY(0);
    opacity: 0;
    }
    100% {
        transform: translateY(-20px);
        opacity: 1;
    }
`;

const shimmer = keyframes`
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
`;

const PaginatorContainer = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    justify-content: left;
`;

const PropertyNameContainer = styled.div`
    width: 650px;
    height: 40px;
    display: flex;
    margin-right: 40px;
    overflow: hidden;

    span{
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: ${Fonts.primary};
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const PropertyNameContainerMobile = styled.div`
    width: 650px;
    height: 40px;
    display: none;
    overflow: hidden;

    span{
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: ${Fonts.primary};
    }

    @media (max-width: 768px) {
        display: flex;
    }
`;

const PropertyName = styled.button<{ selected?: boolean }>`
    border: none;
    font-family: ${Fonts.primary};
    font-weight: bold;
    font-size: 14px;
    display: block;
    padding: 0 10px;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    height: 40px;
    border-radius: 20px;
    color: ${props => (props.selected ? 'white' : 'black')};
    background-color: ${props => (props.selected ? Colors.secondaryLight : 'white')};
    cursor: pointer;
    flex: 0 0 33.33%;

    @media (max-width: 768px) {
        flex: 0 0 100%;
    }
`;

const LeftArrowButton = styled.button`
        background-color: white;

    color: ${Colors.primary};
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const LeftIcon = styled(SVG)`
    width: 40px;
    height: 25px;
    color: ${Colors.primary};
    transform: scaleX(-1);

    &:hover{
        transform: scaleX(-1) scale(1.1);
        transition: 0.1s ease-in-out;
    }
`;

const RightArrowButton = styled.button`
    background-color: white;
    color: ${Colors.primary};
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const RightIcon = styled(SVG)`
    width: 40px;
    height: 25px;
    color: ${Colors.primary};

    &:hover{
        transform: scale(1.1);
        transition: 0.1s ease-in-out;
    }
`;

const ViewAllPropertyButton = styled.button`
    background-color: white;
    color: ${Colors.primary};
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;
`;

const MenuIcon = styled(SVG)`
    width: 40px;
    height: 25px;
    color: ${Colors.primary};

    &:hover{
        transform: scale(1.1);
        transition: 0.1s ease-in-out;
    }
`;

const AddPropertyButton = styled.button`
    background-color: ${Colors.primary};
    border-radius: 50%;
    position: absolute;
    right: 0;
    border: none;
    height: 40px;
    width: 40px;

    &:hover{
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const AddIcon = styled(SVG)`
    width: 12px;
    height: 12px;
    color: ${Colors.black};
`;

const DropdownContent = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;
    height: fit-content;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border-radius: 10px;
    padding: 10px;
    z-index: 10;

    animation: ${slideInTop} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

const DropdownItem = styled.div`
    border-radius: 10px;
    background-color: white;
    padding: 10px 0;
    margin: 5px 0;
    font-family: ${Fonts.primary};
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-inline: 10px;
    &:hover{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    cursor: pointer;
`;

const SkeletonWrapper = styled.div`
    display: inline-block;
    border-radius: 20px;
    width: 100%;
    height: 40px;

    background: linear-gradient(to right, #f6f7f8 8%, #e9e8e8 18%, #f6f7f8 33%);
    background-size: 1000px 100%;
    animation: ${shimmer} 2s linear infinite;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

`;

export default {
    AddIcon,
    AddPropertyButton,
    DropdownContent,
    DropdownItem,
    ViewAllPropertyButton,
    MenuIcon,
    LeftArrowButton,
    LeftIcon,
    PaginatorContainer,
    PropertyName,
    PropertyNameContainer,
    PropertyNameContainerMobile,
    RightArrowButton,
    RightIcon,
    SkeletonWrapper,
};
