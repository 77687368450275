import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import { PackagesPricingPayload } from 'redux/slices/packages/types';

import Utils from 'lib/Utils';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchGetPackages(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesGetPricingAttempt', handlePackages, api);
}

function* handlePackages(api: PackagesGateway, data: PackagesPricingPayload) {
    const { propertyId } = data.payload;

    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const response = yield* call([api, api.getPackagesPricing], {
            propertyId,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
            // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetPricingFailure('Something went wrong. Please try again.'));
                return;
            }
            yield put(Actions.packagesGetPricingSuccess(response.data));
        } else {
            // handle error
            yield put(Actions.packagesGetPricingFailure(response.message));
        }
    }
}
