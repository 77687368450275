import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetLocationPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchLocationProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetLocationAttempt', handleLocation, api);
}

function* handleLocation(api: PropertyGateway, data: SetLocationPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const response = yield* call([api, api.setPropertyLocation], { data: data.payload, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success(Translate.t('GeneralUI.Saved'));
        yield put(Actions.propertySetLocationSuccess(response.data));
        yield put(Actions.setPropertyLocationSubmitted(true));
        const id = { id: data.payload._id };
        yield put(Actions.getPropertyIndividualAttempt(id));
        const CollapsibleOpenOrClose = yield* select(Selectors.setIsPropertyOpenDetailsCollapsible);
        yield put(Actions.setPropertyOpenDetailsCollapsible(!CollapsibleOpenOrClose));
        yield put(Actions.setPropertyLocationCloseCollapsibleAfterSubmitted('Close'));

        if (!response.data) {
            yield put(Actions.propertySetLocationFailure(Translate.t('GeneralUI.SomethingWrong')));
        }
    } else {
        yield put(Actions.propertySetLocationFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralUI.Failed'));
            } else {
                toast.error(Translate.t('GeneralUI.TryAgain'));
            }
        }
    }
}
