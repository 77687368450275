import React, { useState, forwardRef, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';

import SVG from 'react-inlinesvg';

export interface CardCollapsibleProps {
  /**
  * Pre text
  */
  preText?: string;
  /**
   * Title
   */
  title: string;
  /**
   * Subtitle
   */
  subtitle?: string;
  /**
   * Length
   */
  subtitleLength?: number;
  /**
   * Icon
   */
  icon?: string;
  /**
   * Chip text
   */
  chipText?: string;
  /**
   * Jsx element
   */
  children: React.ReactNode;
  /**
   * Focused state
   */
  focused?: boolean;
  /**
  * Style
  */
   style?: React.CSSProperties;

   onclick: () => void;

}

const truncateText = (text: string, maxLength: number): string => (
    text.length > maxLength ? `${text.substring(0, maxLength)}…` : text
);

export const CardCollapsible = forwardRef((props: CardCollapsibleProps, ref:any) => {
    const { preText, title, subtitle, subtitleLength = 38, icon, chipText, children, focused, style, onclick, ...otherProps } = props;
    const [cardCollapsibeOpen, setCardCollapsibeOpen] = useState(false);

    const openCollapsible = () => { setCardCollapsibeOpen(true); };
    const closeCollapsible = () => { setCardCollapsibeOpen(false); };

    useImperativeHandle(ref, () => ({
        openCollapsible,
        closeCollapsible,
    }));

    return (
        <CardContainer style={style} {...otherProps}>
            <CardHeader focused={focused}>
                <CardDescription onClick={onclick}>
                    {preText && <PreText>{preText}</PreText>}

                    <Title onClick={onclick}>{title}</Title>

                    {subtitle && <Subtitle>{truncateText(subtitle, subtitleLength)}</Subtitle>}
                    {chipText && <ChipText>{chipText}</ChipText>}

                    {icon && <StyledIcon src={icon} />}
                </CardDescription>
                <CardHeaderArrow isOpen={cardCollapsibeOpen} onClick={() => setCardCollapsibeOpen(!cardCollapsibeOpen)}>&lsaquo;</CardHeaderArrow>
            </CardHeader>

            <CardDropdownContent isOpen={cardCollapsibeOpen}>
                {children}
            </CardDropdownContent>

        </CardContainer>
    );
});

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 824px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

const CardDescription = styled.div`
  display: flex;
  cursor: pointer;
  font-family: Helvetica;
  text-transform: capitalize;
`;

const PreText = styled.h5`
  display: flex;
  align-items: center;
  margin: 0 32px 0 0;
  padding: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #F2865E;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
  font-size: 42px;
  line-height: 48px;
  font-weight: bold;
`;

const Subtitle = styled.small`
  display: flex;
  align-items: flex-end;
  margin: 0 0 7px 18px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
`;

const ChipText = styled.span`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 8px 0 8px 40px;
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  background: #F2B47E;
  color: white;
  border-radius: 8px;
  max-height: 40px;
  align-self: center;
`;

const StyledIcon = styled(SVG)`
  height: 20px;
  width: 20px;
  margin: auto;
  margin-left: 32px;
  color: #FB7E34;
`;

const CardHeaderArrow = styled.div<{isOpen: boolean}>`
  font-size: 40px;
  user-select: none;
  cursor: pointer;
  transition: all .3s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
  margin-left: 5px;
`;

const CardDropdownContent = styled.div<{isOpen: boolean}>`
  display: block;
  box-sizing: border-box;
  border-radius: 0 0 12px 12px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  background-color: white;
  ${(props) => props.isOpen && css`
    max-height: 100vh;
  `}
`;

/* Need to declare below all other element because need to override css element of child element after focused is true */
const CardHeader = styled.div<{focused?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px 20px 20px;
  background: #FFF2E7;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  ${(props) => props.focused && css`
    background: #FFCFA6;
    ${PreText}, ${Title}, ${Subtitle}, ${StyledIcon}{
      color: white;
    }
  `}
`;
