import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { IPropertyIndividual } from 'entities/property';

import NavActions from 'lib/NavActions';
import Translate from 'lib/Translate';
import { Bars } from 'react-loader-spinner';
import { BreadCrumb, Colors, ImageCarousel, LineInput, IImageCarousel } from '@dm/bigfish';

import MainContainer from 'components/MainContainer';
import DateInput from 'components/DateInput';
import SelectionInput from 'components/SelectionInput';

import SupportInfo from '../../components/SupportInfo';

interface PropertyDetailsProps {
    loading: boolean;
    propertyDetails: IPropertyIndividual;
    getPropertyDetails: (id: string) => void;
}

interface NavParams {
    propertyIndividualId: string;
}

const ViewPropertyDetails = (props: PropertyDetailsProps): JSX.Element => {
    const { loading, propertyDetails, getPropertyDetails } = props;

    const [propertyImageCarousel, setPropertyImageCarouse] = useState<IImageCarousel[] | []>([]);

    const [propertyName, setPropertyName] = useState<string | undefined>('');
    const [propertyDesc, setPropertyDesc] = useState<string | undefined>('');
    const [propertyType, setPropertyType] = useState<number | undefined>(0);
    const [landTitle, setLandTitle] = useState<number | undefined>(0);
    const [unitType, setUnitType] = useState<string | undefined>('');
    const [propertyTitle, setPropertyTitle] = useState<number | undefined>(0);
    const [propertyTenure, setPropertyTenure] = useState<number | undefined>(0);
    const [propertyStorey, setPropertyStorey] = useState<string | undefined>('');
    const [builtSize, setBuiltSize] = useState<string | undefined>('');
    const [builtPrice, setBuiltPrice] = useState<string | undefined>('');
    const [builtDimension, setBuiltDimension] = useState<string | undefined>('');
    const [postedDate, setPostedDate] = useState(new Date());
    const [furnishing, setFurnishing] = useState<number | undefined>(0);
    const [occupancy, setOccupancy] = useState<string | undefined>('');
    const [facingDirection, setFacingDirection] = useState<string | undefined>('');

    const useLocationstate = useLocation();

    const { propertyIndividualId } = useLocationstate.state as NavParams;

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Property.PropertyViewDetails.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Property.PropertyViewDetails.BreadcrumblabelPropertyListing'),
                onClick: () => { return false; },
            },
        ],
    );

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const createImageCarouselGallery = () => {
        const listImageCarousel:IImageCarousel[] = [];

        if (propertyDetails?.images && propertyDetails.images.length) {
            propertyDetails.images.forEach(({ name, url }, mainIndex) => {
                url.forEach((imageUrl, imageUrlIndex) => {
                    const imagesObject = ({
                        image: imageUrl,
                        desc: `${name} ${imageUrlIndex + 1}`,
                    });

                    if (listImageCarousel.length < 5) {
                        listImageCarousel.push(imagesObject);
                    }

                    if (mainIndex === propertyDetails.images.length - 1 && imageUrlIndex === url.length - 1) {
                        setPropertyImageCarouse(listImageCarousel);
                    }
                });
            });
        }
    };

    useEffect(() => {
        if (propertyIndividualId) {
            getPropertyDetails(propertyIndividualId);
        }
    }, []);

    useEffect(() => {
        if (propertyDetails) {
            setPropertyName(propertyDetails.name);
            setPropertyDesc(propertyDetails.description);
            setPropertyType(propertyDetails.details?.type);
            setLandTitle(propertyDetails.details?.landTitle);
            setUnitType(propertyDetails.details?.unitType ?? '');
            setPropertyTitle(propertyDetails.details?.titleType);
            setPropertyTenure(propertyDetails.details?.tenure);
            setPropertyStorey(propertyDetails.details?.storey.toString());
            setBuiltSize(`${propertyDetails.details?.buildUpSize || '0'} sq. ft.`);
            setBuiltPrice(`${propertyDetails.details?.buildUpPricePerSqft || '0'} per sq. ft.`);
            setBuiltDimension(`${propertyDetails.details?.builtUpDimension}`);
            setFurnishing(propertyDetails.details?.furnishing);
            setOccupancy(propertyDetails.details?.occupancy);
            setFacingDirection(propertyDetails.details?.facingDirection);

            createImageCarouselGallery();
        }
    }, [propertyDetails]);

    const PropertyTypeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TypeApartment'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TypeLanded'),
        },
    ];

    const PropertyTitleOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsStrata'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsMaster'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsIndividual'),
        },
    ];

    const LandTitleOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsResidential'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsCommercial'),
        },
    ];

    const TenureTypeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.tenureOptionsFreehold'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.tenureOptionsLeaseHold'),
        },
    ];

    const FurnishingTypeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.furnishingOptionFully'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.furnishingOptionPartial'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.furnishingOptionNone'),
        },
    ];

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>

                {
                    !loading ? (
                        <>
                            <ImageCarouselContainer>
                                {propertyImageCarousel.length > 1 && <ImageCarousel style={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }} data={propertyImageCarousel} />}
                            </ImageCarouselContainer>
                            <DetailsViewContainer>
                                <SideSplitterContainer>
                                    <DetailsInfoContainer>
                                        <BasicDetailsContainer>
                                            <Title>{propertyName || 'Property'}</Title>
                                            <Text>{propertyDesc}</Text>
                                        </BasicDetailsContainer>
                                        <PropertyDetailsContainer>
                                            <Title>{Translate.t('Property.PropertyDetails.CollapsibleTitle')}</Title>
                                            <PropertyDetailsInfoContainer>
                                                <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyType')} data={PropertyTypeOptions} currentValue={propertyType} onChangeSelection={(e) => setPropertyType(+e.target.value)} disabled />
                                                <SelectionInput label='Title' data={LandTitleOptions} currentValue={landTitle} onChangeSelection={(e) => setLandTitle(+e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.UnitType')} value={unitType} onChangeText={(e) => setUnitType(e.target.value)} disabled />
                                                <SelectionInput label={Translate.t('Property.PropertyDetails.LandTitleLabel')} data={PropertyTitleOptions} currentValue={propertyTitle} onChangeSelection={(e) => setPropertyTitle(+e.target.value)} disabled />
                                                <SelectionInput label={Translate.t('Property.PropertyDetails.TenureLabel')} data={TenureTypeOptions} currentValue={propertyTenure} onChangeSelection={(e) => setPropertyTenure(+e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.StoreyLabel')} value={propertyStorey} onChangeText={(e) => setPropertyStorey(e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.BuildUpSizeLabel')} value={builtSize} onChangeText={(e) => setBuiltSize(e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.BuiltupPriceperSqftLabel')} value={builtPrice} onChangeText={(e) => setBuiltPrice(e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.BuiltUpDimensionSqftLabel')} value={builtDimension} onChangeText={(e) => setBuiltDimension(e.target.value)} disabled />
                                                <DateInput label={Translate.t('Property.PropertyViewDetails.PostedDate')} date={postedDate} onChangeDate={(date) => setPostedDate(date)} disabled />
                                                <SelectionInput label={Translate.t('Property.PropertyDetails.FurnishingLabel')} data={FurnishingTypeOptions} currentValue={furnishing} onChangeSelection={(e) => setFurnishing(+e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.OccupancyLabel')} value={occupancy} onChangeText={(e) => setOccupancy(e.target.value)} disabled />
                                                <LineInput label={Translate.t('Property.PropertyDetails.FacingDirectionLabel')} value={facingDirection} onChangeText={(e) => setFacingDirection(e.target.value)} disabled />
                                            </PropertyDetailsInfoContainer>
                                        </PropertyDetailsContainer>
                                        <RoomDetailsContainer>
                                            <Title>{Translate.t('Property.PropertyViewDetails.TitleRoomDetails')}</Title>
                                            <PropertyImageGalleryContainer>
                                                {
                                                    propertyDetails?.images && propertyDetails?.images.map((roomDetail, roomDetailIndex) => {
                                                        const roomDetailKey = roomDetailIndex + roomDetail.name;

                                                        return (
                                                            propertyDetails?.images.length ? (
                                                                <PropertyImageGalleryCard key={roomDetailKey}>
                                                                    <PropertyImageGalleryTitle>{roomDetail.name}</PropertyImageGalleryTitle>
                                                                    <PropertyImageGallery>
                                                                        {
                                                                            roomDetail.url.map((roomImage, roomImageIndex) => {
                                                                                const roomImageKey = roomImageIndex + roomImage;
                                                                                return (
                                                                                    <img src={roomImage} alt='room images' key={roomImageKey} />
                                                                                );
                                                                            })
                                                                        }
                                                                    </PropertyImageGallery>
                                                                    {propertyDetails?.images.length > 1 && <Text style={{ fontSize: '11px', lineHeight: 1, fontStyle: 'italic', color: Colors.greyDark }}>{Translate.t('Property.PropertyViewDetails.PropertyDetailsInstruction')}</Text>}
                                                                </PropertyImageGalleryCard>
                                                            ) : (
                                                                <div>
                                                                    <h4>{Translate.t('Property.PropertyViewDetails.NoImagesFound')}</h4>
                                                                </div>
                                                            ));
                                                    })
                                                }

                                                { !propertyDetails?.images && Translate.t('Property.PropertyViewDetails.NoImagesFound')}
                                            </PropertyImageGalleryContainer>
                                        </RoomDetailsContainer>
                                    </DetailsInfoContainer>
                                </SideSplitterContainer>
                                <RightSideSplitterContainer>
                                    <SupportInfo />
                                </RightSideSplitterContainer>

                            </DetailsViewContainer>
                        </>
                    ) : (
                        <Loader>
                            <Bars color={Colors.primary} height={260} width={260} />
                        </Loader>
                    )
                }
            </MainContainer>
        </>

    );
};

const ImageCarouselContainer = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 20%;
    padding-bottom: 20%;
`;

const DetailsViewContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
`;

const SideSplitterContainer = styled.div`
display: flex;
`;

const RightSideSplitterContainer = styled.div`
margin-left: 16px;
`;

const Title = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: capitalize;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
`;

const DetailsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 824px;

    > div {
        padding: 20px;
        box-sizing: border-box;
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-bottom: 40px;
    }
`;

const BasicDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
  row-gap: 8px;
  column-gap: 56px;
  width: 100%;
  align-items: flex-end;
`;

const RoomDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyImageGalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(380px, 1fr));
    grid-gap: 24px;
`;

const PropertyImageGalleryCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background: ${Colors.primaryLightest};
    box-sizing: border-box;
    border-radius: 12px;
`;

const PropertyImageGalleryTitle = styled.h5`
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 24px;
`;

const PropertyImageGallery = styled.div`
    display: flex;
    width: 340px;
    height: 340px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
    > img {
        width: 85%;
        height: 100%;
        object-fit: cover;
    }
    > img:not(:last-child){
        margin-right: 10px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropPropertyAttempt(state),
    propertyDetails: Selectors.getPropPropertyIndividual(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyDetails: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPropertyDetails);
