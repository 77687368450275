import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import { ForgotPasswordNewPasswordPayload } from 'redux/slices/auth/types';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';
import Translate from 'lib/Translate';

export default function* watchForgotPasswordNewPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authForgotPasswordNewPasswordAttempt', handleForgotPasswordNewPassword, api);
}

function* handleForgotPasswordNewPassword(api: AuthGateway, data: ForgotPasswordNewPasswordPayload) {
    const { newPassword, hash } = data.payload;

    const response = yield* call([api, api.forgotPasswordNewPassword], { newPassword, hash: encodeURIComponent(hash) });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.authForgotPasswordNewPasswordFailure(Translate.t('ResetPassword.ResetPasswordToastError')));
        }

        yield put(Actions.authVerifyForgotPasswordEmailSuccess());

        toast.success(Translate.t('ResetPassword.ResetPasswordToastSuccess'));
        NavActions.navToLogin();
    } else {
        yield put(Actions.authForgotPasswordNewPasswordFailure(response.message));

        toast.error(Translate.t('ResetPassword.ResetPasswordToastError'));
    }
}
