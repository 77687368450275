import React, { useState, useEffect } from 'react';
import { Colors } from '@dm/bigfish';
import { useLocation } from 'react-router-dom';

import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';
import SVG from 'react-inlinesvg';
import { IPackagePrice, Category, IOwnerUser, PaymentTypeEnum, PaymentGatewayResponse } from '@dm/types';
import { TailSpin } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';

import NavActions from 'lib/NavActions';
import config from 'config';
import Icons from '../../assets/icons';

import MainContainer from '../../components/MainContainer';
import PackagesEmailPromptModal from './PackagesEmailPrompt';
import ConfirmPaymentMethod from './ConfirmPaymentMethod';

export interface IDropdownProfile {
    p: string;
    onClick?: () => void;
}

interface SubscribePackagesProps {
    setPackagesPayments(propertyId: string, paymentType: number, bankNumber:string, accountName: string, bankName: string, packages: Category[], reference: string): void;
    packagesPricingList: IPackagePrice;
    userListUserInfo: IOwnerUser | undefined;
    getUserInfo: () => void;
    confirmPayMethod: boolean;
    setConfirmPaymentMethodModalOpen: (state: boolean) => void;
    buyAllPackagesOrNot: boolean;
    buyPackagesSeparately: boolean,
    emailModalOpen: boolean,
    paymentIdData: PaymentGatewayResponse; // PaymentID is name paymentIdData.paymentId because there is another paymentId in line 80 so to differenchiate both payment id
    packagesPurchaseAttempt: boolean;
    paymentIdFailed: string;
}

interface NavParams {
    packageId: string;
    packagesChoosed: number[];
    priceData: number;
}

const SubscribePackages = (props: SubscribePackagesProps) : JSX.Element => {
    const { packagesPricingList, setPackagesPayments, getUserInfo, userListUserInfo, confirmPayMethod, setConfirmPaymentMethodModalOpen, emailModalOpen, buyAllPackagesOrNot, buyPackagesSeparately, paymentIdData, paymentIdFailed, packagesPurchaseAttempt } = props;

    const idAndChoosedPackages = useLocation();

    const { packageId, priceData, packagesChoosed } = idAndChoosedPackages.state as NavParams;

    const [checkVacantContainer, setcheckVacantContainer] = useState(false);
    const [focused, setFocused] = useState('manual');
    const [checkDefectInspectionInspectionContainer, setCheckDefectInspectionInspectionContainer] = useState(false);
    const [checkDesignRenovationContainer, setCheckDesignRenovationContainer] = useState(false);
    const [checkPropertyManagementContainer, setCheckPropertyManagementContainer] = useState(false);
    const [checkVacantManagementContainer, setCheckVacantManagementContainer] = useState(false);
    const [checkLeaseContainer, setcheckLeaseContainer] = useState(false);
    const [paymentType, setPaymentType] = useState(PaymentTypeEnum.ManualBankTransfer);
    const [viewPrice, setViewPrice] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [fullName, setFullName] = useState(firstName + lastName);
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');

    const { defectInspection, designRenovation, leaseSale, propertyManagement, vacantPossession, vacantManagement } = packagesPricingList;

    useEffect(() => {
        getUserInfo();

        if (buyAllPackagesOrNot === true) {
            setViewPrice(true);
            if (packagesChoosed) {
                if (packagesChoosed.some(item => item === Category.VacantPossession)) {
                    setcheckVacantContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.DefectInspection)) {
                    setCheckDefectInspectionInspectionContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.DesignRenovation)) {
                    setCheckDesignRenovationContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.PropertyManagement)) {
                    setCheckPropertyManagementContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.VacantManagement)) {
                    setCheckVacantManagementContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.LeaseSale)) {
                    setcheckLeaseContainer(true);
                }
            }
        } else {
            setViewPrice(false);
        }

        if (buyPackagesSeparately === true) {
            setViewPrice(true);
            if (packagesChoosed) {
                if (packagesChoosed.some(item => item === Category.VacantPossession)) {
                    setcheckVacantContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.DefectInspection)) {
                    setCheckDefectInspectionInspectionContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.DesignRenovation)) {
                    setCheckDesignRenovationContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.PropertyManagement)) {
                    setCheckPropertyManagementContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.VacantManagement)) {
                    setCheckVacantManagementContainer(true);
                }
                if (packagesChoosed.some(item => item === Category.LeaseSale)) {
                    setcheckLeaseContainer(true);
                }
            }
        }

        if (buyPackagesSeparately === false && buyAllPackagesOrNot === false) {
            setViewPrice(false);
        }
    }, [packagesPricingList?.defectInspection, packagesPricingList?.designRenovation, packagesPricingList?.vacantManagement, packagesPricingList?.leaseSale]);

    useEffect(() => {
        if (userListUserInfo) {
            setFirstName(userListUserInfo?.firstName ?? '');
            setLastName(userListUserInfo?.lastName ?? '');
            setBankAccountNumber(userListUserInfo.financialInfo?.bankAccNo || '');
            setBankName(userListUserInfo.financialInfo?.bankName || '');
        }
        checkerIfAllPriceEmpty();
    }, [userListUserInfo?.firstName, userListUserInfo?.lastName, userListUserInfo?.financialInfo?.bankAccNo, userListUserInfo?.financialInfo?.bankType, userListUserInfo?.financialInfo?.bankName]);

    const checkerIfAllPriceEmpty = () => {
        if ((vacantPossession === 0 || !vacantPossession) && (defectInspection === 0 || !defectInspection) && (designRenovation === 0 || !designRenovation) && (propertyManagement === 0 || !propertyManagement) && (leaseSale === 0 || !leaseSale)) {
            NavActions.navToHome();
        }
    };

    // const disableButton = () => {
    //     if (packagesPurchaseError.length) {
    //         return true;
    //     }
    //     return false;
    // };

    const handleSubmit = () => {
        // This will open the stripe payment screen on current screen
        window.open(paymentIdData.url, '_self');
    };

    const setPackagesPayment = (PaymentType: number) => {
        if (PaymentType === PaymentTypeEnum.ManualBankTransfer) {
            setPackagesPayments(packageId, paymentType, bankAccountNumber, fullName, bankName, packagesChoosed, 'Manual Bank Transfer');
        } else {
            setPackagesPayments(packageId, PaymentTypeEnum.PaymentGateway, bankAccountNumber, fullName, bankName, packagesChoosed, 'Payment Gateway');
        }
    };

    const renderTotalPrice = () => {
        if (paymentType === PaymentTypeEnum.PaymentGateway) {
            return (
                <div>
                    {(priceData * 0.04 + priceData).toFixed(2)}
                </div>
            );
        }

        return (
            priceData.toFixed(2)
        );
    };

    // const renderPaymentBodyInfo = () => {
    //     if (packagesPurchaseError.length) {
    //         return (
    //             <FailedPaymentMethodContainer>
    //                 Please try Again
    //             </FailedPaymentMethodContainer>
    //         );
    //     }

    //     return true;
    // };

    return (
        <MainContainer>
            <MainContainerSub>
                <MainContainerBank>
                    <MainTitleContainer>{Translate.t('Packages.PackagesCard.PackagesPaymentOptions')}</MainTitleContainer>
                    <MainCardTypeContainer>
                        <MainCardManualContainerResponsiveManualTransfer onClick={() => [setPaymentType(PaymentTypeEnum.ManualBankTransfer), setFocused('manual')]} focused={focused}>
                            <CardCircleContainer>
                                <ManualTransferType src={Icons.ManualTransfer} />

                            </CardCircleContainer>
                            <TextManualTransfer>{Translate.t('Packages.PackagesCard.BankTransfer')}</TextManualTransfer>

                        </MainCardManualContainerResponsiveManualTransfer>

                        <MainCardManualContainerResponsiveWalletTransfer onClick={() => [setPaymentType(PaymentTypeEnum.PaymentGateway), setFocused('wallet'), setPackagesPayment(PaymentTypeEnum.PaymentGateway)]} focused={focused}>

                            <CardCircleContainer>
                                <ManualTransferType src={Icons.creditCard} />
                            </CardCircleContainer>
                            <TextWalletTransfer>{Translate.t('Packages.PackagesCard.CardTransfer')}</TextWalletTransfer>
                        </MainCardManualContainerResponsiveWalletTransfer>

                    </MainCardTypeContainer>
                    {paymentType === PaymentTypeEnum.ManualBankTransfer && (
                        <>
                            <BankTransferInfoTitle>{Translate.t('Packages.PackagesCard.BankTransferTitle')}</BankTransferInfoTitle>
                            <BankTransferInfoBody>
                                <p>{Translate.t('Packages.PackagesCard.BodySubTitle')}</p>
                                <MoreDetails>
                                    <BankDetailContainer>
                                        <BankDetailText>{Translate.t('Packages.PackagesCard.BankName')}</BankDetailText>
                                        <BankDesciptionText>{config.dormeoBankDetails.bankName}</BankDesciptionText>
                                    </BankDetailContainer>
                                    <BankDetailContainer>
                                        <BankDetailText>{Translate.t('Packages.PackagesCard.BankNumber')}</BankDetailText>
                                        <BankDesciptionText>{config.dormeoBankDetails.bankNumber}</BankDesciptionText>
                                    </BankDetailContainer>
                                    <BankDetailContainer>
                                        <BankDetailText>{Translate.t('Packages.PackagesCard.AccountName')}</BankDetailText>
                                        <BankDesciptionText>{config.dormeoBankDetails.accountName}</BankDesciptionText>
                                    </BankDetailContainer>
                                    <BankDetailContainer>
                                        <BankDetailText>{Translate.t('Packages.PackagesCard.SwiftCode')}</BankDetailText>
                                        <BankDesciptionText>{config.dormeoBankDetails.swiftCode}</BankDesciptionText>
                                    </BankDetailContainer>
                                </MoreDetails>
                                <WarningSignContainer>{Translate.t('Packages.PackagesCard.PackagesTitleMessage')}</WarningSignContainer>
                            </BankTransferInfoBody>
                        </>

                    )}
                    {/* : (
                        <>
                            <BankTransferInfoTitleCard>{Translate.t('Packages.GatewayPaymentDetails.Billinginfo')}</BankTransferInfoTitleCard>
                            <BankTransferWalletBody>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.FullName')}</h5>
                                    <RoundInput value={fullNameCard} placeholder='eg. 123' onChangeText={(e) => setFullNameCard(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.Title')}</h5>
                                    <RoundInput placeholder='eg. 123' value={title} onChangeText={(e) => setTitle(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.BillingAddress')}</h5>
                                    <RoundInput placeholder='eg. 123' value={billingDetailsAddressCard} onChangeText={(e) => setBillingDetailsAddressCard(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.City')}</h5>
                                    <RoundInput placeholder='eg. 123' value={city} onChangeText={(e) => setCity(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.Zipcode')}</h5>
                                    <RoundInput placeholder='eg. 123' value={zipCode} onChangeText={(e) => setZipCode(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.State')}</h5>
                                    <RoundInput placeholder='eg. 123' value={state} onChangeText={(e) => setState(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.Country')}</h5>
                                    <div>
                                        <SelectCountryContainer
                                            value={selectedOption}
                                            onChange={e => setSelectedOption(e.target.value)}
                                            required
                                        >
                                            {countriesOptions.map(item => (
                                                <option key={item.description} value={item.iso}>{item.description}</option>
                                            ))}
                                        </SelectCountryContainer>
                                    </div>
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.ContactNumber')}</h5>
                                    <RoundInput placeholder='eg. 123' value={contactNumber} onChangeText={(e) => setContactNumber(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <WalletPaymentDetailsContainer>
                                    <h5>{Translate.t('Packages.GatewayPaymentDetails.EmailAddress')}</h5>
                                    <RoundInput placeholder='eg. 123' value={email} onChangeText={(e) => setEmail(e.target.value)} />
                                </WalletPaymentDetailsContainer>
                                <GapFillerContainer />
                                <GapFillerContainer />
                                <FlexReverseRowContainer>
                                    <ButtonClear label='Clear' onClick={clearCardDetails} />
                                </FlexReverseRowContainer>
                            </BankTransferWalletBody>

                            <BankTransferInfoTitleCard style={{ marginTop: '20px' }}>{Translate.t('Packages.CardInfo')}</BankTransferInfoTitleCard>

                            <BankTransferWalletBodySample>
                                <form id='payment-form' onSubmit={handleSubmit}>
                                    <h4>{Translate.t('Packages.Card')}</h4>
                                    <BorderCard>
                                        <CardElement id='card-element' />
                                    </BorderCard>

                                </form>

                            </BankTransferWalletBodySample>

                        </>
                    )} */}

                </MainContainerBank>
                <PricingCountContainer>
                    <SummaryContainer>
                        <SummaryMainContainer>
                            <CheckOutSummaryContainer>{Translate.t('Packages.PackagesCard.CheckoutSummary')}</CheckOutSummaryContainer>
                        </SummaryMainContainer>

                        <div>
                            <DropdownContentHeaderContentList>
                                {checkVacantContainer
                                && (
                                    <DropdownContentHeaderContentItemContainer>
                                        <div>
                                            {vacantPossession === 0 || !vacantPossession
                                                ? (
                                                    <PackageLoadingContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleVacantPossession')}
                                                        <TailSpin color={Colors.primary} height={30} width={30} />
                                                    </PackageLoadingContainer>
                                                )
                                                : (
                                                    <PackageChoosedContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleVacantPossession')}
                                                        <p>
                                                            {Translate.t('Packages.MYR')}
                                                            {vacantPossession}
                                                        </p>
                                                    </PackageChoosedContainer>
                                                )}
                                        </div>
                                    </DropdownContentHeaderContentItemContainer>
                                )}

                                {checkDefectInspectionInspectionContainer
                                && (
                                    <DropdownContentHeaderContentItemContainer>

                                        <div>
                                            {defectInspection === 0 || !defectInspection
                                                ? (
                                                    <PackageLoadingContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleDefectInspection')}
                                                        <TailSpin color={Colors.primary} height={30} width={30} />
                                                    </PackageLoadingContainer>
                                                )
                                                : (
                                                    <PackageChoosedContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleDefectInspection')}
                                                        <p>
                                                            {Translate.t('Packages.MYR')}
                                                            {defectInspection}
                                                        </p>
                                                    </PackageChoosedContainer>
                                                )}
                                        </div>

                                    </DropdownContentHeaderContentItemContainer>
                                )}

                                {checkDesignRenovationContainer
                                && (
                                    <DropdownContentHeaderContentItemContainer>

                                        <div>
                                            {designRenovation === 0 || !designRenovation
                                                ? (
                                                    <PackageLoadingContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleDesignRenovation')}
                                                        <TailSpin color={Colors.primary} height={30} width={30} />
                                                    </PackageLoadingContainer>
                                                )
                                                : (
                                                    <PackageChoosedContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleDesignRenovation')}
                                                        <p>
                                                            {Translate.t('Packages.MYR')}
                                                            {designRenovation}
                                                        </p>
                                                    </PackageChoosedContainer>
                                                )}
                                        </div>

                                    </DropdownContentHeaderContentItemContainer>
                                )}

                                {checkPropertyManagementContainer
                                && (
                                    <DropdownContentHeaderContentItemContainer>

                                        <div>
                                            {propertyManagement === 0 || !propertyManagement
                                                ? (
                                                    <PackageLoadingContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitlePropertyManagement')}
                                                        <TailSpin color={Colors.primary} height={30} width={30} />
                                                    </PackageLoadingContainer>
                                                )
                                                : (
                                                    <PackageChoosedContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitlePropertyManagement')}
                                                        <p>
                                                            {Translate.t('Packages.MYR')}
                                                            {propertyManagement}
                                                        </p>
                                                    </PackageChoosedContainer>
                                                )}
                                        </div>

                                    </DropdownContentHeaderContentItemContainer>
                                )}

                                {checkVacantManagementContainer
                                && (
                                    <DropdownContentHeaderContentItemContainer>

                                        <div>
                                            {vacantManagement === 0 || !vacantManagement
                                                ? (
                                                    <PackageLoadingContainer>
                                                        Vacant Management
                                                        <TailSpin color={Colors.primary} height={30} width={30} />
                                                    </PackageLoadingContainer>
                                                )
                                                : (
                                                    <PackageChoosedContainer>
                                                        Vacant Management
                                                        <p>
                                                            {Translate.t('Packages.MYR')}
                                                            {vacantManagement}
                                                        </p>
                                                    </PackageChoosedContainer>
                                                )}
                                        </div>

                                    </DropdownContentHeaderContentItemContainer>
                                )}

                                {checkLeaseContainer
                                && (
                                    <DropdownContentHeaderContentItemContainer>

                                        <div>
                                            {leaseSale === 0 || !leaseSale
                                                ? (
                                                    <PackageLoadingContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleLeaseSale')}
                                                        <TailSpin color={Colors.primary} height={30} width={30} />
                                                    </PackageLoadingContainer>
                                                )
                                                : (
                                                    <PackageChoosedContainer>
                                                        {Translate.t('Packages.PackagesCard.PackagesTitleLeaseSale')}
                                                        <p>
                                                            {Translate.t('Packages.MYR')}
                                                            {leaseSale}
                                                        </p>
                                                    </PackageChoosedContainer>
                                                )}
                                        </div>

                                    </DropdownContentHeaderContentItemContainer>
                                )}
                                {viewPrice && (
                                    <ViewPriceContainer>
                                        <TotalPriceText>Total:</TotalPriceText>
                                        {Translate.t('Packages.MYR')}
                                        {priceData.toFixed(2)}
                                    </ViewPriceContainer>
                                )}
                                {!viewPrice && (
                                    <ViewPriceContainer>0</ViewPriceContainer>

                                )}
                            </DropdownContentHeaderContentList>
                        </div>
                    </SummaryContainer>
                </PricingCountContainer>

            </MainContainerSub>
            <MainContainerRight>
                <SubscribeButtonContainer onClick={() => setConfirmPaymentMethodModalOpen(true)}>
                    {packagesPurchaseAttempt ? (<TailSpin color={Colors.white} height={30} width={30} />) : (
                        <p>{Translate.t('Packages.PackagesCard.ProceedPayment')}</p>
                    )}
                </SubscribeButtonContainer>
                {/* {paymentType === PaymentTypeEnum.PaymentGateway ? (
                    <SubscribeButtonCardContainer onClick={handleSubmit} disabled={disableButton()} packagesPurchaseError={packagesPurchaseError}>
                        {packagesPurchaseAttempt ? (
                            <TailSpin color={Colors.white} height={20} width={20} />
                        )
                            : (<p>{Translate.t('Packages.PackagesCard.ProceedPayment')}</p>
                            )}
                    </SubscribeButtonCardContainer>

                ) : (
                    <SubscribeButtonContainer onClick={() => setConfirmPaymentMethodModalOpen(true)}>
                        {packagesPurchaseAttempt ? (<TailSpin color={Colors.white} height={30} width={30} />) : (
                            <p>{Translate.t('Packages.PackagesCard.ProceedPayment')}</p>
                        )}
                    </SubscribeButtonContainer>
                )} */}
                {confirmPayMethod && (
                    <ConfirmPaymentMethod />
                )}
                <div>
                    {emailModalOpen && (
                        <PackagesEmailPromptModal />
                    )}
                </div>

            </MainContainerRight>
        </MainContainer>
    );
};

const ManualTransferType = styled(SVG)`
  color: #FFCFA6;
  height: 40px;
  width: 40px;
`;

const DropdownContentHeaderContentList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0 0 4px 0;
  padding: 0;

  > li:not(:first-child){
    padding-top: 8px;
  }
`;

const DropdownContentHeaderContentItemContainer = styled.li`
  padding-bottom: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  padding-bottom: 34px;
  &:not(:last-of-type) {
    border-bottom: 0.5px solid grey;
  }
  &:last-of-type {
  padding-bottom: 4px;
  }

`;

const TotalPriceText = styled.p`
    display: flex;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-right: 12px;
    color: #696969;
`;

const ViewPriceContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
`;

const PackageChoosedContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
`;

const PackageLoadingContainer = styled.div`
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const BankDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 28px;
    border-bottom: 0.5px solid #B3B3B3;
`;

const BankDetailText = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
`;

const CheckOutSummaryContainer = styled.p`
    display: flex;
    width: 100%;
    line-height: 22px;
    font-weight: 700;
    font-size: 18px;
    align-items: flex-end;

    @media (max-width: 600px) {
        padding-top: 20px;
        line-height: 18px;
        font-weight: 700;
        font-size: 14px;
    }
`;

const SummaryMainContainer = styled.div`
    display: flex;
    width: 60%;
    height: 32px;
    margin-bottom: 25px;
`;

const BankDesciptionText = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #696969;
`;

const WarningSignContainer = styled.div`
    display: flex;
    max-width: 100%;
    max-height: 14px;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    justify-content: center;
`;

const SummaryContainer = styled.div`
    width: 80%;
    height: 688px;
    background-color: #ffffff;
    margin-bottom: 16%;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 4.65% 20px 4.56% 20px;
`;

const PricingCountContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 8.5%;
    @media (max-width: 600px) {
        margin-top: 21.5%;
    }
`;

const SubscribeButtonCardContainer = styled.button<{packagesPurchaseError: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: #FFCFA6;
        cursor: pointer;
    };
    width: 350px;
    border: #ffffff;
    height: 32px;
    background-color: #F2B47E;
    color: #ffffff;
    margin-bottom: 10%;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;

    @media (max-width: 600px) {
        margin-top: 35px;
        width: 100%; 
    }

`;

const SubscribeButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: #FFCFA6;
        cursor: pointer;
    };
    width: 40%;
    height: 32px;
    background-color: #F2B47E;
    color: #ffffff;
    margin-bottom: 10%;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;

    @media (max-width: 600px) {
        margin-top: 35px;
    }
`;

const MainTitleContainer = styled.div`
    display: flex;
    width: 313px;
    height: 28px;
    max-width: 313px;
    max-height: 28px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: black;
    margin-top: 20px;
    margin-bottom: 48px;
`;

const MainCardTypeContainer = styled.div`
    display: flex;
    margin-bottom: 60px;
`;

const MainCardManualContainerResponsiveManualTransfer = styled.div<{focused?: string}>`
    width: 48.5%;
    height: 19.85%;
    background-color: #F2B47E;
    border-radius: 12px;
    margin-right: 16px;
    margin-bottom: 20px;
    color: #fafafa;
    padding-bottom: 20px;
    &:hover {
            cursor: pointer;
            background-color: #FFCFA6;
        }

        ${props => (props.focused === 'manual') && css`
        background-color: #F2B47E;
    `}

    ${props => (props.focused === 'wallet') && css`
        background-color: white;
        border: 1px solid #F2B47E;
        color: black;
    `}

`;

const MainCardManualContainerResponsiveWalletTransfer = styled.div<{focused?: string}>`
    width: 48.5%;
    height: 19.85%;
    background-color: #F2B47E;
    border-radius: 12px;
    margin-bottom: 20px;
    color: #fafafa;
    padding-bottom: 20px;
    &:hover {
            cursor: pointer;
            background-color: #FFCFA6;
        }

        ${props => (props.focused === 'manual') && css`
        background-color: white;
        border: 1px solid #F2B47E;
        color: black;
    `}

    ${props => (props.focused === 'wallet') && css`
    background-color: #F2B47E;
    `}
`;

const CardCircleContainer = styled.div`
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 32px;
    margin-top: 20px;
    margin-left: 43%;
    margin-bottom: 12px;
`;

const MainContainerBank = styled.div`
    margin: 20px;
    width: 100%;
    margin-bottom: 25px;
`;

const MainContainerSub = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    position: relative;
`;

const MainContainerRight = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 3%;
    align-self: center;
    justify-content: center;
`;

const TextManualTransfer = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
`;

const TextWalletTransfer = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
`;

const BankTransferInfoTitle = styled.div`
    width: 100%;
    height: 5%;
    padding: 8px;
    margin-bottom: 0px;
    padding-left: 20px;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    background-color: #F2B47E;
    border-top: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

const FailedPaymentMethodContainer = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 20%; 
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        color: red;
`;

const MoreDetails = styled.div`
    margin-bottom: 8px;
`;

const BankTransferInfoBody = styled.div`
    width: 100%;
    height: 50%;
    padding: 20px;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
`;

const mapStateToProps = (state: RootState) => ({
    loadingPricingAttempt: Selectors.getPackagesGetPackagesPricingAttempt(state),
    errorPricingFailure: Selectors.getPackagesGetPackagesPricingFailure(state),
    packagesPricingList: Selectors.getPackagesGetPackagesPricing(state),
    confirmPayMethod: Selectors.packagesSetConfirmPaymentMethodModal(state),
    loadingUserInfo: Selectors.getUserGetUserInfoAttempt(state),
    errorUserInfo: Selectors.getUserGetUserInfoFailure(state),
    userListUserInfo: Selectors.getUserGetUserInfo(state),
    buyAllPackagesOrNot: Selectors.packagesSetPackagesBuyAll(state),
    buyPackagesSeparately: Selectors.packagesSetAllPackageCancel(state),
    emailModalOpen: Selectors.packagesSetPackagesEmailModal(state),

    packagesPurchaseAttempt: Selectors.packagessetPackagesPaymentsAttempting(state),
    paymentIdData: Selectors.packagessetPackagesPayments(state),
    paymentIdFailed: Selectors.packagesUploadPackagesDocumentError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserInfo: () => dispatch(Actions.userGetUserInfoAttempt()),
    setConfirmPaymentMethodModalOpen: (state:boolean) => dispatch(Actions.setConfirmPaymentMethodModalOpen(state)),
    setModalOpen: (state: boolean) => dispatch(Actions.setPaymentDocumentModalOpen(state)),
    setPackagesPayments: (propertyId: string, paymentType: number, bankNumber:string, accountName: string, bankName: string, packages: Category[], reference: string) =>
        dispatch(Actions.packagesSetPackagesPaymentAttempt({ propertyId, paymentType, bankNumber, accountName, bankName, packages, reference })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribePackages);
