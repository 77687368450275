import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Routes from 'navigation/Routes';

import { AppDispatch, RootState } from 'redux/store';

import Utils from 'lib/Utils';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { IOwnerUser } from '@dm/types';

import { getUserLanguage, setUserLanguage } from '../lib/Translate';

interface FullProps {
    loading: boolean;
    error: string;
    currentUserInfo: IOwnerUser | undefined;
    startup: () => void;
    setLanguage: (language: string) => void;
    setIsFirstSignUp: (status: boolean) => void;
    setWelcomeModal: (status: boolean) => void;
    setAuthToken: (authToken: string) => void;
}

const Full = (props: FullProps): JSX.Element => {
    const { loading, error, currentUserInfo, startup, setLanguage, setIsFirstSignUp, setWelcomeModal, setAuthToken } = props;

    const authToken = Utils.Auth.getAuthToken();

    useEffect(() => {
        startup();

        // set language
        const currentLanguage = getUserLanguage();
        setUserLanguage(currentLanguage);
        setLanguage(currentLanguage);

        // if authToken exist in local storage, meaning they have already logged in. Set the authToken to redux incase they refresh any pages
        if (authToken) setAuthToken(authToken);
    }, []);

    useEffect(() => {
        // On startup, need to check the current isFirstSignUp status.
        // If true, then is isFirstSignUp should be set as true including welcomeModal
        if (currentUserInfo) {
            const currentStatus = currentUserInfo.isFirstSignUp;

            setIsFirstSignUp(currentStatus ?? false);
            setWelcomeModal(currentStatus ?? false);
        }
    }, [currentUserInfo]);

    // TODO: render proper spinner if loading
    // if (loading) return <Spinner />;

    if (error) {
        // nav to error page
    }

    return (
        <Routes />
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthStartupAttempting(state),
    error: Selectors.getAuthStartupError(state),
    currentUserInfo: Selectors.getUserGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startup: () => dispatch(Actions.authStartupAttempt()),
    setLanguage: (language: string) => dispatch(Actions.setLanguage(language)),
    setIsFirstSignUp: (status: boolean) => dispatch(Actions.setIsFirstSignUp(status)),
    setWelcomeModal: (status: boolean) => dispatch(Actions.setWelcomeModal(status)),
    setAuthToken: (authToken: string) => dispatch(Actions.authLoginSuccess(authToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Full);
