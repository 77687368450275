import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Category, IOwnerUser, PaymentTypeEnum } from '@dm/types';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Modal from '../../components/Modal';
import Icons from '../../assets/icons';

import PaymentUpload from './PaymentUpload';

export interface ConfirmPaymentMethodProps {
    setPackagesPayments(propertyId: string, paymentType: number, bankNumber:string, accountName: string, bankName: string, packages: Category[], reference: string): void;
    isModalOpen: boolean;
    showOrCloseModal: boolean;
    userListUserInfo: IOwnerUser | undefined;
    getUserInfo: () => void;
    setConfirmPaymentMethodModalOpen: (state: boolean) => void;
    setModalOpen: (state: boolean) => void;
}

interface NavParams {
    packageId: string;
    packagesChoosed: number[];
    priceData: number;
}

const ConfirmPaymentMethod = (props : ConfirmPaymentMethodProps) : JSX.Element => {
    const { setPackagesPayments, getUserInfo, userListUserInfo, showOrCloseModal, setConfirmPaymentMethodModalOpen, isModalOpen, setModalOpen } = props;

    const idAndChoosedPackages = useLocation();

    const { packageId, packagesChoosed } = idAndChoosedPackages.state as NavParams;

    const [paymentType, setPaymentType] = useState(PaymentTypeEnum.ManualBankTransfer);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [fullName, setFullName] = useState(firstName + lastName);
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');

    useEffect(() => {
        getUserInfo();

        if (userListUserInfo) {
            setFirstName(userListUserInfo?.firstName ?? '');
            setLastName(userListUserInfo?.lastName ?? '');
            setBankAccountNumber(userListUserInfo.financialInfo?.bankAccNo || '');
            setBankName(userListUserInfo.financialInfo?.bankName || '');
        }
    }, [userListUserInfo?.firstName, userListUserInfo?.lastName, userListUserInfo?.financialInfo?.bankAccNo, userListUserInfo?.financialInfo?.bankType, userListUserInfo?.financialInfo?.bankName]);

    const onClickHandler = () => {
        setConfirmPaymentMethodModalOpen(false);
    };

    const setPackagesPayment = (PaymentType: number) => {
        if (PaymentType === PaymentTypeEnum.ManualBankTransfer) {
            setPackagesPayments(packageId, paymentType, bankAccountNumber, fullName, bankName, packagesChoosed, 'Manual Bank Transfer');
        } else {
            setPackagesPayments(packageId, PaymentTypeEnum.PaymentGateway, bankAccountNumber, fullName, bankName, packagesChoosed, 'Payment Gateway');
        }
    };

    return (
        <MainContainer>
            <Modal show={showOrCloseModal} width='538px'>
                <div>
                    <MainTitle>
                        <Title>Are you sure you want to pay by:</Title>

                        <CloseIcon src={Icons.closebutton} onClick={onClickHandler} />
                    </MainTitle>
                    <MainContent>
                        <MethodContainer src={Icons.ManualTransfer} />
                        <MethodTitle>Manual Transfer</MethodTitle>
                    </MainContent>
                    <ButtonContainer>
                        <ConfirmButton onClick={onClickHandler}>
                            No, Go Back
                        </ConfirmButton>
                        <ConfirmButton onClick={() => setPackagesPayment(paymentType)}>
                            Yes, Confirm Payment
                        </ConfirmButton>
                    </ButtonContainer>
                    {
                        isModalOpen && (
                            <PaymentUpload />)
                    }
                </div>
            </Modal>
        </MainContainer>
    );
};

const CloseIcon = styled(SVG)`
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
`;

const Title = styled.h2`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    background-color: #ffffff;
    margin-bottom: 40px;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;
const MethodContainer = styled(SVG)`
    color: #F2B47E;
    height: 80px;
    width: 80px;
`;

const MethodTitle = styled.h1`
    color: #F2B47E;
    font-weight: bold;
`;

const MainTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ButtonContainer = styled.div`
    margin-top:20px;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
`;

const ConfirmButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: #FFCFA6;
        cursor: pointer;
    };
    width: 40%;
    height: 32px;
    background-color: #F2B47E;
    color: #ffffff;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;

    @media (max-width: 600px) {
        margin-top: 35px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    showOrCloseModal: Selectors.packagesSetConfirmPaymentMethodModal(state),
    isModalOpen: Selectors.packagesSetPaymentDocumentModal(state),
    userListUserInfo: Selectors.getUserGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserInfo: () => dispatch(Actions.userGetUserInfoAttempt()),
    setConfirmPaymentMethodModalOpen: (state:boolean) => dispatch(Actions.setConfirmPaymentMethodModalOpen(state)),
    setModalOpen: (state: boolean) => dispatch(Actions.setPaymentDocumentModalOpen(state)),
    setPackagesPayments: (propertyId: string, paymentType: number, bankNumber:string, accountName: string, bankName: string, packages: Category[], reference: string) =>
        dispatch(Actions.packagesSetPackagesPaymentAttempt({ propertyId, paymentType, bankNumber, accountName, bankName, packages, reference })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentMethod);
