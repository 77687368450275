import { fork } from 'redux-saga/effects';

import config from 'config';

import api from 'api';
import mockApi from 'api/mocks';

import auth from './auth';
import prop from './property';
import user from './user';
import packages from './packages';

import { SagaForkReturnType } from './types';

let apiInstance = api;

if (config.useMockApi) {
    apiInstance = mockApi;
}

export default function* root(): SagaForkReturnType {
    yield fork(auth(apiInstance.auth).rootSaga);
    yield fork(prop(apiInstance.prop).rootSaga);
    yield fork(user(apiInstance.user).rootSaga);
    yield fork(packages(apiInstance.packages).rootSaga);
}
