import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import Translate from 'lib/Translate';
import { Oval } from 'react-loader-spinner';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { CardCollapsible, Button, Colors, BreadCrumb } from '@dm/bigfish';
import { RootState, AppDispatch } from 'redux/store';

import MainContainer from 'components/MainContainer';
import NavActions from 'lib/NavActions';
import { IPropertyIndividual } from 'entities/property';
import Modal from 'components/Modal';
import { IPropertyPackageStatusEnum } from '@dm/types';
import LoadingScreen from 'components/LoadingScreen';
import media from 'lib/Breakpoints';
import SupportInfo from '../../../components/SupportInfo';
import ButtonInfoPackages from '../../../components/ButtonInfoPackages';
import QuickNavigationButton from '../../../components/QuickNavigationButton';

import PropertyImage from '../../../components/PropertyImage';
import Icons from '../../../assets/icons';
import HousingIMG from '../../../assets/images/building.png';
import EventTrailRecentEvents from '../../../components/EventTrailRecentEvents';
import VacantPossesionDocumentUpload from './VacantPossesionDocumentUpload';

interface NavParams {
    propertyIndividualId: string;
}

interface CollapsibleListProps {
    loading: boolean;
    error: string;
    propertyList: IPropertyIndividual;
    getPropertyIndividual: (id: string) => void;
    setDocumentUploadId: (id: string) => void;
    modalLoad: boolean;
    setVacantDocumentUploadModal: (state: boolean) => void;

}
const VacantPossesion = (props: CollapsibleListProps): JSX.Element => {
    const { loading, error, propertyList, getPropertyIndividual, setDocumentUploadId, modalLoad, setVacantDocumentUploadModal } = props;
    const [name, setName] = useState('');
    const [loadModal, setLoadModal] = useState(false);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [selectedDocumentName, setSelectedDocumentName] = useState('');
    const [chip, setChip] = useState(false);
    const [chipnumber, setChipnumber] = useState(0);
    const [country, setCountry] = useState('');
    const [packageBought, setPackageBought] = useState(false);
    const [pendingEvents, setPendingEvents] = useState([]);

    const [ownerTermssStatus, setOwnerTermssStatus] = useState(false);
    const useLocationstate = useLocation();

    const { propertyIndividualId } = useLocationstate.state as NavParams;
    const ownerTermsRef = useRef<any>(null);

    useEffect(() => {
        setOwnerTermssStatus(true);
        onOpenCollapsibleOwner();
        getPropertyIndividual(propertyIndividualId);
        setOwnerStatusForPackage();
    }, []);

    useEffect(() => {
        setVacantPossesionDocumentId();
    }, [selectedDocumentName]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Packages.PackagesCard.CardTitleVacantPossession'),
                onClick: () => { return false; },
            },
        ],
    );

    const setOwnerStatusForPackage = () => {
        if (propertyList.packages) {
            if (propertyList.packages.vacantPossession?.status === IPropertyPackageStatusEnum.Active) {
                setPackageBought(true);
                setName(propertyList.name);
                if (propertyList.location?.address1) {
                    setCity(propertyList.location?.address1);
                }
                if (propertyList.location?.state) {
                    setState(propertyList.location?.state);
                }
                if (propertyList.location?.country) {
                    setState(propertyList.location?.country);
                }
                if (propertyList.packages.vacantPossession.status) {
                    setChip(true);
                    setChipnumber(propertyList.packages.vacantPossession.status);
                }
            }
        }
    };

    const setVacantPossesionDocumentId = () => {
        setDocumentUploadId(propertyIndividualId);
        setLoadModal(false);
        if (selectedDocumentName === '') {
            setVacantDocumentUploadModal(false);
        } else {
            setVacantDocumentUploadModal(true);
        }
    };

    const chooseDocumentTypeModal = () => {
        setLoadModal(true);
    };

    const onOpenCollapsibleOwner = () => {
        if (ownerTermsRef.current) {
            ownerTermsRef.current.openCollapsible();
        }
    };

    const checkPropertyPackagesStatus = (packages: any) => {
        const packagesnumber = packages;

        switch (packagesnumber) {
            case 0:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusNotPurchasedYet');
            case 1:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 2:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 3:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusActive');
            case 4:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPaymentRejected');
            case 5:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusDisabled');
            case 6:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusFreeTrial');
            case 7:
                return Translate.t('Packages.VacantPossession.ButtonInfo.Statuspurchase');
            default:
                return '';
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <HeadersContainer>
                    {/* <CardCollapsibleStyled ref={ownerTermsRef} title={propertyList.name.substring(0, 20)} subtitle={propertyList.location ? `${(propertyList.location?.city) ? propertyList.location.city : city} ${(propertyList.location?.state) ? propertyList.location?.state : state} ${(propertyList.location?.country) ? propertyList.location.country : country}` : ''} chipText={(propertyList.packages?.vacantPossession?.status) ? checkPropertyPackagesStatus(propertyList.packages.vacantPossession.status) : ''}> */}
                    {loading && (
                        <PropertySubInfoContainer>
                            <LoadingScreen />
                        </PropertySubInfoContainer>
                    )}
                    {(!loading) && (propertyList.packages && propertyList.packages.vacantPossession?.status === IPropertyPackageStatusEnum.Active) && (
                        <PropertyManagementContainer>
                            <ModulesPropertyContainer>
                                <PropertySubInfoContainer>
                                    <PropertyImage src={HousingIMG} />
                                </PropertySubInfoContainer>
                                <PropertyInfoContainer>

                                    <ButtonInfoPackages desc={Translate.t('Packages.VacantPossession.UploadVPDocuments')} rightIconSrc={Icons.uploadIcon} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => chooseDocumentTypeModal()} />
                                    {loadModal && (
                                        <ChooseDocumentType setSelectedDocumentName={setSelectedDocumentName} setLoadModal={setLoadModal} />
                                    )}
                                    <ButtonInfoPackages desc={Translate.t('Packages.VacantPossession.CheckVPRelatedDocuments')} status={checkPropertyPackagesStatus(chipnumber)} rightIconSrc={Icons.documentDisplay} onclick={() => NavActions.navToVacantPossesionMain(propertyIndividualId)} />
                                    {modalLoad && (
                                        <VacantPossesionDocumentUpload documentTypeName={selectedDocumentName} />
                                    )}
                                    {/* <EventTrailRecentEvents title={Translate.t('Property.PropertyRecentEvents')} primary buttonLabel={Translate.t('Property.PropertyRecentEvents')} data={(propertyList.packages.vacantPossession.recentEvents && propertyList.packages.vacantPossession.recentEvents[0]) ? propertyList.packages.vacantPossession.recentEvents : pendingEvents} /> */}

                                </PropertyInfoContainer>
                            </ModulesPropertyContainer>

                            {/* <ModulesFooter>
                                    <ModuleFooterNav>
                                        {Translate.t('Property.PropertyVacantPossesion')}
                                        <span>&gt;</span>
                                    </ModuleFooterNav>
                                </ModulesFooter> */}
                        </PropertyManagementContainer>
                    )}
                    {(!loading) && (propertyList.packages && propertyList.packages.vacantPossession?.status !== IPropertyPackageStatusEnum.Active) && (
                        <PropertyManagementContainer>
                            <ButtonContainer>
                                <SubscriberButton label={Translate.t('Packages.VacantPossession.IfNotBought')} onClick={() => NavActions.navToPackages(propertyIndividualId)} />
                            </ButtonContainer>
                        </PropertyManagementContainer>

                    )}
                    {/* </CardCollapsibleStyled> */}

                    <InfoContainer>
                        <SupportContainer>
                            <SupportInfo />
                        </SupportContainer>

                        <NavigationContainer>
                            <NavigationTextTitle>{Translate.t('Packages.PackagesCard.QuickNavigation')}</NavigationTextTitle>
                            <NavigationTextDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</NavigationTextDescription>
                            {/* {THE QUICK NAVIGATION FEATURE TO BE USED IN FUTURE} */}
                            {/* <NavigationContainers>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} onclick={() => undefined} />
                        </NavigationContainers>
                        <NavigationContainers>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} onclick={() => undefined} />
                        </NavigationContainers>
                        <NavigationContainers>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} onclick={() => undefined} />
                        </NavigationContainers> */}
                            <NavigationContainers>
                                <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} onclick={() => NavActions.navToViewLeaseAndSalePackages(propertyIndividualId)} />
                            </NavigationContainers>
                        </NavigationContainer>
                    </InfoContainer>

                </HeadersContainer>

            </MainContainer>
        </>

    );
};

const PropertyManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    object-fit: contain;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 20px;

    ${media.sm`
        margin-right: 0px;
    `}
`;

const HeadersContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 65% 30%  ;
    padding: 20px;
    padding-top: 40px;
    gap: 20px;

    ${media.lg`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `}
`;

const InfoContainer = styled.div`
    width: 100%;
`;

const SupportContainer = styled.div`
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 10%;
    justify-content: center;

    ${media.sm`
        margin-top: 0;
    `}
`;

const NavigationContainers = styled.div`
    margin-top: 12px;
`;

const NavigationTextTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const NavigationTextDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const CardCollapsibleStyled = styled(CardCollapsible)`
    width: 100%;
`;

const SubscriberButton = styled(Button)`
    display: flex;
    width: 90%;
    height: 28%;
    padding: 20px;
    background-color: #F2865E;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-self: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    ${media.sm`
        font-size: 18px;
    `}
`;

const ModulesFooter = styled.div<{ focused?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 48px;
    padding: 12px 20px;
    background-color: #FFF2E7;
    ${(props) => props.focused && css`
    background-color: #F2865E;;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    `}
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
`;

const ModuleFooterNav = styled.button`
    display: flex;
    align-items: center;
    color: #F2865E;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    span {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
    }
`;

const ModulesPropertyContainer = styled.div`
    display: flex;
    padding: 20px;
    img {
        margin-right: 16px;
    }
    ${media.sm`
        padding: 0;
    `}
`;

const PropertySubInfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    >:not(:last-child) {
        margin-bottom: 20px;
    }

    span {
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        font-style: italic;
        max-width: 224px;
        text-align: justify;
    }

    ${media.sm`
        display: none;
    `}
`;

const PropertyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    >:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const ButtonChooseDocument = styled.div`
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 100%;
    height: 28%;
    padding: 5% 40% 5% 40%;
    cursor: pointer;
    background-color: #FFF2E7;
    :hover {
    background-color: #F2865E;
    }
    color: #000000;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
`;

const SelectDocumentNameContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
`;

const CloseContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 8px;

`;

const ContainerCentering = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;

const CloseIcon = styled(SVG)`
    width: 20px;
    height:20px;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
`;

export interface ButtonProps {
    setSelectedDocumentName: (data: string) => void;
    setLoadModal: (state: boolean) => void;
}

const ChooseDocumentType = (props: ButtonProps): JSX.Element => {
    const { setSelectedDocumentName, setLoadModal } = props;
    const [show, setShow] = useState(true);

    return (
        <MainContainer>
            <ContainerCentering>
                <Modal show={show} width='35%'>
                    <CloseContainer>
                        <CloseIcon src={Icons.closebutton} onClick={() => setLoadModal(false)} />
                    </CloseContainer>
                    <SelectDocumentNameContainer>
                        <ButtonChooseDocument onClick={() => setSelectedDocumentName('Document 1')}>{Translate.t('Packages.VacantPossession.UploadDocument.Document1')}</ButtonChooseDocument>
                        <ButtonChooseDocument onClick={() => setSelectedDocumentName('Document 2')}>{Translate.t('Packages.VacantPossession.UploadDocument.Document2')}</ButtonChooseDocument>
                        <ButtonChooseDocument onClick={() => setSelectedDocumentName('others')}>{Translate.t('Packages.VacantPossession.UploadDocument.Others')}</ButtonChooseDocument>
                    </SelectDocumentNameContainer>

                </Modal>
            </ContainerCentering>

        </MainContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropPropertyIndividualAttempt(state),
    error: Selectors.getPropPropertyIndividualFailure(state),
    propertyList: Selectors.getPropPropertyIndividual(state),
    modalLoad: Selectors.packagesSetVacantPossesionModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyIndividual: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
    setDocumentUploadId: (params: string) => dispatch(Actions.setCreateDocumentUploadID(params)),
    setVacantDocumentUploadModal: (state: boolean) => dispatch(Actions.setVacantPossesionModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacantPossesion);
