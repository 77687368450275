import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import config from 'config';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

(async () => {
    const { stripePublicKey } = config.dormeoBankDetails;
    const stripePromise = loadStripe(stripePublicKey!);

    ReactDOM.render(
        <React.StrictMode>
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <App />
                </Provider>
            </Elements>
        </React.StrictMode>,
        document.getElementById('root'),
    );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
