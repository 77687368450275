import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import Translate from 'lib/Translate';
import { BreadCrumb, Colors, Fonts, TabView } from '@dm/bigfish';
import { Category, IDefectInspection, IFile, SortByEnum } from '@dm/types';
import NavActions from 'lib/NavActions';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import MainContainer from 'components/MainContainer';

import InlineSelectionInput from 'components/InlineSelectionInputViewDocument';
import ButtonInfoPackages from 'components/ButtonInfoPackages';
import { IPropertyIndividual } from 'entities/property';
import { GoogleDriveFolderType, IGoogleDriveContent } from 'entities/packages';
import media from 'lib/Breakpoints';
import PackagesListCard from '../PackagesListCard';
import DocumentImagesModal from '../DocumentImagesModal';
import SupportInfo from '../../../components/SupportInfo';
import Icons from '../../../assets/icons';

interface DefectInspectionViewDocumentProps {
    loadingDefectInspectionDocumentData: boolean;
    errorDefectInspectionDocumentData: string;
    defectInspectionDocumentData: IDefectInspection;
    packagesDataSort (category: number, propertyId: string, sort: number): void;
    setTabView: (tabView: string) => void;

    getPropertyIndividual: (id: string) => void;
    propertyIndividual: IPropertyIndividual;

    getGoogleDriveContentAttempt: boolean;
    getGoogleDriveContentError: string;
    googleDriveContent: IGoogleDriveContent[];
    getGoogleDriveContent: (fileId: string) => void;
}

interface NavParams {
    propertyIndividualId: string;
}

const DefectInspectionViewDocument: FunctionComponent<DefectInspectionViewDocumentProps> = (props: DefectInspectionViewDocumentProps) => {
    const {
        loadingDefectInspectionDocumentData,
        errorDefectInspectionDocumentData,
        defectInspectionDocumentData,
        packagesDataSort,
        setTabView,
        getPropertyIndividual,
        propertyIndividual,
        getGoogleDriveContentAttempt,
        getGoogleDriveContentError,
        googleDriveContent,
        getGoogleDriveContent,
    } = props;

    const [selectedDocuments, setSelectedDocuments] = useState<IFile[]>([]);

    const [defectInspectionDocumentList, setDefectInspectionDocumentList] = useState<IFile[]>([]);
    const [dateOrder, setDateOrder] = useState<SortByEnum>(SortByEnum.createdAtDesc);

    const [homeScreenTabView, setHomeScreenTabView] = useState('');
    const [rootFolderId, setRootFolderId] = useState('');
    const [currentFolderId, setCurrentFolderId] = useState('');
    const [folderIdList, setFolderIdList] = useState<string[]>([]);
    const useLocationstate = useLocation();
    const { propertyIndividualId } = useLocationstate.state as NavParams;

    const [tabViewData] = useState([
        {
            text: Translate.t('GeneralUI.all'),
            onClickTabView: () => {
                setHomeScreenTabView(Translate.t('GeneralUI.all'));
            },
        },
    ]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelViewDocuments'),
                onClick: () => { return false; },
            },
        ],
    );

    interface IOption {
        id: string | number,
      description: string | number,
    }

    const dateData: IOption[] = [{
        id: 0,
        description: Translate.t('Packages.ViewDocuments.DateDecending'),
    },
    {
        id: 1,
        description: Translate.t('Packages.ViewDocuments.DateAscending'),
    }];

    const alphabeticalOrder: IOption[] = [
        {
            id: 2,
            description: Translate.t('Packages.ViewDocuments.NameAscendingOrder'),
        }, {
            id: 3,
            description: Translate.t('Packages.ViewDocuments.NameDecendingOrder'),
        },
    ];

    const listHeaders: string[] = [Translate.t('Packages.ViewDocuments.FileName'), Translate.t('Packages.ViewDocuments.DateUploaded'), Translate.t('Packages.ViewDocuments.Actions')];

    const checkForGoogleDrivePath = !!propertyIndividual.packages?.defectInspection?.googleDrivePath;

    useEffect(() => {
        getPropertyIndividual(propertyIndividualId);
    }, []);

    useEffect(() => {
        if (propertyIndividual.packages?.defectInspection) {
            setRootFolderId(propertyIndividual.packages.defectInspection.targetPathRootFolder);
            getGoogleDriveContent(propertyIndividual.packages.defectInspection.targetPathRootFolder);
        }
    }, [propertyIndividual]);

    useEffect(() => {
        if (rootFolderId) {
            setFolderIdList((prevFolderIdList) => [...prevFolderIdList, rootFolderId]);
        }
    }, [rootFolderId]);

    useEffect(() => {
        packagesDataSort(Category.DefectInspection, propertyIndividualId, dateOrder);
        setHomeScreenTabView(Translate.t('GeneralUI.all'));
    }, [dateOrder]);

    useEffect(() => {
        if (defectInspectionDocumentData) {
            setDefectInspectionDocumentList(defectInspectionDocumentData.defectInspectionDocuments);
        }
    }, [defectInspectionDocumentData]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const handleNestedFolderNextClick = (folderId: string | undefined) => {
        if (folderId) {
            setCurrentFolderId(folderId);
            setFolderIdList((prevFolderIdList) => [...prevFolderIdList, folderId]);
            getGoogleDriveContent(folderId);
        }
    };
    const handleNestedFolderPrevClick = (folderId: string | undefined) => {
        if (folderId) {
            const updatedFolderIdList = folderIdList.filter((id) => id !== folderId);
            setFolderIdList(updatedFolderIdList);
            getGoogleDriveContent(updatedFolderIdList[updatedFolderIdList.length - 1]);
            setCurrentFolderId(updatedFolderIdList[updatedFolderIdList.length - 1]);
        }
    };

    const handleContentClick = (link: string | undefined) => {
        if (link) {
            window.open(link, '_blank');
        }
    };

    const renderList = () => {
        if (loadingDefectInspectionDocumentData) {
            return (
                <Loading>
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </Loading>
            );
        }

        if (!defectInspectionDocumentList.length) {
            return (
                <DataContainer>
                    {Translate.t('Packages.ViewDocuments.NoData')}
                </DataContainer>
            );
        }

        return (
            <>
                <HeadersContainer>
                    {listHeaders.map(item => {
                        return (
                            <HeaderText
                                key={item}
                            >
                                {item}
                            </HeaderText>
                        );
                    })}
                </HeadersContainer>
                <div>
                    {defectInspectionDocumentList.map((item, index) => {
                        const { defectInspectionDocuments } = defectInspectionDocumentData;
                        const uniquekey = index + Math.random();

                        return (
                            <PackagesListCard
                                key={uniquekey}
                                id={index}
                                propertyName={(defectInspectionDocuments[index].name && defectInspectionDocuments[index].name === undefined) ? '' : defectInspectionDocuments[index].name ?? ''}
                                documents={defectInspectionDocumentList}
                                setSelectedDocuments={setSelectedDocuments}
                            />
                        );
                    })}
                </div>
            </>
        );
    };
    const renderFilesFromGDrive = () => {
        if (getGoogleDriveContentAttempt) {
            return (
                <Loading>
                    <Oval
                        height={250}
                        width={250}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </Loading>
            );
        }

        if (!googleDriveContent.length) {
            return (
                <FiltersContainer>
                    {Translate.t('Packages.ViewDocuments.NoData')}
                </FiltersContainer>
            );
        }

        if (folderIdList.length > 1) {
            return (
                <GoogleDriveContentContainer>
                    <GoogleDriveContent onClick={() => handleNestedFolderPrevClick(currentFolderId)}>
                        <ContentIcon src={Icons.FolderLeft} color={Colors.secondary} />
                        Back to previous folder
                    </GoogleDriveContent>
                    {googleDriveContent.map((item) => {
                        if (item.type === GoogleDriveFolderType.folder) {
                            return (
                                <GoogleDriveContent key={item.name} onClick={() => handleNestedFolderNextClick(item.folderId)}>
                                    <ContentIcon src={Icons.FolderRight} color={Colors.secondary} />
                                    {item.name}
                                </GoogleDriveContent>
                            );
                        }
                        if (item.type === GoogleDriveFolderType.otherDocuments) {
                            return (
                                <GoogleDriveContent key={item.name} onClick={() => handleContentClick(item.webviewLink?.webViewLink)}>
                                    <ContentIcon src={Icons.PdfIcon} color={Colors.secondary} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                                    {item.name}
                                </GoogleDriveContent>
                            );
                        }
                        return (
                            <GoogleDriveContent key={item.name} onClick={() => handleContentClick(item.webviewLink?.webViewLink)}>
                                <img
                                    src={item.webviewLink ? item.webviewLink.thumbnailLink : ''}
                                    style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                                    alt={item.name}
                                />
                                {item.name}
                            </GoogleDriveContent>
                        );
                    })}
                </GoogleDriveContentContainer>
            );
        }
        return (
            <GoogleDriveContentContainer>
                {googleDriveContent.map((item) => {
                    if (item.type === GoogleDriveFolderType.folder) {
                        return (
                            <GoogleDriveContent key={item.name} onClick={() => handleNestedFolderNextClick(item.folderId)}>
                                <ContentIcon src={Icons.FolderRight} color={Colors.secondary} />
                                {item.name}
                            </GoogleDriveContent>
                        );
                    }
                    if (item.type === GoogleDriveFolderType.otherDocuments) {
                        return (
                            <GoogleDriveContent key={item.name} onClick={() => handleContentClick(item.webviewLink?.webViewLink)}>
                                <ContentIcon src={Icons.PdfIcon} color={Colors.secondary} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                                {item.name}
                            </GoogleDriveContent>
                        );
                    }
                    return (
                        <GoogleDriveContent key={item.name} onClick={() => handleContentClick(item.webviewLink?.webViewLink)}>
                            <img
                                src={item.webviewLink ? item.webviewLink.thumbnailLink : ''}
                                style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                                alt={item.name}
                            />
                            {item.name}
                        </GoogleDriveContent>
                    );
                })}
            </GoogleDriveContentContainer>
        );
    };
    return (
        <>
            <BreadCrumb backLabel={Translate.t('Packages.ViewDocuments.Back')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <InfoContainerMain>
                    <LeftContainer>
                        <InfoContainer>
                            <TabViewContainer>
                                <TabView data={tabViewData} selectedTabView={homeScreenTabView} />
                            </TabViewContainer>
                            {/* TEMP FOR FEATURE */}
                            {/* <TitleMessageContainer>{Translate.t('Packages.ViewDocuments.TitleMessage')}</TitleMessageContainer> */}
                            <FiltersContainer>

                                <FilterTitleBold>{Translate.t('Packages.ViewDocuments.FilterDefectInspectionDocuments')}</FilterTitleBold>

                                <FiltersInputContainer>
                                    <InlineSelectionInput
                                        data={dateData}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}

                                    />
                                    <InlineSelectionInput
                                        data={alphabeticalOrder}
                                        defaultValue={SortByEnum.updatedAtDesc.toString()}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}

                                    />
                                </FiltersInputContainer>
                            </FiltersContainer>
                            <ListContainer>
                                {checkForGoogleDrivePath ? renderFilesFromGDrive() : renderList()}
                            </ListContainer>
                        </InfoContainer>
                    </LeftContainer>

                    <RightContainer>
                        <SupportInfo />
                        <NavigationContainer>
                            <QuickNavigation>{Translate.t('Packages.PackagesCard.QuickNavigation')}</QuickNavigation>
                            <QuickNavigationDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</QuickNavigationDescription>
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewVacantPossessionPackages(propertyIndividualId)} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewLeaseAndSalePackages(propertyIndividualId)} />
                            {/* {FEATURE QUICK NAVIGATION TO BE USED IN FUTURE} */}
                            {/* <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} /> */}
                        </NavigationContainer>
                    </RightContainer>

                </InfoContainerMain>

            </MainContainer>
            <DocumentImagesModal
                documentData={selectedDocuments}
            />

        </>
    );
};

const GoogleDriveContentContainer = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;

const GoogleDriveContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: calc((1/3*100%) - 20px);
    max-width: calc((1/3*100%) - 20px);
    min-height: 200px;
    max-height: 300px;
    padding: 20px;
    border-radius: 12px;
    gap: 10px;
    text-align: center;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const ContentIcon = styled(SVG)`
    display: flex;
    cursor: pointer;
    width: 150px;
    height: 150px;
`;

const FilterTitleBold = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
`;

const HeaderText = styled.h4`
    justify-self: center;
`;

const InfoContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const LeftContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const Loading = styled.div`
    margin: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RightContainer = styled.div`
    margin-top: 10%;
    width: 100%;
    padding-left: 10px;
    padding-right: 20px;

    ${media.sm`
        flex-direction: column;
        width: unset;
    `}
`;

const QuickNavigation = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const QuickNavigationDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const InfoContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px 10px 10px 20px;
`;

const ButtonInfoPackagesStyled = styled(ButtonInfoPackages)`
    margin-top: 12px;
`;

const TabViewContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const HeadersContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 30% 30% 40% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    ${media.lg`
        box-shadow: none;
        border-radius: 0;
    `}
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FiltersInputContainer = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const DataContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

    ${media.lg`
        text-align: center;
        box-shadow: none;
        border-radius: 0;
    `}
`;

const ListContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    height: 100%;

    ${media.lg`
        overflow-x: auto;
    `}
`;

const mapStateToProps = (state: RootState) => ({
    loadingDefectInspectionDocumentData: Selectors.getPackagesDefectInspectionDataDetailsSortAttempt(state),
    errorDefectInspectionDocumentData: Selectors.getPackagesDefectInspectionDataDetailsSortFailure(state),
    defectInspectionDocumentData: Selectors.getDefectInspectionDataDetailsSort(state),
    propertyIndividual: Selectors.getPropPropertyIndividual(state),
    getGoogleDriveContentAttempt: Selectors.packageGetGoogleDriveContentAttempting(state),
    getGoogleDriveContentError: Selectors.packageGetGoogleDriveContentError(state),
    googleDriveContent: Selectors.packageGetGoogleDriveContent(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    packagesDataSort: (category: number, propertyId: string, sort: number) =>
        dispatch(Actions.packagesGetDefectInspectionDataSortAttempt({ category, propertyId, sort })),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getPropertyIndividual: (id: string) => dispatch(Actions.getPropertyIndividualAttempt({ id })),
    getGoogleDriveContent: (fileId: string) => dispatch(Actions.getGoogleDriveContentAttempt({ fileId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefectInspectionViewDocument);
