import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import UserGateway from 'api/User';

import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchUserInfo(api: UserGateway): SagaWatcherReturnType {
    yield takeEvery('userInfo/userGetUserInfoAttempt', handleGetuserInfo, api);
}

function* handleGetuserInfo(api: UserGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.GetUserInfo], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.userGetUserInfoFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.userGetUserInfoSuccess(response.data));
    } else {
        // handle error
        yield put(Actions.userGetUserInfoFailure(response.message));
    }
}
