import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { Button, BreadCrumb } from '@dm/bigfish';

import NavActions from 'lib/NavActions';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

interface VerifySignUp {
    loading: boolean;
    isVerified: boolean;
    verifySignUp(id: string): void;
    clearVerifySignUp(): void;
}

const VerifySignUp = ({ verifySignUp, loading, isVerified, clearVerifySignUp }:VerifySignUp): JSX.Element => {
    const [breadCrumb] = useState([
        {
            label: Translate.t('VerifySignUp.VerifyEmailBcSignUp'),
            onClick: () => { NavActions.navToRegister(); },
        },
        {
            label: Translate.t('VerifySignUp.VerifyEmailBcOwnerSignUp'),
            onClick: () => { NavActions.navToRegister(); },
        },
        {
            label: Translate.t('VerifySignUp.VerifyEmailBcVerificationEmail'),
            onClick: () => { return false; },
        },
    ]);

    const params = useParams();
    const { uuid } = params;

    useEffect(() => {
        if (uuid) {
            verifySignUp(uuid);
        }

        return () => {
            clearVerifySignUp();
        };
    }, []);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Container>
                    {isVerified
                        ? (
                            <>
                                <Title>{Translate.t('VerifySignUp.VerifySignUpTitleSuccess')}</Title>
                                <Text>
                                    {Translate.t('VerifySignUp.VerifySignUpDescSuccess')}
                                </Text>
                            </>
                        ) : (
                            <>
                                <Title>{Translate.t('VerifySignUp.VerifySignUpTitleFailed')}</Title>
                                <Text>
                                    {Translate.t('VerifySignUp.VerifySignUpDescFailed')}
                                </Text>
                            </>
                        )}
                    <ButtonContainer>
                        <Button loading={loading} label={Translate.t('VerifySignUp.VerifySignUpLabel')} onClick={() => NavActions.navToLogin()} />
                    </ButtonContainer>
                </Container>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>
    );
};

const Container = styled.div`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;

    max-width: 554px;

    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    margin-bottom: 12px;
`;

const Text = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    color: #696969;

    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthVerifySignUpAttempt(state),
    isVerified: Selectors.getAuthVerifySignUp(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    verifySignUp: (id: string) =>
        dispatch(Actions.authVerifySignUpAttempt({ id })),
    clearVerifySignUp: () => dispatch(Actions.clearVerifySignUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifySignUp);
