import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';

import { Button } from '@dm/bigfish';
import Modal from './Modal';

import FacilityImage from '../assets/images/facility2.png';

interface WelcomeModalProps {
  show: boolean,
  setShow: (status: boolean) => void;
}

const WelcomeModal = (props: WelcomeModalProps):JSX.Element => {
    const { show, setShow } = props;

    const onClickHandler = () => {
        setShow(false);
        NavActions.navToNewSignIn();
    };

    return (
        <Modal show={show} width='824px'>
            <Container>
                <Title>Welcome to Dormeo!</Title>
                <DescContainer>
                    <Text>
                        Thank you for signing up to Dormeo Homes, the leading property management service website.
                    </Text>
                    <Text>
                        Finish your account setup by filling your personal details to get started.
                    </Text>
                </DescContainer>
                <Image src={FacilityImage} />
                <Button onClick={onClickHandler} style={{ width: '264px' }} label='Start' />
            </Container>
        </Modal>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #000;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;

  margin-bottom: 40px;
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 592px;

  > :not(:last-child) {
    margin-bottom: 20px;
  }

  margin-bottom: 40px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
`;

const Image = styled.img`
  width: 682px;
  height: 360px;
  
  border-radius: 8px;
  
  object-fit: cover;

  margin-bottom: 40px;
`;

const mapStateToProps = (state: RootState) => ({
    show: Selectors.getUiWelcomeModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setShow: (status: boolean) =>
        dispatch(Actions.setWelcomeModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
