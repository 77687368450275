import React, { useState } from 'react';
import styled from 'styled-components';
import Translate from 'lib/Translate';
import { BreadCrumb, Button } from '@dm/bigfish';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import NavActions from 'lib/NavActions';
import Icons from 'assets/icons';
import BackgroundImage from '../../../assets/images/background.jpg';

const StripeSuccess = () : JSX.Element => {
    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Packages.BreadcrumblabelSuccessTransaction'),
                onClick: () => { return false; },
            },
        ],
    );

    const goToHome = () => {
        NavActions.navToHome();
    };

    return (
        <>
            <BreadCrumb onBackPressed={() => NavActions.navToHome()} data={breadCrumb} />
            <ModulesMainContainer>
                <CenteringContainer>
                    <MainColumnContainer>
                        <CenteringImageSuccessImageContainer onClick={() => goToHome()}>
                            <SuccessfullTransactionSVG src={Icons.SuccessfullTransaction} />
                        </CenteringImageSuccessImageContainer>
                        <CenteringButtonContainer>
                            <h1>{Translate.t('Packages.StripeSuccess1stText')}</h1>
                        </CenteringButtonContainer>
                        <CenteringTextContainer>
                            <p>{Translate.t('Packages.StripeSuccess2stText')}</p>
                        </CenteringTextContainer>
                        <Button label={Translate.t('Packages.StripeGoHome')} onClick={goToHome} />
                    </MainColumnContainer>
                </CenteringContainer>

            </ModulesMainContainer>

        </>

    );
};

const SuccessfullTransactionSVG = styled(SVG)`
    width: 40%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #25D366
;
`;

const CenteringImageSuccessImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 20%;
    margin-top: 20%;
    cursor: pointer;
`;

const MainColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CenteringTextContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-content: center;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
`;

const CenteringContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-image: url(${BackgroundImage});
    background-size: contain;
`;

const ModulesMainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: white;
    @media (max-width: 2000px) {
    height: 500px;
    }
`;

const CenteringButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export default connect(null, null)(StripeSuccess);
