import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { EditPropertyDetailsPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchEditPropertyDetails(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/editPropertyDetailsAttempt', handleEditPropertyDetails, api);
}

function* handleEditPropertyDetails(api: PropertyGateway, data: EditPropertyDetailsPayload) {
    const {
        propertyId,
        details,
        location,
        amenities,
        description,
        unitNo,
    } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.editPropertyDetails], {
        propertyId,
        details,
        location,
        amenities,
        description,
        unitNo,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('saved!');
        yield put(Actions.editPropertyDetailsSuccess());
        yield put(Actions.getPropertyIndividualAttempt({ id: propertyId }));
        if (!response.data) {
            yield put(Actions.editPropertyDetailsFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.editPropertyDetailsFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
