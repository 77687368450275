import config from 'config';

import AuthGateway from './Auth';
import PropertyGateway from './Property';
import UserGateway from './User';
import PackagesGateway from './Packages';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const prop = new PropertyGateway(baseUrl);
const user = new UserGateway(baseUrl);
const packages = new PackagesGateway(baseUrl);

export default {
    auth,
    prop,
    user,
    packages,
};
