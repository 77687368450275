import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IUpdateOwnerUser } from 'entities/user';

export interface LoginParams {
    email: string;
    password: string;
    rememberMe?: boolean;
}

export interface LoginResponse {
    token: string;
}

export interface SignUpParams {
    email: string;
    password: string;
    role: number;
}

export interface RequestResetPasswordParams {
    email: string;
}

export interface VerifySignUpParams {
    id: string;
}

export interface DetailSignUpParams {
    data: IUpdateOwnerUser,
    authToken: string,
}

export interface VerifyForgotPasswordEmailParams {
    hash: string
}

export interface ForgotPasswordNewPasswordParams {
    newPassword: string;
    hash: string;
}

export interface UnsubscribeParams {
    hash: string;
}

export abstract class IAuthGateway extends Gateway {
    abstract login(params: LoginParams): GatewayResponse<LoginResponse | null>;

    abstract signUp(params: SignUpParams): GatewayResponse<null>;

    abstract requestResetPassword(params: RequestResetPasswordParams): GatewayResponse<null>;

    abstract verifySignUp(params: VerifySignUpParams): GatewayResponse<null>;

    abstract detailedSignUp(params: DetailSignUpParams): GatewayResponse<string | null>;

    abstract verifyForgotPasswordEmail(params: VerifyForgotPasswordEmailParams): GatewayResponse<string | null>;

    abstract forgotPasswordNewPassword(params: ForgotPasswordNewPasswordParams): GatewayResponse<string | null>;

    abstract unsubscribe(params: UnsubscribeParams): GatewayResponse<null>;
}
