import { Colors, Fonts } from '@dm/bigfish';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

const HomeScreenContent = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    padding: 30px 0;
`;

const LeftContainer = styled.div`
    grid-area: 1 / 1 / 5 / 4;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: fit-content(100%) fit-content(100%) fit-content(100%) 1fr;
`;

const RightContainer = styled.div`
    grid-area: 1 / 4 / 5 / 6;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr fit-content(100%);
`;

const PropertyPaginatorContainer = styled.div`
    grid-area: 1 / 1 / 2 / 3;
    width: 85%;
    margin: 30px;
`;

const PropertyDetailsContainer = styled.div`
    grid-area: 2 / 1 / 3 / 3;
    margin: 10px 15px 10px 30px;
`;

const PendingLeftContainer = styled.div`
    grid-area: 3 / 1 / 4 / 2;
    margin: 10px 10px 10px 30px;
`;

const PendingRightContainer = styled.div`
    grid-area: 3 / 2 / 4 / 3;
    margin: 10px 15px 10px 10px;
`;

const RentalAnalysisContainer = styled.div`
    grid-area: 4 / 1 / 5 / 3;
    margin: 10px 15px 10px 30px;
`;

const WalletContainer = styled.div`
    grid-area: 1 / 1 / 4 / 2;
    margin: 30px 30px 10px 15px;
`;

const PackageContainer = styled.div`
    grid-area: 4 / 1 / 5 / 2;
    margin: 10px 30px 10px 15px ;
`;

const TransactionPendingItem = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr fit-content(100%);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    align-items: center;

    p:nth-child(1){
        grid-area: 1 / 1 / 2 / 2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    p:nth-child(2){
        grid-area: 1 / 2 / 2 / 3;
    }

    span{
        grid-area: 1 / 3 / 2 / 4;
        display: flex;
    }
`;

const ViewButton = styled.button`
    font-weight: 600;
    font-family: ${Fonts.primary};
    color: ${Colors.white};
    background-color: ${Colors.primary};
    border-radius: 20px;
    border: none;
    width: 80px;
    height: 35px;
    transition: scale(1) 0.3s ease;

    &:hover {
        background-color: ${Colors.secondaryLight};
    }
    &:active {
        transform: scale(0.95);
    }
`;

const AcceptButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.white};
    background-color: ${Colors.success};
    border-radius: 50%;
    border: none;
    padding: 4px;
    margin-right: 3px;
    transition: scale(1) 0.3s ease;

    &:hover {
        background-color: #1f9775;
    }
    &:active {
        transform: scale(0.95);
    }
`;

const RejectButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.white};
    background-color: ${Colors.danger};
    border-radius: 50%;
    border: none;
    padding: 4px;
    transition: scale(1) 0.3s ease;

    &:hover {
        background-color: #a33333;
    }
    &:active {
        transform: scale(0.95);
    }
`;

const ButtonIcon = styled(SVG)`
    width: 17px;
    height: 17px;
    color: ${Colors.white};
`;

// ------------

const ModalContentMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
`;

const PaymentMethodText = styled.p`
    display: flex;
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 10px 10px 10px;
`;

const TransactionStatusUpdteButtonMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
`;

const StyledMainButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 100%;
    width: 85px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    margin-left: 5px;
    &:hover{
        opacity: 0.7;
        cursor: pointer;
    };
`;

const UploadDocumentText = styled.p`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export default {
    HomeScreenContent,
    LeftContainer,
    RightContainer,
    PropertyPaginatorContainer,
    PropertyDetailsContainer,
    PendingLeftContainer,
    PendingRightContainer,
    RentalAnalysisContainer,
    WalletContainer,
    PackageContainer,
    TransactionPendingItem,
    ViewButton,
    AcceptButton,
    RejectButton,
    ButtonIcon,
    ModalContent,
    ModalContentMainContainer,
    ModalContentContainer,
    PaymentMethodText,
    TransactionStatusUpdteButtonMainContainer,
    StyledMainButton,
    ButtonContainer,
    UploadDocumentText,
};
