import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IOwnerUser } from '@dm/types';

import { GetUserParams, IUserGateway, UpdateUserParams } from './UserBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class UserGateway extends IUserGateway {
    async GetUserInfo(params: GetUserParams): GatewayResponse<IOwnerUser> {
        const response: ApiResponse<IOwnerUser> = await this.api.get('/owner/user/getUserInfo', {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async SetUserInfo(params: UpdateUserParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/owner/user/updateUserInfo', params.data, getHeaders(params.authToken));
        return this.process(response);
    }
}
