import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useParams } from 'react-router-dom';
import { RootState, AppDispatch } from 'redux/store';

import SVG from 'react-inlinesvg';
import { TailSpin } from 'react-loader-spinner';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/Translate';

import { RoundInput, Button, BreadCrumb, Colors } from '@dm/bigfish';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

import Icons from '../../assets/icons';

interface ForgotPasswordNewPasswordProps {
    loadingVerifyId: boolean;
    loadingChangePassword: boolean;
    verifyId(hash: string): void;
    changePassword(newPassword: string, hash: string): void;
}

const ForgotPasswordNewPassword = (props: ForgotPasswordNewPasswordProps): JSX.Element => {
    const { loadingVerifyId, loadingChangePassword, verifyId, changePassword } = props;

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<boolean | null>(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<boolean | null>(null);

    const [breadCrumb] = useState([
        {
            label: Translate.t('ResetPassword.ResetPasswordBCSignup'),
            onClick: () => { NavActions.navToRegister(); },
        },
        {
            label: Translate.t('ResetPassword.ResetPasswordBCOwnerAccount'),
            onClick: () => { return false; },
        },
        {
            label: Translate.t('ResetPassword.ResetPasswordBCChangePassword'),
            onClick: () => { return false; },
        },
    ]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onChangeTextPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        const validPasswordTest = validator.isStrongPassword(e.target.value, { minLength: 5, minLowercase: 1, minUppercase: 1, minSymbols: 1 });

        setPasswordError(!validPasswordTest);
    };

    const onChangeTextConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    useEffect(() => {
        setConfirmPasswordError(!validator.equals(password, confirmPassword));
    }, [confirmPassword]);

    const params = useParams();
    const { id } = params;

    useEffect(() => {
        if (id) {
            verifyId(id);
        } else {
            NavActions.navToLogin();
        }
    }, []);

    const onSubmitSignupHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (!passwordError && !confirmPasswordError) {
            if (id) changePassword(password, id);
        } else {
            toast.error('Please enter a valid form');
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                {!loadingVerifyId ? (
                    <>
                        <Form onSubmit={onSubmitSignupHandler}>
                            <FormTitle>{Translate.t('ResetPassword.ResetPasswordTitle')}</FormTitle>
                            <Text>{Translate.t('ResetPassword.ResetPasswordDesc')}</Text>
                            <TextListGroup>
                                <TextList>
                                    <BulletIcon src={Icons.BulletIcon} />
                                    <Text>{Translate.t('ResetPassword.ResetPasswordRule1')}</Text>
                                </TextList>
                                <TextList>
                                    <BulletIcon src={Icons.BulletIcon} />
                                    <Text>{Translate.t('ResetPassword.ResetPasswordRule2')}</Text>
                                </TextList>
                                <TextList>
                                    <BulletIcon src={Icons.BulletIcon} />
                                    <Text>{Translate.t('ResetPassword.ResetPasswordRule3')}</Text>
                                </TextList>
                                <TextList>
                                    <BulletIcon src={Icons.BulletIcon} />
                                    <Text>{Translate.t('ResetPassword.ResetPasswordRule4')}</Text>
                                </TextList>
                            </TextListGroup>
                            <InputGroup type='password' label={Translate.t('ResetPassword.ResetPasswordLabelNewPassword')} placeholder={Translate.t('ResetPassword.ResetPasswordPlaceholder')} value={password} onChangeText={onChangeTextPassword} inputError={passwordError || false} errorText={Translate.t('ResetPassword.ResetPasswordErrorNewPassword')} required />
                            <InputGroup type='password' label={Translate.t('ResetPassword.ResetPasswordLabelConfirmNewPassword')} placeholder={Translate.t('ResetPassword.ResetPasswordAgainPlaceholder')} value={confirmPassword} onChangeText={onChangeTextConfirmPassword} inputError={confirmPasswordError || false} errorText={Translate.t('ResetPassword.ResetPasswordErrorConfirmNewPassword')} required />
                            <SubmitButton>
                                <Button loading={loadingChangePassword} label={Translate.t('ResetPassword.ResetPasswordButton')} />
                            </SubmitButton>
                        </Form>
                        <CarelineContainer>
                            <CarelineButton />
                        </CarelineContainer>
                    </>
                ) : (
                    <LoadingContainer>
                        <TailSpin color={Colors.primary} height={260} width={260} />
                    </LoadingContainer>
                )}
            </MainContainer>
        </>
    );
};

const Form = styled.form`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;

    max-width: 554px;

    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const FormTitle = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    margin-bottom: 12px;
`;

const InputGroup = styled(RoundInput)`
    margin-bottom: 16px;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    color: #696969;
`;

const TextList = styled.div`
    display: flex;
    align-items: center;
`;

const TextListGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 20px 28px 20px;

    > ${TextList}:not(:last-child){
        margin-bottom: 12px;
    }
`;

const BulletIcon = styled(SVG)`
    width: 10px;
    height: 10px;
    color: #000;

    margin-right: 9px;
`;

const SubmitButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    
    margin-top: 24px;
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 60vh;
`;

const mapStateToProps = (state: RootState) => ({
    loadingVerifyId: Selectors.getAuthVerifyForgotPasswordEmailAttempt(state),
    loadingChangePassword: Selectors.getAuthForgotPasswordNewPasswordAttempt(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    verifyId: (hash: string) => dispatch(Actions.authVerifyForgotPasswordEmailAttempt({ hash })),
    changePassword: (newPassword: string, hash: string) => dispatch(Actions.authForgotPasswordNewPasswordAttempt({ newPassword, hash })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordNewPassword);
