import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesPoscode, CoordinatesState, CoordinatesCountry, CoordinatesCity, WalletBalanceParams } from 'entities/property';
import { InitState } from 'redux/slices/property';

const getPropertySetDescriptionAttempting = (state: InitState): boolean => state.actions.SetDescription || false;
const getPropertySetDescriptionError = (state: InitState): string => state.error.SetDescription || '';
const getPropertySetDescription = (state: InitState): string => state.description || '';

// set amenities
const getPropertySetPropertyAmenitiesAttempting = (state: InitState): boolean => state.actions.SetPropertyAmenities || false;
const getPropertySetPropertyAmenitiesError = (state: InitState): string => state.error.SetPropertyAmenities || '';

const getPropertySetPropertyDetailsAttempt = (state: InitState): boolean => state.actions.propertydetails || false;
const getPropertySetPropertyDetailsError = (state: InitState): string => state.error.propertydetails || '';
const getPropertySetPropertyDetailsSuccess = (state: InitState): boolean => state.PropertyDetailsStatus || false;

const getPropertySetCreatePropertAttempting = (state: InitState): boolean => state.actions.setCreateProperty || false;
const getPropertySetCreatePropertError = (state: InitState): string => state.error.setCreateProperty || '';
const getPropertySetCreatePropert = (state: InitState): string => state.setCreatePropertyId || '';

const getPropertySetImageAttempting = (state: InitState): boolean => state.actions.setImagesProperty || false;
const getPropertySetImageError = (state: InitState): string => state.error.setImagesProperty || '';
const getPropertySetImage = (state: InitState): string => state.setImagesPropertyId || '';

const getPropertySetTermsAttempting = (state: InitState): boolean => state.actions.setTermsProperty || false;
const getPropertySetTermsError = (state: InitState): string => state.error.setTermsProperty || '';
const getPropertySetTerms = (state: InitState): string => state.terms || '';

const getPropertySetLocationAttempting = (state: InitState): boolean => state.actions.setLocationProperty || false;
const getPropertySetLocationError = (state: InitState): string => state.error.setLocationProperty || '';
const getPropertySetLocation = (state: InitState): string => state.SetLocation || '';

const getPropertyGetCoordinates = (state: InitState): Coordinates => state.coordinates || null;

const getPropertyGetAddress1 = (state: InitState): CoordinatesAddress1 => state.coordinatesaddress1 || null;

const getPropertyGetAddress2 = (state: InitState): CoordinatesAddress2 => state.coordinatesaddress2 || null;

const getPropertyGetPoscode = (state: InitState): CoordinatesPoscode => state.coordinatesposcode || null;

const getPropertyGetState = (state: InitState): CoordinatesState => state.coordinatesstate || null;

const getPropertyGetCountry = (state: InitState): CoordinatesCountry => state.coordinatescountry || null;

const getPropertyGetCity = (state: InitState): CoordinatesCity => state.coordinatescity || null;

const getPropertyApprovalTermsAttempting = (state: InitState): boolean => state.actions.setApprovalProperty || false;
const getPropertySetApprovalError = (state: InitState): string => state.error.setApprovalProperty || '';
const getPropertySetApproval = (state: InitState): string => state.approval || '';

const getPropertyUploadUrlAttempting = (state: InitState): boolean => state.actions.getPropertyUploadUrl || false;
const getPropertyUploadUrlError = (state: InitState): string => state.error.getPropertyUploadUrl || '';

const getPropertyUploadUrl = (state: InitState): string => state.PropertyImageUploadURL || '';

const uploadPropertyImageAttempting = (state: InitState): boolean => state.actions.uploadPropertyDocument || false;
const uploadPropertyImageError = (state: InitState): string => state.error.uploadPropertyDocument || '';

const setIsPropertyPropertyImageSuccess = (state: InitState): boolean => state.PropertyImageUploadComplete || false;

const setPropertySetDeleteImageAttempting = (state: InitState): boolean => state.actions.setDeleteImage || false;
const setPropertySetDeleteImageError = (state: InitState): string => state.error.setDeleteImage || '';
const setPropertySetDeleteImage = (state: InitState): string => state.deletedImage || '';

const setIsPropertyOpenLocationCollapsible = (state: InitState): boolean => state.propertyLocation || false;
const setIsPropertyOpenDetailsCollapsible = (state: InitState): boolean => state.propertyDetails || false;
const setIsPropertyOpenAmenitiesCollapsible = (state: InitState): boolean => state.propertyAmenities || false;
const setIsPropertyOpenImagesCollapsible = (state: InitState): boolean => state.propertyImages || false;
const setIsPropertyOpenDescriptionCollapsible = (state: InitState): boolean => state.propertyDescription || false;

const setPropertyCreatePropertyModal = (state: InitState): boolean => state.createPropertyModal || false;

const setTermsAndConditionSubmitted = (state: InitState): boolean => state.propertyTermsAndConditionSubmitted || false;
const setPropertyLocationSubmitted = (state: InitState): boolean => state.propertyLocationSubmitted || false;
const setPropertyDetailsSubmitted = (state: InitState): boolean => state.propertyDetailsSubmitted || false;
const setPropertyAmenitiesSubmitted = (state: InitState): boolean => state.propertyAmenitiesSubmitted || false;
const setPropertyImagesSubmitted = (state: InitState): boolean => state.propertyImagesSubmitted || false;
const setPropertyDescriptionSubmitted = (state: InitState): boolean => state.propertyDescriptionSubmitted || false;

const setPropertySignLink = (state: InitState): string => state.signLink || '';

const getPropertyWalletDetailsAttempting = (state: InitState): boolean => state.actions.setPropertyWallet || false;
const getPropertyWalletDetailsError = (state: InitState): string => state.error.setPropertyWallet || '';
const getPropertyWalletDetails = (state: InitState): WalletBalanceParams | null => state.walletData || null;

const setPropertyImageUploadFileName = (state: InitState): string => state.ImageUploadFileName || '';

const getPropertyLocationCloseCollapsibleAfterSubmitted = (state: InitState): string => state.propertyLocationMapCollapsibleClose || '';

const createPropertyAttempting = (state: InitState): boolean => state.actions.createProperty || false;
const createPropertyError = (state: InitState): string => state.error.createProperty || '';
const propertyId = (state: InitState): string => state.propertyId || '';

const editPropertyDetailsAttempt = (state: InitState): boolean => state.actions.editPropertyDetails || false;
const editPropertyDetailsError = (state: InitState): string => state.error.editPropertyDetails || '';

export default {
    getPropertySetDescriptionAttempting,
    getPropertySetDescriptionError,
    getPropertySetDescription,

    getPropertySetPropertyAmenitiesAttempting,
    getPropertySetPropertyAmenitiesError,

    getPropertySetPropertyDetailsAttempt,
    getPropertySetPropertyDetailsError,
    getPropertySetPropertyDetailsSuccess,

    getPropertySetCreatePropertAttempting,
    getPropertySetCreatePropertError,
    getPropertySetCreatePropert,

    getPropertySetTermsAttempting,
    getPropertySetTermsError,
    getPropertySetTerms,

    getPropertySetLocationAttempting,
    getPropertySetLocationError,
    getPropertySetLocation,

    getPropertySetImageAttempting,
    getPropertySetImageError,
    getPropertySetImage,

    getPropertyGetCoordinates,
    getPropertyGetAddress1,
    getPropertyGetAddress2,
    getPropertyGetPoscode,
    getPropertyGetState,
    getPropertyGetCountry,
    getPropertyGetCity,

    getPropertyApprovalTermsAttempting,
    getPropertySetApprovalError,
    getPropertySetApproval,

    getPropertyUploadUrlAttempting,
    getPropertyUploadUrlError,
    getPropertyUploadUrl,

    uploadPropertyImageAttempting,
    uploadPropertyImageError,

    setIsPropertyPropertyImageSuccess,

    setPropertySetDeleteImageAttempting,
    setPropertySetDeleteImageError,
    setPropertySetDeleteImage,

    setIsPropertyOpenLocationCollapsible,
    setIsPropertyOpenDetailsCollapsible,
    setIsPropertyOpenAmenitiesCollapsible,
    setIsPropertyOpenImagesCollapsible,
    setIsPropertyOpenDescriptionCollapsible,

    setPropertyCreatePropertyModal,

    setTermsAndConditionSubmitted,
    setPropertyLocationSubmitted,
    setPropertyDetailsSubmitted,
    setPropertyAmenitiesSubmitted,
    setPropertyImagesSubmitted,
    setPropertyDescriptionSubmitted,

    setPropertySignLink,

    getPropertyWalletDetailsAttempting,
    getPropertyWalletDetailsError,
    getPropertyWalletDetails,

    setPropertyImageUploadFileName,

    getPropertyLocationCloseCollapsibleAfterSubmitted,

    createPropertyAttempting,
    createPropertyError,
    propertyId,

    editPropertyDetailsAttempt,
    editPropertyDetailsError,
};
