import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import PackagesGateway from 'api/Packages';
import { GatewayResponseStatus } from 'api/types/types';
import { setSubscriptionTransactionRenewPayload } from 'redux/slices/packages/types';
import Utils from 'lib/Utils';
import { toast } from 'react-toastify';

export default function* watchsetPackagesSubscriptionTransactionsRenew(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesSetSubscriptionTransactionsRenewAttempt', handlesetPackagesSubscriptionTransactionsRenew, api);
}

function* handlesetPackagesSubscriptionTransactionsRenew(api: PackagesGateway, data: setSubscriptionTransactionRenewPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { paymentId, autoRenew } = data.payload;

        const response = yield* call([api, api.SetPackagesSubscriptionTransactionsRenew], { paymentId, autoRenew, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.packagesSetSubscriptionTransactionsRenewFailure(response.message));
            toast.error(`${response.message}`);
            yield put(Actions.setTransactionApproveOrRenewLoaderModalOpen(false));
            return;
        }

        yield put(Actions.packagesSetSubscriptionTransactionsRenewSuccess(response.data));
        yield put(Actions.setTransactionApproveOrRenewLoaderModalOpen(false));
        toast.success('Subscription has been renewed');
    }
}
