import authSlice from 'redux/slices/auth';
import uiSlice from 'redux/slices/ui';
import propSlice from 'redux/slices/prop';
import userInfoSlice from 'redux/slices/user';
import propertySlice from 'redux/slices/property';
import packagesSlice from 'redux/slices/packages';

export default {
    ...authSlice.actions,
    ...uiSlice.actions,
    ...propSlice.actions,
    ...userInfoSlice.actions,
    ...propertySlice.actions,
    ...packagesSlice.actions,

};
