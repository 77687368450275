import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { PropertyAmenitiesPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchGetAmenities(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetPropertyAmenitiesAttempt', handleGetAmenities, api);
}

function* handleGetAmenities(api: PropertyGateway, data: PropertyAmenitiesPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setPropertyAmenities], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.propertySetPropertyAmenitiesFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.propertySetPropertyAmenitiesSuccess());
        yield put(Actions.setPropertyAmenitiesSubmitted(true));
        toast.success('Saved');
    } else {
        // handle error
        yield put(Actions.propertySetPropertyAmenitiesFailure(response.message));
        toast.error('Something went wrong. Please try again in amenities.');
    }
}
