import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { Button } from '@dm/bigfish';

import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';

import SVG from 'react-inlinesvg';

import Modal from '../../../components/Modal';

import Icons from '../../../assets/icons';

import UploadComponentVacantPossession from './UploadComponentVacantPossession';

export interface PropertyModalProps {
    documentTypeName: string;
    modalOpenOrClose: boolean;
    setVacantDocumentUploadModal: (state: boolean) => void;
}

interface AddPaymentUploadData {
    name: string;
}

const VacantPossesionDocumentUpload = (props : PropertyModalProps) : JSX.Element => {
    const { modalOpenOrClose, setVacantDocumentUploadModal, documentTypeName } = props;

    const [counter, setCounter] = useState<AddPaymentUploadData[]>([{ name: '' }]);

    const onClickHandler = () => {
        setVacantDocumentUploadModal(false);
    };

    const addDocumentUpload = () => {
        setCounter(previous => {
            return [...previous, { name: '' }];
        });
    };

    return (
        <MainContainer>
            <Modal show={modalOpenOrClose} width='28%'>
                <div>
                    <DocumentNameTypeContainer>
                        <Title>{documentTypeName}</Title>

                        <CloseIcon src={Icons.closebutton} onClick={onClickHandler} />
                    </DocumentNameTypeContainer>
                    <UploadReferenceText>{Translate.t('Packages.VacantPossession.UploadDocument.Reference')}</UploadReferenceText>
                    {counter.map((item, index) => {
                        const { name } = item;

                        const uniqueKey = name + index;
                        return (
                            <UploadComponentVacantPossession key={uniqueKey} index={index} documentTypeName={documentTypeName} />

                        );
                    })}

                    <AddDocumentContainer>
                        <AddIconContainer>
                            <AddIcon src={Icons.AddIcon} onClick={addDocumentUpload} />
                            <TextAddNew>
                                {Translate.t('Packages.VacantPossession.UploadDocument.AddNew')}
                                {documentTypeName}
                            </TextAddNew>
                        </AddIconContainer>
                        <ButtonStyledContainer>
                            <Button label={Translate.t('Property.PropertyCreate.PropertyConfirmButton')} onClick={() => setVacantDocumentUploadModal(false)} />
                        </ButtonStyledContainer>

                    </AddDocumentContainer>
                </div>

            </Modal>

        </MainContainer>
    );
};

const CloseIcon = styled(SVG)`
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
`;

const AddDocumentContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 20px;
`;

const DocumentNameTypeContainer = styled.div`
display: flex;
justify-content: space-between;
`;

const ButtonStyledContainer = styled.div`
max-width: 125px;
`;

const AddIconContainer = styled.div`
display: flex;
align-items: center;
`;

const AddIcon = styled(SVG)`
    display: flex;
    flex-direction: column;
    margin-right: 8px;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  background-color: #ffffff;
  margin-bottom: 40px;
`;

const UploadReferenceText = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

const TextAddNew = styled.p`
display: flex;
font-size: 12px;
font-weight: 400;
line-height: 16px;
color: #696969;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const mapStateToProps = (state: RootState) => ({
    modalOpenOrClose: Selectors.packagesSetVacantPossesionModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setVacantDocumentUploadModal: (state: boolean) => dispatch(Actions.setVacantPossesionModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacantPossesionDocumentUpload);
