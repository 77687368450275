import { fork } from 'redux-saga/effects';

import PackagesGateway from 'api/Packages';
import { RootSagaReturnType } from 'sagas/types';

import watchGetPackages from './getPackages';
import watchPackagesPayments from './setPackagesPayments';
import watchGetPackagesUploadUrl from './getPackagesUploadUrl';
import watchUploadPackagesDocument from './uploadPackagesDocument';
import watchGetPackagesDataSort from './getVacantPossessionSort';
import watchGetLeaseSaleSort from './getLeaseSaleSort';
import watchGetDefectInspectionSort from './getDefectInspectionSort';
import watchGetSignDocumentTemplate from './getSignTemplate';
import watchSetPackagesUploadSignDocuments from './setPackagesUploadSignDocuments';
import watchUploadSignedPackagesDocument from './uploadSignedPackagesDocument';
import watchPackagesPaymentCreditCard from './getPackagesPaymentCreditCard';
import watchGetPropertyManagementwSort from './getPropertyManagementSort';
import watchPropertyManagementPackageTransactionUpdate from './setPropertyManagementTransactionUpdate';
import watchPropertyManagementPackageTransactionUpdateGateway from './setPropertyManagementTransactionUpdateGateway';

import watchSetPackagesTopUpWallet from './setPackagesTopUpWallet';
import watchGetPackagesPropertyManagementDocumentsSort from './getPackagesPropertyManagementDocumentsSort';
import watchGetPackagesVacantManagementDocumentsSort from './getVacantManagementSort';
import watchGetSubscriptionTransactions from './getSubscriptionTransactions';
import watchsetPackagesSubscriptionTransactionsCancel from './setPackagesSubscriptionTransactionsCancel';
import watchsetPackagesSubscriptionTransactionsRenew from './setPackagesSubscriptionTransactionsRenew';

import watchGetGoogleDriveContent from './getGoogleDriveContent';
import watchCreatePMTransaction from './createPMTransaction';

export default (api: PackagesGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPackages, api);
        yield fork(watchPackagesPayments, api);
        yield fork(watchGetPackagesUploadUrl, api);
        yield fork(watchUploadPackagesDocument, api);
        yield fork(watchGetPackagesDataSort, api);
        yield fork(watchGetLeaseSaleSort, api);
        yield fork(watchGetDefectInspectionSort, api);
        yield fork(watchGetSignDocumentTemplate, api);
        yield fork(watchSetPackagesUploadSignDocuments, api);
        yield fork(watchUploadSignedPackagesDocument, api);
        yield fork(watchPackagesPaymentCreditCard, api);
        yield fork(watchGetPropertyManagementwSort, api);
        yield fork(watchPropertyManagementPackageTransactionUpdate, api);
        yield fork(watchPropertyManagementPackageTransactionUpdateGateway, api);
        yield fork(watchSetPackagesTopUpWallet, api);
        yield fork(watchGetPackagesPropertyManagementDocumentsSort, api);
        yield fork(watchGetPackagesVacantManagementDocumentsSort, api);
        yield fork(watchGetSubscriptionTransactions, api);
        yield fork(watchsetPackagesSubscriptionTransactionsCancel, api);
        yield fork(watchsetPackagesSubscriptionTransactionsRenew, api);
        yield fork(watchGetGoogleDriveContent, api);
        yield fork(watchCreatePMTransaction, api);
    }

    return {
        rootSaga,
    };
};
