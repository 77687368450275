import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IPackagePrice, Category, IVacantPossession, PropertyManagementTransactionResponse, ILeaseSale, IDefectInspection, PaymentGatewayResponse, IPropertyManagement, IVacantManagement, ViewTransactionResponse } from '@dm/types';
import { GetPaymentInfoRTO, IGoogleDriveContent } from 'entities/packages';

export interface GetPackagesPricingParams {
    propertyId: string;
    authToken: string;
}

export interface SetPackagesPaymentsParams {
    propertyId: string;
    paymentType: number;
    bankNumber: string;
    accountName: string;
    bankName: string;
    packages: Category[];
    reference: string;
    authToken: string;
}

export interface PackagesSetDocumentsParams {
    category: string;
    id: string;
    name: string;

}

export interface PackagesGetDataSortParams {
    category: number;
    propertyId: string;
    sort: number;
    authToken: string;
}

export interface GetPackagesUploadUrlParams {
    authToken: string;
    category: Category;
    id: string;
    name: string;
    extension: string;
}

export interface UploadPackagesDocumentParams {
    url: string;
    doc: FormData;
}

export interface GetDocumentTemplateParams {
    authToken: string;
    documentTemplate: number,
    propertyId: string
}

export interface PackagesPaymentCreditCardParams {
    paymentId: string;
    authToken: string;
}

export interface PackagesPropertyManagementSortParams {
    propertyId: string;
    sort: number;
    transactionType: number;
    authToken: string;
}

export interface SetPropertyManagementTransactionUpdateParams {
    authToken: string;
    propertyId: string;
    transactionId: string;
    paymentType: number;
    status: number;
    bankName: string;
    bankNumber: string;
    accountName: string;
}

export interface SetITopUpPaymentParams {
    authToken: string;
    propertyId: string,
    paymentType: number;
    amount: number;
    bankNumber: string;
    accountName: string;
    bankName: string;
    reference: string;
}

export interface PackagesGetSubscriptionTransactionsParams {
    authToken: string;
}

export interface PackagesSetSubscriptionTransactionCancelParams {
    authToken: string;
    paymentId: string;
    category: number;
}

export interface PackagesSetSubscriptionTransactionRenewParams {
    authToken: string;
    paymentId: string;
    autoRenew: number;
}

export interface GetGoogleDriveContentParams {
    authToken: string;
    fileId: string;
}

export interface CreatePMTransactionParams {
    authToken: string,
    propertyId: string;
    amount: number;
    description: string;
    transactionDate: string,
    transactionType: number;
    isRecurring?: boolean,
}

export interface updatePMTransactionParams {
    authToken: string;
    propertyId: string;
    transactionId: string;
    status: number;
  }

export abstract class IPackagesGateway extends Gateway {
    abstract getPackagesPricing(params: GetPackagesPricingParams): GatewayResponse<IPackagePrice>;

    abstract setPackagesPayments(params: SetPackagesPaymentsParams): GatewayResponse<PaymentGatewayResponse>;

    abstract getPackagesUploadUrl(params: GetPackagesUploadUrlParams): GatewayResponse<string>;

    abstract uploadPackagesDocument(params: UploadPackagesDocumentParams): GatewayResponse<null>;

    abstract getPackagesVacantPossessionSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantPossession >;

    abstract getPackagesLeaseSaleSort(params: PackagesGetDataSortParams): GatewayResponse<ILeaseSale>;

    abstract getPackagesDefectInspectionSort(params: PackagesGetDataSortParams): GatewayResponse<IDefectInspection>;

    abstract getSignDocumentTemplate(params: GetDocumentTemplateParams): GatewayResponse<string>;

    abstract getPackagesPaymentCreditCard(params: PackagesPaymentCreditCardParams): GatewayResponse<GetPaymentInfoRTO | null>;

    abstract getPackagesPropertyManagementSort(params: PackagesPropertyManagementSortParams): GatewayResponse<PropertyManagementTransactionResponse[] | null>;

    abstract setPropertyManagementTransactionUpdate(params: SetPropertyManagementTransactionUpdateParams): GatewayResponse<string >;

    abstract setPropertyManagementTransactionUpdateGateway(params: SetPropertyManagementTransactionUpdateParams): GatewayResponse<null | PaymentGatewayResponse>;

    abstract setPackagesTopUpWallet(params: SetITopUpPaymentParams): GatewayResponse<PaymentGatewayResponse | null>;

    abstract getPackagesPropertyManagementDocumentsSort(params: PackagesGetDataSortParams): GatewayResponse<IPropertyManagement | null>;

    abstract getPackagesVacantManagementDocumentsSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantManagement | null>;

    abstract getPackagesSubscriptionTransactions(params: PackagesGetSubscriptionTransactionsParams): GatewayResponse<ViewTransactionResponse[] | null>;

    abstract SetPackagesSubscriptionTransactionsCancel(params: PackagesSetSubscriptionTransactionCancelParams): GatewayResponse<string>;

    abstract SetPackagesSubscriptionTransactionsRenew(params: PackagesSetSubscriptionTransactionRenewParams): GatewayResponse<string>;

    abstract getGoogleDriveContent(params: GetGoogleDriveContentParams): GatewayResponse<IGoogleDriveContent[]>;

    abstract createPMTransaction(params: CreatePMTransactionParams): GatewayResponse<{id: string}>;
}
