import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import { UpdatePropertyManagementTransactionStatusActionPayload } from 'redux/slices/packages/types';

import Utils from 'lib/Utils';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import { toast } from 'react-toastify';

export default function* watchPropertyManagementPackageTransactionUpdate(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesSetPropertyManagementPackageTransactionAttempt', handlePackages, api);
}

function* handlePackages(api: PackagesGateway, data: UpdatePropertyManagementTransactionStatusActionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName } = data.payload;

        const response = yield* call([api, api.setPropertyManagementTransactionUpdate], {
            propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesSetPropertyManagementPackageTransactionFailure(Translate.t('ResetPassword.ResetPasswordToastError')));
                toast.error('try again');
                return;
            }
            yield put(Actions.packagesSetPropertyManagementPackageTransactionSuccess(response.data));
            toast.success(response.data);
            yield put(Actions.packagesGetIPropertyManagementDataSortAttempt({ propertyId, sort: 0, transactionType: 9999 }));
            yield put(Actions.setPackagesPropertyManagementStatusUpdateModalOpen(false));
        } else {
        // handle error
            yield put(Actions.packagesSetPropertyManagementPackageTransactionFailure(response.message));
            toast.error(response.message);
        }
    }
}
