import User from './user.svg';
import propertyBid from './propertyBid.svg';
import CarelineIcon from './careline.svg';
import AddIcon from './add.svg';
import WarningIcon from './warning.svg';
import RightArrow from './right-arrow.svg';
import userlogin from './userlogin.svg';
import BulletIcon from './bullet.svg';
import CautionCircle from './caution_circle.svg';
import LandPropertyType from './land_property_type.svg';
import searchIcon from './searchicon.svg';
import HouseMarkerIcon from './house-marker.svg';
import VerifiedIcon from './VerifiedIcon.svg';
import AddPackage from './addpackage.svg';
import noimage from './noImage.svg';
import ManualTransfer from './manualtransfer.svg';
import uploadIcon from './uploadIcon.svg';
import addbutton from './addbutton.svg';
import closebutton from './closebutton.svg';
import documentview from './documentview.svg';
import documentDisplay from './document.svg';
import sign from './sign.svg';
import creditCard from './creditCard.svg';
import ErrorCloseIcon from './ErrorCloseIcon.svg';
import SuccessfullTransaction from './SuccessfullTransaction.svg';
import Search from './search.svg';
import xmark from './xmark.svg';
import PlusIcon from './plus.svg';
import RightGlyphIcon from './right-glyph.svg';
import MenuIcon from './menu.svg';
import CheckmarkIcon from './checkmark.svg';
import clip from './clip.svg';
import UploadFile from './uploadFile.svg';
import UploadFileCheck from './uploadFileCheck.svg';
import FolderRight from './folderRight.svg';
import FolderLeft from './folderLeft.svg';
import PdfIcon from './pdf.svg';

export default {
    CarelineIcon,
    AddIcon,
    WarningIcon,
    RightArrow,
    userlogin,
    BulletIcon,
    CautionCircle,
    LandPropertyType,
    searchIcon,
    HouseMarkerIcon,
    VerifiedIcon,
    AddPackage,
    noimage,
    ManualTransfer,
    uploadIcon,
    addbutton,
    closebutton,
    documentview,
    documentDisplay,
    propertyBid,
    User,
    sign,
    creditCard,
    ErrorCloseIcon,
    SuccessfullTransaction,
    Search,
    xmark,
    PlusIcon,
    RightGlyphIcon,
    MenuIcon,
    CheckmarkIcon,
    clip,
    UploadFile,
    UploadFileCheck,
    FolderRight,
    FolderLeft,
    PdfIcon,
};
