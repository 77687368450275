import React from 'react';
import styled from 'styled-components';

interface WrapperProps {
  children: JSX.Element | JSX.Element[];
}
const Wrapper = ({ children }: WrapperProps): JSX.Element => {
    return (
        <StyledWrapper>{children}</StyledWrapper>
    );
};

const StyledWrapper = styled.div`
  max-width: 1424px;
  margin: 0 auto;

  /* @media (max-width: 1624px) {
    max-width: 1224px;
  }

  @media (max-width: 1440px) {
    max-width: 100%;
  } */

  /* @media (max-width: 1280px) {
    width: 100%;
  } */
`;

export default Wrapper;
