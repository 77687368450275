import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CardCollapsible, Colors, Button, BreadCrumb } from '@dm/bigfish';
import { RootState, AppDispatch } from 'redux/store';

import MainContainer from 'components/MainContainer';
import NavActions from 'lib/NavActions';
import { IPropertyIndividual } from 'entities/property';
import { Category, IPropertyPackageStatusEnum } from '@dm/types';
import LoadingScreen from 'components/LoadingScreen';
import media from 'lib/Breakpoints';
import SupportInfo from '../../../components/SupportInfo';
import ButtonInfoPackages from '../../../components/ButtonInfoPackages';
import QuickNavigationButton from '../../../components/QuickNavigationButton';

import PropertyImage from '../../../components/PropertyImage';
import Icons from '../../../assets/icons';
import HousingIMG from '../../../assets/images/building.png';
import EventTrailRecentEvents from '../../../components/EventTrailRecentEvents';
import DocumentUploadIndex from '../componentsPackages/DocumentUploadIndex';

interface NavParams {
    propertyIndividualId: string;
}

interface CollapsibleListProps {
    loading: boolean;
    error: string;
    propertyList: IPropertyIndividual;
    getPropertyIndividual: (id: string) => void;
    setLeaseSaleDocumentUploadID: (id: string) => void;

    modalLoad: boolean;
    setModalOpen: (state: boolean) => void;
}

const LeaseSale = (props: CollapsibleListProps): JSX.Element => {
    const { loading, error, propertyList, getPropertyIndividual, setLeaseSaleDocumentUploadID, modalLoad, setModalOpen } = props;

    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [chip, setChip] = useState(false);

    const [chipnumber, setChipnumber] = useState(0);
    const [country, setCountry] = useState('');
    const [packageBought, setPackageBought] = useState(false);
    const ownerTermsRef = useRef<any>(null);
    const useLocationstate = useLocation();
    const { propertyIndividualId } = useLocationstate.state as NavParams;

    const [pendingEvents, setPendingEvents] = useState([]);

    useEffect(() => {
        onOpenCollapsibleOwner();
        getPropertyIndividual(propertyIndividualId);

        setOwnerStatusForPackage();
    }, []);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Packages.PackagesCard.CardTitleLeaseSale'),
                onClick: () => { return false; },
            },
        ],
    );

    const setOwnerStatusForPackage = () => {
        if (propertyList.packages) {
            if (propertyList.packages.leaseSale?.status === IPropertyPackageStatusEnum.Active) {
                setPackageBought(true);
                setName(propertyList.name);
                if (propertyList.location?.address1) {
                    setCity(propertyList.location?.address1);
                }
                if (propertyList.location?.state) {
                    setState(propertyList.location?.state);
                }
                if (propertyList.location?.country) {
                    setState(propertyList.location?.country);
                }
                if (propertyList.packages.leaseSale.status) {
                    setChip(true);
                    setChipnumber(propertyList.packages.leaseSale.status);
                }
            }
        }
    };

    const onOpenCollapsibleOwner = () => {
        if (ownerTermsRef.current) {
            ownerTermsRef.current.openCollapsible();
        }
    };

    const setLeaseSaleDocumentID = () => {
        setLeaseSaleDocumentUploadID(propertyIndividualId);
        setModalOpen(true);
    };

    const checkPropertyPackagesStatus = (packages: any) => {
        const packagesnumber = packages;

        switch (packagesnumber) {
            case 0:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusNotPurchasedYet');
            case 1:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 2:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 3:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusActive');
            case 4:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPaymentRejected');
            case 5:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusDisabled');
            case 6:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusFreeTrial');
            case 7:
                return Translate.t('Packages.VacantPossession.ButtonInfo.Statuspurchase');
            default:
                return '';
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <HeadersContainer>
                    {/* <CardCollapsibleStyled ref={ownerTermsRef} title={propertyList.name.substring(0, 20)} subtitle={propertyList.location ? `${(propertyList.location?.city) ? propertyList.location.city : city} ${(propertyList.location?.state) ? propertyList.location?.state : state} ${(propertyList.location?.country) ? propertyList.location.country : country}` : ''} chipText={(propertyList.packages?.leaseSale?.status) ? checkPropertyPackagesStatus(propertyList.packages.leaseSale.status) : ''}> */}
                    {loading && (
                        <PropertyManagementContainer>
                            <LoadingScreen />
                        </PropertyManagementContainer>
                    )}
                    {(!loading) && (propertyList.packages && propertyList.packages.leaseSale?.status === IPropertyPackageStatusEnum.Active) && (

                        <PropertyManagementContainer>
                            <ModulesPropertyContainer>
                                <PropertySubInfoContainer>
                                    <PropertyImage src={HousingIMG} />
                                </PropertySubInfoContainer>
                                <PropertyInfoContainer>
                                    {/* {VIEW PROPERTY BIDS FEATURE TO BE ADDED IN FEATURE} */}
                                    {/* <ButtonInfoPackages desc={Translate.t('Packages.LeaseAndSale.ViewBid')} rightIconSrc={Icons.propertyBid} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => undefined} /> */}
                                    <ButtonInfoPackages desc={Translate.t('Packages.LeaseAndSale.UploadLeaseDocument')} rightIconSrc={Icons.uploadIcon} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => setLeaseSaleDocumentID()} />
                                    <ButtonInfoPackages desc={Translate.t('Packages.LeaseAndSale.ViewLeaseDocuments')} status={checkPropertyPackagesStatus(chipnumber)} rightIconSrc={Icons.documentDisplay} onclick={() => NavActions.navToLeaseAndSaleViewDocuments(propertyIndividualId)} />
                                    {modalLoad && (
                                        <DocumentUploadIndex packageType={Category.LeaseSale} />
                                    )}
                                    {/* <EventTrailRecentEvents title={Translate.t('Property.PropertyRecentEvents')} primary buttonLabel={Translate.t('Property.PropertyRecentEvents')} data={(propertyList.packages.leaseSale.recentEvents && propertyList.packages.leaseSale.recentEvents[0]) ? propertyList.packages.leaseSale.recentEvents : pendingEvents} /> */}

                                </PropertyInfoContainer>
                            </ModulesPropertyContainer>

                            {/* <ModulesFooter>
                                    <ModuleFooterNav>
                                        {Translate.t('Packages.LeaseAndSale.PackageStatus')}
                                        {(propertyList.packages && propertyList.packages.leaseSale && propertyList.packages.leaseSale.status) ? <ChipText>{checkPropertyPackagesStatus(propertyList.packages.leaseSale.status)}</ChipText> : <ChipText>{checkPropertyPackagesStatus(chipnumber)}</ChipText>}
                                    </ModuleFooterNav>
                                </ModulesFooter> */}
                        </PropertyManagementContainer>
                    )}

                    {(!loading) && (propertyList.packages && propertyList.packages.leaseSale?.status !== IPropertyPackageStatusEnum.Active) && (
                        <PropertyManagementContainer>
                            <ButtonContainer>
                                <SubscriberButton
                                    label={Translate.t('Packages.LeaseAndSale.IfLeasePackageNotBought')}
                                    onClick={() => NavActions.navToPackages(propertyIndividualId)}
                                />
                            </ButtonContainer>
                        </PropertyManagementContainer>

                    )}

                    {/* </CardCollapsibleStyled> */}

                    <InfoContainer>
                        <SupportContainer>
                            <SupportInfo />
                        </SupportContainer>
                        <NavigationContainer>
                            <NavigationTextTitle>{Translate.t('Packages.PackagesCard.QuickNavigation')}</NavigationTextTitle>
                            <NavigationTextDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</NavigationTextDescription>
                            <NavigationContainers>
                                <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} onclick={() => NavActions.navToViewVacantPossessionPackages(propertyIndividualId)} />
                            </NavigationContainers>
                            {/* {THE QUICK NAVIGATION FEATURE TO BE USED IN FUTURE} */}
                            {/* <NavigationContainers>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} onclick={() => undefined} />
                        </NavigationContainers>
                        <NavigationContainers>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} onclick={() => undefined} />
                        </NavigationContainers>
                        <NavigationContainers>
                            <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} onclick={() => undefined} />
                        </NavigationContainers> */}
                        </NavigationContainer>
                    </InfoContainer>

                </HeadersContainer>

            </MainContainer>
        </>

    );
};

const PropertyManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    object-fit: contain;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 20px;

    ${media.sm`
        margin-right: 0px;
    `}
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 10%;
    justify-content: center;

    ${media.sm`
        margin-top: 0;
    `}
`;

const InfoContainer = styled.div`
    width: 100%;
`;

const SupportContainer = styled.div`
    margin-bottom: 20px;
`;
const NavigationContainers = styled.div`
    margin-top: 12px;
`;

const NavigationTextTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const NavigationTextDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const HeadersContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 65% 30%  ;
    padding: 20px;
    padding-top: 40px;

    ${media.lg`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `}
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const CardCollapsibleStyled = styled(CardCollapsible)`
    width: 100%;
`;

const SubscriberButton = styled(Button)`
    display: flex;
    width: 90%;
    height: 28%;
    padding: 20px;
    background-color: #F2865E;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-self: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    height: 80px;

    ${media.sm`
        font-size: 18px;
    `}
`;

const ChipText = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 8px 0 8px 8px;
    padding: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: ${Colors.primary};
    color: ${Colors.white};
    border-radius: 8px;
`;

const ModulesFooter = styled.div<{ focused?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 48px;
    padding: 12px 20px;
    background-color: #FFF2E7;
    ${(props) => props.focused && css`
    background-color: #F2865E;;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    `}
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
`;

const ModuleFooterNav = styled.button`
    display: flex;
    align-items: center;
    color: #F2865E;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    span {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
    }
`;

const ModulesPropertyContainer = styled.div`
    display: flex;
    padding: 20px;
    img {
        margin-right: 16px;
    }
    ${media.sm`
        padding: 0;
    `}
`;

const PropertySubInfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    >:not(:last-child) {
        margin-bottom: 20px;
    }
    span {
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        font-style: italic;
        max-width: 224px;
        text-align: justify;
    }
`;

const PropertyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    >:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropPropertyIndividualAttempt(state),
    error: Selectors.getPropPropertyIndividualFailure(state),
    propertyList: Selectors.getPropPropertyIndividual(state),
    modalLoad: Selectors.packagesSetLeaseSaleModal(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyIndividual: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
    clearProperty: () => dispatch(Actions.clearProperty()),
    setLeaseSaleDocumentUploadID: (params: string) => dispatch(Actions.setCreateDocumentUploadID(params)),
    setModalOpen: (state: boolean) => dispatch(Actions.setLeaseSaleModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaseSale);
