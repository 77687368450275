import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import { create } from 'apisauce';
import Translate from 'lib/Translate';
import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';

import PackagesGateway from 'api/Packages';
import { UploadPackagesDocumentActionPayload } from 'redux/slices/packages/types';

export default function* watchUploadPackagesDocument(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/uploadPackagesDocumentAttempt', handleUploadDocument, api);
}

function* handleUploadDocument(api: PackagesGateway, data: UploadPackagesDocumentActionPayload) {
    const { preSignedUrl, document, docType } = data.payload;

    const formData = new FormData();
    formData.append('file', document);

    const s3Api = create({
        baseURL: preSignedUrl,
        headers: {
            'content-type': `application/${docType}`,
        },
    });

    const response = yield* call(s3Api.put, '', document);

    if (!response.ok) {
        yield put(Actions.uploadPackagesDocumentFailure(Translate.t('Packages.PaymentUpload.Error')));
        toast.error(Translate.t('Packages.PaymentUpload.Error'));
        return;
    }

    yield put(Actions.uploadPackagesDocumentSuccess());
    yield put(Actions.setDocumentUploadStatus(true));
    yield put(Actions.setPackagesUploadedDocuments(true));
    yield put(Actions.setUploadFileModalOpen(false));

    toast.success(Translate.t('Packages.PaymentUpload.UploadSuccess'));
}
