import React from 'react';
import styled from 'styled-components';

import { ContactButton } from '@dm/bigfish';

const SupportInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  padding: 20px ;

  color: #000000;

  border-radius: 12px;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

  >:not(:last-child) {
        margin-bottom: 20px;
  }

  margin-top: 76px;
  margin-left: 16px;
  max-height: 174px;

  max-width: 544px; /* 'initial width' */
  width: 100%;
  min-width: 150px;

`;

const SupportInfoTitle = styled.h3`
  font-size: 18px;
  line-height: 22px;

  font-weight: 700;
  margin-right: 214px;
`;

const SupportInfoParagraph = styled.p`
  font-size: 14px;
  line-height: 18px;

  font-weight: 400;
`;

const SupportInfoCreateProperty = (): JSX.Element => {
    return (
        <SupportInfoContainer>
            <SupportInfoTitle>Need Assistance?</SupportInfoTitle>
            <SupportInfoParagraph>Request a property specialist for further enquiries.</SupportInfoParagraph>
            <ContactButton name='Customer service ' phoneNumber='+60 16-631 4316' />
        </SupportInfoContainer>
    );
};

export default SupportInfoCreateProperty;
