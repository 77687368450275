import { GatewayResponse, GatewayResponseStatus } from 'api/types/types';
import Gateway from 'api/types/Gateway';

import { IServerProperty, IPropertyIndividual, WalletBalanceParams, IPropertyPaginator, IAllPropertyDetailDashboards, IRentalAnalytics, IWalletAnalytics } from 'entities/property';
import { GetAllPropertyDetailsParams, GetRentalAnalyticsParams } from 'api/PropertyBase';

const simulateApi = (response: any) => {
    return new Promise(res => {
        setTimeout(() => {
            res(response);
        }, 1000);
    });
};

export default class PropertyGateway extends Gateway {
    async getProperty(): GatewayResponse<IServerProperty> {
        const response = await simulateApi({
            index: 1,
            maxIndex: 3,
            data: [
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as IServerProperty,
        };
    }

    async getPropertyPaginator(): GatewayResponse<IPropertyPaginator> {
        const response = await simulateApi({
            data: [
                {
                    propertyId: '62d7ae12ac735f1293c0f502',
                    propertyName: 'BLA BLA BLA ',
                    selected: true,
                },
                {
                    propertyId: '62d8b58972ec733ec658b65e',
                    propertyName: 'awdawdaw d',
                    selected: false,
                },
                {
                    propertyId: '62e194951e1b979e830248e9',
                    propertyName: 'sdf',
                    selected: false,
                },
                {
                    propertyId: '62e1f0291af22829af002e8a',
                    propertyName: 'zz',
                    selected: false,
                },
            ],
        });
        return {
            status: GatewayResponseStatus.Success,
            data: response as IPropertyPaginator,
        };
    }

    async getAllPropertyDetails(): GatewayResponse<IAllPropertyDetailDashboards> {
        const response = await simulateApi({
            propertyDetails: {
                name: 'BLA BLA BLA ',
                propertyType: 1,
                titleType: 1,
                unitSize: 0,
                floor: 1,
                unit: '',
                ensure: 1,
                location: {
                    long: 101.6632718,
                    lat: 3.1141124,
                    address1: 'No 2',
                    address2: 'Jalan Kerinchi',
                    postcode: '',
                    city: 'Kuala Lumpur',
                    state: 'Wilayah Persekutuan Kuala Lumpur',
                    country: 'MY',
                },
            },
            pendingAction: {
                total: 2,
                actions: [
                    {
                        package: 'designRenovation',
                        status: 7,
                    },
                    {
                        package: 'leaseSale',
                        status: 2,
                    },
                ],
            },
            pendingTransactions: {
                total: 48,
                transactions: [
                    {
                        amount: 650,
                        description: 'Transaction',
                        transactionsId: '645072e77d506715e4a9e722',
                        createdAt: '2023-05-02T02:18:15.064Z',
                        status: 0,
                        transactionType: 3,
                        fileUrl: [],
                    },
                    {
                        amount: 12,
                        description: '',
                        transactionsId: '63a5214fda1037d47c6b1eac',
                        createdAt: '2022-12-23T03:32:31.944Z',
                        status: 0,
                        fileUrl: [],
                    },
                    {
                        amount: 123213,
                        description: 'sad',
                        transactionsId: '63a27d77631d0a3e50997ce8',
                        createdAt: '2022-12-21T03:28:55.481Z',
                        status: 0,
                        fileUrl: [],
                    },
                    {
                        amount: 110,
                        description: 'asdasdxxxx',
                        transactionsId: '63a27d1e631d0a3e50997c7e',
                        createdAt: '2022-12-21T03:27:26.028Z',
                        status: 0,
                        fileUrl: [
                            'https://test-private-dormeo-bucket.s3.ap-southeast-1.amazonaws.com/property_management_transaction/62d7ae12ac735f1293c0f502/63a27d1e631d0a3e50997c7e/FIdZRFLfTmSZABVx2w3vV.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA2FSWVLGKFYS4EDWT%2F20230707%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20230707T023023Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkgwRgIhAMrE5zsqHig0Mxw6VlsB9w2q9ExE0sTivYK6zNiGZeasAiEAi86z1Eq%2B%2BMFsZPSQi22dLPdWwuhcjCyD%2FOOQ8WTa5vsqkgMIVBACGgw2OTkxODc2ODE2ODQiDHMnK3XKeLuuieeiYSrvAkgvgk4U7PdFc2STGspP3KKK1D%2FsC2vOQPJELXSb78eA49oSWqVLA%2B7XRNVTbpF9RqtfaBKGftewHbGlXDs3S1MTJwdTZk9VlvhaEvh1h%2BtvPvXh18JVWJuXKm5RjEg6ayNxVM7Kuzinom38lY89iKBoRIpNCe0w4YtWtdd5QcxOjH6iuG98BlgJXq7WTUgD3tGYK34tVDWsT0%2F0MOgrnD8VE86t0sge6iLw4RueNSi5rVnpYFy5R4Pcx4P9Gwh1FSaMkJR1wBKNeutY7EIwk1clzsi0mRrDS8So%2BefPBViNaiREQSP2eXsY1tQBLfMMcZF2mbQkZQfC%2FB77OxsVSC%2Bo%2BlpY63RLXATZyAAQUHgTpYmamuYaru6Sc4V06VbHPuQv77d%2BEuS1IB4L7cpbcksrxY0dyhI%2BISdntvQgIqzWtw37Afb6W3JxW7lt4tGO8gnG8UXUdOlDY71nsKMn6vPGQf%2B8NDfyIYWpqWLDYOQwvPGdpQY6nAGn1HCHMvCGiPaoihAcZjE8tXM9P7mj0%2FPlMegG%2B6LVWVTFb2Y2tV9i14nyr3UaeYNimjOT9qRR9h95cpEP21Fla4eyDyV1GpEfO5Y%2BEls8VRAiiXaLCuvrnkI7RujNu59IglU5Z4vmcZLYpX5Ht7nlhPGkSd3sbWlTl8Pw0SCL1kd%2BLWj3%2FEtBlcw%2Fi6HQzLZq2krbYoeExRRH75g%3D&X-Amz-Signature=1272783da7c23726329fb9347241bf7b608fb0d4f7ce3e411174ca96fcb85f50&X-Amz-SignedHeaders=host',
                        ],
                    },
                    {
                        amount: 123,
                        description: 'azzxs',
                        transactionsId: '63a279d1645dfc5ab7e81803',
                        createdAt: '2022-12-21T03:13:21.714Z',
                        status: 0,
                        fileUrl: [
                            'https://test-private-dormeo-bucket.s3.ap-southeast-1.amazonaws.com/property_management_transaction/62d7ae12ac735f1293c0f502/63a279d1645dfc5ab7e81803/iUCVq3YKYuBkdZU_yQ7MT.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA2FSWVLGKFYS4EDWT%2F20230707%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20230707T023023Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkgwRgIhAMrE5zsqHig0Mxw6VlsB9w2q9ExE0sTivYK6zNiGZeasAiEAi86z1Eq%2B%2BMFsZPSQi22dLPdWwuhcjCyD%2FOOQ8WTa5vsqkgMIVBACGgw2OTkxODc2ODE2ODQiDHMnK3XKeLuuieeiYSrvAkgvgk4U7PdFc2STGspP3KKK1D%2FsC2vOQPJELXSb78eA49oSWqVLA%2B7XRNVTbpF9RqtfaBKGftewHbGlXDs3S1MTJwdTZk9VlvhaEvh1h%2BtvPvXh18JVWJuXKm5RjEg6ayNxVM7Kuzinom38lY89iKBoRIpNCe0w4YtWtdd5QcxOjH6iuG98BlgJXq7WTUgD3tGYK34tVDWsT0%2F0MOgrnD8VE86t0sge6iLw4RueNSi5rVnpYFy5R4Pcx4P9Gwh1FSaMkJR1wBKNeutY7EIwk1clzsi0mRrDS8So%2BefPBViNaiREQSP2eXsY1tQBLfMMcZF2mbQkZQfC%2FB77OxsVSC%2Bo%2BlpY63RLXATZyAAQUHgTpYmamuYaru6Sc4V06VbHPuQv77d%2BEuS1IB4L7cpbcksrxY0dyhI%2BISdntvQgIqzWtw37Afb6W3JxW7lt4tGO8gnG8UXUdOlDY71nsKMn6vPGQf%2B8NDfyIYWpqWLDYOQwvPGdpQY6nAGn1HCHMvCGiPaoihAcZjE8tXM9P7mj0%2FPlMegG%2B6LVWVTFb2Y2tV9i14nyr3UaeYNimjOT9qRR9h95cpEP21Fla4eyDyV1GpEfO5Y%2BEls8VRAiiXaLCuvrnkI7RujNu59IglU5Z4vmcZLYpX5Ht7nlhPGkSd3sbWlTl8Pw0SCL1kd%2BLWj3%2FEtBlcw%2Fi6HQzLZq2krbYoeExRRH75g%3D&X-Amz-Signature=8da8706d51be8893ca4bf88b16fcd024376ac311de312a5e665513b9331faad5&X-Amz-SignedHeaders=host',
                        ],
                    },
                    {
                        amount: 0,
                        description: '',
                        transactionsId: '63a27990645dfc5ab7e8179b',
                        createdAt: '2022-12-21T03:12:16.222Z',
                        status: 0,
                        fileUrl: [],
                    },
                    {
                        amount: 0,
                        description: '',
                        transactionsId: '63a27984645dfc5ab7e81734',
                        createdAt: '2022-12-21T03:12:04.408Z',
                        status: 0,
                        fileUrl: [],
                    },

                ],
            },
            propertyPackage: [
                {
                    package: 'vacantPossession',
                    status: 1,
                },
                {
                    package: 'designRenovation',
                    status: 7,
                },
                {
                    package: 'defectInspection',
                    status: 3,
                },
                {
                    package: 'leaseSale',
                    status: 2,
                },
                {
                    package: 'vacantManagement',
                    status: 3,
                },
                {
                    package: 'propertyManagement',
                    status: 3,
                    subscriptionEnd: '2023-12-08T07:36:34.000Z',
                },
            ],
            walletDashboard: {
                walletTotal: 38972,
                walletTransactions: [
                    {
                        _id: '638f498813d8817a58851854',
                        amount: 200000,
                        remarks: 'Wallet topup',
                        fileUrl: [],
                    },
                    {
                        _id: '638f49a6e2db18dfa6640acf',
                        amount: -176500,
                        remarks: 'Property Management Transactions Purchase',
                        fileUrl: [],
                    },
                    {
                        _id: '638f4b7be2db18dfa6640b3d',
                        amount: -10000,
                        remarks: 'Property Management Transactions Purchase',
                        fileUrl: [],
                    },
                    {
                        _id: '638f52fefd0409c76ac9d8c3',
                        amount: 50000,
                        remarks: 'Wallet topup',
                        fileUrl: [],
                    },
                    {
                        _id: '638f53ef66967155d4e4530a',
                        amount: -1765,
                        remarks: 'Property Management Transactions Purchase',
                        fileUrl: [],
                    },
                    {
                        _id: '639aef7b6f54a4ad50194724',
                        amount: -10000,
                        remarks: 'Property Management Transactions Purchase',
                        fileUrl: [],
                    },
                    {
                        _id: '639af0266f54a4ad5019476d',
                        amount: -10000,
                        remarks: 'Property Management Transactions Purchase',
                        fileUrl: [],
                    },
                ],
            },
        });
        return {
            status: GatewayResponseStatus.Success,
            data: response as IAllPropertyDetailDashboards,
        };
    }

    async getRentalAnalytics(): GatewayResponse<IRentalAnalytics> {
        const response = await simulateApi({
            data: {
                totalRent: 1300,
                rentalAnalytics: [
                    {
                        month: 'Apr',
                        year: '2023',
                        amount: 650,
                    },
                    {
                        month: 'May',
                        year: '2023',
                        amount: 650,
                    },
                ],
            },
        });
        return {
            status: GatewayResponseStatus.Success,
            data: response as IRentalAnalytics,
        };
    }

    async getWalletAnalytics(): GatewayResponse<IWalletAnalytics[]> {
        const response = await simulateApi({
            data: [
                {
                    date: '2023-06-14T16:00:00Z',
                    value: 0,
                },
                {
                    date: '2023-06-15T16:00:00Z',
                    value: 0,
                },
                {
                    date: '2023-06-16T16:00:00Z',
                    value: 0,
                },
                {
                    date: '2023-06-17T16:00:00Z',
                    value: 0,
                }],
        });
        return {
            status: GatewayResponseStatus.Success,
            data: response as IWalletAnalytics[],
        };
    }

    async getPropertyIndividual(): GatewayResponse<IPropertyIndividual> {
        const response = await simulateApi({
            data: [
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
                {
                    _id: '623017c8c9f232d0236030a5',
                    name: 'Sky awani 1',
                    userId: '61fa7ed773dd14b1727d593a',
                    terms: {
                        accepted: true,
                    },
                    recentEvents: [],
                    location: {
                        long: 13.49494,
                        lat: 28.3939,
                        address1: 'no 1, taman gembira',
                        address2: 'taman sri kota',
                        postcode: '41200',
                        city: 'Klang',
                        state: 'Selangor',
                        country: 'Malaysia',
                    },
                    packages: {
                        vacantPossesion: {}, // TBD
                        designRenovation: {}, // TBD
                        defectInspection: {}, // TBD
                        propertyManagement: {}, // TBD
                        leaseSale: {}, // TBD
                    },
                    details: {
                        type: 0,
                        titleType: 1,
                        buildUpSize: 2230,
                        occupancy: 'tenanted',
                        landTitle: 0,
                        tenure: 1,
                        furnishing: 0,
                        facingDirection: 'southeast',
                        preferredTenant: [0, 1, 2],
                        unitType: 'corner unit',
                        storey: 1,
                        builtUpDimension: '1231',
                        buildUpPricePerSqft: 1500,
                    },
                    amenities: {
                        furnishing: [{
                            name: '3D Maneuver Gear',
                            available: true,
                        }],
                        facilities: [{
                            name: 'pool',
                            available: true,
                        }],

                    },
                    images: [{
                        name: 'kicthen',
                        url: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
                    }],
                    description: 'asjdiajsoijdajsd ijiojsjdo iajoisjdojao sjodiasjd ',
                },
            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as IPropertyIndividual,
        };
    }

    async getPropertyUploadUrl(): GatewayResponse<string> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async uploadPropertyDocument(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setDescription(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setPropertyAmenities(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setPropertyDetails(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setCreateProperty(): GatewayResponse<{id: string}> {
        return {
            status: GatewayResponseStatus.Success,
            data: {
                id: 'eg',
            },
        };
    }

    async setProperyImage(): GatewayResponse<string> {
        return {
            status: GatewayResponseStatus.Success,
            data: 'undefined',
        };
    }

    async setTermsAndAgreements(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async submitForApproval(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setPropertyLocation(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setPropertyDeleteImage(): GatewayResponse<string> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async getPropertyWalletDetails(): GatewayResponse<WalletBalanceParams | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async createProperty(): GatewayResponse<string | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async editPropertyDetails(): GatewayResponse<string | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }
}
