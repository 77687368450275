import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';

import { SetForApprovalActionPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchGetApproval(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetApprovalAttempt', handleApproval, api);
}

function* handleApproval(api: PropertyGateway, data: SetForApprovalActionPayload) {
    const { id } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.submitForApproval], { id, authToken });

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.propertySetApprovalSuccess('true'));
        toast.success(Translate.t('Property.PropertySubmitted'));
        NavActions.navToHome();

        if (!response.data) {
            yield put(Actions.propertySetApprovalFailure(Translate.t('GeneralUI.SomethingWrong')));
        }
    } else {
        yield put(Actions.propertySetApprovalFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralUI.Failed'));
            } else {
                toast.error(Translate.t('GeneralUI.TryAgain'));
            }
        }
    }
}
