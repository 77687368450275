import { Colors, Fonts } from '@dm/bigfish';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { IPropertyPaginator, IRentalAnalytics } from 'entities/property';
import dayjs from 'dayjs';

interface RentalChartData {
    month: string;
    year: string;
    amount: number;
}

export interface RentalAnalyticProps {
    displayedProperty: IPropertyPaginator['data'];
    allRentalAnalytics: IRentalAnalytics;
    getRentalAnalyticsAttempt: boolean;
    getRentalAnalyticsFailure: string;
    getRentalAnalytics: (propertyId: string, dateFrom: string, dateTo: string) => void;
    loading?: boolean;
    css?: FlattenSimpleInterpolation;
    [x: string]: any;
}

const getPath = (x: number, y: number, width: number, height: number): string => {
    const radius = 10;
    return `M${x + radius},${y}
    H${x + width - radius}
    Q${x + width},${y} ${x + width},${y + radius}
    V${y + height - radius}
    Q${x + width},${y + height} ${x + width - radius},${y + height}
    H${x + radius}
    Q${x},${y + height} ${x},${y + height - radius}
    V${y + radius}
    Q${x},${y} ${x + radius},${y}
    Z`;
};

const TriangleBar: FunctionComponent<any> = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
};

const CustomYAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (

        <text x={x - 10} y={y} fill='#666' textAnchor='end' dominantBaseline='middle' fontSize={12}>
            {`RM ${payload.value}`}
        </text>

    );
};

const CustomBarLabel = (props: any) => {
    const { x, y, width, value } = props;

    return (
        <text x={x + width / 2} y={y} fill='#666' textAnchor='middle' dy={-6}>
            {`RM ${value}`}
        </text>
    );
};

const RentalAnalytic = (props: RentalAnalyticProps):JSX.Element => {
    const {
        displayedProperty,
        allRentalAnalytics,
        getRentalAnalyticsAttempt,
        getRentalAnalyticsFailure,
        getRentalAnalytics,
        loading,
    } = props;

    const [startDate, setStartDate] = useState(dayjs().startOf('month').subtract(2, 'month').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [count, setCount] = useState(0);

    const today = new Date();
    const { totalRent, rentalAnalytics } = allRentalAnalytics;

    useEffect(() => {
        const min = 1000; // Minimum four-digit number
        const max = 9999; // Maximum four-digit number
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min; // Generate a random four-digit number
        let currentCount = 0;

        const timer = setInterval(() => {
            currentCount += randomNumber;
            if (currentCount > max) {
                currentCount = min;
            }
            setCount(currentCount);
        }, 50);

        setTimeout(() => {
            clearInterval(timer);
        }, 3000);
    }, [getRentalAnalyticsAttempt]);

    useEffect(() => {
        setStartDate(dayjs().startOf('month').subtract(2, 'month').toDate());
        setEndDate(today);
    }, [displayedProperty]);

    useEffect(() => {
        if (displayedProperty.length > 0) {
            const selectedProperty = displayedProperty.find((item) => item.selected === true);
            if (selectedProperty) {
                getRentalAnalytics(selectedProperty.propertyId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).endOf('month').format('YYYY-MM-DD'));
            }
        }
    }, [displayedProperty, startDate, endDate]);

    useEffect(() => {
        if (dayjs(endDate).isBefore(startDate)) {
            setEndDate(startDate);
        }
    }, [endDate, startDate]);

    const renderRentalChart = () => {
        if (rentalAnalytics.length === 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    There is no rental data yet. Please adjust the date range or create a rental transaction.
                </div>
            );
        }
        return (
            <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                    data={rentalAnalytics}
                    margin={{
                        top: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray='10' vertical={false} />
                    <XAxis dataKey='month' tickLine={false} />
                    <YAxis tick={<CustomYAxisTick />} />
                    <Bar isAnimationActive={false} dataKey='amount' fill='#8884d8' shape={<TriangleBar />} label={<CustomBarLabel />}>
                        {rentalAnalytics.map((entry) => (
                            <Cell key={`cell-${entry.month}`} fill={Colors.primaryLight} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    };

    const renderMonthPicker = () => {
        if (loading) {
            return null;
        }
        return (
            <Filter>
                <StyledDatePicker
                    value={dayjs(startDate).format('MM/YYYY')}
                    selected={startDate}
                    onChange={(e: Date) => setStartDate(e)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='MM/yyyy'
                    showMonthYearPicker
                    maxDate={today}
                />
                <p>to</p>
                <StyledDatePicker
                    value={dayjs(endDate).format('MM/YYYY')}
                    selected={endDate}
                    onChange={(e: Date) => setEndDate(e)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='MM/yyyy'
                    showMonthYearPicker
                    maxDate={today}
                />
            </Filter>
        );
    };

    return (
        <RentalAnalyticContainer>
            <RentalTitle>
                <Title>
                    <p>RENTAL ANALYTIC</p>
                    <span>
                        RM
                        {' '}
                        {loading ? count : totalRent}
                    </span>
                </Title>
                {renderMonthPicker()}
            </RentalTitle>
            <RentalChart>
                {renderRentalChart()}
            </RentalChart>
        </RentalAnalyticContainer>
    );
};

const RentalAnalyticContainer = styled.div`
    background-color: ${Colors.primaryLightest};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 360px;
    display: flex;
    flex-direction: column;
`;

const RentalTitle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px 20px 20px 20px;
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    p {
        font-family: ${Fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${Colors.secondaryLight};
    }

    span{
        font-family: ${Fonts.primary};
        font-size: 42px;
        font-weight: 600;
        color: ${Colors.success};
    }
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 100px;
    height: 30px;
    margin: 0 20px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    } 
    .react-datepicker__month-text--disabled, 
    .react-datepicker__quarter-text--disabled, 
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

const Filter = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    align-items: center;
`;

const RentalChart = styled.div`
    flex: 3;
    width: 100%;
    padding: 0 20px 20px 20px;
    height: 200px;
`;

const mapStateToProps = (state: RootState) => ({
    displayedProperty: Selectors.getPropDisplayedProperty(state),
    allRentalAnalytics: Selectors.getRentalAnalytics(state),
    getRentalAnalyticsAttempt: Selectors.getRentalAnalyticsAttempt(state),
    getRentalAnalyticsFailure: Selectors.getRentalAnalyticsFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getRentalAnalytics: (propertyId: string, dateFrom: string, dateTo: string) => dispatch(Actions.getRentalAnalyticsAttempt({ propertyId, dateFrom, dateTo })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RentalAnalytic);
