import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IServerProperty, IPropertyIndividual, IPropertyPaginator, IAllPropertyDetailDashboards, IRentalAnalytics, IWalletAnalytics } from 'entities/property';
import { PropReduxState, GetAttemptPropertyActionPayload, SetPropertyIndividualPayload, GetAllPropertyDetailsAttemptPayload, GetRentalAnalyticsAttemptPayload } from './types';

const initialState: PropReduxState = {
    actions: {
        getProperty: false,
        getPropertyPaginator: false,
        getDisplayedProperty: false,
        getAllPropertyDetails: false,
        getRentalAnalytics: false,
        getWalletAnalytics: false,
        getPropertyIndividual: false,
        getPropertyImage: false,
    },
    property: [],
    propertyPaginator: {
        data: [],
    },
    displayedProperty: [],
    currentPropertyIndex: 0,
    menuIsOpen: false,
    allPropertyDetails: {
        propertyDetails: {
            name: '',
            propertyType: 0,
            titleType: 0,
            unitSize: 0,
            unitType: '',
            floor: 0,
            ensure: 0,
            unit: '',
            location: {
                long: 0,
                lat: 0,
                address1: '',
                address2: '',
                postcode: '',
                city: '',
                state: '',
                country: '',
            },
        },
        pendingAction: {
            total: 0,
            actions: [],
        },
        pendingTransactions: {
            total: 0,
            transactions: [],
        },
        propertyPackage: [],
        walletDashboard: {
            walletTotal: 0,
            walletTransactions: [],
        },
    },
    allRentalAnalytics: {
        totalRent: 0,
        rentalAnalytics: [],
    },
    allWalletAnalytics: [],
    propertyIndividual: {
        _id: '',
        name: '',
        userId: '',
        images: [],
        status: 0,
    },
    propertyImageUpdatedData: {
        _id: '',
        name: '',
        userId: '',
        images: [],
        status: 0,
    },
    error: {
        getProperty: '',
        getPropertyPaginator: '',
        getDisplayedProperty: '',
        getAllPropertyDetails: '',
        getRentalAnalytics: '',
        getWalletAnalytics: '',
        getPropertyIndividual: '',
        getPropertyImage: '',
    },
};

const propSlice = createSlice({
    name: 'prop',
    initialState,
    reducers: {
        getPropertyAttempt: (state, _action: GetAttemptPropertyActionPayload) => {
            state.actions.getProperty = true;
            state.error.getProperty = '';
        },
        getPropertySuccess: (state, action: PayloadAction<IServerProperty>) => {
            state.actions.getProperty = false;
            if (action.payload.data.length) {
                const listPropertyIndex = state.property.map(property => property.index);

                if (!listPropertyIndex.includes(action.payload.index)) {
                    state.property.push(action.payload);
                }
            }
        },
        getPropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getProperty = false;
            if (action.payload) {
                state.error.getProperty = action.payload;
            }
        },
        getPropertyPaginatorAttempt: (state, _action: GetAttemptPropertyActionPayload) => {
            state.actions.getPropertyPaginator = true;
            state.error.getProperty = '';
        },
        getPropertyPaginatorSuccess: (state, action: PayloadAction<IPropertyPaginator>) => {
            state.actions.getPropertyPaginator = false;
            if (action.payload) {
                state.propertyPaginator = action.payload;
            }
        },
        getPropertyPaginatorFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyPaginator = false;
            if (action.payload) {
                state.error.getPropertyPaginator = action.payload;
            }
        },
        setDisplayedProperty: (state, action) => {
            state.displayedProperty = action.payload;
        },
        setCurrentPropertyIndex: (state, action) => {
            state.currentPropertyIndex = action.payload;
        },
        setMenuIsOpen: (state, action) => {
            state.menuIsOpen = action.payload;
        },
        clearProperty: (state) => {
            state.property = [];
        },
        getAllPropertyDetailsAttempt: (state, _action: GetAllPropertyDetailsAttemptPayload) => {
            state.actions.getAllPropertyDetails = true;
            state.error.getAllPropertyDetails = '';
        },
        getAllPropertyDetailsSuccess: (state, action: PayloadAction<IAllPropertyDetailDashboards>) => {
            state.actions.getAllPropertyDetails = false;
            if (action.payload) {
                state.allPropertyDetails = action.payload;
            }
        },
        getAllPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAllPropertyDetails = false;
            if (action.payload) {
                state.error.getAllPropertyDetails = action.payload;
            }
        },
        getRentalAnalyticsAttempt: (state, _action: GetRentalAnalyticsAttemptPayload) => {
            state.actions.getRentalAnalytics = true;
            state.error.getRentalAnalytics = '';
        },
        getRentalAnalyticsSuccess: (state, action: PayloadAction<IRentalAnalytics>) => {
            state.actions.getRentalAnalytics = false;
            if (action.payload) {
                state.allRentalAnalytics = action.payload;
            }
        },
        getRentalAnalyticsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getRentalAnalytics = false;
            if (action.payload) {
                state.error.getRentalAnalytics = action.payload;
            }
        },
        getWalletAnalyticsAttempt: (state, _action: GetRentalAnalyticsAttemptPayload) => {
            state.actions.getWalletAnalytics = true;
            state.error.getWalletAnalytics = '';
        },
        getWalletAnalyticsSuccess: (state, action: PayloadAction<IWalletAnalytics[]>) => {
            state.actions.getWalletAnalytics = false;
            if (action.payload) {
                state.allWalletAnalytics = action.payload;
            }
        },
        getWalletAnalyticsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getWalletAnalytics = false;
            if (action.payload) {
                state.error.getWalletAnalytics = action.payload;
            }
        },
        getPropertyIndividualAttempt: (state, _action: SetPropertyIndividualPayload) => {
            state.actions.getPropertyIndividual = true;
            state.error.getPropertyIndividual = '';
        },

        getPropertyIndividualSuccess: (state, action: PayloadAction<IPropertyIndividual>) => {
            state.actions.getPropertyIndividual = false;

            if (action.payload) {
                state.propertyIndividual = action.payload;
            }
        },
        getPropertyIndividualFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyIndividual = false;
            if (action.payload) {
                state.error.getPropertyIndividual = action.payload;
            }
        },

        getPropertyImageDataAttempt: (state, _action: SetPropertyIndividualPayload) => {
            state.actions.getPropertyImage = true;
            state.error.getPropertyImage = '';
        },

        getPropertyImageDataSuccess: (state, action: PayloadAction<IPropertyIndividual>) => {
            state.actions.getPropertyImage = false;

            if (action.payload) {
                state.propertyImageUpdatedData = action.payload;
            }
        },
        getPropertyImageDataFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyImage = false;
            if (action.payload) {
                state.error.getPropertyImage = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: propSlice.actions,
    reducers: propSlice.reducer,
};
