import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import SVG from 'react-inlinesvg';
import { stringify } from 'querystring';
import Icons from '../assets/icons';

interface ButtonInfoProps {
  desc: string;
  leftIconSrc?: string;
  rightIconSrc?: string;
  status?: string;
  onclick: () => void;
}

const StyledButtonRight = styled.button<{status?: string}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  height: 40px;
  padding: 8px;

  box-sizing: border-box;
  border-radius: 8px;
  border: 0.5px solid #F2865E;

  background-color: #fff;

  color: #F2865E;;

  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;

  cursor: pointer;

  ${props => (props.status === 'Active') && css`
    color: white;
    background-color: #F2865E;
    
  `}
  ${props => (props.status === 'PendingSignature') && css`
    color: white;
    background-color: #F2865E;
    
  `}
  ${props => (props.status === 'Pending') && css`
    color: #F2865E;
    background-color: #ffffff;
    border: 0.5px solid #F2865E;    
  `}
  ${props => (props.status === 'pending verification') && css`
    color: #F2865E;
    background-color: #ffffff;
    border: 0.5px solid #F2865E;    
  `}


`;

const StyledIcon = styled(SVG)<{iconColor?: string, status?: string}>`
  width: 20px;
  height: 20px;
  color: #ffffff;
  ${props => (props.status === 'pending verification') && css`
    color: #F2865E;
  `}
`;

const StyledDescRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${StyledIcon}{
    margin-right: 6px;
  }
`;

const StyledButton = styled.button<{status?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  padding: 8px;

  box-sizing: border-box;
  border-radius: 8px;
  border: 0.5px solid #F2865E;;

  background-color: #fff;

  color: #F2865E;;

  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;

  cursor: pointer;

  ${props => (props.status === 'pending verification') && css`
    color: #F2865E;
    background-color: #ffffff;
    border: 0.5px solid #F2865E;    
  `}
`;

const StyledDesc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${StyledIcon}{
    margin-right: 6px;
  }
`;

const ButtonInfo = ({ desc, onclick, leftIconSrc, rightIconSrc, status, ...otherProps }: ButtonInfoProps):JSX.Element => {
    const [checkLeftorRight, setCheckLeftorRight] = useState('');

    useEffect(() => {
        check(status);
    }, []);

    const check = (toCheck: any) => {
        if (toCheck === 'Pending' || 'Active' || 'PendingSignature' || '') {
            setCheckLeftorRight('right');
        }
        if (toCheck === 'pending verification') {
            setCheckLeftorRight('left');
        }
    };

    return (
        <>
            {checkLeftorRight === 'right' && (
                <StyledButtonRight onClick={onclick} status={status} {...otherProps}>
                    <StyledDescRight>
                        {desc}
                        {status === 'Pending' && (
                            <StyledIcon status={status} src={Icons.AddPackage} />
                        )}
                        {status === 'Active' && (
                            <StyledIcon status={status} src={Icons.RightArrow} />
                        )}
                        {status === 'PendingSignature' && (
                            <StyledIcon status={status} src={Icons.sign} />
                        )}
                        {status === '' && (
                            <StyledIcon status={status} src={Icons.AddPackage} />
                        )}
                    </StyledDescRight>
                </StyledButtonRight>
            )}
            {checkLeftorRight === 'left' && (
                <StyledButton onClick={onclick} status={status} {...otherProps}>
                    <StyledDesc>
                        {status === 'pending verification' && (
                            <StyledIcon status={status} src={Icons.WarningIcon} />
                        )}
                        {desc}
                    </StyledDesc>
                </StyledButton>

            )}

        </>

    );
};

ButtonInfo.defaultProps = {
    leftIconSrc: '',
    rightIconSrc: '',
    status: '',
};

export default ButtonInfo;
