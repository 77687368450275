import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import { GetSubscriptionTransactionPayload } from 'redux/slices/packages/types';

import Utils from 'lib/Utils';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';

export default function* watchGetSubscriptionTransactions(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesGetSubscriptionTransactionsAttempt', handleGetSubscriptionTransactions, api);
}

function* handleGetSubscriptionTransactions(api: PackagesGateway) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const response = yield* call([api, api.getPackagesSubscriptionTransactions], {
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetSubscriptionTransactionsFailure('try again'));
                return;
            }
            yield put(Actions.packagesGetSubscriptionTransactionsSuccess(response.data));
        } else {
        // handle error
            yield put(Actions.packagesGetSubscriptionTransactionsFailure(response.message));
        }
    }
}
