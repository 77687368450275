import { InitState } from 'redux/slices/user';
import { IOwnerUser } from '@dm/types';

const getUserInfoAttempt = (state: InitState): boolean => state.actions.getUserInfo || false;
const getUserInfoFailure = (state: InitState): string => state.error.getUserInfo || '';
const getUserInfo = (state: InitState): IOwnerUser | undefined => state.userInfo || undefined;

const setUserInfoAttempt = (state: InitState): boolean => state.actions.setUserInfo || false;
const setUserInfoFailure = (state: InitState): string => state.error.setUserInfo || '';
const updateUserInfo = (state: InitState): string | undefined => state.updateUserInfo || '';

const setSubscriptionTransactionModal = (state: InitState): boolean => state.subscriptionTransactionModal || false;

export default {
    getUserInfoAttempt,
    getUserInfoFailure,
    getUserInfo,

    setUserInfoAttempt,
    setUserInfoFailure,
    updateUserInfo,

    setSubscriptionTransactionModal,

};
