import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IDefectInspection, ILeaseSale, IPackagePrice, IVacantPossession, PropertyManagementTransactionResponse, PaymentGatewayResponse, IPropertyManagement, IVacantManagement, ViewTransactionResponse } from '@dm/types';

import { GetPaymentInfoRTO, IGoogleDriveContent } from 'entities/packages';
import { GetPackagesPricingParams, IPackagesGateway, SetPackagesPaymentsParams, UploadPackagesDocumentParams, GetPackagesUploadUrlParams, PackagesGetDataSortParams, GetDocumentTemplateParams, PackagesPaymentCreditCardParams, PackagesPropertyManagementSortParams, SetPropertyManagementTransactionUpdateParams, SetITopUpPaymentParams, PackagesGetSubscriptionTransactionsParams, PackagesSetSubscriptionTransactionCancelParams, PackagesSetSubscriptionTransactionRenewParams, GetGoogleDriveContentParams, CreatePMTransactionParams, updatePMTransactionParams } from './PackagesBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PackagesGateway extends IPackagesGateway {
    async getPackagesPricing(params: GetPackagesPricingParams): GatewayResponse<IPackagePrice> {
        const data = {
            propertyId: params.propertyId,
        };
        const response: ApiResponse<IPackagePrice> = await this.api.get(`/owner/packages/pricing?propertyId=${data.propertyId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagesPayments(params: SetPackagesPaymentsParams): GatewayResponse<PaymentGatewayResponse> {
        const data = {
            propertyId: params.propertyId,
            paymentType: params.paymentType,
            bankNumber: params.bankName,
            accountName: params.accountName,
            bankName: params.bankName,
            packages: params.packages,
            reference: params.reference,
        };
        const response: ApiResponse<PaymentGatewayResponse> = await this.api.post('/owner/packages/purchase', data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesUploadUrl(params: GetPackagesUploadUrlParams): GatewayResponse<string> {
        const { category, id, name, extension } = params;
        const response: ApiResponse<string> = await this.api.get('/upload/url', { category, id, name, extension }, getHeaders(params.authToken));
        return this.process(response);
    }

    async uploadPackagesDocument(params: UploadPackagesDocumentParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put(params.url, params.doc);
        return this.process(response);
    }

    async getPackagesVacantPossessionSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantPossession> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IVacantPossession > = await this.api.get(`/owner/packages/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesLeaseSaleSort(params: PackagesGetDataSortParams): GatewayResponse<ILeaseSale> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<ILeaseSale> = await this.api.get(`/owner/packages/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesDefectInspectionSort(params: PackagesGetDataSortParams): GatewayResponse<IDefectInspection> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IDefectInspection> = await this.api.get(`/owner/packages/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getSignDocumentTemplate(params: GetDocumentTemplateParams): GatewayResponse<string> {
        const { documentTemplate, propertyId } = params;
        const response: ApiResponse<string> = await this.api.get(`/owner/document/package?propertyId=${propertyId}&category=${documentTemplate}`, { }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesPaymentCreditCard(params: PackagesPaymentCreditCardParams): GatewayResponse<GetPaymentInfoRTO | null> {
        const data = {
            paymentId: params.paymentId,
        };
        const response: ApiResponse<GetPaymentInfoRTO | null> = await this.api.get(`/owner/payment/paymentInfo?paymentId=${data.paymentId}`, { }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesPropertyManagementSort(params: PackagesPropertyManagementSortParams): GatewayResponse<PropertyManagementTransactionResponse[] | null> {
        const data = {
            propertyId: params.propertyId,
            sort: params.sort,
            transactionType: params.transactionType,
        };
        let PMType;

        if (data.transactionType === 9999) {
            PMType = '&transactionType=';
        } else {
            PMType = `&transactionType=${data.transactionType}`;
        }
        const response: ApiResponse<PropertyManagementTransactionResponse[] | null> = await this.api.get(`/owner/packages/viewTransaction?propertyId=${data.propertyId}&sort=${data.sort}${PMType}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyManagementTransactionUpdate(params: SetPropertyManagementTransactionUpdateParams): GatewayResponse<string> {
        const { propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName } = params;
        const response: ApiResponse<string > = await this.api.put('/owner/packages/updateTransaction', { propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName }, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertyManagementTransactionUpdateGateway(params: SetPropertyManagementTransactionUpdateParams): GatewayResponse<null | PaymentGatewayResponse> {
        const { propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName } = params;
        const response: ApiResponse<null | PaymentGatewayResponse> = await this.api.put('/owner/packages/updateTransaction', { propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName }, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPackagesTopUpWallet(params: SetITopUpPaymentParams): GatewayResponse<PaymentGatewayResponse | null> {
        const { propertyId, paymentType, amount, bankNumber, accountName, bankName, reference } = params;
        const response: ApiResponse<PaymentGatewayResponse | null> = await this.api.post('/owner/property/topupWallet', { propertyId, paymentType, amount, bankNumber, accountName, bankName, reference }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesPropertyManagementDocumentsSort(params: PackagesGetDataSortParams): GatewayResponse<IPropertyManagement | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IPropertyManagement | null> = await this.api.get(`/owner/packages/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesVacantManagementDocumentsSort(params: PackagesGetDataSortParams): GatewayResponse<IVacantManagement | null> {
        const data = {
            category: params.category,
            propertyId: params.propertyId,
            sort: params.sort,
        };
        const response: ApiResponse<IVacantManagement | null> = await this.api.get(`/owner/packages/documents?category=${data.category}&propertyId=${data.propertyId}&sort=${data.sort}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPackagesSubscriptionTransactions(params: PackagesGetSubscriptionTransactionsParams): GatewayResponse<ViewTransactionResponse[] | null> {
        const response: ApiResponse<ViewTransactionResponse[] | null> = await this.api.get('/owner/payment/ownerViewTransactions', {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async SetPackagesSubscriptionTransactionsCancel(params: PackagesSetSubscriptionTransactionCancelParams): GatewayResponse<string> {
        const { paymentId, category, authToken } = params;
        const response: ApiResponse<string> = await this.api.put(`/owner/payment/cancelSubscription?paymentId=${paymentId}&category=${category}`, {}, getHeaders(authToken));
        return this.process(response);
    }

    async SetPackagesSubscriptionTransactionsRenew(params: PackagesSetSubscriptionTransactionRenewParams): GatewayResponse<string> {
        const { paymentId, autoRenew, authToken } = params;
        const response: ApiResponse<string> = await this.api.put(`/owner/payment/updateAutoRenew?paymentId=${paymentId}&autoRenew=${autoRenew}`, {}, getHeaders(authToken));
        return this.process(response);
    }

    async getGoogleDriveContent(params: GetGoogleDriveContentParams): GatewayResponse<IGoogleDriveContent[]> {
        const { fileId } = params;
        const response: ApiResponse<IGoogleDriveContent[]> = await this.api.get('/owner/packages/getGoogleDriveContent', { fileId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async createPMTransaction(params: CreatePMTransactionParams): GatewayResponse<{id: string}> {
        const data = {
            propertyId: params.propertyId,
            amount: params.amount,
            description: params.description,
            transactionDate: params.transactionDate,
            transactionType: params.transactionType,
            isRecurring: false,
        };
        const response: ApiResponse<{id: string}> = await this.api.post('/owner/packages/createPMTransaction', data, getHeaders(params.authToken));
        return this.process(response);
    }
}
