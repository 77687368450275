import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import { create } from 'apisauce';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';

import PropertyGateway from 'api/Property';
import { UploadPropertyDocumentActionPayload } from 'redux/slices/property/types';

export default function* watchUploadPropertyDocument(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/uploadPropertyImagesAttempt', handleUploadDocument, api);
}

function* handleUploadDocument(api: PropertyGateway, data: UploadPropertyDocumentActionPayload) {
    const { preSignedUrl, document, docType } = data.payload;

    const formData = new FormData();
    formData.append('file', document);

    const s3Api = create({
        baseURL: preSignedUrl,
        headers: {
            'content-type': `application/${docType}`,
        },
    });

    const response = yield* call(s3Api.put, '', document);

    if (!response.ok) {
        toast.error('Error');
        return;
    }

    yield put(Actions.uploadPropertyImagesSuccess());
    yield put(Actions.setPropertyImageUploadComplete(true));
    yield put(Actions.setPropertyImagesSubmitted(true));
    toast.success('Uploaded Success');
}
