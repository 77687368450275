import { ViewAllPMTransactionResponse } from '@dm/types';
import { InitState } from 'redux/slices/ui';

const getSelectedLanguage = (state: InitState): string => state.selectedLanguage || '';
const getSelectedTabView = (state: InitState): string => state.selectedTabView || '';
const getWelcomeModal = (state: InitState): boolean => state.welcomeModal || false;
const getWelcomeModalSuccess = (state: InitState): boolean => state.welcomeModalSuccess || false;
const getIsUploadFileModalOpen = (state: InitState): boolean => state.isUploadFileModalOpen || false;
const getIsFileViewModalOpen = (state: InitState): boolean => state.isFileViewModalOpen || false;
const getFileList = (state: InitState): ViewAllPMTransactionResponse['fileUrl'] => state.fileList || [];
const getIsPMTransactionModalOpen = (state: InitState): boolean => state.isPMTransactionModalOpen || false;

export default {
    getSelectedLanguage,
    getSelectedTabView,
    getWelcomeModal,
    getWelcomeModalSuccess,
    getIsUploadFileModalOpen,
    getIsFileViewModalOpen,
    getFileList,
    getIsPMTransactionModalOpen,
};
