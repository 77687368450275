import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

import { LoginActionPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authLoginAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: LoginActionPayload) {
    const { email, password, rememberMe } = data.payload;

    const response = yield* call([api, api.login], { email, password, rememberMe });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
            return;
        }

        const { token } = response.data;

        const tokenJwt: any = jwtDecode(token);

        // Get expired time in miliseconds
        const expiredTime = (tokenJwt.exp - tokenJwt.iat) * 1000;

        yield put(Actions.authLoginSuccess(token));
        yield put(Actions.userGetUserInfoAttempt());

        Utils.Auth.storeAuthToken(token, expiredTime);

        toast.success('Login success');

        NavActions.navToHome();
    } else {
        yield put(Actions.authLoginFailure(response.message));

        toast.error('Invalid email address or password');
    }
}
