import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GetAttemptPropertyActionPayload } from 'redux/slices/prop/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchGetProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('prop/getPropertyAttempt', handleGetProperty, api);
}

function* handleGetProperty(api: PropertyGateway, data: GetAttemptPropertyActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const { index } = data.payload;

    const response = yield* call([api, api.getProperty], {
        index,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.getPropertyFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.getPropertySuccess(response.data));
    } else {
        // handle error
        yield put(Actions.getPropertyFailure(response.message));
    }
}
