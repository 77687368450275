import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { GetRentalAnalyticsAttemptPayload } from 'redux/slices/prop/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchGetWalletAnalytics(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('prop/getWalletAnalyticsAttempt', handleGetWalletAnalytics, api);
}

function* handleGetWalletAnalytics(api: PropertyGateway, data: GetRentalAnalyticsAttemptPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const { propertyId, dateFrom, dateTo } = data.payload;

    const response = yield* call([api, api.getWalletAnalytics], {
        propertyId,
        dateFrom,
        dateTo,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.getWalletAnalyticsFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.getWalletAnalyticsSuccess(response.data));
    } else {
        // handle error
        yield put(Actions.getWalletAnalyticsFailure(response.message));
    }
}
