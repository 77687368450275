import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IServerProperty, IPropertyIndividual, IPropertyAmenities, IPropertyDetails, IPropertyLocation, WalletBalanceParams, IPropertyPaginator, IAllPropertyDetailDashboards, IRentalAnalytics, IWalletAnalytics } from 'entities/property';
import { PropertyTypeEnum, PropertyTitleTypeEnum, LandTitleTypeEnum, TenureTypeEnum, FurnishingTypeEnum, RaceEnum, Category, PropertyFacilities } from '@dm/types';

export interface GetPropertyParams {
    index: number;
    authToken: string;
}

export interface GetRentalAnalyticsParams {
    propertyId: string;
    dateFrom: string;
    dateTo: string;
    authToken: string;
}

export interface GetAllPropertyDetailsParams {
    propertyId: string;
    authToken: string;
}

export interface PropertySetDescriptionParams {
    id: string;
    description: string;
    authToken: string;
}

export interface PropertyCreatePropertyParams {
    name: string;
    authToken: string;
}

export interface PropertySetTermsAndAgreementsParams {
    id: string;
    authToken: string;
}

export interface PropertySetPropertyIndividualParams {
    id: string;
    authToken: string;
}

export interface PropertySetApprovaldAgreementsParams {
    id: string;
    authToken: string;
}

export interface DetailsParams {
    _id: string;
    type: PropertyTypeEnum;
    titleType: PropertyTitleTypeEnum;
    buildUpSize: number;
    occupancy: string;
    landTitle: LandTitleTypeEnum;
    tenure: TenureTypeEnum;
    furnishing: FurnishingTypeEnum;
    facingDirection: string;
    preferredTenant: RaceEnum[];
    unitType: string;
    storey: number;
    builtUpDimension: string;
    buildUpPricePerSqft: number;
    authToken: string,
}

export interface Details1Params {
    data: IPropertyDetails,
    authToken: string;
}

export interface PropertySetPropertyAmenitiesParams {
    data: IPropertyAmenities,
    authToken: string;
}

export interface PropertySetPropertyLocationParams {
    data: IPropertyLocation,
    authToken: string;
}

export interface PropertySetImageParams {
    category: string;
    id: string;
    name: string;

}

export interface SetCreatePropertyResponse {
    id: string;
}

export interface GetPropertyUploadUrlParams {
    authToken: string;
    category: Category;
    id: string;
    name: string;
    extension: string;
}

export interface UploadPropertyDocumentParams {
    url: string;
    doc: FormData;
}

export interface ImageDeleteParams {
    authToken: string;
    category: number;
    name: string;
    id: string;
    fileName: string;
}

export interface WalletDetailsParams {
    authToken: string;
    propertyId: string;
}

export interface CreatePropertyParams {
    authToken: string;
    name: string;
}

export interface EditPropertyDetails {
    authToken: string;
    propertyId: string;
    details?: {
        type: number;
        titleType: number;
        buildUpSize: number;
        occupancy: string;
        landTitle: number;
        tenure: number;
        furnishing: number;
        facingDirection: string;
        preferredTenant: number[];
        unitType: string;
        storey: number;
        builtUpDimension: string;
        buildUpPricePerSqft: number;
    },
    location?: {
        long: number;
        lat: number;
        address1: string;
        address2: string;
        postcode: string;
        city: string;
        state: string;
        country: string;
    },
    amenities?: {
        facilities: PropertyFacilities[],
        furnishing: PropertyFacilities[],
    },
    description?:string;
    unitNo?: string;
}

export abstract class IPropertyGateway extends Gateway {
    abstract getProperty(params: GetPropertyParams): GatewayResponse<IServerProperty>;

    abstract getPropertyPaginator(params: GetPropertyParams): GatewayResponse<IPropertyPaginator>;

    abstract getAllPropertyDetails(params: GetAllPropertyDetailsParams): GatewayResponse<IAllPropertyDetailDashboards>;

    abstract getRentalAnalytics(params: GetRentalAnalyticsParams): GatewayResponse<IRentalAnalytics>;

    abstract getWalletAnalytics(params: GetRentalAnalyticsParams): GatewayResponse<IWalletAnalytics[]>;

    abstract setDescription(params: PropertySetDescriptionParams): GatewayResponse<null>;

    abstract setPropertyAmenities(params: PropertySetPropertyAmenitiesParams): GatewayResponse<string | null>;

    abstract setPropertyDetails(params: Details1Params): GatewayResponse<string | null>;

    abstract setCreateProperty(params: PropertyCreatePropertyParams): GatewayResponse<{id : string}>;

    abstract setTermsAndAgreements(params: PropertySetTermsAndAgreementsParams): GatewayResponse<string | null>;

    abstract submitForApproval(params: PropertySetApprovaldAgreementsParams): GatewayResponse<string | null>;

    abstract setProperyImage(params: PropertySetImageParams): GatewayResponse<string>;

    abstract setPropertyLocation(params: PropertySetPropertyLocationParams): GatewayResponse<string | null>;

    abstract getPropertyIndividual(params: PropertySetPropertyIndividualParams): GatewayResponse<IPropertyIndividual>;

    abstract getPropertyUploadUrl(params: GetPropertyUploadUrlParams): GatewayResponse<string>;

    abstract uploadPropertyDocument(params: UploadPropertyDocumentParams): GatewayResponse<null>;

    abstract setPropertyDeleteImage(params: ImageDeleteParams): GatewayResponse<string>;

    abstract getPropertyWalletDetails(params: WalletDetailsParams): GatewayResponse<WalletBalanceParams | null>;

    abstract createProperty(params: CreatePropertyParams): GatewayResponse<string | null>;

    abstract editPropertyDetails(params:EditPropertyDetails): GatewayResponse<string | null>;
}
