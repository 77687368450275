import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Translate, { setUserLanguage } from 'lib/Translate';
import { useDetectClickOutside } from 'react-detect-click-outside';

import NavActions from 'lib/NavActions';

import {
    TabView,
    SearchBar,
    LanguageSwitcher,
    ProfileButton,
    DropdownProfile,
    CompanyLogo,
    TabViewMobile,
} from '@dm/bigfish';

import { IOwnerUser } from '@dm/types';
import media from 'lib/Breakpoints';
import Icons from '../assets/icons';

interface HeaderProps {
    isAuthenticated: string;
    selectedLanguage: string;
    selectedTabView: string;
    setLanguage: (language: string) => void;
    setTabView: (tabView: string) => void;
    setLogOut: () => void;
    userInfo: IOwnerUser | undefined;
}

const Header = (props: HeaderProps): JSX.Element => {
    const {
        isAuthenticated,
        selectedLanguage,
        setLanguage,
        setLogOut,
        selectedTabView,
        setTabView,
        userInfo,
    } = props;

    const [title, setTitle] = useState('');
    const [isDropdownLoad, setIsDropdownLoad] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [profileDropDown] = useState([
        {
            text: 'My Profile',
            onClick: () => { moveToProfileAndClose(); },
        },
        {
            text: 'Subscriptions',
            onClick: () => { moveToProfileAndClose(); },
        },
        {
            text: 'Logout',
            onClick: () => { moveToLogoutAndClose(); },
        },
    ]);

    const [tabViewData] = useState([
        {
            text: 'dashboard',
            onClickTabView: () => {
                setTabView('dashboard');
                NavActions.navToHome();
            },
        },
    ]);

    const [languageSwitcherData] = useState([
        {
            displayText: 'En',
            language: 'en',
            onClickSetLang: () => {
                changeLanguage('en');
            },
        },
        {
            displayText: 'Bm',
            language: 'ms',
            onClickSetLang: () => {
                changeLanguage('ms');
            },
        },
        {
            displayText: '中文',
            language: 'zh',
            onClickSetLang: () => {
                changeLanguage('zh');
            },
        },
    ]);

    const truncate = (str: string) => {
        return str?.length > 5 ? `${str.substring(0, 4)}...` : str;
    };

    const moveToProfileAndClose = () => {
        NavActions.navToProfile();
        setIsDropdownLoad(isDropdownLoad);
    };

    const moveToLogoutAndClose = () => {
        setLogOut();
        setIsDropdownLoad(isDropdownLoad);
    };

    useEffect(() => {
        if (userInfo) {
            setTitle(userInfo.firstName ?? 'Owner');
        } else {
            setTitle('');
        }
    }, [userInfo]);

    const changeLanguage = (language: string) => {
        setLanguage(language);
        setUserLanguage(language);
        window.location.reload();
    };

    const displayText = (selected: string) => {
        const selectedObject = languageSwitcherData.find((data) => data.language === selected);

        if (selectedObject) return selectedObject.displayText;

        return 'EN';
    };

    return (
        <HeaderContainer>
            {/* <LeftHeaderContainer> */}
            <StyledTabViewMobile
                isAuthenthicated={!(isAuthenticated === '')}
                selectedTabView={selectedTabView}
                onOverlayClick={() => setIsMenuOpen(false)}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
                data={tabViewData}
            />
            <StyledCompanyLogo
                companyName={Translate.t('Header.companyName')}
                onClickLogo={() => { NavActions.navToHome(); }}
            />
            {/* </LeftHeaderContainer> */}
            <StyledTabView
                isAuthenthicated={!(isAuthenticated === '')}
                selectedTabView={selectedTabView}
                data={tabViewData}
            />

            <RightHeaderContainer>
                <LanguageSwitcherButton
                    data={languageSwitcherData}
                    languageSelected={displayText(selectedLanguage)}
                />
                <StyledProfileButton
                    title='Admin'
                    icon={Icons.userlogin}
                    dropdownData={profileDropDown}
                    isAuth={isAuthenticated}
                />
            </RightHeaderContainer>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;

    ${media.sm`
        justify-content: end;
    `}
`;

const LeftHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const StyledCompanyLogo = styled(CompanyLogo)`
    margin: 10px;
    width: 150px;

    ${media.sm`
        width: 100px;
    `}

    ${media.sm`
        display: none;
    `}
`;

const StyledTabView = styled(TabView) <{ isAuthenthicated: boolean }>`
    display: ${props => (props.isAuthenthicated ? 'flex' : 'none')};
    margin: 10px 20px;
    align-items: center;

    ${media.lg`
        display: none;
    `}
`;

const StyledTabViewMobile = styled(TabViewMobile) <{ isAuthenthicated: boolean }>`
    display: none;
    margin: 10px 20px;
    z-index: 999;

    ${media.lg`
        display: {props => (props.isAuthenthicated ? 'flex' : 'none')};
        align-items: center;
        justify-content: center;
    `}
`;

const StyledProfileButton = styled(ProfileButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    margin-right: 24px;
`;

const LanguageSwitcherButton = styled(LanguageSwitcher)`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 5px;
    }
`;

const RightHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px;

    ${media.sm`
        justify-content: flex-end;
        padding: 3px;
    `}
`;

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: Selectors.getAuthAuthToken(state),
    selectedLanguage: Selectors.getUiSelectedLanguage(state),
    selectedTabView: Selectors.getUiSelectedTabView(state),
    userInfo: Selectors.getUserGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setLanguage: (language: string) =>
        dispatch(Actions.setLanguage(language)),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    setLogOut: () =>
        dispatch(Actions.authLogout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
