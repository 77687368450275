import { GatewayResponse, GatewayResponseStatus } from 'api/types/types';
import Gateway from 'api/types/Gateway';

import { IDefectInspection, ILeaseSale, IPackagePrice, IPropertyPackagePurchaseTermsEnum, IPropertyPackageStatusEnum, IVacantPossession, PropertyManagementTransactionResponse, PaymentGatewayResponse, IPropertyManagement, IVacantManagement, ViewTransactionResponse } from '@dm/types';
import { GetPaymentInfoRTO, IGoogleDriveContent } from 'entities/packages';

const simulateApi = (response: any) => {
    return new Promise(res => {
        setTimeout(() => {
            res(response);
        }, 1000);
    });
};

export default class PackagesGateway extends Gateway {
    async getPackagesPricing(): GatewayResponse<IPackagePrice> {
        const response = await simulateApi({
            data: [
                {
                    vacantPossession: 1000,
                    defectInspection: 1290,
                    designRenovation: 12230,
                    propertyManagement: 1390,
                    leaseSale: 1100,
                },
            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as IPackagePrice,
        };
    }

    async setPackagesPayments(): GatewayResponse<PaymentGatewayResponse> {
        return {
            status: GatewayResponseStatus.Success,
            data: {
                paymentId: '',
                clientSecret: '',

            },
        };
    }

    async getPackagesUploadUrl(): GatewayResponse<string> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async uploadPackagesDocument(): GatewayResponse<null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async getPackagesVacantPossessionSort(): GatewayResponse<IVacantPossession> {
        const response = await simulateApi({
            data: [
                {
                    status: IPropertyPackageStatusEnum.Pending,
                    term: IPropertyPackagePurchaseTermsEnum.OneYear,
                    recentEvents: [],
                    price: 0,
                    leaseSaleDocuments: [],
                },

            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as IVacantPossession,
        };
    }

    async getPackagesLeaseSaleSort(): GatewayResponse<ILeaseSale> {
        const response = await simulateApi({
            data: [
                {
                    status: IPropertyPackageStatusEnum.Pending,
                    term: IPropertyPackagePurchaseTermsEnum.OneYear,
                    recentEvents: [],
                    price: 0,
                    leaseSaleDocuments: [],
                },

            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as ILeaseSale,
        };
    }

    async getPackagesDefectInspectionSort(): GatewayResponse<IDefectInspection> {
        const response = await simulateApi({
            data: [
                {
                    status: IPropertyPackageStatusEnum.Pending,
                    term: IPropertyPackagePurchaseTermsEnum.OneYear,
                    recentEvents: [],
                    price: 0,
                    defectInspectionDocuments: [],
                },

            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as IDefectInspection,
        };
    }

    async getSignDocumentTemplate(): GatewayResponse<string> {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async getPackagesPaymentCreditCard(): GatewayResponse<GetPaymentInfoRTO | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async getPackagesPropertyManagementSort(): GatewayResponse<PropertyManagementTransactionResponse[] | null> {
        const response = await simulateApi({
            data: [
                {
                    status: IPropertyPackageStatusEnum.Pending,
                    term: IPropertyPackagePurchaseTermsEnum.OneYear,
                    recentEvents: [],
                    price: 0,
                    defectInspectionDocuments: [],
                },

            ],
        });

        return {
            status: GatewayResponseStatus.Success,
            data: response as PropertyManagementTransactionResponse[],
        };
    }

    async setPropertyManagementTransactionUpdate(): GatewayResponse<string > {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async setPropertyManagementTransactionUpdateGateway(): GatewayResponse<null | PaymentGatewayResponse> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async setPackagesTopUpWallet(): GatewayResponse<PaymentGatewayResponse | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async getPackagesPropertyManagementDocumentsSort(): GatewayResponse<IPropertyManagement | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async getPackagesVacantManagementDocumentsSort(): GatewayResponse<IVacantManagement | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async getPackagesSubscriptionTransactions(): GatewayResponse<ViewTransactionResponse[] | null> {
        return {
            status: GatewayResponseStatus.Success,
            data: null,
        };
    }

    async SetPackagesSubscriptionTransactionsCancel(): GatewayResponse<string > {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async SetPackagesSubscriptionTransactionsRenew(): GatewayResponse<string > {
        return {
            status: GatewayResponseStatus.Success,
            data: '',
        };
    }

    async getGoogleDriveContent(): GatewayResponse<IGoogleDriveContent[]> {
        return {
            status: GatewayResponseStatus.Success,
            data: [],
        };
    }

    async createPMTransaction(): GatewayResponse<{id: string}> {
        return {
            status: GatewayResponseStatus.Success,
            data: {
                id: '',
            },
        };
    }
}
