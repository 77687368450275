import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Translate from 'lib/Translate';
import { CardCollapsible, Button } from '@dm/bigfish';

import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { IPropertyIndividual } from 'entities/property';
import Icons from '../../../assets/icons';

interface TermsAndConditionsProps {
    loading: boolean;
    error: string;
    propertyId: string;
    locationCollapsibleStatus: boolean;
    propertyInfo: IPropertyIndividual;
    setPropertyTermsAndConditionSubmitted: (state: boolean) => void;
    setOpenLocationCollapsible: (state: boolean) => void;
    setTerms: (id: string) => void;
}

const TermsAndConditions = (props : TermsAndConditionsProps) : JSX.Element => {
    const { loading, error, setTerms, propertyId, setOpenLocationCollapsible, locationCollapsibleStatus, propertyInfo, setPropertyTermsAndConditionSubmitted } = props;
    const [text1, setText1] = useState('1. ACCEPTANCE THE USE OF LOREM IPSUM TERMS AND CONDITIONS Your access to and use of Lorem Ipsum (the app) is subject exclusively to these Terms and Conditions. You will not use the app for any purpose that is unlawful or prohibited by these Terms and Conditions. By using the app you are fully accepting the terms, conditions and disclaimers contained in this notice. If you do not accept these Terms and Conditions you must immediately stop using the app. \n\n');
    const [text, setText] = useState('2. CREDIT CARD DETAILS All Lorem Ipsum purchases are managed by the individual App stores (Apple, Google Windows) and Lorem Ipsum will never store your credit card information or make it available to any third parties. Any purchasing information provided will be provided directly from you to the respective App Store and you will be subject to their credit card policies. 3. LEGAL ADVICE The contents of Lorem Ipsum app do not constitute advice and should not be relied upon in making or refraining from making, any decision.All material contained on Lorem Ipsum is provided without any or warranty of any kind. You use the material on Lorem Ipsum at your own discretion \n\n');
    const [ownerTermsStatus, setOwnerTermsStatus] = useState(false);
    const ownerTermsRef = useRef<any>(null);

    useEffect(() => {
        setPropertyTermsAndConditionSubmitted(false);
        if (propertyInfo.terms?.accepted === true) {
            setOwnerTermsStatus(true);
            onCloseCollapsibleOwnerTerms();
            setPropertyTermsAndConditionSubmitted(true);
        }
    }, [propertyInfo]);

    const onCloseCollapsibleOwnerTerms = () => {
        if (ownerTermsRef.current) {
            ownerTermsRef.current.closeCollapsible();
        }
    };

    const onClickTerms = () => {
        setTerms(propertyId);
        setOwnerTermsStatus(true);
        onCloseCollapsibleOwnerTerms();
        setOpenLocationCollapsible(!locationCollapsibleStatus);
    };
    return (
        <PropertyMainContainer>
            <CardCollapsible ref={ownerTermsRef} preText={Translate.t('Property.TermsAndConditions.CollapsiblePretext')} title={Translate.t('Property.TermsAndConditions.CollapsibleTitle')} icon={ownerTermsStatus ? Icons.VerifiedIcon : Icons.CautionCircle} focused={ownerTermsStatus}>
                <MainContainer>
                    <Container>
                        <Text>
                            {text}
                            {text1}
                            {text}
                            {text1}
                            {text}
                        </Text>
                    </Container>
                    <ButtonContainer>
                        <ConfirmPropertyButton label={Translate.t('Property.TermsAndConditions.ConfirmButtonLabel')} type='button' loading={loading} onClick={onClickTerms} />
                    </ButtonContainer>
                </MainContainer>
            </CardCollapsible>
        </PropertyMainContainer>

    );
};

const PropertyMainContainer = styled.div`
    margin-bottom: 20px;
`;

const MainContainer = styled.div`
    width: 100%;
    padding: 20px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

`;

const Container = styled.div`
    display: flex;
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: black;
    white-space: pre-line;
`;

const Text = styled.p`
    display: flex;
    font-size: 14px;
    line-height: 16px;
    color: black;
    white-space: pre-line;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ConfirmPropertyButton = styled(Button)`
   align-items: flex-start;
   padding: 8px;

   position: static;
   width: 241px; 
   height: 32px;
   left: 0px;
   border-radius: 8px;

   font-weight: 400;
   font-size: 14px;
   line-height: 16px;

    background-color: #F2B47E;
    color: white;
    border:none;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {background-color:#F2865E;}

    &:active {
    background-color: #F2865E;
    transform: translateY(1px);
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetPropertySetTermsAttempting(state),
    error: Selectors.getPropertyGetPropertySetTermsError(state),
    locationCollapsibleStatus: Selectors.setIsPropertyOpenLocationCollapsible(state),
    propertyInfo: Selectors.getPropPropertyIndividual(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTerms: (id: string) =>
        dispatch(Actions.propertyAcceptTermsAttempt({ id })),
    setOpenLocationCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenLocationCollapsible(state)),
    setPropertyTermsAndConditionSubmitted: (state: boolean) =>
        dispatch(Actions.setPropertyTermsAndConditionSubmitted(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
