import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

export interface IDropdownProfile {
    text: string;
}

export interface PointsDropdownProps {
    focused?: boolean;
    packagespoints: IDropdownProfile[];
}

const PointsDropdown = (props: PointsDropdownProps) : JSX.Element => {
    const { focused, packagespoints } = props;
    const [data] = useState(packagespoints);

    return (
        <>
            <DropdownContainer>
                {
                    data.map((dropdown, index) => {
                        const uniqueKey = dropdown.text + index;
                        return <DropdownContentHeaderContent focused={focused} key={uniqueKey}>{dropdown.text}</DropdownContentHeaderContent>;
                    })
                }
            </DropdownContainer>
            <BorderDroprdown />
        </>
    );
};

const DropdownContainer = styled.div`
    padding: 15px 10px 0px 10px;
    width: 100%;
    margin-top: 48px;
`;

const BorderDroprdown = styled.div`
    margin-top: 20px;
    border-bottom:  0.5px solid #D9D9D9;
    width: 100%;
    padding: 0px 20px 0px 20px;
`;

const DropdownContentHeaderContent = styled.li<{focusBuyAll?: boolean, focused?: boolean}>`
    grid-template-rows: auto;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    color: #000000;
    ${(props) => props.focused && css`
    color: #ffffff;
    `}
    ${(props) => props.focusBuyAll && css`
    color: #ffffff;
    `}
    font-weight: 200;
    font-size: 12px;
    line-height: 14px;

    > li:not(:first-child){
        margin-top: 8px;
    }
    @media (max-width: 800px) {
    font-weight: 100;
    font-size: 10px;
    line-height: 12px;
    }
`;

export default connect(null, null)(PointsDropdown);
