import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RootState, AppDispatch } from 'redux/store';
import Translate from 'lib/Translate';
import { CardCollapsible, Button } from '@dm/bigfish';

import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { IEditPropertyDetails } from 'entities/property';
import Icons from '../../../assets/icons';

export interface PropertyDescriptionProps {
    /**
    * Valueg
    */
    value?: string;
    /**
    * Placeholder
    */
    placeholder?: string;
    /**
     * On change handler
     */
    onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * On enter press handler
     */
    onEnterPressed?: () => void;
    /**
     Readonly
     */
    readOnly?: boolean;
    /**
    * Required
    */
    required?: boolean;
    /**
    Type
    */
    type?: string;
    propertyId: string;
    descriptionData: string;

    loading: boolean;
    setDescription(id: string, description: string): void;
    Createpropertyid: string;

    setOpenDescriptionCollapsible: (state: boolean) => void;
    propertyDescriptionCollapsibleStatus: boolean;
    setPropertyDescriptionSubmitted: (state: boolean) => void;
    editPropertyDetailsAttempt: boolean;
    editPropertyDetails: (data: IEditPropertyDetails) => void;
}

const MainContainer = styled.div`
  width: 100%;
  max-height: 232px;
  padding: 20px;
`;

const Form = styled.form`
    margin: 0px;
    width: 100%;
    height: 100%;
`;

const ItemTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 16px;
`;

const PropertyDescription = ({ setDescription,
    loading,
    propertyId,
    descriptionData,
    setOpenDescriptionCollapsible,
    propertyDescriptionCollapsibleStatus,
    setPropertyDescriptionSubmitted,
    editPropertyDetailsAttempt,
    editPropertyDetails }: PropertyDescriptionProps) : JSX.Element => {
    const [newinput, setNewinput] = useState('');
    const [ownerStatus, setOwnerStatus] = useState(false);
    const ownerRef = useRef<any>(null);

    useEffect(() => {
        setPropertyDescriptionSubmitted(false);
        if (propertyId) {
            if (descriptionData) {
                setOwnerStatus(true);
                onCloseCollapsibleOwner();
                setNewinput(descriptionData);
                setPropertyDescriptionSubmitted(true);
            }
        }
    }, []);

    useEffect(() => {
        if ((propertyDescriptionCollapsibleStatus) && propertyDescriptionCollapsibleStatus === true) {
            onOpenCollapsibleOwner();
        }
    }, [propertyDescriptionCollapsibleStatus]);

    const onOpenCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.openCollapsible();
        }
    };
    const onSubmitCancelHandler = () => {
        setNewinput('');
        setPropertyDescriptionSubmitted(false);
    };

    const onCloseCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.closeCollapsible();
        }
    };

    const onSubmitLoginHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (newinput === '') {
            toast.error(Translate.t('Property.PropertyDescription.InputPlaceHolderError'));
        } else {
            setOwnerStatus(true);
            onCloseCollapsibleOwner();
            editPropertyDetails({
                propertyId,
                description: newinput,
            });
            setOpenDescriptionCollapsible(false);
        }
    };
    return (
        <CardCollapsible ref={ownerRef} preText={Translate.t('Property.PropertyDescription.CollapsiblePretext')} title={Translate.t('Property.PropertyDescription.CollapsibleTitle')} icon={ownerStatus ? Icons.VerifiedIcon : Icons.CautionCircle} focused={ownerStatus}>
            <MainContainer>
                <Form onSubmit={onSubmitLoginHandler}>
                    <ItemTitle>{Translate.t('Property.PropertyDescription.ItemTitle')}</ItemTitle>
                    <InputDescription placeholder=' subang jaya ' value={newinput} onChange={e => setNewinput(e.target.value)} />
                    <ButtonContainer>
                        <ConfirmPropertyButton loading={editPropertyDetailsAttempt} label='Confirm' />
                        <ClearPropertyButton type='button' onClick={onSubmitCancelHandler}>
                            {Translate.t('Property.PropertyClearButton')}
                        </ClearPropertyButton>
                    </ButtonContainer>

                </Form>
            </MainContainer>

        </CardCollapsible>

    );
};

const InputDescription = styled.textarea`
    display: flex;
    width: 85%;
    height: 100px;
    margin-bottom: 16px;
    padding: 20px;
    background-color:#F6F6F6;
    font-family: Helvetica;
    color: black;
    font-size: 12px;
    line-height: 14px;
    border: none;
    border-radius: 12px;
    transition: all .5s;
    &:focus {
      outline-color: #cccccc;
    }
    justify-content: center;
    align-items: center;
    &::placeholder {
      display: flex;
      margin: 0px;
      padding:0px;
      color: #696969;
    }
    overflow-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ConfirmPropertyButton = styled(Button)`
    align-items: flex-start;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: static;
    width: 66px; 
    height: 32px;
    left: 0px;
    border-radius: 8px;
    background-color: #F2B47E;
    color: white;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
`;

const ClearPropertyButton = styled.button`
    align-items: flex-start;
    padding: 8px;
    position: static;
    width: 50px; 
    height: 32px;
    left: 10px;
    border-radius: 8px;
    margin-right: 10px;
    background-color: #ffffff;
    color: black;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetPropertySetDescriptionAttempting(state),
    error: Selectors.getPropertyGetPropertySetDescriptionError(state),
    Createpropertyid: Selectors.getPropertyGetPropertySetCreatePropert(state),
    propertyDescriptionCollapsibleStatus: Selectors.setIsPropertyOpenDescriptionCollapsible(state),
    editPropertyDetailsAttempt: Selectors.getPropertyEditPropertyDetailsAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDescription: (id: string, description: string) =>
        dispatch(Actions.propertySetDescriptionAttempt({ id, description })),
    setOpenDescriptionCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenDescriptionCollapsible(state)),
    setPropertyDescriptionSubmitted: (state: boolean) =>
        dispatch(Actions.setPropertyDescriptionSubmitted(state)),
    editPropertyDetails: (data: IEditPropertyDetails) => dispatch(Actions.editPropertyDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDescription);
