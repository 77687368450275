import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

export default function* watchLogout(): SagaWatcherReturnType {
    yield takeEvery('auth/authLogout', handleLogout);
}

function* handleLogout() {
    yield put(Actions.clearUserInfo());

    Utils.Auth.clearAuthToken();
    NavActions.navToLogin();
}
