import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import { SetIPackagesPropertyManagementSortPayload } from 'redux/slices/packages/types';

import Utils from 'lib/Utils';
import Translate from 'lib/Translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchGetPropertyManagementwSort(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesGetIPropertyManagementDataSortAttempt', handlePackages, api);
}

function* handlePackages(api: PackagesGateway, data: SetIPackagesPropertyManagementSortPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId, sort, transactionType } = data.payload;

        const response = yield* call([api, api.getPackagesPropertyManagementSort], {
            propertyId,
            sort,
            transactionType,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetIPropertyManagementDataSortFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            yield put(Actions.packagesGetIPropertyManagementDataSortSuccess(response.data));
            yield put(Actions.getAllPropertyDetailsAttempt({ propertyId }));
        } else {
        // handle error
            if (response.name === '1614') {
                yield put(Actions.getAllPropertyDetailsAttempt({ propertyId }));
            }
            yield put(Actions.packagesGetIPropertyManagementDataSortFailure(response.message));
        }
    }
}
