import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CardCollapsible, LineInput, Button } from '@dm/bigfish';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { IEditPropertyDetails, IPropertyDetails, IPropertyIndividual } from 'entities/property';
import Icons from '../../../assets/icons';
import SelectionInput from '../../../components/SelectionInput';
import SelectionInputMulti from '../../../components/SelectionInputMulti';

interface PropertyDetailsProps {
    loading: boolean;
    error: string;
    propertyId: string;
    propertyInfo: IPropertyIndividual;
    propertyDetailsCollapsibleStatus: boolean;
    propertyAmenitiesCollapsibleStatus: boolean;
    setDetails(data: IPropertyDetails): void;
    setOpenAmenitiesCollapsible: (state: boolean) => void;
    setOpenPropertyDetailsCollapsible: (state: boolean) => void;
    setPropertyDetailsSubmitted: (state: boolean) => void;
    editPropertyDetailsAttempt: boolean;
    editPropertyDetails: (data: IEditPropertyDetails) => void;
}

const PropertyDetails = (props: PropertyDetailsProps) : JSX.Element => {
    const {
        loading,
        error,
        propertyId,
        propertyInfo,
        propertyDetailsCollapsibleStatus,
        propertyAmenitiesCollapsibleStatus,
        setDetails,
        setOpenPropertyDetailsCollapsible,
        setOpenAmenitiesCollapsible,
        setPropertyDetailsSubmitted,
        editPropertyDetailsAttempt,
        editPropertyDetails,
    } = props;
    const [type, setType] = useState(0);
    const [titleType, setTitleType] = useState(1);
    const [landTitle, setLandTitle] = useState(1);
    const [preferredTenant, setPreferredTenant] = useState<number[]>([]);
    const [tenure, setTenure] = useState(1);
    const [unitType, setUnitType] = useState('');
    const [storey, setStorey] = useState(1);
    const [occupancy, setOccupancy] = useState('');
    const [facingDirection, setFacingDirection] = useState('');
    const [buildUpSize, setBuildUpSize] = useState(0);
    const [furnishing, setFurnishing] = useState(0);
    const [builtUpDimension, setBuiltUpDimension] = useState('');
    const [buildUpPricePerSqft, setBuildUpPricePerSqft] = useState(0);
    const [ownerStatus, setOwnerStatus] = useState(false);
    const ownerRef = useRef<any>(null);
    // const [descriptionPreffecredTenant, setDescriptionPreffecredTenant] = useState([]);
    const [unitNumber, setUnitNumber] = useState('');

    const [isToggle, setIsToggle] = useState(true);

    useEffect(() => {
        setPropertyDetailsSubmitted(false);
        setOwnerStatus(false);
        if ((propertyInfo?.details?.buildUpPricePerSqft !== undefined)
            && (propertyInfo?.details?.buildUpSize !== undefined)
            && (propertyInfo?.details?.builtUpDimension !== undefined)
            && (propertyInfo?.details?.facingDirection !== undefined)
            && (propertyInfo?.details?.furnishing !== undefined)
            && (propertyInfo?.details?.landTitle !== undefined)
            && (propertyInfo?.details?.occupancy !== undefined)
            && (propertyInfo?.details?.storey !== undefined)
            && (propertyInfo?.details?.tenure !== undefined)
            && (propertyInfo?.details?.titleType !== undefined)
            && (propertyInfo?.details?.type !== undefined)
            && (propertyInfo?.details?.unitType !== undefined)) {
            onPropertyDetailsPersist();
            setOwnerStatus(true);
            onCloseCollapsibleOwner();
            setPropertyDetailsSubmitted(true);
        }
    }, [propertyInfo.details]);

    useEffect(() => {
        if ((propertyDetailsCollapsibleStatus) && propertyDetailsCollapsibleStatus === true) {
            onOpenCollapsibleOwner();
        }
    }, [propertyDetailsCollapsibleStatus]);

    const onPropertyDetailsPersist = () => {
        setBuildUpPricePerSqft(propertyInfo.details?.buildUpPricePerSqft ?? 0);
        setBuildUpSize(propertyInfo.details?.buildUpSize ?? 0);
        setBuiltUpDimension(propertyInfo.details?.builtUpDimension ?? '');
        setFacingDirection(propertyInfo.details?.facingDirection ?? '');
        setFurnishing(propertyInfo.details?.furnishing ?? 0);
        setLandTitle(propertyInfo.details?.landTitle ?? 0);
        setOccupancy(propertyInfo.details?.occupancy ?? '');
        setPreferredTenant(propertyInfo.details?.preferredTenant ?? []);
        setStorey(propertyInfo.details?.storey ?? 0);
        setTenure(propertyInfo.details?.tenure ?? 1);
        setTitleType(propertyInfo.details?.titleType ?? 1);
        setType(propertyInfo.details?.type ?? 0);
        setIsToggle(false);
    };

    const onOpenCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.openCollapsible();
        }
    };

    const onSubmitConfirmHandlerCondo = () => {
        setType(0);
        setIsToggle(!isToggle);
    };

    const onSubmitConfirmHandlerLanded = () => {
        setType(1);
        setIsToggle(!isToggle);
    };

    const onSubmitCancelHandler = (e: React.FormEvent) => {
        e.preventDefault();
        setType(0);
        setTitleType(1);
        setBuildUpSize(0);
        setOccupancy('');
        setLandTitle(0);
        setTenure(0);
        setFurnishing(0);
        setFacingDirection('');
        setPreferredTenant([]);
        setUnitType('');
        setStorey(0);
        setBuiltUpDimension('');
        setBuildUpPricePerSqft(0);
        setPropertyDetailsSubmitted(false);
    };

    const onCloseCollapsibleOwner = () => {
        if (ownerRef.current) {
            ownerRef.current.closeCollapsible();
        }
    };

    const onSubmitLoginHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const details = {
            type,
            titleType,
            buildUpSize,
            occupancy,
            landTitle,
            tenure,
            furnishing,
            facingDirection,
            preferredTenant,
            unitType,
            storey,
            builtUpDimension,
            buildUpPricePerSqft,
        };

        editPropertyDetails({
            propertyId,
            details,
            unitNo: unitNumber,
        });
        setOwnerStatus(true);
        onCloseCollapsibleOwner();

        setOpenPropertyDetailsCollapsible(false);
        setOpenAmenitiesCollapsible(!propertyAmenitiesCollapsibleStatus);
    };

    const typeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TypeApartment'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TypeLanded'),
        },
    ];

    const setTitleTypeOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsStrata'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsMaster'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.TitleTypeOptionsIndividual'),
        },
    ];
    const tenureOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.tenureOptionsFreehold'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.tenureOptionsLeaseHold'),
        },

    ];

    const LandTitleOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsResidential'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.LandTitleOptionsCommercial'),
        },

    ];

    const preferredTenantOptions = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsChinese'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsIndian'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsMalay'),
        },
        {
            id: 3,
            description: Translate.t('Property.PropertyDetails.preferredTenantOptionsOthers'),
        },

    ];

    const furnishingOption = [
        {
            id: 0,
            description: Translate.t('Property.PropertyDetails.furnishingOptionFully'),
        },
        {
            id: 1,
            description: Translate.t('Property.PropertyDetails.furnishingOptionPartial'),
        },
        {
            id: 2,
            description: Translate.t('Property.PropertyDetails.furnishingOptionNone'),
        },
    ];

    const onChangePreferredTenantAction = (newSelectedOptions: number[]) => {
        setPreferredTenant(newSelectedOptions);
    };

    return (
        <PropertyDetailsMainContainer>
            <CardCollapsible ref={ownerRef} preText={Translate.t('Property.PropertyDetails.CollapsiblePretext')} title={Translate.t('Property.PropertyDetails.CollapsibleTitle')} icon={ownerStatus ? Icons.VerifiedIcon : Icons.CautionCircle} focused={ownerStatus}>
                {isToggle
            && (
                <MainContainerMessage>
                    <Title>
                        {Translate.t('Property.PropertyDetails.TitleMessage')}
                    </Title>
                    <PropertyTypeContainer>
                        <PropertyInfoContainerLanded onClick={onSubmitConfirmHandlerLanded}>
                            <img src={Icons.LandPropertyType} alt='' />
                            <SavePropertyButtonLanded>{Translate.t('Property.PropertyDetails.PropertyTypeLanded')}</SavePropertyButtonLanded>
                        </PropertyInfoContainerLanded>
                        <PropertyInfoContainerCondo onClick={onSubmitConfirmHandlerCondo}>
                            <img src={Icons.LandPropertyType} alt='' />
                            <SavePropertyButtonCondo>{Translate.t('Property.PropertyDetails.PropertyTypeCondo')}</SavePropertyButtonCondo>
                        </PropertyInfoContainerCondo>
                    </PropertyTypeContainer>
                </MainContainerMessage>

            )}
                {!isToggle
            && (
                <MainContainer>
                    <Form onSubmit={onSubmitLoginHandler}>
                        <DetailsContainer>
                            <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyType')} data={typeOptions} currentValue={type} onChangeSelection={(e) => setType(+e.target.value)} />
                            <SelectionInput label={Translate.t('Property.PropertyDetails.LandTitleLabel')} data={LandTitleOptions} currentValue={landTitle} onChangeSelection={(e) => setLandTitle(+e.target.value)} />
                            <SelectionInputMulti label={Translate.t('Property.PropertyDetails.PreferredTenantLabel')} options={preferredTenantOptions} currentValue={preferredTenant} onChange={onChangePreferredTenantAction} />
                            <SelectionInput label={Translate.t('Property.PropertyDetails.PropertyTitleTypeLabel')} data={setTitleTypeOptions} currentValue={titleType} onChangeSelection={(e) => setTitleType(+e.target.value)} />
                            <SelectionInput label={Translate.t('Property.PropertyDetails.TenureLabel')} data={tenureOptions} currentValue={tenure} onChangeSelection={(e) => setTenure(+e.target.value)} required />
                            <LineInput label={Translate.t('Property.PropertyDetails.UnitTypeLabel')} placeholder={Translate.t('Property.PropertyDetails.UnitTypePlaceHolder')} value={unitType} onChangeText={e => setUnitType(e.target.value)} />
                            <LineInputWithDetail label={Translate.t('Property.PropertyDetails.BuildUpSizeLabel')} value={buildUpSize} onChangeText={e => setBuildUpSize(Number(e.target.value))} />
                            <LineInputWithDetail label={Translate.t('Property.PropertyDetails.BuiltupPriceperSqftLabel')} placeholder='1234' value={buildUpPricePerSqft} onChangeText={e => setBuildUpPricePerSqft(Number(e.target.value))} />
                            <LineInputWithDetail label={Translate.t('Property.PropertyDetails.BuiltUpDimensionSqftLabel')} placeholder='1234' value={builtUpDimension} onChangeText={e => setBuiltUpDimension((e.target.value))} />
                            <LineInputWithDetail label={Translate.t('Property.PropertyDetails.StoreyLabel')} value={storey} onChangeText={e => setStorey(Number(e.target.value))} />
                            <SelectionInput label={Translate.t('Property.PropertyDetails.FurnishingLabel')} data={furnishingOption} currentValue={furnishing} onChangeSelection={(e) => setFurnishing(+e.target.value)} />
                            <LineInput label={Translate.t('Property.PropertyDetails.OccupancyLabel')} placeholder={Translate.t('Property.PropertyDetails.OccupancyPlaceHolder')} value={occupancy} onChangeText={e => setOccupancy(e.target.value)} />
                            <LineInput label={Translate.t('Property.PropertyDetails.FacingDirectionLabel')} placeholder={Translate.t('Property.PropertyDetails.FacingDirectionPlaceHolder')} value={facingDirection} onChangeText={e => setFacingDirection(e.target.value)} />
                        </DetailsContainer>

                        <ButtonContainer>
                            <ConfirmPropertyButton loading={editPropertyDetailsAttempt} label={Translate.t('Property.PropertyConfirmButton')} />
                            <ClearPropertyButton type='button' onClick={onSubmitCancelHandler}>
                                {Translate.t('Property.PropertyClearButton')}
                                {' '}
                            </ClearPropertyButton>
                        </ButtonContainer>
                    </Form>

                </MainContainer>
            ) }
            </CardCollapsible>
        </PropertyDetailsMainContainer>

    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetPropertyDetailsAttempt(state),
    error: Selectors.getPropertyGetPropertyDetailsError(state),
    Createpropertyid: Selectors.getPropertyGetPropertySetCreatePropert(state),
    propertyInfo: Selectors.getPropPropertyIndividual(state),
    propertyDetailsCollapsibleStatus: Selectors.setIsPropertyOpenDetailsCollapsible(state),
    propertyAmenitiesCollapsibleStatus: Selectors.setIsPropertyOpenAmenitiesCollapsible(state),
    editPropertyDetailsAttempt: Selectors.getPropertyEditPropertyDetailsAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDetails: (data: IPropertyDetails) =>
        dispatch(Actions.propertySetPropertyDetailsAttempt(data)),
    setOpenPropertyDetailsCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenDetailsCollapsible(state)),
    setOpenAmenitiesCollapsible: (state: boolean) => dispatch(Actions.setPropertyOpenAmenitiesCollapsible(state)),
    setPropertyDetailsSubmitted: (state: boolean) =>
        dispatch(Actions.setPropertyDetailsSubmitted(state)),
    editPropertyDetails: (data: IEditPropertyDetails) => dispatch(Actions.editPropertyDetailsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);

const PropertyDetailsMainContainer = styled.div`
    margin-bottom: 20px;
`;

const MainContainerMessage = styled.div`
    max-width: 824px;
    max-height: 306px;
    padding: 20px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const Form = styled.form`
    margin: 0px;
    width: 100%;
    height: 100%;
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 16px;
    width: 100%;
    margin-bottom: 16px;
    margin-bottom: 16px;
`;

const MainContainer = styled.div`
    padding: 20px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
`;

const ConfirmPropertyButton = styled(Button)`
    align-items: flex-start;
    padding: 8px;
    position: static;
    width: 66px; 
    height: 32px;
    left: 0px;
    border-radius: 8px;
    background-color: #F2B47E;
    color: white;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const ClearPropertyButton = styled.button`
    align-items: flex-start;
    padding: 8px;
    position: static;
    width: 50px; 
    height: 32px;
    left: 10px;
    border-radius: 8px;
    margin-right: 10px;
    background-color: #ffffff;
    color: black;
    border:none;
    box-sizing: border-box;
    cursor: pointer;
`;

const Title = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    max-width: 120px;
    max-height: 22px;
`;

const PropertyTypeContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80%;
    padding-right:28px;
    padding-left: 28px;
    >:not(:last-child) {
        margin-right: 16px;
    }      
`;

const PropertyInfoContainerLanded = styled.div`
    max-width: 224px;
    height: 224px;
    position: relative;
`;
const PropertyInfoContainerCondo = styled.div`
    max-width: 224px;
    height: 224px;
    position: relative;
    
`;

const LineInputMainContainer = styled.div`
    display: flex;
`;

const LineInputText = styled.p`
    font-weight: 400;
    line-height: 14px;
    font-size: 12px;
    position: absolute;
    margin-left: 3%;
    margin-top: 12px;
    color: #696969;    
`;

const SavePropertyButtonLanded = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: white;
    position: absolute;
    width: 74px;
    height: 38px;
    left: 75px;
    top: 93px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    background: #FFCFA6;
    border-radius: 12px;
    border:none;
`;

const SavePropertyButtonCondo = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: white;
    position: absolute;
    width: 74px;
    height: 38px;
    left: 75px;
    top: 93px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    background: #FFCFA6;
    border-radius: 12px;
    border:none;
`;

export interface LineInputWithDetailProps {
    /**
    * Label
    */
    label?: string;
    /**
    * Placeholder
    */
    placeholder?: string;
    /**
    * Valueg
    */
    value?: string | number;
    Description?: string | number;

    /**
    * Disabled
    */
    disabled?: boolean;
    /**
     * On change handler
     */
    onChangeText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * On enter press handler
     */
    onEnterPressed?: () => void;
    /**
     Readonly
     */
    readOnly?: boolean;
    /**
    * Required
    */
    required?: boolean;
    /**
    * Validate status
    */
    inputError?: boolean;
    /**
    * Validate status
    */
    errorText?: string;
    /**
    Type
    */
    type?: string;
    /**
    * Style
    */
    style?: React.CSSProperties;
  }

  type InputProps = Omit<LineInputWithDetailProps, 'label'>;

const FormGroup = styled.div`
    margin: 2px 0;
    font-family: #FFCFA6;
    overflow: hidden;
`;

const Label = styled.label<{disabled?: boolean}>`
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
`;

const Input = styled.input<InputProps>`
    display: block;
    position: relative;
    width: 224px;
    margin-top: 6px;
    padding: 7px 10px 10px 0;
    background: none;
    background-color: white;
    color: grey;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;
    &:focus {
        outline: none;
    }
    ${props => props.disabled && css`
        pointerEvents: none;
        cursor: text;
    `}
    ${props => props.inputError && css`
        border-bottom: 1px solid red;
        margin-bottom: 6px;
    `}
`;

export const LineInputWithDetail = ({ label, placeholder, value, Description, disabled, onChangeText, onEnterPressed, style, ...otherProps }: LineInputWithDetailProps): JSX.Element => {
    return (
        <FormGroup style={style} {...otherProps}>
            {label ? <Label disabled={disabled}>{label}</Label> : ''}

            <LineInputMainContainer>
                <Input placeholder={placeholder} disabled={disabled} onChange={onChangeText} onKeyDown={onEnterPressed} value={value} Description={Description} />
                <LineInputText>{Description}</LineInputText>
            </LineInputMainContainer>

        </FormGroup>
    );
};
