import { fork } from 'redux-saga/effects';

import PropertyGateway from 'api/Property';
import { RootSagaReturnType } from 'sagas/types';

import watchGetProperty from './getProperty';
import watchGetPropertyPaginator from './getPropertyPaginator';
import watchDesription from './getDesription';
import watchGetAmenities from './getAmenities';
import watchDetails from './getDetails';
// import watchCreateProperty from './createNewDraftProperty';
import watchTermsProperty from './getTerms';
import watchLocationProperty from './getLocation';
import watchImagesProperty from './getImages';
import watchGetApproval from './getApproval';
import watchGetPropertyIndividual from './getPropertyIndividual';
import watchGetPropertyUploadUrl from './getPropertyUploadUrl';
import watchUploadPropertyDocument from './uploadPropertyDocument';
import watchGetPropertyDeleteImage from './setDeleteImage';
import watchGetPropertyImageData from './getImageData';
import watchGetPropertyWalletDetails from './getWalletDetails';
import watchGetAllPropertyDetails from './getAllPropertyDetails';
import watchGetRentalAnalytics from './getRentalAnalytics';
import watchGetWalletAnalytics from './getWalletAnalytics';
import watchCreateProperty from './createProperty';
import watchEditPropertyDetails from './editPropertyDetails';

export default (api: PropertyGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetProperty, api);
        yield fork(watchGetPropertyPaginator, api);
        yield fork(watchDesription, api);
        yield fork(watchGetAmenities, api);
        yield fork(watchDetails, api);
        // yield fork(watchCreateProperty, api);
        yield fork(watchTermsProperty, api);
        yield fork(watchLocationProperty, api);
        yield fork(watchImagesProperty, api);
        yield fork(watchGetApproval, api);
        yield fork(watchGetPropertyIndividual, api);
        yield fork(watchGetPropertyUploadUrl, api);
        yield fork(watchUploadPropertyDocument, api);
        yield fork(watchGetPropertyDeleteImage, api);
        yield fork(watchGetPropertyImageData, api);
        yield fork(watchGetPropertyWalletDetails, api);
        yield fork(watchGetAllPropertyDetails, api);
        yield fork(watchGetRentalAnalytics, api);
        yield fork(watchGetWalletAnalytics, api);
        yield fork(watchCreateProperty, api);
        yield fork(watchEditPropertyDetails, api);
    }

    return {
        rootSaga,
    };
};
