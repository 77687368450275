import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import NavActions from 'lib/NavActions';
import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { BreadCrumb, Button, Colors, Fonts, LineInput } from '@dm/bigfish';
import Translate from 'lib/Translate';
import 'react-datepicker/dist/react-datepicker.css';
import { Category, IOwnerUser, PaymentStatusEnum, PropertyManagementTransactionStatus, StripeSubscriptionStatusEnum, ViewTransactionResponse } from '@dm/types';
import { Oval } from 'react-loader-spinner';

import { IUpdateUser } from 'entities/user';
import icons from 'assets/icons';

import media from 'lib/Breakpoints';
import MainContainer from '../../components/MainContainer';
import SelectionInput from '../../components/SelectionInputsample';
import DateInput from '../../components/DateInputsample';
import countriesOptions from '../../components/Countries';
import Modal from './ProfileModal';

interface ProfileProps {
    loading: boolean;
    error: string;
    userList: IOwnerUser | undefined;

    packagesSubscriptionTransactionsLoading: boolean;
    packagesSubscriptionTransactionsError: string;
    packagesSubscriptionTransactionsList: ViewTransactionResponse[]| null;

    profileSetSubscriptionTransactionModal: boolean,

    packagesSetPackagesSubscriptionTransactionsCancelLoading: boolean;
    packagesSetPackagesSubscriptionTransactionsCancelError: string;
    packagesSetPackagesSubscriptionTransactionsCancel: string;

    packagesSetPackagesSubscriptionTransactionsRenewLoading: boolean;
    packagesSetPackagesSubscriptionTransactionsRenewError: string;
    packagesSetPackagesSubscriptionTransactionsRenew: string;

    packagesSetTransactionApproveOrRenewLoaderModal: boolean;

    getUserInfo: () => void;
    setTabView: (tabView: string) => void;
    setUserInfo(data: IUpdateUser): void;
    getSubscriptionTransaction(): void;
    setSubscriptionTransactionModalOpen(state: boolean): void;
    setPackagesSetSubscriptionTransactionsCancelAttempt(paymentId: string, category: number): void;
    setPackagesSetSubscriptionTransactionsRenewAttempt(paymentId: string, autoRenew: number): void;

    setTransactionApproveOrRenewLoaderModal(state: boolean): void;

}

const Profile = (props : ProfileProps) : JSX.Element => {
    const { loading,
        error,
        userList,
        packagesSubscriptionTransactionsLoading,
        packagesSubscriptionTransactionsError,
        packagesSubscriptionTransactionsList,
        profileSetSubscriptionTransactionModal,

        packagesSetPackagesSubscriptionTransactionsCancelLoading,
        packagesSetPackagesSubscriptionTransactionsCancelError,
        packagesSetPackagesSubscriptionTransactionsCancel,

        packagesSetPackagesSubscriptionTransactionsRenewLoading,
        packagesSetPackagesSubscriptionTransactionsRenewError,
        packagesSetPackagesSubscriptionTransactionsRenew,

        packagesSetTransactionApproveOrRenewLoaderModal,

        getUserInfo,
        setTabView,
        setUserInfo,
        getSubscriptionTransaction,
        setSubscriptionTransactionModalOpen,
        setPackagesSetSubscriptionTransactionsCancelAttempt,
        setPackagesSetSubscriptionTransactionsRenewAttempt,
        setTransactionApproveOrRenewLoaderModal } = props;
    const location = useLocation();
    const [title, setTitle] = useState('');
    const [gender, setGender] = useState('');
    const [correspondingAddres, setCorrespondingAddres] = useState('');
    const [personalAddress2, setPersonalAddress2] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [idNo, setIdNo] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [cityTownPersonal, setCityTownPersonal] = useState('');
    const [passportNo, setPassportNo] = useState('');
    const [postcode, setPostcode] = useState('');
    const [personalHouseTel, setPersonalHouseTel] = useState('');
    const [countryOfOrigin, setCountryOfOrigin] = useState('');
    const [state, setState] = useState('');
    const [race, setRace] = useState<string>('');
    const [religionrace, setReligionrace] = useState(0);
    const [countryOfResidence, setCountryOfResidence] = useState('');
    const [nationality, setNationality] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [useremail, setUseremail] = useState('');

    // Tempdata
    const [tempTitle, setTempTitle] = useState('');
    const [tempgender, setTempGender] = useState('');
    const [tempcorrespondingAddres, setTempCorrespondingAddres] = useState('');
    const [tempPersonalAddress2, setTempPersonalAddress2] = useState('');
    const [tempfirstName, setTempFirstName] = useState('');
    const [templastName, setTempLastName] = useState('');
    const [tempidNo, setTempIdNo] = useState('');
    const [tempphoneNumber, setTempPhoneNumber] = useState('');
    const [tempcityTownPersonal, setTempCityTownPersonal] = useState('');
    const [temppassportNo, setTempPassportNo] = useState('');
    const [temppostcode, setTempPostcode] = useState('');
    const [temppersonalHouseTel, setTempPersonalHouseTel] = useState('');
    const [tempcountryOfOrigin, setTempCountryOfOrigin] = useState('');
    const [tempstate, setTempState] = useState('');
    const [temprace, setTempRace] = useState('');
    const [tempcountryOfResidence, setTempCountryOfResidence] = useState('');
    const [tempnationality, setTempNationality] = useState('');
    const [tempdateOfBirth, setTempDateOfBirth] = useState(new Date());
    const [tempuseremail, setTempUseremail] = useState('');
    const [tempbanktype, setTempBanktype] = useState('');
    const [tempcurrency, setTempCurrency] = useState('');
    const [tempbranchAddress, setTempBranchAddress] = useState('');
    const [tempbankName, setTempBankName] = useState('');
    const [tempswiftCode, setTempSwiftCode] = useState('');
    const [tempfinancialCityTown, setTempFinancialCityTown] = useState('');
    const [tempfinancialPoscode, setTempFinancialPoscode] = useState('');
    const [tempfinancialState, setTempFinancialState] = useState('');
    const [tempfinancialCountry, setTempFinancialCountry] = useState('');
    const [tempcompanyName, setTempCompanyName] = useState('');
    const [tempdesignation, setTempDesignation] = useState('');
    const [tempofficeNo, setTempOfficeNo] = useState('');
    const [tempfullName, setTempFullName] = useState('');
    const [temprelationship, setTempRelationship] = useState('');
    const [tempmobileNo, setTempMobileNo] = useState('');
    const [tempcontactEmail, setTempContactEmail] = useState('');

    // BankType

    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [banktype, setBanktype] = useState('');
    const [currency, setCurrency] = useState('');
    const [branchAddress, setBranchAddress] = useState('');
    const [bankName, setBankName] = useState('');
    const [swiftCode, setSwiftCode] = useState('');
    const [financialCityTown, setFinancialCityTown] = useState('');
    const [financialPoscode, setFinancialPoscode] = useState('');
    const [financialState, setFinancialState] = useState('');
    const [financialCountry, setFinancialCountry] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [designation, setDesignation] = useState('');
    const [officeNo, setOfficeNo] = useState('');

    const [fullName, setFullName] = useState('');
    const [relationship, setRelationship] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [contactEmail, setContactEmail] = useState('');

    const [isEditing, setIsEditing] = useState(true);
    const [isDisplay, setisDisplay] = useState(true);
    const [isExpand, setIsExpand] = useState(true);

    const listHeaders: string[] = ['createdAt', 'status', 'per year amount'];
    const listHeadersMain: string[] = ['Property name', 'Subscription Start', 'Description', 'Status', 'View', 'Action'];

    const [subscriptionTransactionsData, setSubscriptionTransactions] = useState<
    {
        createdAt: Date;
        status: StripeSubscriptionStatusEnum;
        amount: string;
        _id: string;
    }[] | ''>('');

    useEffect(() => {
        getUserInfo();
    }, []);

    useEffect(() => {
        if (userList) {
            const stringToDate = dayjs(userList.DOB).toDate();

            setTitle(userList.title ?? '');
            setTempTitle(userList.title ?? '');
            setGender(userList.gender ?? '');
            setTempGender(userList.gender ?? '');
            setCorrespondingAddres(userList.correspondingAddress?.address1 ?? '');
            setTempCorrespondingAddres(userList.correspondingAddress?.address1 ?? '');
            setPersonalAddress2(userList.correspondingAddress?.address2 ?? '');
            setTempPersonalAddress2(userList.correspondingAddress?.address2 ?? '');
            setFirstName(userList.firstName ?? '');
            setTempFirstName(userList.firstName ?? '');
            setLastName(userList.lastName ?? '');
            setTempLastName(userList.lastName ?? '');
            setIdNo(userList.userId ?? '');
            setTempIdNo(userList.userId ?? '');
            setPhoneNumber(userList.phoneNumber ?? '');
            setTempPhoneNumber(userList.phoneNumber ?? '');
            setCityTownPersonal(userList.correspondingAddress?.city ?? '');
            setPassportNo(userList.passportNo ?? '');
            setTempPassportNo(userList.passportNo ?? '');
            setTempCityTownPersonal(userList.correspondingAddress?.city ?? '');
            setPostcode(userList.correspondingAddress?.postcode ?? '');
            setTempPostcode(userList.correspondingAddress?.postcode ?? '');
            setCountryOfOrigin(userList.countryOfOrigin ?? '');
            setTempCountryOfOrigin(userList.countryOfOrigin ?? '');
            setPersonalHouseTel(userList?.houseTel ?? '');
            setTempPersonalHouseTel(userList?.houseTel ?? '');
            setState(userList.correspondingAddress?.state ?? '');
            setTempState(userList.correspondingAddress?.state ?? '');
            setRace(userList.race?.toString() ?? '');
            setTempRace(userList.race?.toString() ?? '');
            setCountryOfResidence(userList.countryOfResidence ?? '');
            setTempCountryOfResidence(userList.countryOfResidence ?? '');
            setNationality(userList.nationality ?? '');
            setTempNationality(userList.nationality ?? '');
            setDateOfBirth(stringToDate ?? '');
            setTempDateOfBirth(stringToDate ?? '');
            setUseremail(userList.email ?? '');
            setTempUseremail(userList.email ?? '');

            setBankAccountNumber(userList.financialInfo?.bankAccNo ?? '');
            setBanktype(userList.financialInfo?.bankType ?? '');
            setTempBanktype(userList.financialInfo?.bankType ?? '');
            setCurrency(userList.financialInfo?.currency ?? '');
            setTempCurrency(userList.financialInfo?.currency ?? '');
            setBranchAddress(userList.financialInfo?.branchAddress.address1 ?? '');
            setTempBranchAddress(userList.financialInfo?.branchAddress.address1 ?? '');
            setBankName(userList.financialInfo?.bankName ?? '');
            setTempBankName(userList.financialInfo?.bankName ?? '');
            setSwiftCode(userList.financialInfo?.swiftCode ?? '');
            setTempSwiftCode(userList.financialInfo?.swiftCode ?? '');
            setFinancialCityTown(userList.financialInfo?.branchAddress.city ?? '');
            setTempFinancialCityTown(userList.financialInfo?.branchAddress.city ?? '');
            setFinancialPoscode(userList.financialInfo?.branchAddress.postcode ?? '');
            setTempFinancialPoscode(userList.financialInfo?.branchAddress.postcode ?? '');
            setFinancialState(userList.financialInfo?.branchAddress.state ?? '');
            setTempFinancialState(userList.financialInfo?.branchAddress.state ?? '');
            setFinancialCountry(userList.financialInfo?.branchAddress.country ?? '');
            setTempFinancialCountry(userList.financialInfo?.branchAddress.country ?? '');

            setCompanyName(userList.companyDetails?.companyName ?? '');
            setTempCompanyName(userList.companyDetails?.companyName ?? '');
            setDesignation(userList.companyDetails?.designation ?? '');
            setTempDesignation(userList.companyDetails?.designation ?? '');
            setOfficeNo(userList.companyDetails?.officeNo ?? '');
            setTempOfficeNo(userList.companyDetails?.officeNo ?? '');
            setFullName(userList.emergencyContact?.fullName ?? '');
            setTempFullName(userList.emergencyContact?.fullName ?? '');
            setRelationship(userList.emergencyContact?.relationship ?? '');
            setTempRelationship(userList.emergencyContact?.relationship ?? '');
            setMobileNo(userList.emergencyContact?.phoneNo ?? '');
            setTempMobileNo(userList.emergencyContact?.relationship ?? '');
            setContactEmail(userList.emergencyContact?.email ?? '');
            setTempContactEmail(userList.emergencyContact?.email ?? '');
            getSubscriptionTransaction();
        }
    }, [userList]);

    const onSubmitForm = () => {
        const data = {
            firstName,
            lastName,
            email: useremail,
            phoneNumber,
            title,
            nationality,
            race: religionrace,
            DOB: dateOfBirth.toString(),
            gender,
            countryOfResidence,
            houseTel: personalHouseTel,
            passportNo,
            countryOfOrigin,
            idNo,
            correspondingAddress: {
                address1: correspondingAddres,
                address2: personalAddress2,
                city: cityTownPersonal,
                postcode,
                state,
                country: countryOfResidence,
            },
            financialInfo: { bankAccNo: bankAccountNumber, bankName, bankType: banktype, currency, branchAddress: { address1: branchAddress, address2: branchAddress, city: financialCityTown, postcode: financialPoscode, state: financialState, country: financialCountry }, swiftCode },
            companyDetails: { companyName, designation, officeNo },
            emergencyContact: { fullName, relationship, phoneNo: mobileNo, email: contactEmail },

        };
        setUserInfo(data);
    };

    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
        setisDisplay(!isDisplay);
    };

    const toggleIsSaving = () => {
        setIsEditing(!isEditing);
        setisDisplay(!isDisplay);
        onSubmitForm();
        if (userList) {
            toast.success(Translate.t('Profile.UserInformationUpdated'));
        }
    };

    const genderOptions = [
        {
            id: Translate.t('Profile.Male'),
            description: Translate.t('Profile.Male'),
        },
        {
            id: Translate.t('Profile.Female'),
            description: Translate.t('Profile.Female'),
        },
    ];

    const raceOptions = [
        {
            id: 0,
            description: Translate.t('Profile.Chinese'),
        },
        {
            id: 1,
            description: Translate.t('Profile.Indian'),
        },
        {
            id: 2,
            description: Translate.t('Profile.Malay'),
        },
        {
            id: 3,
            description: Translate.t('Profile.Others'),
        },
    ];
    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Profile.Breadcrumblabel'),
                onClick: () => { NavActions.navToLogin(); },
            },
            {
                label: Translate.t('Profile.Breadcrumblabel'),
                onClick: () => { return false; },
            },
        ],
    );

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onClickExpand = () => {
        setIsExpand(false);
    };

    const cancelFunction = () => {
        setTitle(tempTitle);
        setGender(tempgender);
        setCorrespondingAddres(tempcorrespondingAddres);
        setPersonalAddress2(tempPersonalAddress2);
        setFirstName(tempfirstName);
        setLastName(templastName);
        setIdNo(tempidNo);
        setPhoneNumber(tempphoneNumber);
        setCityTownPersonal(tempcityTownPersonal);
        setPassportNo(temppassportNo);
        setPostcode(temppostcode);
        setPersonalHouseTel(temppersonalHouseTel);
        setCountryOfOrigin(tempcountryOfOrigin);
        setState(tempstate);
        setCountryOfResidence(tempcountryOfResidence);
        setNationality(tempnationality);
        setUseremail(tempuseremail);
        setRace(temprace);
        setDateOfBirth(tempdateOfBirth);
        setUseremail(tempuseremail);
        setBanktype(tempbanktype);
        setCurrency(tempcurrency);
        setBranchAddress(tempbranchAddress);
        setBankName(tempbankName);
        setSwiftCode(tempswiftCode);
        setFinancialCityTown(tempfinancialCityTown);
        setFinancialPoscode(tempfinancialPoscode);
        setFinancialState(tempfinancialState);
        setFinancialCountry(tempfinancialCountry);
        setCompanyName(tempcompanyName);
        setDesignation(tempdesignation);
        setOfficeNo(tempofficeNo);
        setFullName(tempfullName);
        setRelationship(temprelationship);
        setMobileNo(tempmobileNo);
        setContactEmail(tempcontactEmail);
    };

    const onClickExpandClose = () => {
        setIsExpand(true);
    };

    useEffect(() => {
        const pathName = location.pathname.replace('/', '');
        setTabView(pathName);
    }, []);

    const checkPropertyPackagesStatus = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case StripeSubscriptionStatusEnum.Ongoing:
                return 'Ongoing';
            case StripeSubscriptionStatusEnum.Closed:
                return 'Closed';
            case StripeSubscriptionStatusEnum.PaymentUnsuccessful:
                return 'PaymentUnsuccessful';
            default:
                return 'Nothing payment info';
        }
    };

    const checkPropertyPackagesTypeStatus = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case Category.PropertyManagement:
                return 'Property Management';
            case Category.VacantManagement:
                return 'Vacant Management';
            default:
                return 'No Package Type';
        }
    };

    const setSubscriptionTransactionsData = (data : {
        createdAt: Date;
        status: StripeSubscriptionStatusEnum;
        amount: string;
        _id: string;
    }[]) => {
        if (data.length) {
            setSubscriptionTransactions(data);
        } else {
            setSubscriptionTransactions('');
        }
    };

    const truncate = (str:string) => {
        return str?.length > 8 ? `${str.substring(0, 8)}..` : str;
    };

    const checkPackagesStatusType = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case StripeSubscriptionStatusEnum.Ongoing:
                return 'Ongoing';
            case StripeSubscriptionStatusEnum.Closed:
                return 'Closed';
            case StripeSubscriptionStatusEnum.PaymentUnsuccessful:
                return 'PaymentUnsuccessful';
            default:
                return 'Nothing payment info';
        }
    };

    const renderActionButtons = (status: number, paymentId: string, category: number) => {
        return (
            <CenterButtonMainContainer>
                <ButtonMainContainer>
                    {status === StripeSubscriptionStatusEnum.Ongoing ? (
                        <CustomButtonMainContainer>
                            <StyledMainButton
                                onClick={() => [setPackagesSetSubscriptionTransactionsRenewAttempt(paymentId, 0), setTransactionApproveOrRenewLoaderModal(!packagesSetTransactionApproveOrRenewLoaderModal)]}
                            >

                                <UploadDocumentText>
                                    {packagesSetPackagesSubscriptionTransactionsRenewLoading ? (
                                        <Oval
                                            height={25}
                                            width={25}
                                            color={Colors.secondary}
                                            secondaryColor={Colors.primary}
                                        />
                                    ) : <div>Renew</div>}
                                </UploadDocumentText>
                            </StyledMainButton>

                        </CustomButtonMainContainer>
                    ) : (
                        <CustomButtonUnclickableMainContainer>
                            <StyledMainButton>

                                <UploadDocumentText>
                                    {packagesSetPackagesSubscriptionTransactionsRenewLoading ? (
                                        <Oval
                                            height={25}
                                            width={25}
                                            color={Colors.secondary}
                                            secondaryColor={Colors.primary}
                                        />
                                    ) : <div>Renew</div>}

                                </UploadDocumentText>
                            </StyledMainButton>

                        </CustomButtonUnclickableMainContainer>
                    )}

                    {status === StripeSubscriptionStatusEnum.Ongoing ? (
                        <CustomButtonMainContainer>
                            <StyledMainButton
                                onClick={() => [setPackagesSetSubscriptionTransactionsCancelAttempt(paymentId, category), setTransactionApproveOrRenewLoaderModal(!packagesSetTransactionApproveOrRenewLoaderModal)]}
                            >

                                <UploadDocumentText>
                                    {packagesSetPackagesSubscriptionTransactionsCancelLoading ? (
                                        <Oval
                                            height={25}
                                            width={25}
                                            color={Colors.secondary}
                                            secondaryColor={Colors.primary}
                                        />
                                    ) : <div>Cancel</div>}
                                </UploadDocumentText>
                            </StyledMainButton>

                        </CustomButtonMainContainer>
                    )
                        : (
                            <CustomButtonUnclickableMainContainer>
                                <StyledMainButton>

                                    <UploadDocumentText>
                                        {packagesSetPackagesSubscriptionTransactionsCancelLoading ? (
                                            <Oval
                                                height={25}
                                                width={25}
                                                color={Colors.secondary}
                                                secondaryColor={Colors.primary}
                                            />
                                        ) : <div>Cancel</div>}
                                    </UploadDocumentText>
                                </StyledMainButton>

                            </CustomButtonUnclickableMainContainer>
                        ) }

                </ButtonMainContainer>
            </CenterButtonMainContainer>

        );
    };

    const renderList = () => {
        if (packagesSubscriptionTransactionsLoading) {
            return (
                <LoadingContainer>
                    <Oval
                        height={50}
                        width={50}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>
            );
        }

        if (packagesSubscriptionTransactionsError.length) {
            return (
                <ErrorContainer>
                    <h4>{Translate.t('Packages.ViewDocuments.NoData')}</h4>
                </ErrorContainer>
            );
        }

        if (packagesSubscriptionTransactionsList !== null) {
            return (
                <FiltersContainer>

                    {packagesSubscriptionTransactionsList.map((item) => {
                        const { category,
                            status,
                            paymentId,
                            propertyId,
                            subscriptionEnd,
                            subscriptionId,
                            subscriptionStart,
                            subscriptionTransactions,
                            updatedAt,
                            propertyName } = item;
                        return (
                            <HeadersContainerMain>

                                <TextTitle>{truncate(propertyName)}</TextTitle>
                                <TextTitle>
                                    {(dayjs(subscriptionStart).format('DD/MM/YYYY'))}
                                </TextTitle>
                                <TextTitle>
                                    {checkPropertyPackagesTypeStatus(category)}
                                </TextTitle>

                                <TextTitle>
                                    {checkPropertyPackagesStatus(status)}
                                </TextTitle>
                                {(status === StripeSubscriptionStatusEnum.Ongoing) ? (
                                    <StyledIconButton onClick={() => [setSubscriptionTransactionModalOpen(true), setSubscriptionTransactionsData(subscriptionTransactions)]}>
                                        <SearchIcon src={icons.searchIcon} />
                                    </StyledIconButton>
                                ) : <div>{/* empty container */}</div>}

                                {renderActionButtons(status, paymentId, category)}

                            </HeadersContainerMain>
                        );
                    })}
                    <Modal
                        show={packagesSetTransactionApproveOrRenewLoaderModal}
                        closeCursor
                        width='50%'
                        height='40%'
                        onClickClose={() => {
                            setTransactionApproveOrRenewLoaderModal(false);
                        }}
                    >
                        <CenterModalContainer>
                            <Oval
                                height={250}
                                width={250}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </CenterModalContainer>

                    </Modal>
                </FiltersContainer>
            );
        }

        return true;
    };

    return (
        <div>
            <div>
                <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
                <MainContainer>
                    <Container>
                        <Form onSubmit={onSubmitForm}>
                            <SectionContainer>
                                <SectionTitle>
                                    {Translate.t('Profile.PersonalDetails')}
                                </SectionTitle>
                                <DetailsContainer>
                                    <LineInput label={Translate.t('Profile.Titlelabel')} value={title} disabled={isDisplay} onChangeText={e => setTitle(e.target.value)} />
                                    <SelectionInput label={Translate.t('Profile.Genderlabel')} disabled={isDisplay} data={genderOptions} currentValue={gender} onChangeSelection={(e) => setGender(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCPAddress1')} value={correspondingAddres} disabled={isDisplay} onChangeText={e => setCorrespondingAddres(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCPAddress2')} value={personalAddress2} disabled={isDisplay} onChangeText={(e) => setPersonalAddress2(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelFirstName')} value={firstName} disabled={isDisplay} onChangeText={e => setFirstName(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelLastName')} value={lastName} disabled={isDisplay} onChangeText={e => setLastName(e.target.value)} />
                                    <LineInput label={Translate.t('Profile.IDNolabel')} value={idNo} disabled={isDisplay} onChangeText={e => setIdNo(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCity')} value={cityTownPersonal} disabled={isDisplay} onChangeText={e => setCityTownPersonal(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelPassport')} value={passportNo} disabled={isDisplay} onChangeText={e => setPassportNo(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelMobile')} value={phoneNumber} disabled={isDisplay} onChangeText={e => setPhoneNumber(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelPostcode')} value={postcode} disabled={isDisplay} onChangeText={e => setPostcode(e.target.value)} />
                                    <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountryOrigin')} data={countriesOptions} disabled={isDisplay} currentValue={countryOfOrigin} onChangeSelection={(e) => setCountryOfOrigin(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelHouseTel')} value={personalHouseTel} disabled={isDisplay} onChangeText={e => setPersonalHouseTel(e.target.value)} />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelState')} value={state} disabled={isDisplay} onChangeText={e => setState(e.target.value)} />
                                    <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelRace')} data={raceOptions} currentValue={race} disabled={isDisplay} onChangeSelection={(e) => setRace(e.target.value)} />
                                    <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountryResidence')} currentValue={countryOfResidence} disabled={isDisplay} data={countriesOptions} onChangeSelection={(e) => setCountryOfResidence(e.target.value)} />
                                    <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountry')} currentValue={nationality} disabled={isDisplay} data={countriesOptions} onChangeSelection={(e) => setNationality(e.target.value)} />
                                    <DateInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelDOB')} date={dateOfBirth} onChangeDate={(date) => setDateOfBirth(date)} disabled={isDisplay} required />
                                    <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelEmail')} value={useremail} disabled={isDisplay} onChangeText={e => setUseremail(e.target.value)} />

                                </DetailsContainer>
                                {!isExpand && (
                                    <div>
                                        <SectionTitle>{Translate.t('Profile.FinancialDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBankType')} value={banktype} disabled={isDisplay} onChangeText={e => setBanktype(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCurrency')} value={currency} disabled={isDisplay} onChangeText={e => setCurrency(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBranchAccNo')} value={bankAccountNumber} disabled={isDisplay} onChangeText={e => setBankAccountNumber(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBranchAddress')} value={branchAddress} disabled={isDisplay} onChangeText={e => setBranchAddress(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBranchName')} value={bankName} disabled={isDisplay} onChangeText={e => setBankName(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelSwiftCode')} value={swiftCode} disabled={isDisplay} onChangeText={e => setSwiftCode(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCity')} value={financialCityTown} disabled={isDisplay} onChangeText={e => setFinancialCityTown(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelPostcode')} value={financialPoscode} disabled={isDisplay} onChangeText={e => setFinancialPoscode(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelState')} value={financialState} disabled={isDisplay} onChangeText={e => setFinancialState(e.target.value)} />
                                            <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountry')} disabled={isDisplay} data={countriesOptions} currentValue={financialCountry} onChangeSelection={(e) => setFinancialCountry(e.target.value)} />
                                        </DetailsContainer>

                                        <SectionTitle>{Translate.t('Profile.CompanyDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCompanyName')} value={companyName} disabled={isDisplay} onChangeText={e => setCompanyName(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelDesignation')} value={designation} disabled={isDisplay} onChangeText={e => setDesignation(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelOfficeNo')} value={officeNo} disabled={isDisplay} onChangeText={e => setOfficeNo(e.target.value)} />
                                        </DetailsContainer>

                                        <SectionTitle>{Translate.t('Profile.EmergencyContactDetails')}</SectionTitle>
                                        <DetailsContainer>
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelFullName')} value={fullName} disabled={isDisplay} onChangeText={e => setFullName(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelRelationship')} value={relationship} disabled={isDisplay} onChangeText={e => setRelationship(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelMobile')} value={mobileNo} disabled={isDisplay} onChangeText={e => setMobileNo(e.target.value)} />
                                            <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelEmail')} value={contactEmail} disabled={isDisplay} onChangeText={e => setContactEmail(e.target.value)} />
                                        </DetailsContainer>
                                    </div>

                                )}

                                {isExpand && (
                                    <ShowmoreContainer>
                                        <Showmore onClick={onClickExpand}>
                                            {Translate.t('Profile.Showmore')}
                                            <StyledSpan>&#x22C1;</StyledSpan>
                                        </Showmore>
                                    </ShowmoreContainer>
                                )}

                                {!isExpand && (
                                    <ShowmoreContainer>
                                        <Showmore onClick={onClickExpandClose}>
                                            {Translate.t('Profile.Seeless')}
                                            <StyledSpan>&#x22C0;</StyledSpan>
                                        </Showmore>
                                    </ShowmoreContainer>
                                )}
                                <ButtonContainer>
                                    {isDisplay && (
                                        <Button label={Translate.t('Profile.EditDetails')} onClick={toggleIsEditing} />

                                    )}
                                    {!isDisplay && (
                                        <Button label={Translate.t('Profile.SaveDetails')} onClick={toggleIsSaving} />

                                    )}

                                    {!isDisplay && (
                                        <StyledButton label={Translate.t('Profile.Cancel')} onClick={cancelFunction} />
                                    )}
                                </ButtonContainer>
                            </SectionContainer>
                        </Form>
                    </Container>

                    <SubscriptionTitle>
                        <h4>{Translate.t('Profile.Subscriptions')}</h4>
                    </SubscriptionTitle>
                    <ListContainer>
                        <HeadersContainerMain>
                            {listHeadersMain.map(item => {
                                return (
                                    <HeaderText
                                        key={item}
                                    >
                                        {item}
                                    </HeaderText>
                                );
                            })}
                        </HeadersContainerMain>
                        {renderList()}
                    </ListContainer>

                    <Modal
                        show={profileSetSubscriptionTransactionModal}
                        closeCursor
                        width='80%'
                        height='100%'
                        onClickClose={() => {
                            setSubscriptionTransactionModalOpen(false);
                        }}
                    >

                        <SubscriptionSubTitle>
                            <h4>{Translate.t('Profile.SubscriptionsTransactions')}</h4>
                        </SubscriptionSubTitle>
                        <SubscriptionTitltListContainer>
                            <HeadersTitleOfTransactionContainerWithoutBorder>
                                {listHeaders.map(item => {
                                    return (
                                        <HeaderText
                                            key={item}
                                        >
                                            {item}
                                        </HeaderText>
                                    );
                                })}
                            </HeadersTitleOfTransactionContainerWithoutBorder>
                            {subscriptionTransactionsData !== '' ? (
                                <>

                                    {subscriptionTransactionsData.map((item) => {
                                        const { amount, createdAt, status } = item;
                                        return (
                                            <HeadersTitleOfTransactionContainer>
                                                <TextTitle>{(dayjs(createdAt).format('DD/MM/YYYY'))}</TextTitle>
                                                <TextTitle>
                                                    {checkPackagesStatusType(status)}
                                                </TextTitle>
                                                <TextTitle>{amount}</TextTitle>
                                            </HeadersTitleOfTransactionContainer>
                                        );
                                    })}
                                </>
                            ) : (
                                <SubscriptionSubTitle>
                                    {Translate.t('Profile.NoSubscriptions')}
                                </SubscriptionSubTitle>
                            )}
                        </SubscriptionTitltListContainer>
                    </Modal>

                </MainContainer>
            </div>

        </div>
    );
};

const SubscriptionTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-bottom: 10px;
    width: 80%;

    ${media.lg`
        width: 100%;
    `}
`;

const SubscriptionSubTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-bottom: 10px;
    width: 100%;
`;

const ButtonMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
`;

const CenterButtonMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
`;

const CenterModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
`;

const StyledIconButton = styled.button`
  display: inline-block;
  border: none;
  background: transparent;
  cursor: pointer;

  transition: all .2s;

  &:active {
    transform: translateY(-3px);
  }
`;

const StyledMainButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: white;
`;

const CustomButtonMainContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 100%;
    width: 85px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    @media (max-width: 850px)  {
    max-width: 90%;
    height: 30px;
    }
`;

const CustomButtonUnclickableMainContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 100%;
    width: 85px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: lightgrey;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    @media (max-width: 850px)  {
    max-width: 90%;
    height: 30px;
    }
    cursor: not-allowed;
`;

const UploadDocumentText = styled.p`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const SearchIcon = styled(SVG)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const TextTitle = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

    ${media.lg`
        box-shadow: none;
    `}
`;

const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

    ${media.lg`
        box-shadow: none;
    `}
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

    ${media.lg`
        box-shadow: none;
    `}
`;

const HeaderText = styled.h3`
    justify-self: center;
    display: flex;
    flex-wrap: nowrap;
`;

const HeadersContainerMain = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 16.67% 16.67% 16.67% 16.67% 16.67% 16.67% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;

    justify-content: center;

    ${media.lg`
        justify-content: unset;
        align-items: unset;
        display: flex;
        overflow-x: auto;
        gap: 20px;
        padding-right: 20px;
    `}
`;

const HeadersTitleOfTransactionContainerWithoutBorder = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 33.33% 33.33% 33.33% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 1400px)  {
    column-gap: 0px;
    }
    justify-content: center;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 12px;
    box-shadow: -4px 0px 4px #F2B47E, 4px 0px 4px #F2B47E, 0px 4px 4px #F2B47E, 0px -4px 4px #F2B47E;
    margin-bottom: 20px;
`;

const HeadersTitleOfTransactionContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 33.33% 33.33% 33.33% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 1400px)  {
    column-gap: 0px;
    }
    justify-content: center;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);

    ${media.lg`
        box-shadow: none;
    `}
`;

const ListContainer = styled.div`
    width: 80%;
    margin-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    padding-left: 20px;

    ${media.lg`
        width: 100%;
    `}
`;

const SubscriptionTitltListContainer = styled.div`
    padding-top: 20px;
    width: 100%;
    margin-top: 20px;
    height: 95%;
    padding-left: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;

    ${media.lg`
        overflow-x: auto;
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 40px 20px 20px 20px;
    color: #000;
    position: relative;

    ${media.lg`
        width: 100%;
        padding: 20px;
    `}
`;

const Form = styled.form`
    margin: 0px;
    width: 100%;
    height: 100%;
`;

const Showmore = styled.div`
    display: flex;
    align-items: flex-start;
    position: absolute;
    color: #2F80ED;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
`;

const ShowmoreContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 60px;
    height: 20px;
    margin-top: 0px;
    padding-right: 20px;
`;

const StyledSpan = styled.span`
    margin-left: 6px;
    width: 8px;
    font-weight: normal;
    font-size: 7px;
    line-height: 14px;
`;

const StyledButton = styled(Button)`
    transition: 1s all;
    margin-left: 6px;
    animation-name: ButtonMove;
    animation-duration: 0.5s;
    @keyframes ButtonMove {
    from {margin-left: 0px;}
    to {margin-left: 6px;}
    }
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 8px;
    border: 0.5px solid #F2B47E;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    &:first-of-type{
    margin-top: 40px;
    }
    text-transform: capitalize;
    ${media.lg`
        box-shadow: none;
    `}
`;

const SectionTitle = styled.h3`
font-size: 18px;
line-height: 22px;
font-weight: 700;
margin-bottom: 12px;
margin-top: 30px;
`;

const DetailsContainer = styled.div`
display: grid;
grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
row-gap: 8px;
column-gap: 56px;
width: 100%;
`;

const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 12px;
padding-right: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getUserGetUserInfoAttempt(state),
    error: Selectors.getUserGetUserInfoFailure(state),
    userList: Selectors.getUserGetUserInfo(state),

    packagesSubscriptionTransactionsLoading: Selectors.getPackagesPackagesSubscriptionTransactionsAttempt(state),
    packagesSubscriptionTransactionsError: Selectors.getPackagesPackagesSubscriptionTransactionsFailure(state),
    packagesSubscriptionTransactionsList: Selectors.getIPackagesPackagesSubscriptionTransactions(state),

    profileSetSubscriptionTransactionModal: Selectors.setProfileSetSubscriptionTransactionModal(state),

    packagesSetPackagesSubscriptionTransactionsCancelLoading: Selectors.packagesSetPackagesSubscriptionTransactionsCancelAttempting(state),
    packagesSetPackagesSubscriptionTransactionsCancelError: Selectors.packagesSetPackagesSubscriptionTransactionsCancelError(state),
    packagesSetPackagesSubscriptionTransactionsCancel: Selectors.packagesSetPackagesSubscriptionTransactionsCancel(state),

    packagesSetPackagesSubscriptionTransactionsRenewLoading: Selectors.packagesSetPackagesSubscriptionTransactionsRenewAttempting(state),
    packagesSetPackagesSubscriptionTransactionsRenewError: Selectors.packagesSetPackagesSubscriptionTransactionsRenewError(state),
    packagesSetPackagesSubscriptionTransactionsRenew: Selectors.packagesSetPackagesSubscriptionTransactionsCancel(state),

    packagesSetTransactionApproveOrRenewLoaderModal: Selectors.packagesSetTransactionApproveOrRenewLoaderModal(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getUserInfo: () =>
        dispatch(Actions.userGetUserInfoAttempt()),
    setUserInfo: (data: IUpdateUser) =>
        dispatch(Actions.userSetUserInfoAttempt(data)),
    getSubscriptionTransaction: () =>
        dispatch(Actions.packagesGetSubscriptionTransactionsAttempt()),

    setSubscriptionTransactionModalOpen: (state: boolean) => dispatch(Actions.setSubscriptionTransactionModal(state)),

    setPackagesSetSubscriptionTransactionsCancelAttempt: (paymentId: string, category: number) => dispatch(Actions.packagesSetSubscriptionTransactionsCancelAttempt({ paymentId, category })),
    setPackagesSetSubscriptionTransactionsRenewAttempt: (paymentId: string, autoRenew: number) => dispatch(Actions.packagesSetSubscriptionTransactionsRenewAttempt({ paymentId, autoRenew })),

    setTransactionApproveOrRenewLoaderModal: (state: boolean) => dispatch(Actions.setTransactionApproveOrRenewLoaderModalOpen(state)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
