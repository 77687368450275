import React, { useState, useEffect } from 'react';

import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Translate from 'lib/Translate';
import NavActions from 'lib/NavActions';

import {
    BreadCrumb,
    Colors,
    Fonts,
    PackageCard,
    PendingCard,
    PropertyCard,
} from '@dm/bigfish';
import {
    IOwnerUser,
    IPropertyPackageStatusEnum,
    PackageCategory,
    PaymentGatewayResponse,
    PropertyManagementTransactionStatus,
    PropertyTitleTypeEnum,
    PropertyTypeEnum,
    TenureTypeEnum,
    ViewAllPMTransactionResponse,
} from '@dm/types';

import { IAllPropertyDetailDashboards, IPropertyPaginator } from 'entities/property';

import icons from 'assets/icons';
import PropertyModal from 'containers/property/components/PropertyModal';
import FileListModal from 'components/FileListModal';
import Reminder from 'components/Reminder';
import dayjs from 'dayjs';
import MainContainer from '../../components/MainContainer';
import RentalAnalytic from './components/RentalAnalytic';
import WalletStatistic from './components/WalletStatistic';
import PropertySlider from './components/PropertySlider';

import StyledComponent from './styles/dashboardStyle';
import PendingTransactionModal from './components/PendingTransactionModal';

interface propertyPackageProps {
    packageName: string;
    status: number;
    subscriptionEnd?: string;
}

interface HomeScreenProps {
    setTabView: (tabView: string) => void;
    UserListUserInfo: IOwnerUser | undefined;

    getPropertyPaginatorAttempt: boolean;
    displayedProperty: IPropertyPaginator['data'];

    getAllPropertyDetails: (propertyId: string) => void;
    allPropertyDetails: IAllPropertyDetailDashboards;
    getAllPropertyDetailsAttempt: boolean;
    getAllPropertyDetailsFailure: string;

    isCreatePropertyModalOpen: boolean;
    setPropertyCreateModal: (state: boolean) => void;

    setPackagesPropertyManagementStatusUpdateModal(state: boolean): void;

    propertyManagementTransactionStatusUpdateSuccess: string;

    propertyManagementTransactionStatusUpdateSuccessGateway: null | PaymentGatewayResponse;

    UserDataSuccess: IOwnerUser | undefined;
    fileList: ViewAllPMTransactionResponse['fileUrl'];
    setFileList: (value: ViewAllPMTransactionResponse['fileUrl']) => void;
    setIsFileListModalOpen: (state: boolean) => void;
}

const HomeScreen = (props: HomeScreenProps): JSX.Element => {
    // ! destructuring zone
    const {
        setTabView,
        UserListUserInfo,
        getPropertyPaginatorAttempt,
        displayedProperty,
        getAllPropertyDetails,
        allPropertyDetails,
        getAllPropertyDetailsAttempt,
        getAllPropertyDetailsFailure,
        isCreatePropertyModalOpen,
        setPropertyCreateModal,
        setPackagesPropertyManagementStatusUpdateModal,
        propertyManagementTransactionStatusUpdateSuccess,
        propertyManagementTransactionStatusUpdateSuccessGateway,
        UserDataSuccess,
        setIsFileListModalOpen,
        fileList,
        setFileList,
    } = props;

    const {
        HomeScreenContent,
        LeftContainer,
        RightContainer,
        PropertyPaginatorContainer,
        PropertyDetailsContainer,
        PendingLeftContainer,
        PendingRightContainer,
        RentalAnalysisContainer,
        WalletContainer,
        PackageContainer,
        TransactionPendingItem,
        ViewButton,
        AcceptButton,
        RejectButton,
        ButtonIcon,
        ImageUploadIcon,
    } = StyledComponent;

    const {
        propertyDetails,
        pendingAction,
        pendingTransactions,
        propertyPackage,
        walletDashboard,
    } = allPropertyDetails;

    const {
        name: propertyName,
        propertyType,
        unitSize,
        unitType,
        floor,
        ensure,
        unit,
        location: propertyLocation,
    } = propertyDetails;

    const {
        total,
        actions,
    } = pendingAction;

    const {
        total: transactionTotal,
        transactions,
    } = pendingTransactions;

    const {
        walletTotal,
        walletTransactions,
    } = walletDashboard;

    const propertyPackageData: propertyPackageProps[] = propertyPackage.map((item) => ({
        packageName: item.package,
        status: Number(item.status),
        subscriptionEnd: item.subscriptionEnd,
    }));

    // ! useState zone
    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: `${Translate.t('GeneralUI.BreadcrumblabelWelcomeBackUser')} ${(UserListUserInfo?.firstName) ? UserListUserInfo.firstName : ''}`,
            },
        ],
    );

    const [propertyId, setPropertyId] = useState('');

    const [showModalSetTransactionType, setShowModalSetTransactionType] = useState(0);
    const [transactionIdNumber, setTransactionIdNumber] = useState('');
    const [PMSubscriptionDate, setPMSubscriptionDate] = useState('');

    useEffect(() => {
        setTabView('dashboard');
        setBreadCrumb(
            [
                {
                    label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                    onClick: () => { NavActions.navToHome(); },
                },
                {
                    label: `${Translate.t('GeneralUI.BreadcrumblabelWelcomeBackUser')} ${(UserListUserInfo?.firstName) ? UserListUserInfo.firstName : ''}`,
                    onClick: () => { return false; },
                },
            ],
        );
    }, [UserDataSuccess]);
    useEffect(() => {
        if (displayedProperty.length > 0) {
            const selectedProperty = displayedProperty.find((item) => item.selected === true);
            if (selectedProperty) {
                getAllPropertyDetails(selectedProperty.propertyId);
                setPropertyId(selectedProperty.propertyId);
            }
        }
    }, [displayedProperty, propertyManagementTransactionStatusUpdateSuccess, propertyManagementTransactionStatusUpdateSuccessGateway]);

    useEffect(() => {
        const propertyManagementSubscription = propertyPackage.find((item) => item.package === 'propertyManagement');
        setPMSubscriptionDate(propertyManagementSubscription?.subscriptionEnd ?? '');
    }, [propertyPackage]);
    // ! functions
    function getPackageType(category: string): number {
        return PackageCategory[category as keyof typeof PackageCategory];
    }

    function getStatus(packageName: string) {
        const packageData = propertyPackageData.find((item) => item.packageName === packageName);
        if (packageData) {
            return packageData.status;
        } return 5;
    }

    const VPPackageType = getPackageType('VacantPossession');
    const DIPackageType = getPackageType('DefectInspection');
    const PMPackageType = getPackageType('PropertyManagement');
    const VMPackageType = getPackageType('VacantManagement');
    const LSPackageType = getPackageType('LeaseSale');

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const signOrRedirectToPackages = (status: number, _id: string, packageType: PackageCategory) => {
        if (status === IPropertyPackageStatusEnum.PendingSignature) {
            NavActions.navToPackagesSign(_id, packageType);
        } else {
            if (packageType === PackageCategory.VacantPossession) {
                NavActions.navToViewVacantPossessionPackages(_id);
            }
            if (packageType === PackageCategory.DefectInspection) {
                NavActions.navToViewDefectInspectionPackages(_id);
            }
            // {TEMP for future use}
            // if (packageType === PackageCategory.DesignRenovation) {

            // }
            if (packageType === PackageCategory.PropertyManagement) {
                NavActions.navToViewPropertyManagementPackages(_id);
            }
            if (packageType === PackageCategory.VacantManagement) {
                NavActions.navToViewVacantManagementPackages(_id);
            }
            if (packageType === PackageCategory.LeaseSale) {
                NavActions.navToViewLeaseAndSalePackages(_id);
            }
        }
    };
    const onClickCreatNewProperty = () => {
        setPropertyCreateModal(!isCreatePropertyModalOpen);
    };
    const onClickSetAction = (ActionType: number, transactionId: string) => {
        setTransactionIdNumber(transactionId);

        if (ActionType === PropertyManagementTransactionStatus.Accepted) {
            setPackagesPropertyManagementStatusUpdateModal(true);
            setShowModalSetTransactionType(PropertyManagementTransactionStatus.Accepted);
        }
        if (ActionType === PropertyManagementTransactionStatus.Rejected) {
            setPackagesPropertyManagementStatusUpdateModal(true);
            setShowModalSetTransactionType(PropertyManagementTransactionStatus.Rejected);
        }
    };
    const viewFileHandler = (data: ViewAllPMTransactionResponse['fileUrl']) => {
        if (data && data.length > 0) {
            setFileList(data);
            setIsFileListModalOpen(true);
        }

        return null;
    };

    const checkSubscription = () => {
        const triggerDate = dayjs(PMSubscriptionDate).subtract(1, 'months');

        console.log(triggerDate, 'triggerDate');
        if (triggerDate) {
            if (dayjs().isAfter(triggerDate)) {
                return true;
            }
            return false;
        }
        return false;
    };
    // ! main render
    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Reminder
                    reminderDate={dayjs(PMSubscriptionDate).format('DD/MM/YYYY') ?? ''}
                    isActive={checkSubscription()}
                />
                <HomeScreenContent>
                    <LeftContainer>
                        <PropertyPaginatorContainer>
                            <PropertySlider createPropertyClick={onClickCreatNewProperty} />
                            {isCreatePropertyModalOpen && (
                                <PropertyModal />
                            )}
                        </PropertyPaginatorContainer>
                        <PropertyDetailsContainer>
                            <PropertyCard
                                disableEdit
                                name={propertyName}
                                onClick={() => NavActions.navToCreateNewProperty(propertyId)}
                                type={PropertyTypeEnum[propertyType]}
                                title={PropertyTitleTypeEnum[allPropertyDetails.propertyDetails.titleType]}
                                size={unitSize && unitSize > 0 ? `${allPropertyDetails.propertyDetails.unitSize} spft` : ''}
                                unitType={unitType}
                                floor={floor && floor > 0 ? `${allPropertyDetails.propertyDetails.floor}` : ''}
                                ensure={TenureTypeEnum[ensure]}
                                unit={unit && unit.trim() !== '' ? unit : ''}
                                location={`${propertyLocation?.address1},
                                        ${propertyLocation?.address2},
                                        ${propertyLocation?.postcode} ${propertyLocation?.city},
                                        ${propertyLocation?.state}`}
                                NoData={!getAllPropertyDetailsAttempt && allPropertyDetails.propertyDetails.name === ''}
                                loading={getAllPropertyDetailsAttempt || getPropertyPaginatorAttempt}
                            />
                        </PropertyDetailsContainer>
                        <PendingLeftContainer>
                            <PendingCard
                                title='PENDING ACTIONS'
                                pendingValue={`${total}`}
                                listTitle='Pending Actions'
                                onClick={() => null}
                                loading={getAllPropertyDetailsAttempt || getPropertyPaginatorAttempt}
                                hideViewButton
                            >
                                {actions.length > 0 ? (actions.map((item) => {
                                    const { package: packageName, status } = item;
                                    const packageString = packageName.replace(/([a-z])([A-Z])/g, '$1 $2');
                                    const capitalizePackageString = packageString.charAt(0).toUpperCase() + packageString.slice(1);
                                    const statusString = IPropertyPackageStatusEnum[status].replace(/([a-z])([A-Z])/g, '$1 $2');

                                    const renderViewButton = () => {
                                        if (packageName === 'vacantPossession') {
                                            return (
                                                <ViewButton onClick={() => signOrRedirectToPackages(getStatus(packageName), propertyId, VPPackageType)}>
                                                    View
                                                </ViewButton>
                                            );
                                        }
                                        if (packageName === 'defectInspection') {
                                            return (
                                                <ViewButton onClick={() => signOrRedirectToPackages(getStatus(packageName), propertyId, DIPackageType)}>
                                                    View
                                                </ViewButton>
                                            );
                                        }
                                        if (packageName === 'propertyManagement') {
                                            return (
                                                <ViewButton onClick={() => signOrRedirectToPackages(getStatus(packageName), propertyId, PMPackageType)}>
                                                    View
                                                </ViewButton>
                                            );
                                        }

                                        return (
                                            <ViewButton onClick={() => signOrRedirectToPackages(getStatus(packageName), propertyId, VMPackageType)}>
                                                View
                                            </ViewButton>
                                        );
                                    };

                                    if (packageName === 'leaseSale') {
                                        const leaseSaleString = capitalizePackageString.split(' ').join(' & ');
                                        return (
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ flexDirection: 'column', fontFamily: `${Fonts.primary}` }}>
                                                    <p>{statusString}</p>
                                                    <p style={{ color: `${Colors.grey}`, fontSize: '14px' }}>{leaseSaleString}</p>
                                                </div>
                                                <ViewButton onClick={() => signOrRedirectToPackages(getStatus('leaseSale'), propertyId, LSPackageType)}>
                                                    View
                                                </ViewButton>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ flexDirection: 'column' }}>
                                                <p>{statusString}</p>
                                                <p style={{ color: `${Colors.grey}`, fontSize: '14px' }}>{capitalizePackageString}</p>
                                            </div>
                                            {renderViewButton()}
                                        </div>
                                    );
                                })) : ''}
                            </PendingCard>
                        </PendingLeftContainer>
                        <PendingRightContainer>
                            <PendingCard
                                title='TOTAL TRANSACTIONS ON PENDING'
                                pendingValue={`${transactionTotal}`}
                                listTitle='Pending Transactions'
                                onClick={() => NavActions.navTopropertyManagementViewDocuments(propertyId)}
                                loading={getAllPropertyDetailsAttempt || getPropertyPaginatorAttempt}
                                hideViewButton={(transactions.length === 0) || getAllPropertyDetailsAttempt}
                            >
                                {transactions.length > 0 ? (transactions.slice(0, 6).map((item) => {
                                    const { amount, description, transactionsId, fileUrl } = item;

                                    return (
                                        <TransactionPendingItem key={description + amount}>
                                            <p>{description || 'No transaction data'}</p>
                                            <p>{`RM ${amount.toLocaleString()}`}</p>
                                            <span style={{ height: 25 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px', alignItems: 'center' }}>
                                                    <ImageUploadIcon
                                                        src={icons.clip}
                                                        onClick={() => viewFileHandler(fileUrl)}
                                                        disabled={fileUrl && fileUrl.length === 0}
                                                    />
                                                    <p>{fileUrl && fileUrl.length > 0 ? fileUrl.length : 0}</p>
                                                </div>
                                                <AcceptButton onClick={() => onClickSetAction(PropertyManagementTransactionStatus.Accepted, transactionsId)}>
                                                    <ButtonIcon src={icons.CheckmarkIcon} />
                                                </AcceptButton>
                                                <RejectButton onClick={() => onClickSetAction(PropertyManagementTransactionStatus.Rejected, transactionsId)}>
                                                    <ButtonIcon src={icons.xmark} />
                                                </RejectButton>
                                            </span>
                                        </TransactionPendingItem>
                                    );
                                })) : ''}
                            </PendingCard>
                        </PendingRightContainer>
                        <RentalAnalysisContainer>
                            <RentalAnalytic
                                loading={getAllPropertyDetailsAttempt || getPropertyPaginatorAttempt}
                            />
                        </RentalAnalysisContainer>
                    </LeftContainer>
                    <RightContainer>
                        <WalletContainer>
                            <WalletStatistic
                                walletTotal={walletTotal || 0}
                                transactions={walletTransactions && walletTransactions.length > 0 ? walletTransactions : []}
                                onClick={() => NavActions.navTopropertyManagementViewDocuments(propertyId)}
                                loading={getAllPropertyDetailsAttempt || getPropertyPaginatorAttempt}
                                hideViewButton={(walletTransactions && walletTransactions.length === 0) || getAllPropertyDetailsAttempt}
                            />
                        </WalletContainer>
                        <PackageContainer>
                            <PackageCard
                                title='Property Packages'
                                propertyPackages={propertyPackageData}
                                isPriceSet={propertyPackage.length > 0}
                                VPOnClick={() => signOrRedirectToPackages(getStatus('vacantPossession'), propertyId, VPPackageType)}
                                DIOnClick={() => signOrRedirectToPackages(getStatus('defectInspection'), propertyId, DIPackageType)}
                                PMOnClick={() => signOrRedirectToPackages(getStatus('propertyManagement'), propertyId, PMPackageType)}
                                VMOnClick={() => signOrRedirectToPackages(getStatus('vacantManagement'), propertyId, VMPackageType)}
                                LSOnClick={() => signOrRedirectToPackages(getStatus('leaseSale'), propertyId, LSPackageType)}
                                loading={getAllPropertyDetailsAttempt || getPropertyPaginatorAttempt}
                            />
                        </PackageContainer>
                    </RightContainer>
                </HomeScreenContent>
            </MainContainer>
            <PendingTransactionModal
                propertyId={propertyId}
                setTransactionType={showModalSetTransactionType}
                transactionId={transactionIdNumber}
            />
            <FileListModal />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    UserListUserInfo: Selectors.getUserGetUserInfo(state),
    displayedProperty: Selectors.getPropDisplayedProperty(state),
    allPropertyDetails: Selectors.getAllPropertyDetails(state),
    getAllPropertyDetailsAttempt: Selectors.getAllPropertyDetailsAttempt(state),
    getAllPropertyDetailsFailure: Selectors.getAllPropertyDetailsFailure(state),
    getPropertyPaginatorAttempt: Selectors.getPropPropertyPaginatorAttempt(state),
    isCreatePropertyModalOpen: Selectors.setPropertyCreatePropertyModal(state),
    errorPropertyManagementTransactionStatusUpdate: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessError(state),
    propertyManagementTransactionStatusUpdateSuccess: Selectors.packagesSetPropertyManagementTransactionUpdateSuccess(state),
    propertyManagementTransactionStatusUpdateSuccessGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGateway(state),
    UserDataSuccess: Selectors.getUserGetUserInfo(state),
    fileList: Selectors.getUiGetFileList(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getPropertyPaginator: (index: number) =>
        dispatch(Actions.getPropertyPaginatorAttempt({ index })),
    getAllPropertyDetails: (propertyId: string) => dispatch(Actions.getAllPropertyDetailsAttempt({ propertyId })),
    setPropertyCreateModal: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
    setPackagesPropertyManagementStatusUpdateModal: (state: boolean) => dispatch(Actions.setPackagesPropertyManagementStatusUpdateModalOpen(state)),
    setIsFileListModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
    setFileList: (data: ViewAllPMTransactionResponse['fileUrl']) => dispatch(Actions.setFileList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
