import React, { useEffect } from 'react';

import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { IPropertyPaginator } from 'entities/property';
import { IOwnerUser, PackageCategory } from '@dm/types';

import icons from 'assets/icons';
import NavActions from 'lib/NavActions';
import StyledComponent from './styles/propertySliderStyle';

interface IPropertyList {
    propertyId: string;
    propertyName: string;
    selected: boolean;
}

export interface PropertySliderProps {
    // * get the property list for paginator
    getPropertyPaginator: (index: number) => void;
    getPropertyPaginatorAttempt: boolean;
    getPropertyPaginatorFailure: string;
    propertyPaginator: IPropertyPaginator;
    // * for the displayed property list on the paginator
    displayedProperty: IPropertyList[];
    setDisplayedProperty: (data: IPropertyPaginator['data']) => void;
    // * for menu displaying all the properties
    menuIsOpen: boolean;
    setMenuIsOpen: (state: boolean) => void;
    // * setting index for current selected property
    currentPropertyIndex: number;
    setCurrentPropertyIndex: (index: number) => void;
    // * get user info
    UserListUserInfo: IOwnerUser | undefined;
    createPropertyClick: () => void;
    style?: React.CSSProperties;
}

const PropertySlider = (props: PropertySliderProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        getPropertyPaginator,
        getPropertyPaginatorAttempt,
        getPropertyPaginatorFailure,
        propertyPaginator,
        displayedProperty,
        setDisplayedProperty,
        menuIsOpen,
        setMenuIsOpen,
        currentPropertyIndex,
        setCurrentPropertyIndex,
        UserListUserInfo,
        createPropertyClick,
    } = props;
    // * destructuring styled component
    const {
        AddIcon,
        AddPropertyButton,
        DropdownContent,
        DropdownItem,
        ViewAllPropertyButton,
        MenuIcon,
        LeftArrowButton,
        LeftIcon,
        PaginatorContainer,
        PropertyName,
        PropertyNameContainer,
        PropertyNameContainerMobile,
        RightArrowButton,
        RightIcon,
        SkeletonWrapper,
    } = StyledComponent;

    // * useEffect zone
    useEffect(() => {
        if (UserListUserInfo) {
            getPropertyPaginator(0);
        }
    }, [UserListUserInfo]);

    useEffect(() => {
        if (propertyPaginator.data.length > 0) {
            setDisplayedProperty(propertyPaginator.data);
        }
    }, [propertyPaginator]);

    // ! functions
    // * handle selecting specific property
    const handleSelectProperty = (propertyId: string, propertyIndex: number) => {
        const updatedProperty = displayedProperty.map((property) => {
            if (property.propertyId === propertyId) {
                return { ...property, selected: true };
            }
            return { ...property, selected: false };
        });
        setDisplayedProperty(updatedProperty);
        setCurrentPropertyIndex(propertyIndex);
    };
    // * handle selecting specific proerty in the dropdown
    const handleDropdownSelectProperty = (propertyId: string, propertyIndex: number) => {
        const updatedProperty = displayedProperty.map((property) => {
            if (property.propertyId === propertyId) {
                return { ...property, selected: true };
            }
            return { ...property, selected: false };
        });

        if (propertyIndex === (updatedProperty.length - 1)) {
            const lastIndex = updatedProperty.pop();
            updatedProperty.unshift(lastIndex as IPropertyList);
            setDisplayedProperty(updatedProperty);
        } else if (propertyIndex < (updatedProperty.length - 1)) {
            const currentIndex = updatedProperty.length - propertyIndex;
            for (let i = 0; i < currentIndex; i += 1) {
                updatedProperty.unshift(updatedProperty.pop() as IPropertyList);
            }
            setDisplayedProperty(updatedProperty);
            setCurrentPropertyIndex(0);
        }
        setMenuIsOpen(false);
    };
    // * handle selecting next button
    const handleSelectNextButton = () => {
        if (currentPropertyIndex === 2) {
            const shiftedProperty = [...displayedProperty];
            const shiftDisplayedData = shiftedProperty.shift();
            if (shiftDisplayedData !== undefined) {
                shiftedProperty.push(shiftDisplayedData);
                const updatedProperty = shiftedProperty.map((property) => {
                    if (property.propertyId === shiftedProperty[2].propertyId) {
                        return { ...property, selected: true };
                    }
                    return { ...property, selected: false };
                });
                setDisplayedProperty(updatedProperty);
                setCurrentPropertyIndex(2);
            }
        } else if (currentPropertyIndex === displayedProperty.length) {
            const updatedPropertyId = displayedProperty[currentPropertyIndex + 1].propertyId;
            handleSelectProperty(updatedPropertyId, currentPropertyIndex + 1);
        } else {
            if (displayedProperty.length === 1) {
                handleSelectProperty(displayedProperty[0].propertyId, 0);
            }
            const updatedPropertyId = displayedProperty[currentPropertyIndex + 1].propertyId;
            handleSelectProperty(updatedPropertyId, currentPropertyIndex + 1);
        }
    };
    // * handle selecting previous button
    const handleSelectPreviousButton = () => {
        if (currentPropertyIndex === 0) {
            const shiftedProperty = [...displayedProperty];
            const shiftDisplayedData = shiftedProperty.pop();
            if (shiftDisplayedData !== undefined) {
                shiftedProperty.splice(0, 0, shiftDisplayedData);
                const updatedProperty = shiftedProperty.map((property) => {
                    if (property.propertyId === shiftedProperty[0].propertyId) {
                        return { ...property, selected: true };
                    }
                    return { ...property, selected: false };
                });
                setDisplayedProperty(updatedProperty);
                setCurrentPropertyIndex(0);
            }
        } else if (currentPropertyIndex === displayedProperty.length - 1) {
            const updatedPropertyId = displayedProperty[currentPropertyIndex - 1].propertyId;
            handleSelectProperty(updatedPropertyId, currentPropertyIndex - 1);
        } else {
            if (displayedProperty.length === 1) {
                handleSelectProperty(displayedProperty[0].propertyId, 0);
            }
            const updatedPropertyId = displayedProperty[currentPropertyIndex - 1].propertyId;
            handleSelectProperty(updatedPropertyId, currentPropertyIndex - 1);
        }
    };
    // * handle clicking menu button
    const onClickMenuHandler = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    // ! render zone
    //* loading
    if (getPropertyPaginatorAttempt) {
        return (
            <SkeletonWrapper />
        );
    }
    // * no data or error
    if (propertyPaginator.data.length === 0 || getPropertyPaginatorFailure) {
        return (
            <PaginatorContainer>
                <LeftArrowButton>
                    <LeftIcon src={icons.RightGlyphIcon} />
                </LeftArrowButton>
                <RightArrowButton>
                    <RightIcon src={icons.RightGlyphIcon} />
                </RightArrowButton>
                <ViewAllPropertyButton>
                    <MenuIcon src={icons.MenuIcon} />
                </ViewAllPropertyButton>
                <PropertyNameContainer>
                    <span>
                        {'There is no property yet. Create some!' || getPropertyPaginatorFailure}
                    </span>
                </PropertyNameContainer>
                <AddPropertyButton onClick={createPropertyClick}>
                    <AddIcon src={icons.PlusIcon} />
                </AddPropertyButton>
            </PaginatorContainer>
        );
    }
    // * main render
    return (
        <PaginatorContainer>
            <LeftArrowButton onClick={() => handleSelectPreviousButton()}>
                <LeftIcon src={icons.RightGlyphIcon} />
            </LeftArrowButton>
            <RightArrowButton onClick={() => handleSelectNextButton()}>
                <RightIcon src={icons.RightGlyphIcon} />
            </RightArrowButton>
            <ViewAllPropertyButton onClick={() => onClickMenuHandler()}>
                <MenuIcon src={icons.MenuIcon} />
            </ViewAllPropertyButton>
            {menuIsOpen && (
                <DropdownContent>
                    {displayedProperty.map((property, index) => {
                        const { propertyId, propertyName } = property;
                        return (
                            <DropdownItem
                                key={propertyId}
                                onClick={() => handleDropdownSelectProperty(propertyId, index)}
                            >
                                {propertyName}
                            </DropdownItem>
                        );
                    })}
                </DropdownContent>
            )}
            <PropertyNameContainer>
                {displayedProperty.slice(0, 3).map((property, index) => {
                    const { propertyId, propertyName: name, selected } = property;
                    return (
                        <PropertyName
                            key={propertyId}
                            selected={selected}
                            onClick={() => handleSelectProperty(propertyId, index)}
                        >
                            {name}
                        </PropertyName>
                    );
                })}
            </PropertyNameContainer>

            <PropertyNameContainerMobile>
                {displayedProperty.map((property, index) => {
                    const { propertyId, propertyName: name, selected } = property;

                    if (selected) {
                        return (
                            <PropertyName
                                key={propertyId}
                                selected={selected}
                                onClick={() => handleSelectProperty(propertyId, index)}
                            >
                                {name}
                            </PropertyName>
                        );
                    }

                    return null;
                })}
            </PropertyNameContainerMobile>
            <AddPropertyButton onClick={createPropertyClick}>
                <AddIcon src={icons.PlusIcon} />
            </AddPropertyButton>
        </PaginatorContainer>

    );
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    getPropertyPaginatorAttempt: Selectors.getPropPropertyPaginatorAttempt(state),
    getPropertyPaginatorFailure: Selectors.getPropPropertyPaginatorFailure(state),
    propertyPaginator: Selectors.getPropPropertyPaginator(state),
    displayedProperty: Selectors.getPropDisplayedProperty(state),
    menuIsOpen: Selectors.getPropMenuIsOpen(state),
    currentPropertyIndex: Selectors.getPropCurrentPropertyIndex(state),
    UserListUserInfo: Selectors.getUserGetUserInfo(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyPaginator: (index: number) => dispatch(Actions.getPropertyPaginatorAttempt({ index })),
    setDisplayedProperty: (data: IPropertyPaginator['data']) => dispatch(Actions.setDisplayedProperty(data)),
    setMenuIsOpen: (isOpen: boolean) => dispatch(Actions.setMenuIsOpen(isOpen)),
    setCurrentPropertyIndex: (index: number) => dispatch(Actions.setCurrentPropertyIndex(index)),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(PropertySlider);
