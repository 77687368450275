import React, { useEffect, useState } from 'react';

import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { IOwnerUser, PaymentGatewayResponse, PaymentTypeEnum, PropertyManagementTransactionStatus } from '@dm/types';
import Translate from 'i18n-js';

import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import { Button, Colors } from '@dm/bigfish';

import StyledComponent from './styles/pendingTransactionModalStyle';
import Modal from './Modal';

interface PendingTransactionModalProps {
  // * received props from parent
  propertyId: string;
  setTransactionType: number;
  transactionId: string;
  // * redux props
  packagesPropertyManagementStatusUpdateModal: boolean;
  setPackagesPropertyManagementStatusUpdateModal(state: boolean): void;

  loadingPropertyManagementTransactionStatusUpdate: boolean;
    propertyManagementTransactionStatusUpdateSuccess: string;
    propertyManagementTransactionStatusUpdate(propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,): void;

    loadingPropertyManagementTransactionStatusUpdateGateway: boolean,
    errorPropertyManagementTransactionStatusUpdateGateway: string;
    propertyManagementTransactionStatusUpdateSuccessGateway: null | PaymentGatewayResponse;
    propertyManagementTransactionStatusUpdateGateway(propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,): void;

    loadingUserData: boolean;
    errorUserData: string;
    UserDataSuccess: IOwnerUser | undefined;

}

const PendingTransactionModal = (props: PendingTransactionModalProps):JSX.Element => {
    // ! destructuring zone
    const {
        propertyId,
        setTransactionType,
        transactionId,
        packagesPropertyManagementStatusUpdateModal,
        setPackagesPropertyManagementStatusUpdateModal,
        loadingPropertyManagementTransactionStatusUpdate,
        propertyManagementTransactionStatusUpdateSuccess,
        propertyManagementTransactionStatusUpdate,
        loadingPropertyManagementTransactionStatusUpdateGateway,
        errorPropertyManagementTransactionStatusUpdateGateway,
        propertyManagementTransactionStatusUpdateSuccessGateway,
        propertyManagementTransactionStatusUpdateGateway,
        loadingUserData,
        errorUserData,
        UserDataSuccess,
    } = props;

    const {
        ModalContent,
        ModalContentMainContainer,
        ModalContentContainer,
        PaymentMethodText,
        TransactionStatusUpdteButtonMainContainer,
        StyledMainButton,
        ButtonContainer,
        UploadDocumentText,
    } = StyledComponent;

    // ! useState zone

    const [propertyManagementPaymentType, setpropertyManagementPaymentType] = useState<number | null >(null);

    const [bankName, setBankName] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [paymentMethodChoosed, setPaymentMethodChoosed] = useState('');

    // ! useEffect zone
    useEffect(() => {
        setpropertyManagementPaymentType(null);
    }, [propertyManagementTransactionStatusUpdateSuccess, propertyManagementTransactionStatusUpdateSuccessGateway]);

    useEffect(() => {
        if (UserDataSuccess) {
            setBankName(UserDataSuccess.financialInfo?.bankName || '');
            setBankNumber(UserDataSuccess.financialInfo?.bankAccNo || '');
            setAccountName(`${UserDataSuccess.firstName} ${UserDataSuccess.lastName} `);
        }
    }, [UserDataSuccess]);

    // ! functions
    // * function to check payment method in the modal
    const checkPaymentMethodChoosed = (name: string) => {
        setPaymentMethodChoosed(name);
    };
    // * function to confirm payment in the modal
    const confirmPayment = (actionType: string) => {
        if (propertyManagementPaymentType === null && actionType === 'approve') {
            toast.error(Translate.t('Packages.PropertyManagement.NoPayment'));
        } else {
            if (actionType === 'approve') {
                if (propertyManagementPaymentType === PaymentTypeEnum.PaymentGateway) {
                    propertyManagementTransactionStatusUpdateGateway(
                        propertyId,
                        transactionId,
                        propertyManagementPaymentType,
                        PropertyManagementTransactionStatus.Accepted,
                        bankName,
                        bankNumber,
                        accountName,
                    );
                }
            }
            if (propertyManagementPaymentType === PaymentTypeEnum.Wallet) {
                propertyManagementTransactionStatusUpdate(
                    propertyId,
                    transactionId,
                    propertyManagementPaymentType,
                    PropertyManagementTransactionStatus.Accepted,
                    bankName,
                    bankNumber,
                    accountName,
                );
            }

            if (propertyManagementPaymentType === PaymentTypeEnum.ManualBankTransfer) {
                propertyManagementTransactionStatusUpdateGateway(
                    propertyId,
                    transactionId,
                    propertyManagementPaymentType,
                    PropertyManagementTransactionStatus.Accepted,
                    bankName,
                    bankNumber,
                    accountName,
                );
            }

            if (actionType === 'reject') {
                propertyManagementTransactionStatusUpdateGateway(
                    propertyId,
                    transactionId,
                    PaymentTypeEnum.Wallet,
                    PropertyManagementTransactionStatus.Rejected,
                    bankName,
                    bankNumber,
                    accountName,
                );
            }
        }
    };

    // ! main render
    return (
        <Modal
            show={packagesPropertyManagementStatusUpdateModal}
            closeCursor
            width='600px'
            height='300px'
            onClickClose={() => {
                setPackagesPropertyManagementStatusUpdateModal(false);
            }}
        >
            <ModalContent>
                {setTransactionType === PropertyManagementTransactionStatus.Accepted ? (
                    <ModalContentMainContainer>
                        <h4>{Translate.t('Packages.PropertyManagement.SelectPaymentMethod')}</h4>
                        <TransactionStatusUpdteButtonMainContainer>
                            <ButtonContainer>
                                <StyledMainButton
                                    onClick={() => [setpropertyManagementPaymentType(PaymentTypeEnum.Wallet), checkPaymentMethodChoosed('wallet')]}
                                >
                                    <UploadDocumentText>
                                        <h5>{Translate.t('Packages.PropertyManagement.Wallet')}</h5>
                                    </UploadDocumentText>
                                </StyledMainButton>
                            </ButtonContainer>
                        </TransactionStatusUpdteButtonMainContainer>
                        {paymentMethodChoosed !== '' && (
                            <div>
                                <PaymentMethodText>
                                    {Translate.t('Packages.PropertyManagement.PaymentMethod')}
                                    {' '}
                                    {paymentMethodChoosed}
                                </PaymentMethodText>
                            </div>
                        )}
                        {(loadingPropertyManagementTransactionStatusUpdate || loadingPropertyManagementTransactionStatusUpdateGateway) && (
                            <Oval
                                height={50}
                                width={50}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        )}
                        <ModalContentContainer>
                            <Button
                                label={Translate.t('Packages.PropertyManagement.Confirm')}
                                disabled={loadingPropertyManagementTransactionStatusUpdate}
                                onClick={() => [confirmPayment('approve'), checkPaymentMethodChoosed('')]}
                            />
                        </ModalContentContainer>
                    </ModalContentMainContainer>
                )
                    : (
                        <ModalContentMainContainer>
                            <h4>{Translate.t('Packages.PropertyManagement.ApproveRejection')}</h4>
                            {loadingPropertyManagementTransactionStatusUpdate && (
                                <Oval
                                    height={100}
                                    width={100}
                                    color={Colors.primary}
                                    secondaryColor={Colors.primary}
                                />
                            )}
                            <ModalContentContainer>
                                <Button
                                    label={Translate.t('Packages.PropertyManagement.Confirm')}
                                    disabled={loadingPropertyManagementTransactionStatusUpdate}
                                    onClick={() => [confirmPayment('reject'), checkPaymentMethodChoosed('')]}
                                />
                            </ModalContentContainer>
                        </ModalContentMainContainer>
                    )}
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    loadingUserInfo: Selectors.getUserGetUserInfoAttempt(state),
    errorUserInfo: Selectors.getUserGetUserInfoFailure(state),
    UserListUserInfo: Selectors.getUserGetUserInfo(state),
    displayedProperty: Selectors.getPropDisplayedProperty(state),
    allPropertyDetails: Selectors.getAllPropertyDetails(state),
    getAllPropertyDetailsAttempt: Selectors.getAllPropertyDetailsAttempt(state),
    getAllPropertyDetailsFailure: Selectors.getAllPropertyDetailsFailure(state),
    getPropertyPaginatorAttempt: Selectors.getPropPropertyPaginatorAttempt(state),
    isCreatePropertyModalOpen: Selectors.setPropertyCreatePropertyModal(state),
    loadingPropertyManagementTransactionStatusUpdate: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessAttempting(state),
    errorPropertyManagementTransactionStatusUpdate: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessError(state),
    propertyManagementTransactionStatusUpdateSuccess: Selectors.packagesSetPropertyManagementTransactionUpdateSuccess(state),
    loadingPropertyManagementTransactionStatusUpdateGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGatewayAttempting(state),
    errorPropertyManagementTransactionStatusUpdateGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGatewayError(state),
    propertyManagementTransactionStatusUpdateSuccessGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGateway(state),
    packagesPropertyManagementStatusUpdateModal: Selectors.packagesSetpackagesPropertyManagementStatusUpdateModal(state),
    loadingUserData: Selectors.getUserGetUserInfoAttempt(state),
    errorUserData: Selectors.getUserGetUserInfoFailure(state),
    UserDataSuccess: Selectors.getUserGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    getPropertyPaginator: (index: number) =>
        dispatch(Actions.getPropertyPaginatorAttempt({ index })),
    getAllPropertyDetails: (propertyId: string) => dispatch(Actions.getAllPropertyDetailsAttempt({ propertyId })),
    setPropertyCreateModal: (state: boolean) => dispatch(Actions.propertyCreatePropertyModal(state)),
    setPackagesPropertyManagementStatusUpdateModal: (state: boolean) => dispatch(Actions.setPackagesPropertyManagementStatusUpdateModalOpen(state)),
    propertyManagementTransactionStatusUpdate: (
        propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,
    ) =>
        dispatch(Actions.packagesSetPropertyManagementPackageTransactionAttempt({ propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName })),

    propertyManagementTransactionStatusUpdateGateway: (
        propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,
    ) =>
        dispatch(Actions.packagesSetPropertyManagementPackageTransactionGatewayAttempt({ propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingTransactionModal);
