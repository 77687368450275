import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Oval } from 'react-loader-spinner';
import Translate from 'lib/Translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BreadCrumb, Colors, Fonts, TabView } from '@dm/bigfish';
import { Category, IFile, IPropertyManagement, SortByEnum } from '@dm/types';

import NavActions from 'lib/NavActions';

import MainContainer from 'components/MainContainer';

import InlineSelectionInput from 'components/InlineSelectionInputViewDocument';

import ButtonInfoPackages from 'components/ButtonInfoPackages';
import media from 'lib/Breakpoints';
import PackagesListCard from '../PackagesListCard';
import DocumentImagesModal from '../DocumentImagesModal';
import SupportInfo from '../../../components/SupportInfo';
import Icons from '../../../assets/icons';

interface PropertyManagementViewDocumentUploadedProps {
    loadingPropertyManagementDocumentData: boolean;
    errorPropertyManagementDocumentData: string;
    propertyManagementDocumentData: IPropertyManagement | null;
    packagesDataSort(category: number, propertyId: string, sort: number): void;
    setTabView: (tabView: string) => void;
}

interface NavParams {
    propertyIndividualId: string;
}

const PropertyManagementViewDocumentUploaded: FunctionComponent<PropertyManagementViewDocumentUploadedProps> = (props: PropertyManagementViewDocumentUploadedProps) => {
    const {
        loadingPropertyManagementDocumentData,
        errorPropertyManagementDocumentData,
        propertyManagementDocumentData,
        packagesDataSort,
        setTabView,
    } = props;

    const [selectedDocuments, setSelectedDocuments] = useState<IFile[]>([]);

    const [propertyManagementDocumentDataList, setPropertyManagementPossesionDocumentList] = useState<IFile[]>([]);
    const [dateOrder, setDateOrder] = useState<SortByEnum>(SortByEnum.createdAtDesc);

    const [homeScreenTabView, setHomeScreenTabView] = useState('');

    const useLocationstate = useLocation();
    const { propertyIndividualId } = useLocationstate.state as NavParams;

    const [tabViewData] = useState([
        {
            text: Translate.t('GeneralUI.all'),
            onClickTabView: () => {
                setHomeScreenTabView(Translate.t('GeneralUI.all'));
            },
        },
    ]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelViewDocuments'),
                onClick: () => { return false; },
            },
        ],
    );

    interface IOption {
        id: string | number,
        description: string | number,
    }

    const dateData: IOption[] = [{
        id: 0,
        description: Translate.t('Packages.ViewDocuments.DateDecending'),
    },
    {
        id: 1,
        description: Translate.t('Packages.ViewDocuments.DateAscending'),
    }];

    const alphabeticalOrder: IOption[] = [
        {
            id: 2,
            description: Translate.t('Packages.ViewDocuments.NameAscendingOrder'),
        }, {
            id: 3,
            description: Translate.t('Packages.ViewDocuments.NameDecendingOrder'),
        },
    ];

    const listHeaders: string[] = [Translate.t('Packages.ViewDocuments.FileName'), Translate.t('Packages.ViewDocuments.DateUploaded'), Translate.t('Packages.ViewDocuments.Actions')];

    useEffect(() => {
        packagesDataSort(Category.PropertyManagement, propertyIndividualId, dateOrder);
        setHomeScreenTabView(Translate.t('GeneralUI.all'));
    }, [dateOrder]);

    useEffect(() => {
        if (propertyManagementDocumentData) {
            setPropertyManagementPossesionDocumentList(propertyManagementDocumentData.propertyManagementDocuments);
        }
    }, [propertyManagementDocumentData]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const renderList = () => {
        if (loadingPropertyManagementDocumentData) {
            return (
                <tr>
                    <td id='loading' colSpan={3}>
                        <div>
                            <Oval
                                height={250}
                                width={250}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        if (!propertyManagementDocumentDataList.length) {
            return (
                <tr>
                    <td colSpan={3}>
                        {Translate.t('Packages.ViewDocuments.NoData')}
                    </td>
                </tr>
            );
        }

        return propertyManagementDocumentDataList.map((item, index) => {
            if (propertyManagementDocumentData?.propertyManagementDocuments.length) {
                const { propertyManagementDocuments } = propertyManagementDocumentData;
                const uniquekey = index + Math.random();

                return (
                    <tr>
                        <PackagesListCard
                            key={uniquekey}
                            id={index}
                            propertyName={(propertyManagementDocuments[index].name && propertyManagementDocuments[index].name === undefined) ? '' : propertyManagementDocuments[index].name ?? ''}
                            documents={propertyManagementDocumentDataList}
                            setSelectedDocuments={setSelectedDocuments}
                        />
                    </tr>
                );
            } return true;
        });
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Packages.ViewDocuments.Back')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <InfoContainerMain>
                    <LeftContainer>
                        <InfoContainer>
                            <TabViewContainer>
                                <TabView data={tabViewData} selectedTabView={homeScreenTabView} />
                            </TabViewContainer>
                            <TitleMessageContainer>{Translate.t('Packages.ViewDocuments.TitleMessage')}</TitleMessageContainer>
                            <FiltersContainer>

                                <FilterTitleBold>{Translate.t('Packages.ViewDocuments.FilterPropertyManagement')}</FilterTitleBold>

                                <FiltersInputContainer>
                                    <InlineSelectionInput
                                        data={dateData}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}

                                    />
                                    <InlineSelectionInput
                                        data={alphabeticalOrder}
                                        defaultValue={SortByEnum.updatedAtDesc.toString()}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}

                                    />
                                </FiltersInputContainer>
                            </FiltersContainer>
                            <ReportTableContainer>
                                <ReportTable>
                                    <tr id='header'>
                                        {listHeaders.map((item) => {
                                            return (
                                                <th
                                                    key={`header-${item}`}
                                                >
                                                    {item}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    {renderList()}
                                </ReportTable>
                            </ReportTableContainer>
                        </InfoContainer>
                    </LeftContainer>

                    <RightContainer>
                        <SupportInfo />
                        <NavigationContainer>
                            <QuickNavigation>{Translate.t('Packages.PackagesCard.QuickNavigation')}</QuickNavigation>
                            <QuickNavigationDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</QuickNavigationDescription>
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewVacantPossessionPackages(propertyIndividualId)} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewDefectInspectionPackages(propertyIndividualId)} />
                            {/* {FEATURE QUICK NAVIGATION TO BE USED IN FUTURE} */}
                            {/* <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} rightIconSrc={Icons.RightArrow} onclick={() => undefined} /> */}
                            <ButtonInfoPackagesStyled desc='Vacant Management' rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewVacantManagementPackages(propertyIndividualId)} />
                        </NavigationContainer>
                    </RightContainer>

                </InfoContainerMain>

            </MainContainer>
            <DocumentImagesModal
                documentData={selectedDocuments}
            />

        </>
    );
};

const FilterTitleBold = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
`;

const TitleMessageContainer = styled.p`
    margin-bottom: 40px;
`;

const HeaderText = styled.h4`
    justify-self: center;
`;

const InfoContainerMain = styled.div`
    display: flex;
    height: 100%;
    width: 100%;

    ${media.sm`
        flex-direction: column;
    `}
`;

const LeftContainer = styled.div`
    display: flex;
    width: 70%;
    height: 100%;

    ${media.sm`
        flex-direction: column;
        width: 100%;
    `}
`;

const Loading = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

`;

const RightContainer = styled.div`
    margin-top: 10%;
    width: 40%;
    padding-left: 10px;
    padding-right: 20px;

    ${media.sm`
        width: 100%;
        padding-right: 10px;
    `}
`;

const QuickNavigation = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const QuickNavigationDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const InfoContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
`;

const ButtonInfoPackagesStyled = styled(ButtonInfoPackages)`
    margin-top: 12px;
`;

const TabViewContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const HeadersContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 30% 30% 40% ;
    font-family: ${Fonts.primary};
    color: #696969;
    column-gap: 10px;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FiltersInputContainer = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const ListContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    height: 100%;
`;

const ReportTableContainer = styled.div`
    width: 100%;
    padding-block: 20px;

    ${media.sm`
        overflow-x: auto;
    `}
`;

const ReportTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    ${media.sm`
        width: max-content;
    `}

    td,
    th {
        padding: 20px;
        text-align: center;

        /* ${media.sm`
            width: 200px;
        `} */
    }

    #header {
        background-color: ${Colors.primaryLight};

        th:first-of-type {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        th:last-of-type {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    #loading > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        ${media.sm`
            width: 86vw;
        `}
    }

    #actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    tr:nth-child(odd) {
        background-color: ${Colors.greyLightest};

        td:first-of-type {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        td:last-of-type {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    loadingPropertyManagementDocumentData: Selectors.getPackagesIPropertyManagementDocumentViewDataDetailsSortAttempt(state),
    errorPropertyManagementDocumentData: Selectors.getPackagesIPropertyManagementDocumentViewDataDetailsSortFailure(state),
    propertyManagementDocumentData: Selectors.getIPropertyManagementDataDocumentViewDetailsSort(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    packagesDataSort: (category: number, propertyId: string, sort: number) =>
        dispatch(Actions.packagesGetIPropertyManagementDataSortUploadedDocumentAttempt({ category, propertyId, sort })),
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManagementViewDocumentUploaded);
