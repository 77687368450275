import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';
import { TailSpin } from 'react-loader-spinner';
import { Category, IPackagePrice, IPropertyPackageStatusEnum } from '@dm/types';
import { toast } from 'react-toastify';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';

import { Colors, Button } from '@dm/bigfish';
import { connect } from 'react-redux';
import NavActions from 'lib/NavActions';

import { IPropertyIndividual } from 'entities/property';
import PointsDropdown from './PointsDropDown';

export interface IPackagePoints {
    text: string;
}

export interface PackagesCardLoadProps {
    packagesPointsVacantPossessionPoints: IPackagePoints[];
    packagesPointsDesignRenovation: IPackagePoints[];
    packagesPointsDefectInspection: IPackagePoints[];
    packagesPointsPropertyManagement: IPackagePoints[];
    packagesPointsVacantManagement: IPackagePoints[]
    getPackagesPricing(propertyId: string): void;
    focused?: boolean;
    focusBuyAll: boolean;
    disablepackage?: boolean;
    packagesList: IPackagePrice;
    packagesInfo: IPropertyIndividual;
    buyType: string;
    getIndividualpackagesInfo: (id: string) => void;
    setBuyAll: (state: boolean) => void;
    setBuyingPackagesSeparately: (state: boolean) => void;
    setBuyType: (state: string) => void;
}

interface NavParams {
    packageId: string;
}

const PackagesCardLoad = (props: PackagesCardLoadProps) : JSX.Element => {
    const { setBuyType, buyType, focusBuyAll, packagesList, getPackagesPricing, packagesPointsVacantPossessionPoints, packagesPointsDesignRenovation, packagesPointsDefectInspection, packagesPointsPropertyManagement, packagesPointsVacantManagement, getIndividualpackagesInfo, packagesInfo, setBuyAll, setBuyingPackagesSeparately } = props;
    const [vacantPossessionFocus, setVacantPossessionFocus] = useState(false);
    const [designRenovationFocus, setDesignRenovationFocus] = useState(false);
    const [defectInspectionFocus, setDefectInspectionFocus] = useState(false);
    const [managementFocus, setManagementFocus] = useState(false);
    const [leaseFocus, setLeaseFocus] = useState(false);
    const [vacantmanagementFocus, setVacantManagementFocus] = useState(false);

    const [disableBuy, setDisableBuy] = useState(false);
    const [buyVacantPossession, setBuyVacantPossession] = useState(false);
    const [buyDefectInspection, setBuyDefectInspection] = useState(false);

    const [vacantPossessionPoints] = useState(packagesPointsVacantPossessionPoints);
    const [designnRenovationPoints] = useState(packagesPointsDesignRenovation);
    const [defectInspectionPoints] = useState(packagesPointsDefectInspection);
    const [propertyManagementpoints] = useState(packagesPointsPropertyManagement);
    const [VacantManagementpoints] = useState(packagesPointsVacantManagement);

    const [packagesSelected, setPackagesselected] = useState<number[]>([]); // data set by push method
    const [priceData, setPriceData] = useState(0);
    const { defectInspection, designRenovation, leaseSale, propertyManagement, vacantPossession, vacantManagement } = packagesList;
    const useLocationstate = useLocation();

    const { packageId } = useLocationstate.state as NavParams;
    useEffect(() => {
        getPackagesPricing(packageId);
        if (packageId) {
            getIndividualpackagesInfo(packageId);
        }
    }, []);

    useEffect(() => {
        if (packagesList) setPricing();
    }, [packagesList?.defectInspection, packagesList?.designRenovation, packagesList?.leaseSale]);

    useEffect(() => {
        if ((focusBuyAll === true) && (buyType === 'buy all')) {
            buyAllFunction();
        }

        if ((focusBuyAll === false) && (buyType === 'cancel all')) {
            cancelAllFunction();
        }
    }, [focusBuyAll]);

    const cancelAllFunction = () => {
        setPriceData(0);
        packagesSelected.splice(0);
    };

    const buyAllFunction = () => {
        setPriceData(0);
        packagesSelected.splice(0);
        if (packagesInfo) {
            let buyAllVacantPosession = 0;
            let buyAllDefectInspection = 0;
            let buyAllDesignRenovation = 0;
            let buyAllPropertyManagement = 0;
            let buyAllVacantManagement = 0;

            if ((packagesInfo.packages?.vacantPossession?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.vacantPossession?.status !== IPropertyPackageStatusEnum.PendingSignature) && (packagesInfo.packages?.vacantPossession?.price !== 0) && (packagesInfo.packages?.vacantPossession?.price !== undefined)) {
                packagesSelected.push(Category.VacantPossession);
                buyAllVacantPosession = packagesInfo.packages.vacantPossession.price;
                if (vacantPossessionFocus === true) {
                    setVacantPossessionFocus(false);
                }
            }
            if ((packagesInfo.packages?.defectInspection?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.defectInspection?.status !== IPropertyPackageStatusEnum.PendingSignature) && (packagesInfo.packages?.defectInspection?.price !== 0) && (packagesInfo.packages?.defectInspection?.price !== undefined)) {
                packagesSelected.push(Category.DefectInspection);
                buyAllDefectInspection = packagesInfo.packages.defectInspection.price;
                if (defectInspectionFocus) {
                    setDefectInspectionFocus(false);
                }
            }
            if ((packagesInfo.packages?.designRenovation?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.designRenovation?.status !== IPropertyPackageStatusEnum.PendingSignature) && (packagesInfo.packages?.designRenovation?.price !== 0) && (packagesInfo.packages?.designRenovation?.price !== undefined)) {
                packagesSelected.push(Category.DesignRenovation);
                buyAllDesignRenovation = packagesInfo.packages.designRenovation.price;
                if (designRenovationFocus === true) {
                    setDesignRenovationFocus(false);
                }
            }
            if ((packagesInfo.packages?.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.propertyManagement?.status !== IPropertyPackageStatusEnum.PendingSignature) && (packagesInfo.packages?.propertyManagement?.price !== 0) && (packagesInfo.packages?.propertyManagement?.price !== undefined)) {
                packagesSelected.push(Category.PropertyManagement);
                buyAllPropertyManagement = packagesInfo.packages.propertyManagement.price;
                if (managementFocus === true) {
                    setManagementFocus(false);
                }
            }
            if ((packagesInfo.packages?.vacantManagement?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.vacantManagement?.status !== IPropertyPackageStatusEnum.PendingSignature) && (packagesInfo.packages?.vacantManagement?.price !== 0) && (packagesInfo.packages?.vacantManagement?.price !== undefined)) {
                packagesSelected.push(Category.VacantManagement);
                buyAllVacantManagement = packagesInfo.packages.vacantManagement.price;
                if (vacantmanagementFocus === true) {
                    setVacantManagementFocus(false);
                }
            }
            if (buyDefectInspection === true) {
                setBuyDefectInspection(false);
            }
            if (buyVacantPossession === true) {
                setBuyVacantPossession(false);
            }
            setPriceData(buyAllVacantPosession + buyAllDefectInspection + buyAllDesignRenovation + buyAllPropertyManagement + buyAllVacantManagement);
        }
    };

    const setCardFocusedOrNot = (category: number) => {
        if (focusBuyAll === true) {
            setBuyAll(false);
            packagesSelected.splice(0);
            if (category === Category.VacantPossession) {
                setVacantPossessionFocus(!vacantPossessionFocus);
                packagesSelected.push(Category.VacantPossession);
                setPriceData(vacantPossession);
            }

            if (category === Category.DefectInspection) {
                if ((packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active)) {
                    setDefectInspectionFocus(!defectInspectionFocus);
                    packagesSelected.push(Category.DefectInspection);
                    setPriceData(defectInspection);
                } else {
                    setDefectInspectionFocus(!defectInspectionFocus);
                    packagesSelected.push(Category.DefectInspection, Category.VacantPossession);
                    setPriceData(vacantPossession + defectInspection);
                    setVacantPossessionFocus(!vacantPossessionFocus);
                    setBuyVacantPossession(true);
                    toast.error(Translate.t('Packages.BuyDefectMessage'));
                }
            }

            if (category === Category.DesignRenovation) {
                setDesignRenovationFocus(!designRenovationFocus);
                packagesSelected.push(Category.DesignRenovation);
                setPriceData(designRenovation);
            }
            if (category === Category.PropertyManagement) {
                setManagementFocus(!managementFocus);
                packagesSelected.push(Category.PropertyManagement);
                setPriceData(propertyManagement);
            }
            if (category === Category.LeaseSale) {
                setLeaseFocus(!leaseFocus);
                packagesSelected.push(Category.LeaseSale);
                setPriceData(leaseSale);
            }
            if (category === Category.VacantManagement) {
                setVacantManagementFocus(!vacantmanagementFocus);
                packagesSelected.push(Category.VacantManagement);
                setPriceData(vacantManagement);
            }

            setBuyingPackagesSeparately(true);
        }

        if (focusBuyAll === false) {
            setBuyingPackagesSeparately(true);
            if (defectInspectionFocus === true) {
                if (category === Category.VacantPossession) {
                    setBuyDefectInspection(true);
                    setBuyVacantPossession(false);
                    toast.error(Translate.t('Packages.BuyDefectMessage'));
                }
            } else if (category === Category.VacantPossession) {
                setVacantPossessionFocus(!vacantPossessionFocus);
                if (vacantPossessionFocus === false) {
                    packagesSelected.push(Category.VacantPossession);
                    setPriceData(priceData + vacantPossession);
                    setBuyDefectInspection(false);
                }
                if (vacantPossessionFocus === true) {
                    const RemovePackageVacantPossession = packagesSelected.indexOf(Category.VacantPossession);
                    packagesSelected.splice(RemovePackageVacantPossession, 1);
                    setPriceData(priceData - vacantPossession);
                    setBuyDefectInspection(false);
                }
            }

            if (category === Category.DefectInspection) {
                if ((packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active)) {
                    setDefectInspectionFocus(!defectInspectionFocus);
                    if (defectInspectionFocus === false) {
                        packagesSelected.push(Category.DefectInspection);
                        setPriceData(priceData + defectInspection);
                    }
                    if (defectInspectionFocus === true) {
                        const RemoveDefectInspection = packagesSelected.indexOf(Category.DefectInspection);
                        packagesSelected.splice(RemoveDefectInspection, 1);
                        setPriceData(priceData - defectInspection);
                    }
                } else if (category === Category.DefectInspection) {
                    setDefectInspectionFocus(!defectInspectionFocus);
                    if (defectInspectionFocus === false) {
                        if (packagesSelected.some(item => item === Category.VacantPossession)) {
                            packagesSelected.push(Category.DefectInspection);
                            setPriceData(priceData + defectInspection);
                        } else {
                            setVacantPossessionFocus(!vacantPossessionFocus);
                            setBuyVacantPossession(true);
                            toast.error(Translate.t('Packages.BuyDefectMessage'));
                            if (vacantPossessionFocus === false) {
                                packagesSelected.push(Category.DefectInspection, Category.VacantPossession);
                                setPriceData(priceData + vacantPossession + defectInspection);
                            }
                            if (vacantPossessionFocus === true) {
                                const RemovePackageVacantPossession = packagesSelected.indexOf(Category.VacantPossession);
                                packagesSelected.splice(RemovePackageVacantPossession, 1);
                                setPriceData(priceData - vacantPossession);
                            }
                        }
                    }
                    if (defectInspectionFocus === true) {
                        if (packagesSelected.some(item => item === Category.VacantPossession)) {
                            setVacantPossessionFocus(!vacantPossessionFocus);
                            const RemoveDefectInspection = packagesSelected.indexOf(Category.DefectInspection);
                            packagesSelected.splice(RemoveDefectInspection, 1);
                            setBuyVacantPossession(false);
                            setBuyDefectInspection(false);
                            const RemovePackageVacantPossession = packagesSelected.indexOf(Category.VacantPossession);
                            packagesSelected.splice(RemovePackageVacantPossession, 1);
                            setPriceData(priceData - defectInspection - vacantPossession);
                        } else {
                            const RemoveDefectInspection = packagesSelected.indexOf(Category.DefectInspection);
                            packagesSelected.splice(RemoveDefectInspection, 1);
                            setPriceData(priceData - defectInspection);
                            setBuyVacantPossession(false);
                        }
                    }
                }
            }

            if (category === Category.DesignRenovation) {
                setDesignRenovationFocus(!designRenovationFocus);
                if (designRenovationFocus === false) {
                    packagesSelected.push(Category.DesignRenovation);
                    setPriceData(priceData + designRenovation);
                }
                if (designRenovationFocus === true) {
                    const RemoveDesignRenovationPackage = packagesSelected.indexOf(Category.DesignRenovation);
                    packagesSelected.splice(RemoveDesignRenovationPackage, 1); // the reason add 1 behind is to specify how many elements to delete after starting at particular index
                    setPriceData(priceData - designRenovation);
                }
            }

            if (category === Category.PropertyManagement) {
                setManagementFocus(!managementFocus);
                if (managementFocus === false) {
                    packagesSelected.push(Category.PropertyManagement);
                    setPriceData(priceData + propertyManagement);
                }
                if (managementFocus === true) {
                    const RemovePackagePropertyManagement = packagesSelected.indexOf(Category.PropertyManagement);
                    packagesSelected.splice(RemovePackagePropertyManagement, 1);
                    setPriceData(priceData - propertyManagement);
                }
            }

            if (category === Category.LeaseSale) {
                setLeaseFocus(!leaseFocus);
                if (leaseFocus === false) {
                    packagesSelected.push(Category.LeaseSale);
                    setPriceData(priceData + leaseSale);
                }
                if (leaseFocus === true) {
                    const RemovePackageLeaseSale = packagesSelected.indexOf(Category.LeaseSale);
                    packagesSelected.splice(RemovePackageLeaseSale, 1);
                    setPriceData(priceData - leaseSale);
                }
            }

            if (category === Category.VacantManagement) {
                setVacantManagementFocus(!vacantmanagementFocus);
                if (vacantmanagementFocus === false) {
                    packagesSelected.push(Category.VacantManagement);
                    setPriceData(priceData + vacantManagement);
                }
                if (vacantmanagementFocus === true) {
                    const RemovePackageVacantManagement = packagesSelected.indexOf(Category.VacantManagement);
                    packagesSelected.splice(RemovePackageVacantManagement, 1);
                    setPriceData(priceData - vacantManagement);
                }
            }
        }
    };

    const setPackagesChoosed = () => {
        NavActions.navToPaymentOption(packageId, packagesSelected, priceData, packageId);
    };

    const setPricing = () => {
        if (packagesList) {
            if ((!defectInspection || defectInspection === 0) && (!designRenovation || designRenovation === 0) && (!propertyManagement || propertyManagement === 0) && (vacantPossession === 0 || !vacantPossession)
            && (!leaseSale || leaseSale === 0)
            ) {
                setDisableBuy(true);
            } else {
                setDisableBuy(false);
            }
        }
    };

    return (
        <div>
            <CardContainer>
                <PackagesCardMainContainer onClick={() => setCardFocusedOrNot(Category.VacantPossession)} focused={vacantPossessionFocus} disablepackage={vacantPossession === 0 || !vacantPossession || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.PendingSignature} focusBuyAll={focusBuyAll}>
                    <CardTitleContainer>
                        <CardTitleMain focusBuyAll={focusBuyAll} focused={vacantPossessionFocus}>{Translate.t('Packages.PackagesCard.CardTitleVacantPossession')}</CardTitleMain>
                        <CardTitleSubtitleMain focusBuyAll={focusBuyAll} focused={vacantPossessionFocus}>{Translate.t('Packages.PackagesCard.CardSubTitleVacantPossession')}</CardTitleSubtitleMain>
                    </CardTitleContainer>
                    <CardTitlePricingSubContainer>
                        <div>
                            {vacantPossession === 0 || !vacantPossession || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active
                                ? (
                                    <div>
                                        {(packagesInfo.packages?.vacantPossession?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.vacantPossession?.price) && <CardTitlePricingContainerMain focused={vacantPossessionFocus}><TailSpin color={Colors.primary} height={20} width={20} /></CardTitlePricingContainerMain>}
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={vacantPossessionFocus}>{packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active ? <p>{Translate.t('Packages.PackagesCard.PackageBought')}</p> : <p>{Translate.t('Packages.PackagesCard.PackageNotAvailable')}</p>}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )

                                : (
                                    <div>
                                        <CardTitlePricingContainerMain focusBuyAll={focusBuyAll} focused={vacantPossessionFocus}>{`Rm ${vacantPossession}`}</CardTitlePricingContainerMain>
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={vacantPossessionFocus}>{Translate.t('Packages.PackagesCard.CardMonthyPricing')}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )}
                        </div>
                    </CardTitlePricingSubContainer>
                    <CardButtonMainContainer>
                        <CardButtonContainer secondary={focusBuyAll} disabled={!vacantPossession || vacantPossession === 0 || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.PendingSignature} label={packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.PendingSignature ? Translate.t('Packages.PackagesCard.PackagesPending') : Translate.t('Packages.PackagesCard.PackagesBuy')} />
                    </CardButtonMainContainer>
                    {(packagesInfo.packages?.vacantPossession?.price && packagesInfo.packages.vacantPossession.status !== IPropertyPackageStatusEnum.Active) && <PointsDropdown packagespoints={vacantPossessionPoints} focused={vacantPossessionFocus} />}
                </PackagesCardMainContainer>

                <PackagesCardMainContainer onClick={() => setCardFocusedOrNot(Category.DefectInspection)} focused={defectInspectionFocus} disablepackage={!defectInspection || defectInspection === 0 || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.PendingSignature} focusBuyAll={focusBuyAll}>
                    <CardTitleContainer>
                        <CardTitleMain focusBuyAll={focusBuyAll} focused={defectInspectionFocus}>{Translate.t('Packages.PackagesCard.CardTitleDefectInspection')}</CardTitleMain>
                        <CardTitleSubtitleMain focusBuyAll={focusBuyAll} focused={defectInspectionFocus}>{Translate.t('Packages.PackagesCard.CardSubTitleDefectInspection')}</CardTitleSubtitleMain>
                    </CardTitleContainer>
                    <CardTitlePricingSubContainer>
                        <div>
                            {defectInspection === 0 || !defectInspection || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.Active
                                ? (
                                    <div>
                                        {(packagesInfo.packages?.defectInspection?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.defectInspection?.price) && <CardTitlePricingContainerMain focused={defectInspectionFocus}><TailSpin color={Colors.primary} height={20} width={20} /></CardTitlePricingContainerMain>}
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={defectInspectionFocus}>{packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.Active ? <p>{Translate.t('Packages.PackagesCard.PackageBought')}</p> : <p>{Translate.t('Packages.PackagesCard.PackageNotAvailable')}</p>}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )

                                : (
                                    <div>
                                        <CardTitlePricingContainerMain focusBuyAll={focusBuyAll} focused={defectInspectionFocus}>{`Rm ${defectInspection}`}</CardTitlePricingContainerMain>
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={defectInspectionFocus}>{Translate.t('Packages.PackagesCard.CardMonthyPricing')}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )}
                        </div>
                    </CardTitlePricingSubContainer>
                    <CardButtonMainContainer>
                        <CardButtonContainer secondary={focusBuyAll} disabled={!defectInspection || defectInspection === 0 || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.PendingSignature} label={packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.PendingSignature ? Translate.t('Packages.PackagesCard.PackagesPending') : Translate.t('Packages.PackagesCard.PackagesBuy')} />
                    </CardButtonMainContainer>
                    {(packagesInfo.packages?.defectInspection?.price && packagesInfo.packages.defectInspection.status !== IPropertyPackageStatusEnum.Active) && <PointsDropdown packagespoints={defectInspectionPoints} focused={defectInspectionFocus} />}

                </PackagesCardMainContainer>

                <PackagesCardMainContainer onClick={() => setCardFocusedOrNot(Category.DesignRenovation)} focused={designRenovationFocus} disablepackage={!designRenovation || designRenovation === 0 || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.PendingSignature} focusBuyAll={focusBuyAll}>
                    <CardTitleContainer>
                        <CardTitleMain focusBuyAll={focusBuyAll} focused={designRenovationFocus}>{Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')}</CardTitleMain>
                        <CardTitleSubtitleMain focusBuyAll={focusBuyAll} focused={designRenovationFocus}>{Translate.t('Packages.PackagesCard.CardSubTitleDesignRenovation')}</CardTitleSubtitleMain>
                    </CardTitleContainer>
                    <CardTitlePricingSubContainer>
                        <div>
                            {designRenovation === 0 || !designRenovation || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.Active
                                ? (
                                    <div>
                                        {(packagesInfo.packages?.designRenovation?.status !== IPropertyPackageStatusEnum.Active && (packagesInfo.packages?.designRenovation?.price)) && <CardTitlePricingContainerMain focused={designRenovationFocus}><TailSpin color={Colors.primary} height={20} width={20} /></CardTitlePricingContainerMain>}
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={designRenovationFocus}>{packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.Active ? <p>{Translate.t('Packages.PackagesCard.PackageBought')}</p> : <p>{Translate.t('Packages.PackagesCard.PackageNotAvailable')}</p>}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )

                                : (
                                    <div>
                                        <CardTitlePricingContainerMain focusBuyAll={focusBuyAll} focused={designRenovationFocus}>{`Rm ${designRenovation}`}</CardTitlePricingContainerMain>
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={designRenovationFocus}>{Translate.t('Packages.PackagesCard.CardMonthyPricing')}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )}
                        </div>
                    </CardTitlePricingSubContainer>
                    <CardButtonMainContainer>
                        <CardButtonContainer secondary={focusBuyAll} disabled={!designRenovation || designRenovation === 0 || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.PendingSignature} label={packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.PendingSignature ? Translate.t('Packages.PackagesCard.PackagesPending') : Translate.t('Packages.PackagesCard.PackagesBuy')} />
                    </CardButtonMainContainer>
                    {(packagesInfo.packages?.designRenovation?.price && packagesInfo.packages.designRenovation.status !== IPropertyPackageStatusEnum.Active) && <PointsDropdown packagespoints={designnRenovationPoints} focused={designRenovationFocus} />}

                </PackagesCardMainContainer>

                <PackagesCardMainContainer onClick={() => setCardFocusedOrNot(Category.PropertyManagement)} focused={managementFocus} disablepackage={!propertyManagement || propertyManagement === 0 || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.PendingSignature} focusBuyAll={focusBuyAll}>
                    <CardTitleContainer>
                        <CardTitleMain focusBuyAll={focusBuyAll} focused={managementFocus}>{Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')}</CardTitleMain>
                        <CardTitleSubtitleMain focusBuyAll={focusBuyAll} focused={managementFocus}>{Translate.t('Packages.PackagesCard.CardSubTitlePropertyManagement')}</CardTitleSubtitleMain>
                    </CardTitleContainer>
                    <CardTitlePricingSubContainer>
                        <div>
                            {propertyManagement === 0 || !propertyManagement || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.Active
                                ? (
                                    <div>
                                        {(packagesInfo.packages?.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.propertyManagement?.price) && <CardTitlePricingContainerMain focused={managementFocus}><TailSpin color={Colors.primary} height={20} width={20} /></CardTitlePricingContainerMain>}
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={managementFocus}>{packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.Active ? <p>{Translate.t('Packages.PackagesCard.PackageBought')}</p> : <p>{Translate.t('Packages.PackagesCard.PackageNotAvailable')}</p>}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )

                                : (
                                    <div>
                                        <CardTitlePricingContainerMain focusBuyAll={focusBuyAll} focused={managementFocus}>{`Rm ${propertyManagement}`}</CardTitlePricingContainerMain>
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={managementFocus}>{Translate.t('Packages.PackagesCard.CardMonthyPricing')}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )}
                        </div>

                    </CardTitlePricingSubContainer>
                    <CardButtonMainContainer>
                        <CardButtonContainer secondary={focusBuyAll} disabled={!propertyManagement || propertyManagement === 0 || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.PendingSignature} label={packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.PendingSignature ? Translate.t('Packages.PackagesCard.PackagesPending') : Translate.t('Packages.PackagesCard.PackagesBuy')} />
                    </CardButtonMainContainer>
                    {(packagesInfo.packages?.propertyManagement?.price && packagesInfo.packages.propertyManagement.status !== IPropertyPackageStatusEnum.Active) && <PointsDropdown packagespoints={propertyManagementpoints} focused={managementFocus} />}
                </PackagesCardMainContainer>

                <PackagesCardMainContainer onClick={() => setCardFocusedOrNot(Category.VacantManagement)} focused={vacantmanagementFocus} disablepackage={!vacantManagement || vacantManagement === 0 || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.PendingSignature} focusBuyAll={focusBuyAll}>
                    <CardTitleContainer>
                        <CardTitleMain focusBuyAll={focusBuyAll} focused={vacantmanagementFocus}>Vacant Management</CardTitleMain>
                        <CardTitleSubtitleMain focusBuyAll={focusBuyAll} focused={vacantmanagementFocus}>Subtitle 1</CardTitleSubtitleMain>

                    </CardTitleContainer>
                    <CardTitlePricingSubContainer>
                        <div>
                            {vacantManagement === 0 || !vacantManagement || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.Active
                                ? (
                                    <div>
                                        {(packagesInfo.packages?.vacantManagement?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.vacantManagement?.price) && <CardTitlePricingContainerMain focused={vacantmanagementFocus}><TailSpin color={Colors.primary} height={20} width={20} /></CardTitlePricingContainerMain>}
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={vacantmanagementFocus}>{packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.Active ? <p>{Translate.t('Packages.PackagesCard.PackageBought')}</p> : <p>{Translate.t('Packages.PackagesCard.PackageNotAvailable')}</p>}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )

                                : (
                                    <div>
                                        <CardTitlePricingContainerMain focusBuyAll={focusBuyAll} focused={vacantmanagementFocus}>{`Rm ${vacantManagement}`}</CardTitlePricingContainerMain>
                                        <CardTitlePricingSubtitleSubContainer focusBuyAll={focusBuyAll} focused={vacantmanagementFocus}>{Translate.t('Packages.PackagesCard.CardMonthyPricing')}</CardTitlePricingSubtitleSubContainer>
                                    </div>
                                )}
                        </div>

                    </CardTitlePricingSubContainer>
                    <CardButtonMainContainer>
                        <CardButtonContainer secondary={focusBuyAll} disabled={!vacantManagement || vacantManagement === 0 || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.PendingSignature} label={packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.PendingSignature ? Translate.t('Packages.PackagesCard.PackagesPending') : Translate.t('Packages.PackagesCard.PackagesBuy')} />
                    </CardButtonMainContainer>
                    {(packagesInfo.packages?.vacantManagement?.price && packagesInfo.packages.vacantManagement.status !== IPropertyPackageStatusEnum.Active) && <PointsDropdown packagespoints={VacantManagementpoints} focused={vacantmanagementFocus} />}

                </PackagesCardMainContainer>
            </CardContainer>

            <SubscriberButtonContainer>
                {(((packagesInfo.packages?.vacantPossession?.status === 3) && (packagesInfo.packages?.defectInspection?.status === 3)
                    && (packagesInfo.packages?.designRenovation?.status === 3)
                    && (packagesInfo.packages?.propertyManagement?.status === 3)
                    && (packagesInfo.packages?.leaseSale?.status === 3))) ? (
                        <AllBoughtSubscriberButton label={Translate.t('Packages.PackagesCard.PackagesSubscribeLabel')} />
                    ) : <SubscriberButton disableBuy={disableBuy} label={Translate.t('Packages.PackagesCard.PackagesSubscribeLabel')} onClick={setPackagesChoosed} />}
            </SubscriberButtonContainer>
        </div>

    );
};

const SubscriberButtonContainer = styled.div`
    width: 100%;
    padding-top: 2.5%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
`;

const AllBoughtSubscriberButton = styled(Button)`
    display: flex;
    width: 417px;
    height: 32px;
    background-color: #F6F6F6;
    color: black;
    cursor: not-allowed;
    pointer-events: none;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 8px;
`;

const SubscriberButton = styled(Button)<{disableBuy?: boolean, }>`
    display: flex;
    width: 417px;
    height: 32px;
    background-color: #F2B47E;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 8px;
    ${(props) => props.disableBuy && css`
    background-color: #F6F6F6;
    color: black;
    cursor: not-allowed;
    pointer-events: none;
    `}
`;

const CardContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-top: 80px;
    justify-content: space-around;

    > * {
            &:nth-child(5) {
                margin-right: 0px;
            }
        }

`;

const PackagesCardMainContainer = styled.div<{focused?: boolean, focusBuyAll?: boolean, disablepackage?:boolean, }>`
    padding: 0px 20px 0px 20px;
    width: 18%;
    min-height: 640px;
    height: 100%;
    background-color: #ffffff;
    ${(props) => props.focused && css`
    background-color: #F2B47E;
    `}
    ${(props) => props.focusBuyAll && css`
    background-color: #F2B47E;
    `}
    ${(props) => props.disablepackage && css`
    background-color: #F6F6F6;
    color: black;
    cursor: not-allowed;
    pointer-events: none;
    `}
    color: white;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;

const CardTitleContainer = styled.div`
    margin-top: 48px;
    margin-bottom: 48px;
`;

const DefectInpectionDependencyContainer = styled.h5`
    color: red;
    margin-bottom: 5px;
`;

const CardTitleMain = styled.div<{focusBuyAll?: boolean, focused?: boolean}>`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 65%;
    color: black;
    ${(props) => props.focused && css`
    color: #ffffff;
    `}
    ${(props) => props.focusBuyAll && css`
    color: #050000;;
    `}
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
`;
const CardTitleSubtitleMain = styled.div<{focusBuyAll?: boolean, focused?: boolean}>`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 35%;
    color: black;
    ${(props) => props.focused && css`
    color: #ffffff;;
    `}
    ${(props) => props.focusBuyAll && css`
    color: #000000;;
    `}
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const CardTitlePricingSubContainer = styled.div`
    justify-content: flex-start;
`;

const CardTitlePricingContainerMain = styled.div<{focusBuyAll?: boolean, focused?: boolean}>`
    width: 100%;
    height: 100%;
    color: black;
    ${(props) => props.focused && css`
    color: #ffffff;;
    `}
    ${(props) => props.focusBuyAll && css`
    color: black;
    `}
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
`;

const CardTitlePricingSubtitleSubContainer = styled.div<{focusBuyAll?: boolean, focused?: boolean}>`
    width: 100%;
    height: 100%;
    color: black;
    ${(props) => props.focused && css`
    color: #ffffff;;
    `}
    ${(props) => props.focusBuyAll && css`
    color: #000000;;
    `}
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const CardButtonMainContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 48px;
    align-items: center;
`;

const CardButtonContainer = styled(Button)<{focusBuyAll?: boolean, }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    ${(props) => props.focusBuyAll && css`
    color: #F2B47E;;
    `}
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPackagesGetPackagesPricingAttempt(state),
    error: Selectors.getPackagesGetPackagesPricingFailure(state),
    packagesList: Selectors.getPackagesGetPackagesPricing(state),
    packagesInfo: Selectors.getPropPropertyIndividual(state),
    focusBuyAll: Selectors.packagesSetPackagesBuyAll(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPackagesPricing: (propertyId: string) =>
        dispatch(Actions.packagesGetPricingAttempt({ propertyId })),
    getIndividualpackagesInfo: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
    setBuyAll: (state: boolean) => dispatch(Actions.setPackagesBuyAll(state)),
    setBuyingPackagesSeparately: (state: boolean) => dispatch(Actions.setPackagesAllCancel(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesCardLoad);
