import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';

import { GatewayResponseStatus } from 'api/types/types';
import { GetPackagesUploadUrlActionPayload } from 'redux/slices/packages/types';
import PackagesGateway from 'api/Packages';

export default function* watchSetPackagesUploadSignDocuments(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/getPackagesSignedUploadUrlAttempt', handleGetUploadUrl, api);
}

function* handleGetUploadUrl(api: PackagesGateway, data: GetPackagesUploadUrlActionPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { category, id, name, extension, document } = data.payload;

        const response = yield* call([api, api.getPackagesUploadUrl], { category, id, name, extension, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.getPackagesSignedUploadUrlFailure(response.message));
            return;
        } if (response.status === GatewayResponseStatus.Success) {
            yield put(Actions.getPackagesSignedUploadUrlSuccess(response.data));
            let fileType = '';

            if (extension === '.pdf') fileType = 'pdf';
            else fileType = 'image';

            yield put(Actions.uploadSignedPackagesDocumentAttempt({ preSignedUrl: response.data, document, docType: fileType }));
        }
    }
}
