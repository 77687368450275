import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';

import { Button } from '@dm/bigfish';
import Modal from './Modal';

interface WelcomeModalSuccessProps {
  show: boolean,
  setShow: (status: boolean) => void;
  setIsFirstSignUp: (status: boolean) => void;
}

const WelcomeModalSuccess = (props: WelcomeModalSuccessProps):JSX.Element => {
    const { show, setShow, setIsFirstSignUp } = props;

    const onClickHandler = () => {
        setShow(false);
        setIsFirstSignUp(false);
        NavActions.navToHome();
    };

    return (
        <Modal show={show} width='544px'>
            <Container>
                <Title>Your Account Details Have Been Saved!</Title>
                <Text>
                    You can now begin to use the Dormeo Homes’ services
                </Text>
                <ButtonContainer>
                    <Button onClick={onClickHandler} style={{ width: '83px' }} label='Got it' />
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  color: #000;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;

  margin-bottom: 12px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;

  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    show: Selectors.getUiWelcomeModalSuccess(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setShow: (status: boolean) => dispatch(Actions.setWelcomeModalSuccess(status)),
    setIsFirstSignUp: (status: boolean) => dispatch(Actions.setIsFirstSignUp(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModalSuccess);
