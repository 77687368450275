import AuthGateway from './Auth';
import PropertyGateway from './Property';
import UserGateway from './User';
import PackagesGateway from './Packages';

const auth = new AuthGateway('');
const prop = new PropertyGateway('');
const user = new UserGateway('');
const packages = new PackagesGateway('');

export default {
    auth,
    prop,
    user,
    packages,
};
