import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import {
    LoginParams,
    LoginResponse,
    SignUpParams,
    RequestResetPasswordParams,
    VerifySignUpParams,
    DetailSignUpParams,
    VerifyForgotPasswordEmailParams,
    ForgotPasswordNewPasswordParams,
    IAuthGateway,
    UnsubscribeParams,
} from './AuthBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class AuthGateway extends IAuthGateway {
    async login(params: LoginParams): GatewayResponse<LoginResponse | null> {
        const response: ApiResponse<LoginResponse> = await this.api.post('/auth/login', params);
        return this.process(response);
    }

    async signUp(params: SignUpParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('/auth/signup', params);
        return this.process(response);
    }

    async requestResetPassword(params: RequestResetPasswordParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('/auth/resetPassword', params);
        return this.process(response);
    }

    async verifySignUp(params: VerifySignUpParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post(`/auth/verifyEmail/${params.id}`);
        return this.process(response);
    }

    async detailedSignUp(params: DetailSignUpParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.put('/owner/user/updateUserInfo', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async verifyForgotPasswordEmail(params: VerifyForgotPasswordEmailParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('/auth/validateResetPassword', params);
        return this.process(response);
    }

    async forgotPasswordNewPassword(params: ForgotPasswordNewPasswordParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('/auth/newPassword', params);
        return this.process(response);
    }

    async unsubscribe(params: UnsubscribeParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post(`/auth/unsubscribe/${params.hash}`);
        return this.process(response);
    }
}
