import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Bars } from 'react-loader-spinner';
import { BreadCrumb, Button, Colors } from '@dm/bigfish';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/Translate';
import { IPropertyIndividual } from 'entities/property';
import SupportInfoCreateProperty from '../../components/SupportInfoCreateProperty';
import MainContainer from '../../components/MainContainer';
import TermsAndConditions from './components/TermsAndConditions';
import Location from './components/Location';
import PropertyDetails from './components/PropertyDetails';
import PropertyAmenities from './components/PropertyAmenities';
import PropertyImages from './components/Image';
import PropertyDescription from './components/PropertyDescription';

interface CreatePropertyProps {
    getPropertyDetailsLoader: boolean;
    propertyInfo: IPropertyIndividual;
    loading: boolean;
    isCompletedSubmittingTerm: boolean;
    isCompletedSubmittingLocation: boolean;
    isCompletedSubmittingDetails: boolean;
    isCompletedSubmittingAmenities: boolean;
    isCompletedSubmittingImages: boolean;
    isCompletedSubmittingDescription: boolean;
    loadingPropertyDescription: boolean;
    setTabView: (tabView: string) => void;
    setForApproval: (id: string)=> void;
    getPropertyIndividual: (id: string) => void;
}

interface NavParams {
    propertyIndividualId: string;
}

const CreateProperty = ({
    getPropertyDetailsLoader,
    propertyInfo,
    loading,
    isCompletedSubmittingTerm,
    isCompletedSubmittingLocation,
    isCompletedSubmittingDetails,
    isCompletedSubmittingAmenities,
    isCompletedSubmittingImages,
    isCompletedSubmittingDescription,
    loadingPropertyDescription,
    setTabView,
    getPropertyIndividual,
    setForApproval,
}:CreatePropertyProps) : JSX.Element => {
    const location = useLocation();
    const [propertyID, setPropertyID] = useState('');
    const [locationUpdate, setLocationUpdate] = useState(false);
    const [allCriteriaFullFilled, setAllCriteriaFullFilled] = useState(true);
    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelPropertyListings'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelSetupProcess'),
                onClick: () => { return false; },
            },
        ],
    );

    const { propertyIndividualId = '' } = location.state as NavParams;

    useEffect(() => {
        setAllCriteriaFullFilled(true);
        const pathName = location.pathname.replace('/', '');
        setTabView(pathName);
        getPropertyIndividual(propertyIndividualId);
        setPropertyID(propertyIndividualId);
    }, [locationUpdate]);

    useEffect(() => {
        if ((isCompletedSubmittingTerm !== true) || (isCompletedSubmittingLocation !== true)
        || (isCompletedSubmittingDetails !== true) || (isCompletedSubmittingAmenities !== true)
        || (isCompletedSubmittingImages !== true) || (isCompletedSubmittingDescription !== true)) {
            setAllCriteriaFullFilled(true);
        }
        if ((isCompletedSubmittingTerm === true) && (isCompletedSubmittingLocation === true)
        && (isCompletedSubmittingDetails === true) && (isCompletedSubmittingAmenities === true)
        && (isCompletedSubmittingImages === true) && (isCompletedSubmittingDescription === true)) {
            setAllCriteriaFullFilled(false);
        }
        if (propertyInfo.images.length < 0) {
            setAllCriteriaFullFilled(false);
        }
    }, [isCompletedSubmittingTerm,
        isCompletedSubmittingLocation,
        isCompletedSubmittingDetails,
        isCompletedSubmittingAmenities,
        isCompletedSubmittingImages,
        isCompletedSubmittingDescription,
        propertyInfo.images.length]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onSubmitConfirmHandler = () => {
        setForApproval(propertyIndividualId);
    };
    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <SplitterContainer>
                    <LeftContainer>
                        <MainContainerPadding>
                            <SmallTextContainer>{Translate.t('Property.PropertyCreate.PropertyTitleMessage')}</SmallTextContainer>
                            {getPropertyDetailsLoader && (
                                <Loader>
                                    <Bars color={Colors.primary} height={260} width={260} />
                                </Loader>
                            )}
                            {!getPropertyDetailsLoader && (
                                <div>
                                    <TermsAndConditions propertyId={propertyID} />
                                    <Location locationUpdate={locationUpdate} setLocationUpdate={setLocationUpdate} propertyId={propertyID} />
                                    <PropertyDetails propertyId={propertyID} />
                                    <PropertyAmenities propertyId={propertyID} />
                                    <PropertyImages propertyId={propertyID} />
                                    <PropertyDescription descriptionData={propertyInfo.description ?? ''} propertyId={propertyID} />
                                </div>
                            )}
                        </MainContainerPadding>
                        <ButtonContainer>
                            <ConfirmPropertyButton label={Translate.t('Property.PropertyCreate.PropertyConfirmButton')} type='button' disabled={allCriteriaFullFilled} loading={loading || loadingPropertyDescription} onClick={onSubmitConfirmHandler} />
                        </ButtonContainer>
                    </LeftContainer>
                    <StyledSupportInfo />
                </SplitterContainer>
            </MainContainer>
        </>

    );
};

const StyledSupportInfo = styled(SupportInfoCreateProperty)`
    margin-top: 76px;
    margin-left: 16px;
`;

const SplitterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const LeftContainer = styled.div`
    width: 59%;
    height: 100%;
`;

const ConfirmPropertyButton = styled(Button)<{disabled?: boolean, }>`
    align-items: flex-start;
    padding: 8px;
    position: static;
    width: 66px;
    height: 32px;
    left: 0px;
    border-radius: 8px;
    margin-top: 20px;
    margin-right: 20px;
    background-color: #F2B47E;
    color: white;
    cursor: pointer;
    border: black;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    ${(props) => props.disabled && css`
    background-color: #F6F6F6;
    cursor: not-allowed;
    pointer-events: none;
    color: black;
    `}
`;

const SmallTextContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: black;
`;

const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 20%;
    padding-bottom: 20%;
`;

const MainContainerPadding = styled.div`
    padding-left:20px;
    >:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 120px;
    align-items: flex-end;
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    setForApproval: (id: string) =>
        dispatch(Actions.propertySetApprovalAttempt({ id })),
    getPropertyIndividual: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
});

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropertyGetPropertySetApprovalAttempting(state),
    getPropertyDetailsLoader: Selectors.getPropPropertyIndividualAttempt(state),
    error: Selectors.getPropertyGetPropertySetApprovalError(state),
    propertyInfo: Selectors.getPropPropertyIndividual(state),
    isCompletedSubmittingTerm: Selectors.setPropertySetTermsAndConditionSubmitted(state),
    isCompletedSubmittingLocation: Selectors.setPropertySetPropertyLocationSubmitted(state),
    isCompletedSubmittingDetails: Selectors.setPropertySetPropertyDetailsSubmitted(state),
    isCompletedSubmittingAmenities: Selectors.setPropertySetPropertyAmenitiesSubmitted(state),
    isCompletedSubmittingImages: Selectors.setPropertySetPropertyImagesSubmitted(state),
    isCompletedSubmittingDescription: Selectors.setPropertySetPropertyDescriptionSubmitted(state),
    loadingPropertyDescription: Selectors.getPropertyGetPropertySetDescriptionAttempting(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProperty);
