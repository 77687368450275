import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import { SetPropertyIndividualPayload } from 'redux/slices/prop/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';

export default function* watchGetPropertyImageData(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('prop/getPropertyImageDataAttempt', handleGetPropertyIndividual, api);
}

function* handleGetPropertyIndividual(api: PropertyGateway, data: SetPropertyIndividualPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { id } = data.payload;

        const response = yield* call([api, api.getPropertyIndividual], {
            id,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
            // perform operation here
            if (!response.data) {
                yield put(Actions.getPropertyImageDataFailure('Something went wrong. Please try again.'));
                return;
            }
            yield put(Actions.getPropertyImageDataSuccess(response.data));
        } else {
            // handle error
            yield put(Actions.getPropertyImageDataFailure(response.message));
        }
    }
}
