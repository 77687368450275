import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history } from 'redux/store';

import Login from 'containers/auth/Login';
import SignUp from 'containers/auth/SignUp';
import RequestResetPassword from 'containers/auth/RequestResetPassword';
import VerifySignUp from 'containers/auth/VerifySignUp';
import VerificationEmailSent from 'containers/auth/VerificationEmailSent';
import DetailedSignUp from 'containers/auth/DetailedSignUp';
import ForgotPasswordNewPassword from 'containers/auth/ForgotPasswordNewPassword';
import Unsubscribe from 'containers/auth/Unsubscribe';
import StripeSuccess from 'containers/packages/StripePageComponents/StripeSuccess';
import StripeFailed from 'containers/packages/StripePageComponents/StripeFailed';

import Dummy from 'containers/Dummy';

import Profile from 'containers/settings/Profile';
import HomeScreen from 'containers/home';
import CreateProperty from 'containers/property/CreateProperty';
import Packages from 'containers/packages/Packages';
import SubscribePackages from 'containers/packages/SubscribePackages';
import ViewPropertyDetails from 'containers/property/ViewPropertyDetails';
import VacantPossesion from 'containers/packages/VacantPossessionPackages/VacantPossession';
import VacantPossesionDocuments from 'containers/packages/VacantPossessionPackages/VacantPosessionViewDocuments';
import LeaseSale from 'containers/packages/LeaseSalePackages/LeaseSale';
import LeaseAndSaleDocuments from 'containers/packages/LeaseSalePackages/LeaseAndSaleViewDocuments';
import DefectInspection from 'containers/packages/DefectInspectionPackages/DefectInspection';
import DefectInspectionDocuments from 'containers/packages/DefectInspectionPackages/DefectInspectionViewDocument';
import PropertyManagement from 'containers/packages/PropertyManagementPackages/PropertyManagement';
import PropertyManagementViewDocuments from 'containers/packages/PropertyManagementPackages/PropertyManagementViewDocuments';
import PropertyManagementViewDocumentUploaded from 'containers/packages/PropertyManagementPackages/PropertyManagementViewDocumentUploaded';
import VacantManagement from 'containers/packages/VacantManagement/VacantManagement';
import VacantManagamentViewDocuments from 'containers/packages/VacantManagement/VacantManagamentViewDocuments';
import PackagesSign from 'containers/packages/PackagesSign';
import TopupWallet from 'containers/packages/PackagesWalletTopUp';

import Dashboardv2 from 'containers/home/Dashboardv2';
import PrivateRoute from './PrivateRoute';
import FilterFirstSignUpRoute from './FilterFirstSignUpRoute';

const PrivateBucket = (): JSX.Element => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route
                index
                element={<FilterFirstSignUpRoute><Dashboardv2 /></FilterFirstSignUpRoute>}
            />
            <Route path='/' element={<FilterFirstSignUpRoute><Dashboardv2 /></FilterFirstSignUpRoute>} />
            <Route path='/oldDashboard' element={<FilterFirstSignUpRoute><HomeScreen /></FilterFirstSignUpRoute>} />
            <Route path='/listings' element={<FilterFirstSignUpRoute><Dummy /></FilterFirstSignUpRoute>} />
            <Route path='/notifications' element={<FilterFirstSignUpRoute><Dummy /></FilterFirstSignUpRoute>} />
            <Route path='/reports' element={<FilterFirstSignUpRoute><Dummy /></FilterFirstSignUpRoute>} />
            <Route path='/support' element={<FilterFirstSignUpRoute><Dummy /></FilterFirstSignUpRoute>} />
            <Route path='/profile' element={<FilterFirstSignUpRoute><Profile /></FilterFirstSignUpRoute>} />
            <Route path='/property' element={<FilterFirstSignUpRoute><HomeScreen /></FilterFirstSignUpRoute>} />
            <Route path='/subscribe' element={<FilterFirstSignUpRoute><Packages /></FilterFirstSignUpRoute>} />
            <Route path='/newSignIn' element={<DetailedSignUp />} />
            <Route path='/property/new' element={<CreateProperty />} />
            <Route path='/paymentoption' element={<SubscribePackages />} />
            <Route path='/viewpropertydetails' element={<ViewPropertyDetails />} />
            <Route path='/vacantpossesion' element={<VacantPossesion />} />
            <Route path='/VacantPossesionViewDocuments' element={<VacantPossesionDocuments />} />
            <Route path='/leaseAndSale' element={<LeaseSale />} />
            <Route path='/LeaseAndSaleViewDocuments' element={<LeaseAndSaleDocuments />} />
            <Route path='/defectInspection' element={<DefectInspection />} />
            <Route path='/defectInspectionViewDocuments' element={<DefectInspectionDocuments />} />
            <Route path='/propertyManagement' element={<PropertyManagement />} />
            <Route path='/propertyManagementViewDocuments' element={<PropertyManagementViewDocuments />} />
            <Route path='/propertyManagementViewDocumentsUploaded' element={<PropertyManagementViewDocumentUploaded />} />
            <Route path='/vacantManagement' element={<VacantManagement />} />
            <Route path='/vacantManagementViewDocuments' element={<VacantManagamentViewDocuments />} />
            <Route path='/packagesSign' element={<PackagesSign />} />
            <Route path='/PaymentGatewaySuccess' element={<StripeSuccess />} />
            <Route path='/PaymentGatewayFailed' element={<StripeFailed />} />
            <Route path='/topupWallet' element={<TopupWallet />} />
        </Route>
    );
};

const NavRoutes = (): JSX.Element => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<SignUp />} />
                <Route path='/requestResetPassword' element={<RequestResetPassword />} />
                <Route path='/verifyEmail/:uuid' element={<VerifySignUp />} />
                <Route path='/verificationEmail' element={<VerificationEmailSent />} />
                <Route path='/resetPassword/:id' element={<ForgotPasswordNewPassword />} />
                <Route path='/unsubscribe/:hash' element={<Unsubscribe />} />
                <Route path='*' element={<Navigate replace to='/' />} />
                {PrivateBucket()}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
