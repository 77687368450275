import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import { SetITopUpPayment } from 'redux/slices/packages/types';

import Utils from 'lib/Utils';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { PaymentTypeEnum } from '@dm/types';

export default function* watchSetPackagesTopUpWallet(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesSetTopUpPaymentAttempt', handleSetPackagesTopUpWallet, api);
}

function* handleSetPackagesTopUpWallet(api: PackagesGateway, data: SetITopUpPayment) {
    const { propertyId, paymentType, amount, bankNumber, accountName, bankName, reference } = data.payload;

    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const response = yield* call([api, api.setPackagesTopUpWallet], {
            authToken,
            propertyId,
            paymentType,
            amount,
            bankNumber,
            accountName,
            bankName,
            reference,
        });

        if (response.status === GatewayResponseStatus.Success) {
            // perform operation here
            if (!response.data) {
                yield put(Actions.packagesSetTopUpPaymentFailure('Something went wrong. Please try again.'));
                return;
            }
            if (data.payload.paymentType === PaymentTypeEnum.ManualBankTransfer) {
                yield put(Actions.packagesSetTopUpPaymentSuccess(response.data));
                const modalOpen = yield* select(Selectors.packagesSetTopUpPaymentModal);
                yield put(Actions.setTopUpPaymentModalOpen(!modalOpen));
            }
            yield put(Actions.packagesSetTopUpPaymentSuccess(response.data));
        } else {
            // handle error
            yield put(Actions.packagesSetTopUpPaymentFailure(response.message));
        }
    }
}
