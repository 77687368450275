import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Signature from 'signature_pad';
import _ from 'lodash';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { Button } from '@dm/bigfish';

export interface SignProps {

    isPackageSigned: boolean;
    loadingGetUrl: boolean;
    setIsPackagesSigned: (state: boolean) => void;
    setSignData: (state: string) => void;

}

const SignaturePad = (props : SignProps): JSX.Element => {
    const { setSignData, setIsPackagesSigned, isPackageSigned, loadingGetUrl } = props;
    const [signaturePad, setSignaturePad] = useState<any>();

    const signatureRedoArray: any = [];

    const readyPad = () => {
        const wrapper = document.getElementById('signature-pad');
        const canvas = wrapper?.querySelector('canvas');
         canvas!.getContext('2d')!.scale(1, 1);

         const tempSignaturePad = new Signature(canvas!, {
             backgroundColor: 'rgb(255, 255, 255)',
         });
         setSignaturePad(tempSignaturePad);
    };

    const handleSave = () => {
        const signData = signaturePad.toDataURL('image/jpeg');
        setSignData(signData);
        setIsPackagesSigned(true);
    };

    const handleUndo = () => {
        let signatureRemovedData = [];
        const signatureData = signaturePad.toData();
        const signatureRedoData = _.cloneDeep(signatureData); // original data

        if (signatureData.length > 0) {
            signatureData.pop(); // remove the last dot or line
            signaturePad.fromData(signatureData);
            signatureRemovedData = signatureRedoData[signatureRedoData.length - 1];
            signatureRedoArray.push(signatureRemovedData);
        }
    };

    const handleRedo = () => {
        if (signatureRedoArray.length !== 0) {
            const values = signaturePad.toData();
            const lastValue = signatureRedoArray[signatureRedoArray.length - 1];
            values.push(lastValue);
            signaturePad.fromData(values);
            signatureRedoArray.pop(lastValue); // remove the redo item from array
        }
    };

    const handleClear = () => {
        signaturePad.clear();
        setIsPackagesSigned(false);
    };

    useEffect(() => {
        readyPad();
    }, []);

    return (
        <MainContainer>
            <div>

                <div id='signature-pad'>
                    <CenteringContainer>

                        <CanvasDraw />

                    </CenteringContainer>

                    <ColumnContainer />
                    <CenteringContainer>

                        <Container>
                            <SubscriberButton loading={loadingGetUrl} label={Translate.t('Sign.Save')} onClick={handleSave} />
                            <SubscriberButton label={Translate.t('Sign.Undo')} onClick={handleUndo} />
                            <SubscriberButton label={Translate.t('Sign.Redo')} onClick={handleRedo} />
                            <SubscriberButton label={Translate.t('Sign.Clear')} onClick={handleClear} />
                        </Container>
                    </CenteringContainer>

                </div>

            </div>

        </MainContainer>
    );
};
const CenteringContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
`;
const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;
const SubscriberButton = styled(Button)`
    display: flex;
    margin-right: 5px;
`;

const Container = styled.div`
    display: flex;

`;

const CanvasDraw = styled.canvas`
    outline: grey 3px solid;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const mapStateToProps = (state: RootState) => ({
    isPackageSigned: Selectors.packagesSetIsPackageSigned(state),
    loadingGetUrl: Selectors.packagesGetPackagesSignedUploadUrlAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setSignData: (state: string) => dispatch(Actions.setPropertySign(state)),
    setIsPackagesSigned: (state: boolean) => dispatch(Actions.setIsPackagesSigned(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignaturePad);
