import React from 'react';

import styled from 'styled-components';
import Translate from 'lib/Translate';
import { Button } from '@dm/bigfish';
import SVG from 'react-inlinesvg';
import { IOwnerUser } from '@dm/types';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { connect } from 'react-redux';

import Modal from '../../components/Modal';
import Icons from '../../assets/icons';

export interface PackagesEmailPromptProps {
    showOrClosemodal: boolean;
    userInfo: IOwnerUser | undefined;
    setModalOpenOrClose: (state: boolean) => void;
}

const PackagesEmailPrompt = (props : PackagesEmailPromptProps) : JSX.Element => {
    const { showOrClosemodal, userInfo, setModalOpenOrClose } = props;

    const onClickHandler = () => {
        setModalOpenOrClose(false);
        NavActions.navToHome();
    };

    const navToHomeAndCloseModal = () => {
        setModalOpenOrClose(false);
        NavActions.navToHome();
    };

    return (
        <MainContainer>
            <Modal show={showOrClosemodal} width='538px'>
                <div>
                    <MainTitle>
                        <Title>
                            {Translate.t('Packages.PaymentUpload.EmailNotification')}
                        </Title>
                        <CloseIcon src={Icons.closebutton} onClick={onClickHandler} />
                    </MainTitle>
                    {(userInfo) && (userInfo.email) ? <EmailMessageContainer>{`${Translate.t('Packages.PaymentUpload.EmailMessage')} ${userInfo?.email} ${Translate.t('Packages.PaymentUpload.EmailMessageTo')}   `}</EmailMessageContainer> : <EmailMessageContainer>{`${Translate.t('Packages.PaymentUpload.EmailMessageNoUser')} ${Translate.t('Packages.PaymentUpload.EmailMessageTo')}   `}</EmailMessageContainer> }
                    <ButtonContainer>
                        <Button label={Translate.t('Packages.PaymentUpload.Okay')} onClick={navToHomeAndCloseModal} />
                    </ButtonContainer>
                </div>

            </Modal>

        </MainContainer>
    );
};

const CloseIcon = styled(SVG)`
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
`;
const EmailMessageContainer = styled.h5`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background-color: #ffffff;
    margin-top: 12px;
    color: #696969;
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
`;

const Title = styled.h2`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    background-color: #ffffff;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const MainTitle = styled.div`
    display: flex;
    justify-content: space-between;    
`;

const mapStateToProps = (state: RootState) => ({
    showOrClosemodal: Selectors.packagesSetPackagesEmailModal(state),
    userInfo: Selectors.getUserGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpenOrClose: (state: boolean) => dispatch(Actions.setPackagesEmailModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesEmailPrompt);
