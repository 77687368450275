import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';

import { CreatePropertyPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchCreateProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/createPropertyAttempt', handleCreateProperty, api);
}

function* handleCreateProperty(api: PropertyGateway, data: CreatePropertyPayload) {
    const { name } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.createProperty], {
        name,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('property created!');
        yield put(Actions.propertyCreatePropertyModal(false));

        if (response.data) {
            yield put(Actions.createPropertySuccess(response.data));
            NavActions.navToCreateNewProperty(response.data);
            yield put(Actions.propertyCreatePropertyModal(false));
        }

        if (!response.data) {
            yield put(Actions.createPropertyFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.createPropertyFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
