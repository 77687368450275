import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import PackagesGateway from 'api/Packages';
import { GatewayResponseStatus } from 'api/types/types';
import { setSubscriptionTransactionCancelPayload } from 'redux/slices/packages/types';
import Utils from 'lib/Utils';
import { toast } from 'react-toastify';

export default function* watchsetPackagesSubscriptionTransactionsCancel(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesSetSubscriptionTransactionsCancelAttempt', handlesetPackagesSubscriptionTransactionsCancel, api);
}

function* handlesetPackagesSubscriptionTransactionsCancel(api: PackagesGateway, data: setSubscriptionTransactionCancelPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { paymentId, category } = data.payload;

        const response = yield* call([api, api.SetPackagesSubscriptionTransactionsCancel], { paymentId, category, authToken });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.packagesSetSubscriptionTransactionsCancelFailure(response.message));
            yield put(Actions.setTransactionApproveOrRenewLoaderModalOpen(false));

            toast.error(`${response.message}`);
            return;
        }

        yield put(Actions.packagesSetSubscriptionTransactionsCancelSuccess(response.data));
        toast.success('Subscription has been cancelled');
        yield put(Actions.setTransactionApproveOrRenewLoaderModalOpen(false));
    }
}
