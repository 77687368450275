import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Translate from 'lib/Translate';

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';

import { BreadCrumb, TabView } from '@dm/bigfish';

import { IOwnerUser } from '@dm/types';
import PropertyList from './property';
import StatsDashboard from './stats';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

interface HomeScreenProps {
    setTabView: (tabView: string) => void;

    UserListUserInfo: IOwnerUser | undefined;
    errorUserInfo: string;
    loadingUserInfo: boolean;
    GetUserInfo: () => void;
}

const TabViewContainer = styled.div`
    margin-top: 30px;
    margin-left: 20px;
`;

const HomeScreenContent = styled.div`
    padding: 40px 20px;
`;

const CarelineContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 20px;
`;

const HomeScreen = ({ setTabView, GetUserInfo, UserListUserInfo, errorUserInfo, loadingUserInfo }: HomeScreenProps): JSX.Element => {
    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: `${Translate.t('GeneralUI.BreadcrumblabelWelcomeBackUser')} ${(UserListUserInfo?.firstName) ? UserListUserInfo.firstName : ''}`,
                onClick: () => { return false; },
            },
        ],
    );

    const [homeScreenTabView, setHomeScreenTabView] = useState('');

    const [tabViewData] = useState([
        // TEMP
        // {
        //     text: 'stats',
        //     onClickTabView: () => {
        //         setHomeScreenTabView('stats');
        //         NavActions.navToDashboard();
        //     },
        // },

        {
            text: 'modules',
            onClickTabView: () => {
                setHomeScreenTabView('modules');
                NavActions.navToProperty();
            },
        },
    ]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const location = useLocation();

    useEffect(() => {
        // TEMP
        // const pathName = location.pathname.replace('/', '');
        // if (pathName === 'property') {
        //     setHomeScreenTabView('modules');
        // } else {
        //     setHomeScreenTabView('stats');
        // }

        setHomeScreenTabView('modules');
        setTabView('dashboard');
    }, []);

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />

            <MainContainer>
                <TabViewContainer>
                    <TabView data={tabViewData} selectedTabView={homeScreenTabView} />
                </TabViewContainer>
                <HomeScreenContent>
                    {/* TEMP */}
                    {/* {homeScreenTabView === 'stats' && <StatsDashboard />} */}
                    {homeScreenTabView === 'modules' && <PropertyList />}
                </HomeScreenContent>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    loadingUserInfo: Selectors.getUserGetUserInfoAttempt(state),
    errorUserInfo: Selectors.getUserGetUserInfoFailure(state),
    UserListUserInfo: Selectors.getUserGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
    GetUserInfo: () =>
        dispatch(Actions.userGetUserInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
