import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';

import NavActions from 'lib/NavActions';

export default function* watchStartup(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup, api);
}

function* handleStartup(api: AuthGateway) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        yield put(Actions.authLoginSuccess(authToken));
    }

    yield put(Actions.authStartupSuccess());
    yield put(Actions.userGetUserInfoAttempt());
}
