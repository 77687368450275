import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import validator from 'validator';
import dayjs from 'dayjs';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import NavActions from 'lib/NavActions';
import Translate from 'lib/Translate';

import { IUpdateOwnerUser } from 'entities/user';
import { Button, BreadCrumb, LineInput } from '@dm/bigfish';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';
import DateInput from '../../components/DateInput';
import SelectionInput from '../../components/SelectionInput';

import countriesOptions from '../../components/Countries';

interface DetailedSignUpProps {
    loading: boolean;
    detailedSignUp(data: IUpdateOwnerUser): void;
    isFirstSignUp: boolean,
}

const DetailedSignUp = (props: DetailedSignUpProps): JSX.Element => {
    const { loading, detailedSignUp, isFirstSignUp } = props;

    useEffect(() => {
        if (!isFirstSignUp) NavActions.navToHome();
    }, [isFirstSignUp]);

    const [breadCrumb] = useState([
        {
            label: 'My Profile',
            onClick: () => { return false; },
        },
    ]);

    // personal details
    const [personalTitle, setPersonalTitle] = useState('mr');
    const [personalGender, setPersonalGender] = useState('male');
    const [personalAddress, setPersonalAddress] = useState('');
    const [personalAddress2, setPersonalAddress2] = useState('');
    const [personalFirstName, setPersonalFirstName] = useState('');
    const [personalLastName, setPersonalLastName] = useState('');
    const [personalIdNo, setPersonalIdNo] = useState('');
    const [personalCity, setPersonalCity] = useState('');
    const [personalPassportNo, setPersonalPassportNo] = useState('');
    const [personalMobileNo, setPersonalMobileNo] = useState('');
    const [personalPostcode, setPersonalPostcode] = useState('');
    const [personalCountryOrigin, setPersonalCountryOrigin] = useState('');
    const [personalHouseTel, setPersonalHouseTel] = useState('');
    const [personalState, setPersonalState] = useState('');
    const [personalRace, setPersonalRace] = useState(0);
    const [personalCountryResidence, setPersonalCountryResidence] = useState('');
    const [personalCountry, setPersonalCountry] = useState('1');
    const [dateOfBirth, setDateOfBirth] = useState(new Date());

    // financial
    const [financialBankType, setFinancialBankType] = useState('');
    const [financialCurrency, setFinancialCurrency] = useState('');
    const [financialAddress, setFinancialAddress] = useState('');
    const [financialBankName, setFinancialBankName] = useState('');
    const [financialAccNo, setFinancialAccNo] = useState('');
    const [financialSwiftCode, setFinancialSwiftCode] = useState('');
    const [financialCity, setFinancialCity] = useState('');
    const [financialPostcode, setFinancialPostcode] = useState('');
    const [financialState, setFinancialState] = useState('');
    const [financialCountry, setFinancialCountry] = useState('1');

    // company details
    const [companyName, setCompanyName] = useState('');
    const [companyDesignation, setCompanyDesignation] = useState('');
    const [companyOfficeNo, setCompanyOfficeNo] = useState('');

    // emergency
    const [emergencyFullName, setEmergencyFullName] = useState('');
    const [emergencyRelationship, setEmergencyRelationship] = useState('');
    const [emergencyMobileNo, setEmergencyMobileNo] = useState('');
    const [emergencyEmail, setEmergencyEmail] = useState('');
    const [emergencyEmailIsError, setEmergencyEmailIsError] = useState(false);

    const titleOptions = [
        {
            id: 'mr',
            description: 'Mr',
        },
        {
            id: 'miss',
            description: 'Miss',
        },
        {
            id: 'mrs',
            description: 'Mrs',
        },
    ];

    const genderOptions = [
        {
            id: 'male',
            description: 'Male',
        },
        {
            id: 'female',
            description: 'Female',
        },
    ];

    const raceOptions = [
        {
            id: 0,
            description: 'Chinese',
        },
        {
            id: 1,
            description: 'Indian',
        },
        {
            id: 2,
            description: 'Malay',
        },
        {
            id: 3,
            description: 'Others',
        },
    ];

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const onChangeTextEmergencyEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmergencyEmail(e.target.value);
        setEmergencyEmailIsError(!validator.isEmail(e.target.value));
    };

    const onSubmitForm = (e: React.FormEvent) => {
        e.preventDefault();

        if (!emergencyEmailIsError) {
            const data = {
                firstName: personalFirstName,
                lastName: personalLastName,
                // email: personalEmail,
                phoneNumber: personalMobileNo,
                title: personalTitle,
                nationality: personalCountry,
                race: personalRace,
                DOB: dayjs(dateOfBirth).format('YYYY-MM-DD'),
                gender: personalGender,
                countryOfResidence: personalCountryResidence,
                houseTel: personalHouseTel,
                passportNo: personalPassportNo,
                countryOfOrigin: personalCountryOrigin,
                idNo: personalIdNo,
                correspondingAddress: {
                    address1: personalAddress,
                    address2: personalAddress2,
                    city: personalCity,
                    postcode: personalPostcode,
                    state: personalState,
                    country: personalCountry,
                },
                financialInfo: {
                    bankType: financialBankType,
                    bankName: financialBankName,
                    currency: financialCurrency,
                    bankAccNo: financialAccNo,
                    swiftCode: financialSwiftCode,
                    branchAddress: {
                        address1: financialAddress,
                        address2: 'asdasd',
                        city: financialCity,
                        postcode: financialPostcode,
                        state: financialState,
                        country: financialCountry,
                    },
                },
                companyDetails: {
                    companyName,
                    designation: companyDesignation,
                    officeNo: companyOfficeNo,
                },
                emergencyContact: {
                    fullName: emergencyFullName,
                    relationship: emergencyRelationship,
                    phoneNo: emergencyMobileNo,
                    email: emergencyEmail,
                },
            };

            detailedSignUp(data);
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>

                <Container>
                    <Description>{Translate.t('DetailedSignUp.DetailedSignUpDesc')}</Description>
                    <Form onSubmit={onSubmitForm}>
                        <SectionContainer>
                            <SectionTitle>{Translate.t('DetailedSignUp.DetailedSignUpTitle')}</SectionTitle>
                            <DetailsContainer>
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelTitle')} data={titleOptions} currentValue={personalTitle} onChangeSelection={(e) => setPersonalTitle(e.target.value)} required />
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelGender')} data={genderOptions} currentValue={personalGender} onChangeSelection={(e) => setPersonalGender(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCPAddress1')} value={personalAddress} onChangeText={(e) => setPersonalAddress(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCPAddress2')} value={personalAddress2} onChangeText={(e) => setPersonalAddress2(e.target.value)} />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelFirstName')} value={personalFirstName} onChangeText={(e) => setPersonalFirstName(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelLastName')} value={personalLastName} onChangeText={(e) => setPersonalLastName(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelIDNo')} value={personalIdNo} onChangeText={(e) => setPersonalIdNo(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCity')} value={personalCity} onChangeText={(e) => setPersonalCity(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelPassport')} value={personalPassportNo} onChangeText={(e) => setPersonalPassportNo(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelMobile')} value={personalMobileNo} onChangeText={(e) => setPersonalMobileNo(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelPostcode')} value={personalPostcode} onChangeText={(e) => setPersonalPostcode(e.target.value)} required />
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountryOrigin')} data={countriesOptions} currentValue={personalCountryOrigin} onChangeSelection={(e) => setPersonalCountryOrigin(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelHouseTel')} value={personalHouseTel} onChangeText={(e) => setPersonalHouseTel(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelState')} value={personalState} onChangeText={(e) => setPersonalState(e.target.value)} required />
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelRace')} data={raceOptions} currentValue={personalRace} onChangeSelection={(e) => setPersonalRace(+e.target.value)} required />
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountryResidence')} data={countriesOptions} currentValue={personalCountryResidence} onChangeSelection={(e) => setPersonalCountryResidence(e.target.value)} required />
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountry')} data={countriesOptions} currentValue={personalCountry} onChangeSelection={(e) => setPersonalCountry(e.target.value)} required />
                                <DateInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelDOB')} date={dateOfBirth} onChangeDate={(date) => setDateOfBirth(date)} required />
                                {/* <LineInput label='Email' value={personalEmail} onChangeText={onChangeTextPersonalEmail} inputError={personalEmailIsError} errorText='Insert a valid email' required /> */}
                            </DetailsContainer>
                        </SectionContainer>

                        <SectionContainer>
                            <SectionTitle>Financial Details</SectionTitle>
                            <DetailsContainer>
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBankType')} value={financialBankType} onChangeText={(e) => setFinancialBankType(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCurrency')} value={financialCurrency} onChangeText={(e) => setFinancialCurrency(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBranchAddress')} value={financialAddress} onChangeText={(e) => setFinancialAddress(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBranchName')} value={financialBankName} onChangeText={(e) => setFinancialBankName(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelBranchAccNo')} value={financialAccNo} onChangeText={(e) => setFinancialAccNo(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelSwiftCode')} value={financialSwiftCode} onChangeText={(e) => setFinancialSwiftCode(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCity')} value={financialCity} onChangeText={(e) => setFinancialCity(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelPostcode')} value={financialPostcode} onChangeText={(e) => setFinancialPostcode(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelState')} value={financialState} onChangeText={(e) => setFinancialState(e.target.value)} required />
                                <SelectionInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCountry')} currentValue={financialCountry} data={countriesOptions} onChangeSelection={(e) => setFinancialCountry(e.target.value)} required />
                            </DetailsContainer>
                        </SectionContainer>

                        <SectionContainer>
                            <SectionTitle>Company Details</SectionTitle>
                            <DetailsContainer>
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelCompanyName')} value={companyName} onChangeText={(e) => setCompanyName(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelDesignation')} value={companyDesignation} onChangeText={(e) => setCompanyDesignation(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelOfficeNo')} value={companyOfficeNo} onChangeText={(e) => setCompanyOfficeNo(e.target.value)} required />
                            </DetailsContainer>
                        </SectionContainer>

                        <SectionContainer>
                            <SectionTitle>Emergency Contact Details</SectionTitle>
                            <DetailsContainer>
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelFullName')} value={emergencyFullName} onChangeText={(e) => setEmergencyFullName(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelRelationship')} value={emergencyRelationship} onChangeText={(e) => setEmergencyRelationship(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelMobile')} value={emergencyMobileNo} onChangeText={(e) => setEmergencyMobileNo(e.target.value)} required />
                                <LineInput label={Translate.t('DetailedSignUp.DetailedSignUpLabelEmail')} value={emergencyEmail} onChangeText={onChangeTextEmergencyEmail} inputError={emergencyEmailIsError} errorText={Translate.t('DetailedSignUp.DetailedSignUpErrorEmail')} required />
                            </DetailsContainer>
                        </SectionContainer>

                        <ButtonContainer>
                            <Button loading={loading} label={Translate.t('DetailedSignUp.DetailedSignUpButton')} />
                        </ButtonContainer>
                    </Form>

                </Container>

                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 824px;
  
  padding: 40px 20px 162px 20px;

  color: #000;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

const Form = styled.form`
    margin-top: 40px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
  margin-bottom: 16px;

  width: 100%;

  box-sizing: border-box;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;

  margin-bottom: 12px;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );

  row-gap: 8px;
  column-gap: 56px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CarelineContainer = styled.div`
  position: absolute;
  bottom: 23px;
  right: 21px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthDetailedSignUpAttempt(state),
    error: Selectors.getAuthDetailedSignUpError(state),
    isFirstSignUp: Selectors.getAuthIsFirstSignUp(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    detailedSignUp: (data: IUpdateOwnerUser) =>
        dispatch(Actions.authDetailedSignUpAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailedSignUp);
