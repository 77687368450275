import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetIPackagesPayment } from 'redux/slices/packages/types';

import { GatewayResponseStatus } from 'api/types/types';
import { PaymentTypeEnum } from '@dm/types';

export default function* watchPackagesPayments(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesSetPackagesPaymentAttempt', handleDocumentsPayments, api);
}

function* handleDocumentsPayments(api: PackagesGateway, data: SetIPackagesPayment) {
    const { propertyId, paymentType, bankNumber, accountName, bankName, packages, reference } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call(
        [api, api.setPackagesPayments],
        {
            propertyId,
            paymentType,
            bankNumber,
            accountName,
            bankName,
            packages,
            reference,
            authToken,
        },
    );

    if (response.status === GatewayResponseStatus.Success) {
        if (data.payload.paymentType === PaymentTypeEnum.ManualBankTransfer) {
            yield put(Actions.packagesSetPackagesPaymentSuccess(response.data));
            const modalOpen = yield* select(Selectors.packagesSetPaymentDocumentModal);
            yield put(Actions.setPaymentDocumentModalOpen(!modalOpen));
        } else {
            yield put(Actions.packagesSetPackagesPaymentSuccess(response.data));
            const { paymentId } = response.data;
            yield put(Actions.getPackagesPaymentCreditCardAttempt({ paymentId }));
        }

        if (!response.data) {
            yield put(Actions.packagesSetPackagesPaymentFailure(Translate.t('GeneralUI.SomethingWrong')));
        }
    } else {
        yield put(Actions.packagesSetPackagesPaymentFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralUI.Failed'));
            } else {
                toast.error(Translate.t('GeneralUI.TryAgain'));
            }
        }
    }
}
