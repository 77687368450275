import { fork } from 'redux-saga/effects';

import AuthGateway from 'api/Auth';
import { RootSagaReturnType } from 'sagas/types';

import watchLogin from './login';
import watchStartup from './startup';
import watchSignUp from './signUp';
import watchLogout from './logout';
import watchRequestResetPassword from './requestResetPassword';
import watchVerifySignUp from './verifySignUp';
import watchDetailedSignUp from './detailedSignUp';
import watchVerifyForgotPasswordEmail from './verifyForgotPasswordEmail';
import watchForgotPasswordNewPassword from './forgotPasswordNewPassword';
import watchUnsubscribe from './unsubscribe';

export default (api: AuthGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchLogin, api);
        yield fork(watchStartup, api);
        yield fork(watchSignUp, api);
        yield fork(watchLogout);
        yield fork(watchRequestResetPassword, api);
        yield fork(watchVerifySignUp, api);
        yield fork(watchVerifySignUp, api);
        yield fork(watchDetailedSignUp, api);
        yield fork(watchVerifyForgotPasswordEmail, api);
        yield fork(watchForgotPasswordNewPassword, api);
        yield fork(watchUnsubscribe, api);
    }

    return {
        rootSaga,
    };
};
