import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';
import { Oval } from 'react-loader-spinner';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CardCollapsible, Button, Colors, BreadCrumb, RoundInput } from '@dm/bigfish';
import { RootState, AppDispatch } from 'redux/store';

import MainContainer from 'components/MainContainer';
import NavActions from 'lib/NavActions';
import { IPropertyIndividual, WalletBalanceParams } from 'entities/property';
import { Category, IPropertyPackageStatusEnum } from '@dm/types';
import LoadingScreen from 'components/LoadingScreen';
import { toast } from 'react-toastify';
import media from 'lib/Breakpoints';
import SupportInfo from '../../../components/SupportInfo';
import ButtonInfoPackages from '../../../components/ButtonInfoPackages';
import QuickNavigationButton from '../../../components/QuickNavigationButton';

import PropertyImage from '../../../components/PropertyImage';
import Icons from '../../../assets/icons';
import HousingIMG from '../../../assets/images/building.png';
import EventTrailRecentEvents from '../../../components/EventTrailRecentEvents';
import Modal from './Modal';
import DocumentUploadIndex from '../componentsPackages/DocumentUploadIndex';

interface NavParams {
    propertyIndividualId: string;
}

interface CollapsibleListProps {
    loading: boolean;
    error: string;
    propertyList: IPropertyIndividual;
    walletBalanceAttemp: boolean;
    walletBalanceError: string;
    walletBalance: WalletBalanceParams | null;
    modalLoad: boolean;
    getWalletBalance: (propertyId: string) => void;
    getPropertyIndividual: (id: string) => void;
    setModalOpen: (state: boolean) => void;
    setPropertyManagementDocumentUploadID: (id: string) => void;
}
const PropertyManagement = (props: CollapsibleListProps): JSX.Element => {
    const { loading, error, propertyList, walletBalanceAttemp, walletBalanceError, walletBalance, modalLoad, getWalletBalance, getPropertyIndividual, setModalOpen, setPropertyManagementDocumentUploadID } = props;
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [chipnumber, setChipnumber] = useState(0);
    const [country, setCountry] = useState('');
    const [pendingEvents, setPendingEvents] = useState([]);
    const [topUpModal, setTopUpModal] = useState(false);
    const [amount, setAmount] = useState('0');

    const useLocationstate = useLocation();

    const { propertyIndividualId } = useLocationstate.state as NavParams;
    const ownerTermsRef = useRef<any>(null);

    useEffect(() => {
        onOpenCollapsibleOwner();
        getPropertyIndividual(propertyIndividualId);
        getWalletBalance(propertyIndividualId);
        setOwnerStatusForPackage();
    }, []);

    const setAmountfunctiom = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setAmount(e.target.value);
    };

    const confirmNavigateToPayment = () => {
        if (Number(amount) === 0 || Number.isNaN(Number(amount))) {
            toast.error(Translate.t('TopUp.EnterAmount'));
        } else {
            NavActions.navToTopupWallet(propertyIndividualId, Number(amount));
        }
    };

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Packages.PackagesCard.CardTitlePropertyManagement'),
                onClick: () => { return false; },
            },
        ],
    );

    const setPropertyManagementDocumentID = () => {
        setPropertyManagementDocumentUploadID(propertyIndividualId);
        setModalOpen(true);
    };

    const setOwnerStatusForPackage = () => {
        if (propertyList.packages) {
            if (propertyList.packages.propertyManagement?.status === IPropertyPackageStatusEnum.Active) {
                if (propertyList.location?.address1) {
                    setCity(propertyList.location?.address1);
                }
                if (propertyList.location?.state) {
                    setState(propertyList.location?.state);
                }
                if (propertyList.location?.country) {
                    setCountry(propertyList.location?.country);
                }
                if (propertyList.packages.propertyManagement.status) {
                    setChipnumber(propertyList.packages.propertyManagement.status);
                }
            }
        }
    };

    const renderPrice = () => {
        return (
            <div>
                {walletBalanceAttemp && !walletBalanceError.length && (
                    <Oval
                        height={25}
                        width={25}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                )}
                {/* {(walletBalanceError) && (
                    <div>
                        <ErrorMessage>
                            {Translate.t('GeneralUI.TryAgainTopUp')}
                        </ErrorMessage>
                    </div>
                )} */}
                {(walletBalance?.amount) && (walletBalance?.amount !== 0) && !walletBalanceError.length ? (
                    <div>
                        {(walletBalanceError.length) ? <span>&nbsp;0</span> : walletBalance.amount}
                    </div>

                ) : <span>&nbsp;0</span>}
            </div>
        );
    };

    const onOpenCollapsibleOwner = () => {
        if (ownerTermsRef.current) {
            ownerTermsRef.current.openCollapsible();
        }
    };

    const checkPropertyPackagesStatus = (packages: number) => {
        const packagesnumber = packages;

        switch (packagesnumber) {
            case 0:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusNotPurchasedYet');
            case 1:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 2:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPending');
            case 3:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusActive');
            case 4:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusPaymentRejected');
            case 5:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusDisabled');
            case 6:
                return Translate.t('Packages.VacantPossession.ButtonInfo.StatusFreeTrial');
            case 7:
                return Translate.t('Packages.VacantPossession.ButtonInfo.Statuspurchase');
            default:
                return '';
        }
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <HeadersContainer>
                    {/* <CardCollapsibleStyled ref={ownerTermsRef} title={propertyList.name.substring(0, 20)} subtitle={propertyList.location ? `${(propertyList.location?.city) ? propertyList.location.city : city} ${(propertyList.location?.state) ? propertyList.location?.state : state} ${(propertyList.location?.country) ? propertyList.location.country : country}` : ''} chipText={(propertyList.packages?.propertyManagement?.status) ? checkPropertyPackagesStatus(propertyList.packages.propertyManagement.status) : ''}> */}
                    {loading && (
                        <PropertyManagementContainer>
                            <LoadingScreen />
                        </PropertyManagementContainer>
                    )}
                    {/* this line commented until implementing sign feature */}
                    {/* {(!loading) && (propertyList.packages && propertyList.packages.propertyManagement?.status === IPropertyPackageStatusEnum.Active) && ( */}
                    {(!loading) && ((propertyList.packages && propertyList.packages.propertyManagement?.status === IPropertyPackageStatusEnum.Active) || (propertyList.packages && propertyList.packages.propertyManagement?.status === IPropertyPackageStatusEnum.PendingSignature)) && (
                        <PropertyManagementContainer>
                            <WalletMainContainer>
                                <WalletDetailsContainer>
                                    <WalletDetailsTitleContainer>
                                        {Translate.t('Packages.PackagesCard.CardSubTitlePropertyManagementCurrentFunds')}
                                    </WalletDetailsTitleContainer>
                                    <WalletDetailsBalanceContainer>
                                        RM
                                        {' '}
                                        {renderPrice()}
                                    </WalletDetailsBalanceContainer>
                                </WalletDetailsContainer>
                                <WalletButtonContainer>
                                    <Button size='sm' label={Translate.t('Packages.PackagesCard.CardSubTitlePropertyManagementAddFunds')} onClick={() => setTopUpModal(!topUpModal)} />
                                    <Button size='sm' label='withdraw -' />
                                </WalletButtonContainer>
                            </WalletMainContainer>
                            <ModulesPropertyContainer>
                                <PropertySubInfoContainer>
                                    <PropertyImage src={HousingIMG} />
                                </PropertySubInfoContainer>
                                <PropertyInfoContainer>
                                    <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.UploadPropertyManagementDocument')} rightIconSrc={Icons.uploadIcon} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => setPropertyManagementDocumentID()} />
                                    <ButtonInfoPackages desc={Translate.t('Packages.PackagesCard.CardSubTitlePropertyManagementViewExpanses')} rightIconSrc={Icons.documentview} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => NavActions.navTopropertyManagementViewDocuments(propertyIndividualId)} />
                                    <ButtonInfoPackages desc='View Uploaded Documents' rightIconSrc={Icons.documentview} status={checkPropertyPackagesStatus(chipnumber)} onclick={() => NavActions.navTopropertyManagementViewDocumentsUploaded(propertyIndividualId)} />
                                    {/* <EventTrailRecentEvents title={Translate.t('Property.PropertyRecentEvents')} primary buttonLabel={Translate.t('Property.PropertyRecentEvents')} data={(propertyList.packages?.propertyManagement.recentEvents && propertyList.packages.propertyManagement.recentEvents[0]) ? propertyList.packages.propertyManagement.recentEvents : pendingEvents} /> */}
                                    {modalLoad && (
                                        <DocumentUploadIndex packageType={Category.PropertyManagement} />
                                    )}
                                </PropertyInfoContainer>
                            </ModulesPropertyContainer>

                            {/* <ModulesFooter>
                                <ModuleFooterNav>
                                    {Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')}
                                    <span>&gt;</span>
                                </ModuleFooterNav>
                            </ModulesFooter> */}

                            {/* this line commendted until implementing sign feature */}
                            {/* {(!loading) && (propertyList.packages && propertyList.packages.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && ( */}
                        </PropertyManagementContainer>
                    )}
                    {(!loading) && (propertyList.packages && propertyList.packages.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && (propertyList.packages && propertyList.packages.propertyManagement?.status !== IPropertyPackageStatusEnum.PendingSignature) && (
                        <PropertyManagementContainer>
                            <ButtonContainer>
                                <SubscriberButton
                                    label={Translate.t('Packages.PackagesCard.CardSubTitleBuyPropertyManagement')}
                                    onClick={() => NavActions.navToPackages(propertyIndividualId)}
                                />
                            </ButtonContainer>
                        </PropertyManagementContainer>

                    )}
                    {/* </CardCollapsibleStyled> */}

                    <InfoContainer>
                        <SupportContainer>
                            <SupportInfo />
                        </SupportContainer>

                        <NavigationContainer>
                            <NavigationTextTitle>{Translate.t('Packages.PackagesCard.QuickNavigation')}</NavigationTextTitle>
                            <NavigationTextDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</NavigationTextDescription>
                            {/* {THE QUICK NAVIGATION FEATURE TO BE USED IN FUTURE} */}
                            <NavigationContainers>
                                <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} onclick={() => NavActions.navToViewVacantPossessionPackages(propertyIndividualId)} />
                            </NavigationContainers>
                            <NavigationContainers>
                                <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} onclick={() => NavActions.navToViewDefectInspectionPackages(propertyIndividualId)} />
                            </NavigationContainers>
                            {/* <NavigationContainers>
                                <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleDesignRenovation')} onclick={() => undefined} />
                            </NavigationContainers> */}
                            <NavigationContainers>
                                <QuickNavigationButton desc={Translate.t('Packages.PackagesCard.CardTitleLeaseSale')} onclick={() => NavActions.navToViewLeaseAndSalePackages(propertyIndividualId)} />
                            </NavigationContainers>
                        </NavigationContainer>
                    </InfoContainer>

                </HeadersContainer>
                <Modal
                    show={topUpModal}
                    closeCursor
                    width='fit-content'
                    height='200px'
                    onClickClose={() => {
                        setTopUpModal(false);
                    }}
                >
                    <ModalContentMainContainer>
                        <p style={{ textAlign: 'center' }}>{Translate.t('TopUp.EnterTopUpAmount')}</p>
                        <RoundInputStyled placeholder={Translate.t('TopUp.EnterAmount')} value={amount} onChangeText={setAmountfunctiom} required />
                        <Button label={Translate.t('TopUp.Confirm')} size='sm' onClick={() => confirmNavigateToPayment()} />
                    </ModalContentMainContainer>
                </Modal>
            </MainContainer>
        </>

    );
};

const PropertyManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    object-fit: contain;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 20px;

    ${media.sm`
        margin-right: 0px;
    `}
`;

const CenteringRounInputDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const RoundInputStyled = styled(RoundInput)`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ModalContentMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 110%;
    padding: 20px;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const HeadersContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 65% 30%  ;
    padding: 20px;
    padding-top: 40px;
    gap: 20px;

    ${media.lg`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `}
`;

const InfoContainer = styled.div`
    width: 100%;
`;

const SupportContainer = styled.div`
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 10%;
    justify-content: center;

    ${media.sm`
        margin-top: 0;
    `}
`;

const NavigationContainers = styled.div`
    margin-top: 12px;
`;

const NavigationTextTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const NavigationTextDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    max-height: 544px;
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const CardCollapsibleStyled = styled(CardCollapsible)`
    width: 100%;
`;

const SubscriberButton = styled(Button)`
    display: flex;
    width: 90%;
    height: 28%;
    padding: 20px;
    background-color: #F2865E;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-self: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    height: 80px;

    ${media.sm`
        font-size: 18px;
    `}
`;

const ModulesFooter = styled.div<{ focused?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 48px;
    padding: 12px 20px;
    background-color: #FFF2E7;
    ${(props) => props.focused && css`
    background-color: #F2865E;;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    `}
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
`;

const ModuleFooterNav = styled.button`
    display: flex;
    align-items: center;
    color: #F2865E;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    span {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
    }
`;

const ModulesPropertyContainer = styled.div`
    display: flex;
    padding: 0px 20px 20px 20px;
    img {
        margin-right: 16px;
    }

    ${media.sm`
        padding: 0;
    `}
`;

const WalletMainContainer = styled.div`
    display: flex;
    padding: 40px;
    width: 100%;
    height: 10%;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    gap: 20px;

    ${media.sm`
        padding: 15px;
        flex-direction: column;
    `}
`;

const WalletDetailsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding: 15px;
`;

const WalletButtonContainer = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 20px;

    ${media.sm`
        margin-left: 0;
    `}
`;

const WalletDetailsTitleContainer = styled.div`
    display: flex;
    color: #696969;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
`;

const WalletDetailsBalanceContainer = styled.div`
    display: flex;
    color: black;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-left: 12px;
`;

const ErrorMessage = styled.p`
    display: flex;
    color: #ff0000;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-left: 12px;
`;

const PropertySubInfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    >:not(:last-child) {
        margin-bottom: 20px;
    }

    span {
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        font-style: italic;
        max-width: 224px;
        text-align: justify;
    }

    ${media.sm`
        display: none;
    `}
`;

const PropertyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    >:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getPropPropertyIndividualAttempt(state),
    error: Selectors.getPropPropertyIndividualFailure(state),
    propertyList: Selectors.getPropPropertyIndividual(state),
    walletBalanceAttemp: Selectors.getPropertyWalletDetailsAttempting(state),
    walletBalanceError: Selectors.getPropertyWalletDetailsError(state),
    walletBalance: Selectors.getPropertyWalletDetails(state),
    modalLoad: Selectors.packagesSetLeaseSaleModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyIndividual: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
    getWalletBalance: (propertyId: string) =>
        dispatch(Actions.propertyPropertyWalletAttempt({ propertyId })),
    setModalOpen: (state: boolean) => dispatch(Actions.setLeaseSaleModal(state)),
    setPropertyManagementDocumentUploadID: (params: string) => dispatch(Actions.setCreateDocumentUploadID(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManagement);
