import { fork } from 'redux-saga/effects';

import UserGateway from 'api/User';
import { RootSagaReturnType } from 'sagas/types';

import watchUserInfo from './getUserInfo';
import watchUpdateUserInfo from './setUpdateUserInfo';

export default (api: UserGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchUserInfo, api);
        yield fork(watchUpdateUserInfo, api);
    }

    return {
        rootSaga,
    };
};
