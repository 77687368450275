import React, { useState } from 'react';
import styled from 'styled-components';
import Translate from 'lib/Translate';
import { Button } from '@dm/bigfish';
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';

import media from 'lib/Breakpoints';
import Modal from '../../../components/Modal';
import Icons from '../../../assets/icons';
import UploadComponent from './TopUpWalletUploadDocuments';

export interface PaymentUploadProps {
    showOrClosemodal: boolean;
    isDocumentUploadGetUrlLoading: boolean;
    isgetDocumentUploadAttemp: boolean;
    setModalOpen: (state: boolean) => void;
    setEmailModal: (state: boolean) => void;
}

interface AddPaymentUploadData {
    name: string;
}

const WalletTopUpUpload = (props: PaymentUploadProps): JSX.Element => {
    const { showOrClosemodal, isDocumentUploadGetUrlLoading, isgetDocumentUploadAttemp, setModalOpen, setEmailModal } = props;

    const [counter, setCounter] = useState<AddPaymentUploadData[]>([{ name: '' }]);

    const onClickHandler = () => {
        setModalOpen(false);
    };

    const navCloseModal = () => {
        setEmailModal(true);
        setModalOpen(false);
    };

    const addDocumentUpload = () => {
        setCounter(previous => {
            return [...previous, { name: '' }];
        });
    };

    return (
        <MainContainer>
            <Modal show={showOrClosemodal} height='fit-content' width='538px'>
                <div>
                    <MainTitle>
                        <Title>{Translate.t('Packages.PaymentUpload.MainTitle')}</Title>

                        <CloseIcon src={Icons.closebutton} onClick={onClickHandler} />
                    </MainTitle>
                    <ReferenceContainer>{Translate.t('Packages.PaymentUpload.Reference')}</ReferenceContainer>
                    {counter.map((item, index) => {
                        const { name } = item;

                        const uniqueKey = name + index;
                        return (
                            <UploadComponent key={uniqueKey} index={index} />
                        );
                    })}

                    <AddDocumentContainer>
                        <AddDocumentContainerAddIcon>
                            <AddIcon src={Icons.AddIcon} onClick={addDocumentUpload} />
                            <TextNewDocument>{Translate.t('Packages.PaymentUpload.AddNewDocuments')}</TextNewDocument>
                        </AddDocumentContainerAddIcon>
                        <AddDocumentContainerButton>
                            <Button
                                label={Translate.t('Property.PropertySubmitButton')}
                                loading={isDocumentUploadGetUrlLoading || isgetDocumentUploadAttemp}
                                onClick={() => navCloseModal()}
                            />
                        </AddDocumentContainerButton>

                    </AddDocumentContainer>

                </div>
            </Modal>
        </MainContainer>
    );
};

const CloseIcon = styled(SVG)`
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
`;

const AddIcon = styled(SVG)`
    display: flex;
    flex-direction: column;
    margin-right: 8px;
`;

const Title = styled.h2`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    background-color: #ffffff;
`;

const MainContainer = styled.div`
    max-width: 824px;
    max-height: 584px;
    padding: 20px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const MainTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    ${media.sm`
        margin-bottom: 20px;
    `}
`;

const AddDocumentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;

const AddDocumentContainerAddIcon = styled.div`
    display: flex;
    align-items: center;
`;

const AddDocumentContainerButton = styled.div`
    max-width: 125px;
`;

const ReferenceContainer = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
`;

const TextNewDocument = styled.p`
    display: flex;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #696969;
`;

const mapStateToProps = (state: RootState) => ({
    showOrClosemodal: Selectors.packagesSetTopUpPaymentModal(state),
    isDocumentUploadGetUrlLoading: Selectors.packagesgetPackagesUploadUrlAttempting(state),
    isgetDocumentUploadAttemp: Selectors.packagesgetPackagesUploadUrlAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setModalOpen: (state: boolean) => dispatch(Actions.setTopUpPaymentModalOpen(state)),
    setEmailModal: (state: boolean) => dispatch(Actions.setPackagesEmailModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletTopUpUpload);
