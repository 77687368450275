import React from 'react';
import WelcomeModal from './WelcomeModal';
import WelcomeModalSuccess from './WelcomeModalSuccess';

const ModalSection = (): JSX.Element => {
    return (
        <>
            <WelcomeModal />
            <WelcomeModalSuccess />
        </>
    );
};

export default ModalSection;
