import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { toast } from 'react-toastify';

import UserGateway from 'api/User';

import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { PropertyUserInfoPayload } from 'redux/slices/user/types';

export default function* watchUpdateUserInfo(api: UserGateway): SagaWatcherReturnType {
    yield takeEvery('userInfo/userSetUserInfoAttempt', handleSetUpdateuserInfo, api);
}

function* handleSetUpdateuserInfo(api: UserGateway, data: PropertyUserInfoPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.SetUserInfo], {
        data: data.payload,
        authToken,

    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.userSetUpdateUserInfoFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.updateUserInfo(response.data));
        toast.success('User Information Updated');
    } else {
        // handle error
        yield put(Actions.userSetUpdateUserInfoFailure(response.message));
    }
}
