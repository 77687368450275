import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';

import { VerifySignUpPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchVerifySignUp(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authVerifySignUpAttempt', handleVerifySignUp, api);
}

function* handleVerifySignUp(api: AuthGateway, data: VerifySignUpPayload) {
    const { id } = data.payload;

    const response = yield* call([api, api.verifySignUp], { id: encodeURIComponent(id) });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.authVerifySignUpFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.authVerifySignUpSuccess(true));
    } else {
        // handle error
        yield put(Actions.authVerifySignUpFailure(response.message));
    }
}
