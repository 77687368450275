import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';
import { SetILeaseSalePayload } from 'redux/slices/packages/types';

import Utils from 'lib/Utils';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';

export default function* watchGetPackagesDataSort(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesGetIVacantPossessionDataSortAttempt', handlePackages, api);
}

function* handlePackages(api: PackagesGateway, data: SetILeaseSalePayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId, category, sort } = data.payload;

        const response = yield* call([api, api.getPackagesVacantPossessionSort], {
            category,
            propertyId,
            sort,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesGetIVacantPossessionDataSortFailure(Translate.t('ResetPassword.ResetPasswordToastError')));
                return;
            }
            yield put(Actions.packagesGetIVacantPossessionDataSortSuccess(response.data));
        } else {
        // handle error
            yield put(Actions.packagesGetIVacantPossessionDataSortFailure(response.message));
        }
    }
}
