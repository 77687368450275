import React from 'react';

import { Footer } from '@dm/bigfish';
import styled from 'styled-components';

import Full from './containers/Full';
import Toast from './components/Toast';
import GlobalStyle from './components/GlobalStyle';
import Header from './components/Header';
import ModalSection from './components/ModalSection';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const App = (): JSX.Element => {
    return (
        <AppContainer className='App'>
            <GlobalStyle />
            <Header />
            <ModalSection />
            <Full />
            <Footer />
            <Toast />
        </AppContainer>
    );
};

const AppContainer = styled.div`
    /* styles for all screen sizes */

    @media (max-width: 1285px) {
        /* styles for screens with a maximum width of 768px */
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        /* styles for screens with a width between 769px and 1024px */
    }

    @media (min-width: 1025px) {
        /* styles for screens with a minimum width of 1025px */
    }
`;

export default App;
