import { InitState } from 'redux/slices/packages';
import { IDefectInspection, ILeaseSale, IPackagePrice, IVacantPossession, PropertyManagementTransactionResponse, PaymentGatewayResponse, IProperty, IPropertyManagement, IVacantManagement, ViewTransactionResponse } from '@dm/types';
import { GetPaymentInfoRTO, IGoogleDriveContent, PackagesiD } from 'entities/packages';

const getPackagesPricingAttempt = (state: InitState): boolean => state.actions.getPackagesPricing || false;
const getPackagesPricingFailure = (state: InitState): string => state.error.getPackagesPricing || '';
const getPackagesPricing = (state: InitState): IPackagePrice => state.PackagesPricing || undefined;

const setPackagesPaymentsAttempting = (state: InitState): boolean => state.actions.setPackagesPayments || false;
const setPackagesPaymentsError = (state: InitState): string => state.error.setPackagesPayments || '';
const setPackagesPayments = (state: InitState): PaymentGatewayResponse => state.IPackagesPayment || '';

const getPackagesId = (state: InitState): PackagesiD => state.packagesId || null;
const setPaymentDocumentModal = (state: InitState): boolean => state.paymentDocumentModal || false;
const setConfirmPaymentMethodModal = (state: InitState): boolean => state.confirmPaymentMethodModal || false;

const getPackagesUploadUrlAttempting = (state: InitState): boolean => state.actions.getPackagesUploadUrl || false;
const getPackagesUploadUrlError = (state: InitState): string => state.error.getPackagesUploadUrl || '';
const getPackagesUploadUrl = (state: InitState): string => state.PackagesDocumentUploadURL || '';

const uploadPackagesDocumentAttempting = (state: InitState): boolean => state.actions.uploadPackagesDocument || false;
const uploadPackagesDocumentError = (state: InitState): string => state.error.uploadPackagesDocument || '';
const setDocumentUploadStatus = (state: InitState): boolean => state.documentUploadStatus || false;

const setPackagesDocumentUploadId = (state: InitState): string => state.documentUploadId || '';

const setVacantPossesionModal = (state: InitState): boolean => state.vacantPossesionModal || false;

const setpackagesDocumentViewVacantPossesion = (state: InitState): boolean => state.packagesDocumentVacantPossessionView || false;

const getPackagesDataDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesDataSort || false;
const getPackagesDataDetailsSortFailure = (state: InitState): string => state.error.getpackagesDataSort || '';

const getPackagesDataDetailsSort = (state: InitState): IVacantPossession => state.IPackagesVacantPossessionSort || undefined;

const setLeaseSaleModal = (state: InitState): boolean => state.leaseSaleModal || false;

const getPackagesLeaseAndSaleDataDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesLeaseAndSaleDataSort || false;
const getPackagesLeaseAndSaleDataDetailsSortFailure = (state: InitState): string => state.error.getpackagesLeaseAndSaleDataSort || '';

const getPackagesLeaseAndSaleDataDetailsSort = (state: InitState): ILeaseSale => state.IPackagesLeaseSaleDataDetailsSort || undefined;

const setPackagesBuyAll = (state: InitState): boolean => state.buyAll || false;
const setAllPackageCancel = (state: InitState): boolean => state.allCancel || false;

const getPackagesDefectInspectionDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesDefectInspectionDataSort || false;
const getPackagesDefectInspectionDetailsSortFailure = (state: InitState): string => state.error.getpackagesDefectInspectionDataSort || '';
const getPackagesDefectInspectionDetailsSort = (state: InitState): IDefectInspection => state.IPackagesDefectInspectionDataDetailsSort || undefined;
const setPackagesEmailModal = (state: InitState): boolean => state.packagesEmailModal || false;

const setAllPackagesUploadedDocumentComplete = (state: InitState): boolean => state.uploadedDocument || false;

const getPackagesDownloadSignDocumentsAttempting = (state: InitState): boolean => state.actions.getSignDocumentTemplate || false;
const getPackagesDownloadSignDocumentsError = (state: InitState): string => state.error.getSignDocumentTemplate || '';
const getPackagesDownloadSignDocuments = (state: InitState): string => state.packagesDocumentTemplate || '';

const setIsPackageSigned = (state: InitState): boolean => state.isPackageSigned || false;
const setSignCompleted = (state: InitState): boolean => state.signCompleted || false;

const getPackagesSignedUploadUrlAttempting = (state: InitState): boolean => state.actions.getPackagesSignedUploadUrl || false;
const getPackageSignedsUploadUrlError = (state: InitState): string => state.error.getPackagesSignedUploadUrl || '';
const getPackagesSignedUploadUrl = (state: InitState): string => state.PackagesSignedDocumentUploadURL || '';

const uploadPackagesSignedDocumentAttempting = (state: InitState): boolean => state.actions.uploadSignedPackagesDocument || false;
const uploadPackagesSignedDocumentError = (state: InitState): string => state.error.uploadSignedPackagesDocument || '';

const getPackagesPaymentCreditCardAttempting = (state: InitState): boolean => state.actions.getPackagesPaymentCreditCard || false;
const getPackagesPaymentCreditCardError = (state: InitState): string => state.error.getPackagesPaymentCreditCard || '';
const getPackagesPaymentCreditCard = (state: InitState): GetPaymentInfoRTO | null => state.PackagesPaymentCreditCardData || null;

const getPackagesPropertyManagementDetailsSortAttempt = (state: InitState): boolean => state.actions.getpackagesPropertyManagementDataSort || false;
const getPackagesPropertyManagementDetailsSortFailure = (state: InitState): string => state.error.getpackagesPropertyManagementDataSort || '';
const getPackagesPropertyManagementDetailsSort = (state: InitState): PropertyManagementTransactionResponse[] | null => state.IPackagesPropertyManagementDataDetailsSort || null;

const getPackagesPropertyManagementTransactionUpdateAttempting = (state: InitState): boolean => state.actions.setPropertyManagementTransaction || false;
const getPackagePropertyManagementTransactionUpdateError = (state: InitState): string => state.error.setPropertyManagementTransaction || '';
const getPackagesPropertyManagementTransactionUpdateSuccess = (state: InitState): string => state.PropertyManagementTransactionUpdatedData || '';

const getPackagesPropertyManagementTransactionUpdateGatewayAttempting = (state: InitState): boolean => state.actions.setPropertyManagementTransactionGateway || false;
const getPackagePropertyManagementTransactionUpdateGatewayError = (state: InitState): string => state.error.setPropertyManagementTransactionGateway || '';
const getPackagesPropertyManagementTransactionUpdateGatewaySuccess = (state: InitState): PaymentGatewayResponse | null => state.PropertyManagementTransactionUpdatedDataGateway || null;

const setpackagesPropertyManagementStatusUpdateModal = (state: InitState): boolean => state.packagesPropertyManagementStatusUpdateModal || false;

const getPackagesSetTopUpPaymentsAttempting = (state: InitState): boolean => state.actions.setTopUpPayments || false;
const getPackageSetTopUpPaymentsError = (state: InitState): string => state.error.setTopUpPayments || '';
const getPackagesSetTopUpPaymentsSuccess = (state: InitState): PaymentGatewayResponse | null => state.ITopUpPayment || null;

const setTopUpPaymentModal = (state: InitState): boolean => state.TopUpPaymentModal || false;

const getPackagesPropertyManagementDataDetailsSortAttempt = (state: InitState): boolean => state.actions.getPackagesDataPropertyManagementSort || false;
const getPackagesPropertyManagementDataDetailsSortFailure = (state: InitState): string => state.error.getPackagesDataPropertyManagementSort || '';
const getPackagesPropertyManagementDataDetailsSort = (state: InitState): IPropertyManagement | null => state.IPackagesPropertyManagementDataSortSuccess || null;

const getPackagesVacantManagementDataSortAttempt = (state: InitState): boolean => state.actions.getPackagesIvacantManagementDataSort || false;
const getPackagesVacantManagementDataSortFailure = (state: InitState): string => state.error.getPackagesIvacantManagementDataSort || '';
const getPackagesVacantManagementDataSort = (state: InitState): IVacantManagement | null => state.IPackagesIvacantManagementDataDetailsSort || null;

const getPackagesSubscriptionTransactionsAttempt = (state: InitState): boolean => state.actions.getSubscriptionTransactions || false;
const getPackagesSubscriptionTransactionsFailure = (state: InitState): string => state.error.getSubscriptionTransactions || '';
const getPackagesSubscriptionTransactions = (state: InitState): ViewTransactionResponse[] | null => state.IPackagesSubscriptionTransactionData || null;

const setPackagesSubscriptionTransactionsCancelAttempting = (state: InitState): boolean => state.actions.setSubscriptionTransactionsCancel || false;
const setPackagesSubscriptionTransactionsCancelError = (state: InitState): string => state.error.setSubscriptionTransactionsCancel || '';
const setPackagesSubscriptionTransactionsCancelSuccess = (state: InitState): string => state.IPackagesSubscriptionTransactionCancelData || '';

const setPackagesSubscriptionTransactionsRenewAttempting = (state: InitState): boolean => state.actions.setSubscriptionTransactionsRenew || false;
const setPackagesSubscriptionTransactionsRenewError = (state: InitState): string => state.error.setSubscriptionTransactionsRenew || '';
const setPackagesSubscriptionTransactionsRenewSuccess = (state: InitState): string => state.IPackagesSubscriptionTransactionRenewData || '';

const setTransactionApproveOrRenewLoaderModal = (state: InitState): boolean => state.TransactionApproveOrRenewLoaderModal || false;

const getGoogleDriveContentAttempting = (state: InitState): boolean => state.actions.getGoogleDriveContent || false;
const getGoogleDriveContentError = (state: InitState): string => state.error.getGoogleDriveContent || '';
const getGoogleDriveContent = (state: InitState): IGoogleDriveContent[] => state.googleDriveContent || [];

const getCreatePMTransactionAttempting = (state: InitState): boolean => state.actions.createPMTransaction || false;
const getCreatePMTransactionError = (state: InitState): string => state.error.createPMTransaction || '';
const getCreatedPMTransactionId = (state: InitState): string => state.transactionId || '';

export default {
    getPackagesPricingAttempt,
    getPackagesPricingFailure,
    getPackagesPricing,

    getPackagesId,

    setPackagesPaymentsAttempting,
    setPackagesPaymentsError,
    setPackagesPayments,

    setPaymentDocumentModal,
    setConfirmPaymentMethodModal,

    getPackagesUploadUrlAttempting,
    getPackagesUploadUrlError,
    getPackagesUploadUrl,

    uploadPackagesDocumentAttempting,
    uploadPackagesDocumentError,
    setDocumentUploadStatus,

    setPackagesDocumentUploadId,

    setVacantPossesionModal,

    setpackagesDocumentViewVacantPossesion,

    getPackagesDataDetailsSortAttempt,
    getPackagesDataDetailsSortFailure,
    getPackagesDataDetailsSort,

    setLeaseSaleModal,

    getPackagesLeaseAndSaleDataDetailsSortAttempt,
    getPackagesLeaseAndSaleDataDetailsSortFailure,
    getPackagesLeaseAndSaleDataDetailsSort,

    setPackagesBuyAll,
    setAllPackageCancel,

    getPackagesDefectInspectionDetailsSortAttempt,
    getPackagesDefectInspectionDetailsSortFailure,
    getPackagesDefectInspectionDetailsSort,

    getPackagesDownloadSignDocumentsAttempting,
    getPackagesDownloadSignDocumentsError,
    getPackagesDownloadSignDocuments,

    setIsPackageSigned,

    setSignCompleted,

    getPackagesSignedUploadUrlAttempting,
    getPackageSignedsUploadUrlError,
    getPackagesSignedUploadUrl,

    uploadPackagesSignedDocumentAttempting,
    uploadPackagesSignedDocumentError,
    setPackagesEmailModal,

    setAllPackagesUploadedDocumentComplete,

    getPackagesPaymentCreditCardAttempting,
    getPackagesPaymentCreditCardError,
    getPackagesPaymentCreditCard,

    getPackagesPropertyManagementDetailsSortAttempt,
    getPackagesPropertyManagementDetailsSortFailure,
    getPackagesPropertyManagementDetailsSort,

    getPackagesPropertyManagementTransactionUpdateAttempting,
    getPackagePropertyManagementTransactionUpdateError,
    getPackagesPropertyManagementTransactionUpdateSuccess,

    getPackagesPropertyManagementTransactionUpdateGatewayAttempting,
    getPackagePropertyManagementTransactionUpdateGatewayError,
    getPackagesPropertyManagementTransactionUpdateGatewaySuccess,

    setpackagesPropertyManagementStatusUpdateModal,

    getPackagesSetTopUpPaymentsAttempting,
    getPackageSetTopUpPaymentsError,
    getPackagesSetTopUpPaymentsSuccess,

    setTopUpPaymentModal,

    getPackagesPropertyManagementDataDetailsSortAttempt,
    getPackagesPropertyManagementDataDetailsSortFailure,
    getPackagesPropertyManagementDataDetailsSort,

    getPackagesVacantManagementDataSortAttempt,
    getPackagesVacantManagementDataSortFailure,
    getPackagesVacantManagementDataSort,

    getPackagesSubscriptionTransactionsAttempt,
    getPackagesSubscriptionTransactionsFailure,
    getPackagesSubscriptionTransactions,

    setPackagesSubscriptionTransactionsCancelAttempting,
    setPackagesSubscriptionTransactionsCancelError,
    setPackagesSubscriptionTransactionsCancelSuccess,

    setPackagesSubscriptionTransactionsRenewAttempting,
    setPackagesSubscriptionTransactionsRenewError,
    setPackagesSubscriptionTransactionsRenewSuccess,

    setTransactionApproveOrRenewLoaderModal,

    getGoogleDriveContentAttempting,
    getGoogleDriveContentError,
    getGoogleDriveContent,

    getCreatePMTransactionAttempting,
    getCreatePMTransactionError,
    getCreatedPMTransactionId,
};
