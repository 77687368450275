import React, { FunctionComponent, useState, useRef, useEffect, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import Translate from 'lib/Translate';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';

import icons from 'assets/icons';
import { RoundInput, Colors } from '@dm/bigfish';
import { Category, PaymentGatewayResponse } from '@dm/types';

interface UploadComponentProps {
    index: number
    paymentID: PaymentGatewayResponse; // PaymentID is name paymentID.paymentId because there is another paymentId in line 80 so to differenchiate both payment id
    uploadLoading: boolean;
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
    isDocumentUploadComplete: boolean;
    setUploadStatusReset: (state: boolean) => void;
}

const UploadComponent: FunctionComponent<UploadComponentProps> = (props: UploadComponentProps) => {
    const { index, getUploadUrl, paymentID, isDocumentUploadComplete, setUploadStatusReset, uploadLoading } = props;

    const [name, setName] = useState('');
    const [disableButton, setdisableButton] = useState(false);
    const [receiptShow, setReceiptShow] = useState('');

    const reference = useRef<HTMLInputElement>(null);

    useEffect(() => {
        onStatusChange();
    }, [isDocumentUploadComplete]);

    const onClickHandler = () => {
        if (reference.current) {
            reference.current.value = '';
            reference.current.click();
        }
    };

    const onStatusChange = () => {
        if (isDocumentUploadComplete === true) {
            setdisableButton(true);
            setUploadStatusReset(false);
        }
    };

    const fileSelectHandler = async (event: SyntheticEvent) => {
        if (!name) {
            toast.error(Translate.t('Packages.PackagesCard.InsertNameError'));
            return;
        }

        const target = event.target as HTMLInputElement;

        let documentExtension = '';

        if (target.files) {
            const doc = target.files[0];

            const { type } = doc;

            switch (type) {
                case 'application/pdf': documentExtension = '.pdf'; break;
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }

            if ((documentExtension === ('.png')) || (documentExtension === ('.jpeg')) || (documentExtension === ('.jpg')) || (documentExtension === ('.pdf'))) {
                getUploadUrl(Category.BankTransfer, paymentID.paymentId, name, documentExtension, doc);
                setReceiptShow(URL.createObjectURL((target.files as FileList)[0]));
            } else {
                toast.error(Translate.t('Packages.PaymentUpload.Filetypenotsupported'));
            }
        }
    };

    return (

        <MainContainerUploadComponent>
            <RoundInput value={name} placeholder='eg. 123' onChangeText={(e) => setName(e.target.value)} />
            {receiptShow && (
                <HrefContainer>
                    <a target='_blank' href={receiptShow} rel='noreferrer'>Receipt View</a>
                </HrefContainer>
            )}

            {!uploadLoading ? (
                <div>
                    <InputItemContainer onClick={onClickHandler} disabled={disableButton}>
                        <UploadIcon
                            src={icons.uploadIcon}
                        />
                        <p>{Translate.t('Packages.PaymentUpload.UploadDocument')}</p>
                        <input
                            onChange={(e: SyntheticEvent) => fileSelectHandler(e)}
                            accept='.jpg,.jpeg,.png,application/pdf,'
                            type='file'
                            hidden
                            ref={reference}
                        />
                    </InputItemContainer>

                </div>
            ) : (
                <div>
                    <InputItemContainer onClick={onClickHandler} disabled={disableButton}>
                        <TailSpin color={Colors.primary} height='20' width='125' />
                    </InputItemContainer>
                </div>
            )}
        </MainContainerUploadComponent>

    );
};

const MainContainerUploadComponent = styled.div`
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
`;

const UploadIcon = styled(SVG)`
    display: flex;
    margin-right: 5px;
`;

const InputItemContainer = styled.div<{disabled?: boolean, }>`
    display: flex;
    max-width: 125px;
    background: #F2B47E;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
    margin-bottom: 8px;
    ${props => (props.disabled) && css`
    color: ${Colors.greyLightest};
    background-color: ${Colors.greyLight};
    pointerEvents: none;
    cursor: none;
    &:hover{
        opacity: 1;
    };
  `}
`;

const HrefContainer = styled.div`
    display: flex;
    width: 40px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`;

const mapStateToProps = (state: RootState) => ({
    paymentID: Selectors.packagessetPackagesPayments(state),
    paymentIDError: Selectors.packagessetPackagesPaymentsError(state),
    uploadLoading: Selectors.packagesUploadPackagesDocumentAttempting(state),
    isDocumentUploadComplete: Selectors.packagesSetDocumentUploadStatus(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) =>
        dispatch(Actions.getPackagesUploadUrlAttempt({ category, id, name, extension, document })),
    setUploadStatusReset: (state: boolean) => dispatch(Actions.setDocumentUploadStatus(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadComponent);
