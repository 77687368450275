import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import { DetailedSignUpPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export default function* watchDetailedSignUp(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authDetailedSignUpAttempt', handleDetailedSignUp, api);
}

function* handleDetailedSignUp(api: AuthGateway, data: DetailedSignUpPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.detailedSignUp], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.authDetailedSignUpFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.authDetailedSignUpSuccess());
        yield put(Actions.setWelcomeModalSuccess(true));
    } else {
        // handle error
        yield put(Actions.authDetailedSignUpFailure(response.message));
        toast.error('Something went wrong. Please try again.');
    }
}
