export default {
    Ui: {
        UiCareline: 'Contact Our Careline',
        UiBack: 'Back',
    },

    GeneralUI: {
        BreadcrumblabelDashboard: 'Dashboard',
        BreadcrumblabelPropertyListings: 'Property Listings',
        BreadcrumblabelSetupProcess: 'Setup Process',
        BreadcrumblabelWelcomeBackUser: 'Welcome Back',
        BreadcrumblabelViewDocuments: 'View Documents',
        BreadcrumblabelTransactions: 'View Transactions',

        CollapsibleStatusPendingApproval: 'Pending Approval',
        CollapsibleStatusVacantVacant: 'vacant',
        CollapsibleStatusTenanted: 'tenanted',
        CollapsibleStatusDeactivated: 'deactivated',
        CollapsibleStatusDraftProperty: 'draft Property',
        CollapsibleStatusApproved: 'approved',
        CollapsibleStatusNone: '',
        Eg: 'eg. 123',
        all: 'all',
        UploadDocument: 'Upload Document',
        Noimagesfound: 'No images found',

        Failed: 'failed',
        TryAgain: 'failed! Please try again later',
        SomethingWrong: 'Something went wrong. Please try again.',
        Saved: 'Saved',
        Submit: 'Submit',
        TryAgainTopUp: 'Balance 0 or fail attemp',
    },

    Sign: {
        DocumentSign: 'Document Signing',
        Save: 'save',
        Undo: 'Undo',
        Redo: 'Redo',
        Clear: 'Clear',
        Agreement: 'Agreement',
    },

    Login: {
        LoginLabel: 'Login',
        LoginOwnerLogin: 'Owner Login',
        LoginEmail: 'Your email address',
        LoginPlaceholder: 'eg. example123@gmail.com',
        LoginPlaceholderPassword: 'enter a password',
        LoginRememberMe: 'Remember me',
        LoginPassword: 'Your password',
        LoginHaveYou: 'Have you',
        LoginForgotPassword: 'forgotten your password?',
        LoginNewTo: 'New to Dormeo?',
        LoginJoinNow: 'Join now',
        LoginErrorEmail: 'Please insert a valid email address',
        LoginBcLogin: 'Login',
        LoginBcOwnerLogin: 'Owner Login',
    },

    resetPassword: {
        ResetPasswordLabel: 'Email me a recovery link',
        ResetPasswordTitle: 'Reset your owner account’s forgotten password',
        ResetPasswordDesc: 'We will send you an email with the recovery link to reset your owner account’s password.',
        ResetPasswordEmail: 'Your email address',
        ResetPasswordEmailPlaceholder: 'eg. example123@gmail.com',
        ResetPasswordErrorEmail: 'Please insert a valid email address',
        ResetPasswordBcSignUp: 'Signup',
        ResetPasswordBcOwnerSignUp: 'Owner Signup',
        ResetPasswordBcForgotPassword: 'Forgot Password',
    },

    SignUp: {
        SignUpLabel: 'Signup',
        SignUpOwnerSignup: 'Owner Signup',
        SignUpToastError: 'Password and confirm password must be match',
        SignUpErrorEmail: 'Enter a valid email address',
        SignUpEmail: 'Your email address',
        SignUpPlaceholderEmail: 'eg. example123@gmail.com',
        SignUpPlaceholderPassword: 'enter a password',
        SignUpPassword: 'Your password',
        SignUpConfirmPassword: 'Confirm your password',
        SignUpAlreadyUser: 'Already a user?',
        SignUpLogin: 'Login',
        SignUpBcSignUp: 'Signup',
        SignUpBcOwnerSignUp: 'Owner Signup',
        SignUpPasswordStrengthText: 'Password must contain atleast 1 upper case, 1 lower case, 1 symbol and atleast 5 characters long',
    },

    VerifySignUp: {
        VerifySignUpLabel: 'Okay',
        VerifySignUpTitleSuccess: 'Your Account Has Been Verified!',
        VerifySignUpDescSuccess: 'Your Owner account has been verified, please login your Owner account to begin using Dormeo Homes services.',
        VerifySignUpTitleFailed: 'Your Account Has Not Been Verified!',
        VerifySignUpDescFailed: 'Your Owner account has not been verified, please check your email',
        VerifyEmailBcSignUp: 'Signup',
        VerifyEmailBcOwnerSignUp: 'Owner Signup',
        VerifyEmailBcVerificationEmail: 'Email Verification',

        VerifySignUplabel: 'signup',
        VerifySignUplabelOwner: 'owner signup',
        VerifySignUplabelemailverification: 'email verification',
        VerifySignUpTitleVerified: 'Your Account Has Been Verified!',
        VerifySignUpTextVerifiec: 'Your Owner account has been verified, please login your Owner account to begin using Dormeo Homes services.',
        VerifySignUpTitleNotVerified: 'Your Account Has Not Been Verified!',
    },

    Header: {
        companyName: 'Dormeo Homes',
        Searchtitle: 'buy',
        ProfileButtonTitle: 'login',
    },

    Profile: {
        Breadcrumblabel: 'My Profile',
        Titlelabel: 'Title',
        placeholder: 'Mr.',
        FullNamelabel: 'Full Name',
        johnplaceholder: 'Customer service',
        PassportNolabel: 'Passport No',
        Limplaceholder: 'Lim',
        CountryofOriginlabel: 'Country of Origin',
        Malaysiaplaceholder: 'Malaysia',
        Racelabel: 'Race',
        Chineseplaceholder: 'Chinese',
        DateofBirthlabel: 'Date of Birth',
        Showmorelabel: 'Show more',
        Genderlabel: 'Gender',
        IDNolabel: 'ID No.',
        numplaceholder: '912345-67-8900',
        MobileNolabel: 'Mobile No.',
        nump2laceholder: '012-34567890',
        HouseTellabel: 'House Tel.',
        nump3laceholder: '012-34567890',
        CountryofResidencelabel: 'Country of Residence',
        Japanplaceholder: 'Japan',
        Emaillabel: 'Email',
        gmailplaceholder: 'Josiah@dormeo.my',
        CorrespondingAddreslabel: 'Corresponding Addres',
        Jalanplaceholder: 'Jalan 1, Taman ABC',
        CityTownlabel: 'City/Town',
        Setapakplaceholder: 'Setapak',
        Postcodelabel: 'Postcode',
        Postcodeplaceholder: '56000',
        Statelabel: 'State',
        klplaceholder: 'Kuala Lumpur',
        Countrylabel: 'Country',

        UserInformationUpdated: 'User Information Updated',
        Male: 'Male',
        Female: 'Female',
        Chinese: 'Chinese',
        Indian: 'Indian',
        Malay: 'Malay',
        Others: 'Others',

        Showmore: 'Show more',
        Seeless: 'See Less',
        EditDetails: 'Edit Details',
        SaveDetails: 'Save Details',
        Cancel: 'Cancel',
        PersonalDetails: 'Personal Details',
        FinancialDetails: 'Financial Details',
        CompanyDetails: 'Company Details',
        EmergencyContactDetails: 'Emergency Contact Details',
        Subscriptions: 'Subscriptions',
        SubscriptionsTransactions: 'Subscriptions Transactions',
        NoSubscriptions: 'No subscriptions',

    },

    VerificationEmail: {
        VerificationEmailLabel: 'Okay, Got It.',
        VerificationEmailTitle: 'Email Verification',
        VerificationEmailDesc: 'Your account requires an email verification, please check your email.',
        VerificationEmailBcSignUp: 'Signup',
        VerificationEmailBcOwnerSignUp: 'Owner Signup',
        VerificationEmailBcVerificationEmail: 'Email Verification',
    },

    Property: {
        PropertyNo: 'no',
        PropertyRecentEvents: 'Recent Events',
        PropertyRecentEventsNav: 'Go to Defect Infection page',
        PropertyRecentEventsInfo: 'You have no recent events',
        PropertyVacantPossesion: 'Vacant Possesion',
        PropertyDefectInspection: 'Defect Inspection',
        PropertyDesignRenovation: 'Design Renovation',
        PropertyManagement: 'Property Management',
        PropertyLeaseSale: 'Lease & Sale',
        PropertyPage: 'Property Page',
        PropertyNoProperty: 'You currently have no attached properties. Add one now!',
        PropertyAddNewProperty: 'Add New Property',
        PropertyPendingActivites: 'Pending Activities',
        PropertyPendingActivitesNav: 'Go to Pending Activities page',
        PropertyPendingActivitesInfo: 'You have no recent activities',
        PropertyError: '⚠️ The server encountered an error and could not complete your request.',
        PropertyPendingApproval: 'Pending Approval',
        PropertyVacant: 'Vacant',
        PropertyTenanted: 'Tenanted',
        PropertyDeactivated: 'Deactivated',
        PropertyDraftProperty: 'Draft Property',
        PropertyApproved: 'Approved',

        PropertySubmitButton: 'Submit',
        PropertyConfirmButton: 'Confirm',
        PropertyClearButton: 'Clear',
        PropertyRemoveButton: 'Remove',
        PropertySubmitted: 'Submitted for approval',

        PropertyCreate: {
            PropertyTitleMessage: 'Please ensure each step of the process is filled in correctly to ensure no errors.',
            PropertyConfirmButton: 'Confirm',
            PropertyName: 'Property Name',
        },

        TermsAndConditions: {
            CollapsibleTitle: 'Terms And Conditions',
            CollapsiblePretext: 'Step 1:',
            ConfirmButtonLabel: 'I agree with the Terms & Conditions.',
        },

        Location: {
            TitleMessage: 'Please provide a description for your property.',
            CollapsibleTitle: 'Add Location',
            CollapsiblePretext: 'Step 2:',
            PlacesSearchBox: 'Places Search Box',
        },

        PropertyDetails: {
            TitleMessage: 'Property Type',
            CollapsibleTitle: 'Property Details',
            CollapsiblePretext: 'Step 3:',
            PropertyTypeLanded: 'Landed',
            PropertyTypeCondo: 'Condo',
            PropertyTypeRoom: 'Room',
            PropertyType: 'Property Type',
            UnitType: 'Unit Type',
            TypeApartment: 'Apartment',
            TypeLanded: 'Landed',

            TitleTypeOptionsStrata: 'Strata',
            TitleTypeOptionsMaster: 'Master',
            TitleTypeOptionsIndividual: 'Individual',

            tenureOptionsFreehold: 'Freehold',
            tenureOptionsLeaseHold: 'LeaseHold',

            LandTitleOptionsResidential: 'Residential',
            LandTitleOptionsCommercial: 'Commercial',

            preferredTenantOptionsChinese: 'Chinese',
            preferredTenantOptionsIndian: 'Indian',
            preferredTenantOptionsMalay: 'Malay',
            preferredTenantOptionsOthers: 'Others',

            furnishingOptionFully: 'Fully',
            furnishingOptionPartial: 'Partial',
            furnishingOptionNone: 'None',

            LandTitleLabel: 'LandTitle',
            PreferredTenantLabel: 'Preferred Tenant',
            PropertyTitleTypeLabel: 'Property Title Type',
            TenureLabel: 'Tenure',
            UnitTypeLabel: 'Unit Type',
            BuildUpSizeLabel: 'BuildUpSize',
            BuiltupPriceperSqftLabel: 'Built-up Price per sq. ft.',
            BuiltUpDimensionSqftLabel: 'Built Up Dimension sq. ft.',
            StoreyLabel: 'Storey',
            LandAreaSizeLabel: 'Land Area Size',
            FurnishingLabel: 'Furnish',
            OccupancyLabel: 'Occupancy',
            FacingDirectionLabel: 'Facing Direction',

            UnitTypePlaceHolder: 'Corner Unit',
            OccupancyPlaceHolder: 'Tenanted',
            FacingDirectionPlaceHolder: 'South East',
        },

        PropertyImagesAdd: {
            addImage: 'Add Image',
            addNewRoom: 'Add New Room',
            imageDeleteSuccess: 'Image Deletion Success',
            uploadImage: 'Create Room',
            providePropertyRoomName: 'Provide Room Name:',
        },

        PropertyAmenities: {

            ItemTitleFurnishing: 'Furnishing',
            ItemTitleFacilities: 'Facilities',
            CollapsibleTitle: 'Property Amenities',
            CollapsiblePretext: 'Step 4:',

            TelevisionLabel: 'Television',
            CurtainLabel: 'Curtain',
            MattressLabel: 'Mattress',
            WashingMachineLabel: 'Washing Machine',
            HoodHubLabel: 'Hood & Hub',
            AirConditionerLabel: 'Air-Conditioner',
            WaterHeaterLabel: 'Water Heater',
            DiningTableLabel: 'Dining Table',
            WardrobeLabel: 'Wardrobe',
            KitchenCabinetLabel: 'Kitchen Cabinet',
            OvenLabel: 'Oven',
            RefrigeratorLabel: 'Refrigerator',
            SofaLabel: 'Sofa',
            MicrowaveLabel: 'Microwave',
            BedframeLabel: 'Bedframe',
            InternetLabel: 'Internet',

            CoveredParkingLabel: 'Covered Parking',
            GymnasiumLabel: 'Gymnasium',
            BasketballCourtLabel: 'Basketball Court',
            RestaurantLabel: 'Restaurant',
            DobiLabel: 'Dobi / Laundromat',
            NurseryLabel: 'Nursery',
            PlaygroundLabel: 'Playground',
            SaunaLabel: 'Sauna',
            SwimmingPoolLabel: 'Swimming Pool',
            TennisCourtLabel: 'Tennis Court',
            SecurityLabel: '24-Hour Security',
            MiniMartLabel: 'Mini Mart',
            SquashCourtLabel: 'Squash Court',
            BadmintonCourtLabel: 'Badminton Court',
            ElevatorLabel: 'Elevator',
        },

        PropertyImages: {
            ItemTitle: 'You are required to provide photos of each rooms in your property.',
            CollapsibleTitle: 'Add Images',
            CollapsiblePretext: 'Step 5:',
            NewRoomLabel: 'Add another room',
            RoomNamePlaceHolder: 'Room name here',
            NameToastError: 'Insert Name Error',
        },

        PropertyDescription: {
            ItemTitle: 'Please provide a description for your property.',
            CollapsibleTitle: 'Property Description',
            CollapsiblePretext: 'Step 6:',
            InputPlaceHolder: 'Write your property description here',
            InputPlaceHolderError: 'Please write something about the property',
        },

        PropertyViewDetails: {
            BreadcrumblabelDashboard: 'Details Dashboard',
            BreadcrumblabelPropertyListing: 'Details Property Listing',
            referenceNumber: 'reference Number',
            PostedDate: 'Posted Date',
            TitleRoomDetails: 'Room Details',
            PropertyDetailsInstruction: 'Property Details Instruction',
            NoImagesFound: 'No images found',
        },

        ToPackagesSetup: 'Dormeo Homes Service Packages Setup',
    },

    PropertyImages: {
        ItemTitle: 'You are required to provide photos of each rooms in your property.',
        CollapsibleTitle: 'Add Images',
        CollapsiblePretext: 'Step 5:',
        NewRoomLabel: 'Add another room',
        RoomNamePlaceHolder: 'Room name here',
        NameToastError: 'Insert Name Error',
    },

    Packages: {
        BreadcrumblabelServicePackages: 'Service Packages',
        PackageMainTitle: 'Subscribe the Dormeo Homes Complete Service Package for ',
        PackageSubTitle: 'Complete Package',
        PackagesPerMonth: 'per month ',
        AllPackagesBought: 'All Packages Bought',
        Includesall: 'Includes all',
        services: 'services',
        NoPackages: 'no packages available',
        BuyDefectMessage: 'Defect Inspection requires Vacant Possession',

        PackagesAlternativeTitle: 'Alternatively',
        PackageChooseTitle: 'Choose the best Dormeo services below.',

        VacantPossessionPoints1: 'Verify deposit payment and obtain person-in-charge’s contact of building',
        VacantPossessionPoints2: 'Verify no outstanding payment by home owners ',
        VacantPossessionPoints3: 'Verify deposit payment and connectivity of utilities ',
        VacantPossessionPoints4: 'Check Certificate of completion and compliance',
        VacantPossessionPoints5: 'Receive Notice of vacant possession from developer',
        VacantPossessionPoints6: 'Prepare Authorisation Letter',

        DefectInspectionPoints1: 'Help owners to save time and cost with quality assured audit',
        DefectInspectionPoints2: 'Services cover high-rise condominiums, landed Properties , retail shops',
        DefectInspectionPoints3: 'Home defects inspection services ',
        DefectInspectionPoints4: 'Manage rectification works by developer',
        DefectInspectionPoints5: 'Defect inspection done to make sure the quality of service',

        DesignRenoVationPoints1: 'Brief analysis, concept of design and Renovation',
        DesignRenoVationPoints2: 'Work closely on the design process in order to reduce costs',
        DesignRenoVationPoints3: 'Recommendations on repairs and cosmetic improvements',
        DesignRenoVationPoints4: 'Detailed documentation of the interior and exterior',
        DesignRenoVationPoints5: 'Reduce costs without compromising on quality and aesthetics',

        PropertyManagementPoints1: 'Accounting property management services',
        PropertyManagementPoints2: 'Make payments onbehalf of owner',
        PropertyManagementPoints3: 'Maintain all historical records',
        PropertyManagementPoints4: 'Provide annual reporting',
        PropertyManagementPoints5: 'Advise owner on relevant tax deductions',

        LeaseAndSalePoints: 'Lease points here',

        StripeSuccess1stText: 'Payment was successful',
        StripeSuccess2stText: 'Transaction was  completed',
        BreadcrumblabelFailedTransaction: 'Payment Failed',
        BreadcrumblabelSuccessTransaction: 'Payment Success',
        StripeFailed: 'Transaction was not completed , please try again.',
        StripeGoHome: 'Back to home',
        CardInfo: 'Card Info',
        Card: 'Card',
        GatewayPaymentDetails: {
            Billinginfo: 'Billing info',
            FullName: 'Full Name',
            Title: 'Title',
            BillingAddress: 'Billing Address',
            City: 'City',
            Zipcode: 'Zipcode',
            State: 'State',
            Country: 'Country',
            ContactNumber: 'Contact No.',
            EmailAddress: 'Email Address',
        },

        PackagesCard: {

            QuickNavigation: 'Quick Navigation',
            QuickLink: 'Here are the quick links to our other services',
            CardTitleVacantPossession: 'Vacant Possession',
            CardSubTitleVacantPossession: 'Subtitle 1',
            CardMonthyPricing: 'per month',

            CardTitleDefectInspection: 'Defect Inspection',
            CardSubTitleDefectInspection: 'Subtitle 1',

            CardTitleDesignRenovation: 'Design & Renovation ',
            CardSubTitleDesignRenovation: 'Subtitle 1',

            CardTitlePropertyManagement: 'Property Management ',
            CardSubTitlePropertyManagement: 'Subtitle 1',
            CardSubTitlePropertyManagementAddFunds: 'Top up +',
            CardSubTitlePropertyManagementTransactions: 'Transactions',
            CardSubTitlePropertyManagementCurrentFunds: 'Current Funds:',
            CardSubTitlePropertyManagementViewExpanses: 'view property management transactions',
            UploadPropertyManagementDocument: 'Upload Property Management Related Documents',

            CardSubTitleBuyPropertyManagement: 'Purchase Property Management Package',

            CardTitleVacantManagement: 'Vacant Management',

            CardTitleLeaseSale: 'Lease & Sale ',
            CardSubTitleLeaseSale: 'Subtitle 1',

            PackageBought: 'package bought',
            PackageNotAvailable: 'package not available',

            PackagesBuy: 'Subscribe now',
            PackagesPending: 'Pending signature',

            PackagesSubscribeLabel: 'Subscribe Selected Packages',

            PackagesTitleVacantPossession: 'Vacant Possession Package.',
            PackagesTitleDefectInspection: 'Defect Inspection Package.',
            PackagesTitleDesignRenovation: 'Design Renovation Package.',
            PackagesTitlePropertyManagement: 'Property Management Package.',
            PackagesTitleLeaseSale: 'Lease Sale Package.',

            PackagesPaymentOptions: 'Select your Payment Options',
            BankTransfer: 'Manual Bank Transfer',
            CardTransfer: 'Credit/Debit Card',

            BankTransferTitle: 'Bank Transfer Procedure',
            BodySubTitle: 'Please proceed with bank transfer to:',

            BankName: 'Bank Name',
            BankNumber: 'Bank Number',
            AccountName: 'Account Name',
            SwiftCode: 'Swift Code',

            PackagesTitleMessage: 'Please ensure the correct amount is transferred into the account.',
            CheckoutSummary: 'Checkout Summary',

            ProceedPayment: 'Proceed with Payment',
            InsertNameError: 'Insert Name Error',

        },

        PaymentUpload: {
            MainTitle: 'Upload Receipt',
            Reference: 'Reference',
            AddNewDocuments: 'Add New Receipt',
            UploadDocument: 'Upload Receipt',
            InsertNameError: 'Insert Name Error',
            Filetypenotsupported: 'File type not supported , accepted formats are pdf and images ',
            Edit: 'Edit',
            PleaseUploadDocument: 'please upload a document to proceed',
            EmailNotification: 'Email notification',
            EmailMessage: 'after admin approve you will receive an email to',
            EmailMessageTo: ' on the next steps ',
            EmailMessageNoUser: 'after admin approve you will receive an email ',
            Okay: 'Okay',
            UploadSuccess: 'Uploaded Success',
            Error: 'Error when uploading',

        },

        VacantPossession: {

            ButtonInfo: {
                StatusNotPurchasedYet: 'not purchased yet',
                StatusPending: 'Pending',
                StatusActive: 'Active',
                StatusPaymentRejected: 'PaymentRejected',
                StatusDisabled: 'Disabled',
                StatusFreeTrial: 'FreeTrial',
                Statuspurchase: 'purchase',
            },

            CollapsibleStatus: {
                StatusPending: 'Property Pending Approval',
                StatusVacant: 'Vacant',
                StatusPropertyTenanted: 'PropertyTenanted',
                StatusPropertyDeactivated: 'PropertyDeactivated',
                StatusDraft: 'Property Draft Property',
                StatusApproved: 'roperty Approved',
            },

            UploadDocument: {
                Document1: 'Document 1',
                Document2: 'Document 2',
                Others: 'Others',
                Reference: 'Reference',
                AddNew: 'Add New',
                Upload: 'Upload',
            },

            UploadVPDocuments: 'Upload VP Documents',
            CheckVPRelatedDocuments: 'Check VP-Related Documents',
            IfNotBought: 'Purchase the Vacant Possession Package for this Property +',

        },

        LeaseAndSale: {

            ViewBid: 'View Property Bids',
            UploadLeaseDocument: 'Upload Lease & Sale-Related Documents',
            ViewLeaseDocuments: 'Check Lease & Sale-Related Documents',

            IfLeasePackageNotBought: 'Purchase the Lease & Sale Package for this Property +',
            PackageStatus: 'Package Status:',

            UploadDocument: {
                Reference: 'Reference',
                AddNew: 'Add New Document',
                Upload: 'Upload',
            },

        },

        DefectInspection: {

            ViewBid: 'View Property Bids',
            UploadDefectInspectionDocument: 'Upload Defect Inspection-Related Documents',
            ViewDefectInspectionDocuments: 'Check Defect Inspection-Related Documents',

            IfDefectInspectionNotBought: 'Purchase the Defect Inspection Package for this Property +',
            IfVacantPossessionNotBought: 'Purchase the Vacant Possesion Package as it is dependency for Defect Inspection Package for this Property +',
            IfNotBuyBothPackage: 'Purchase the Vacant Possesion And Defect Inspection Package for this Property +',
            PackageStatus: 'Package Status:',

            UploadDocument: {
                Reference: 'Reference',
                AddNew: 'Add New Document',
                Upload: 'Upload',
            },

        },

        ViewDocuments: {

            TransactionType: 'Types',
            Topup: 'Topup',
            Transaction: 'Transaction',
            Withdrawal: 'Withdrawal',
            Rental: 'Rental',

            DateDecending: 'Date Decending',
            DateAscending: 'Date Ascending',

            NameAscendingOrder: 'Name Ascending Order',
            NameDecendingOrder: 'Name Decending Order',

            FileName: 'File Name',
            DateUploaded: 'Date Uploaded',
            Actions: 'Actions',

            NoData: 'No Data Available',

            Back: 'Back',

            TitleMessage: 'Please make sure you select whether to search for tenants or properties.',
            FilterVacantDocuments: 'Filter Documents in Vacant Possession',
            FilterLeaseDocuments: 'Filter Documents in Lease And Sale',
            FilterPropertyManagement: 'Filter Documents in Property Management',
            FilterVacantManagement: 'Filter Documents in Vacant Management',
            FilterDefectInspectionDocuments: 'Filter Documents in Defect Inspection',

            ButtonDownloadLabel: 'Download',
            ButtonViewLabel: 'View',

            NoImages: 'No images',
            Documents: 'Documents',

        },

        PropertyManagement: {
            StatusPending: 'Pending',
            StatusAccepted: 'Accepted',
            StatusRejected: 'Rejected',
            StatusCompleted: 'Completed',
            StatusNoStatus: 'No status',
            StatusApprove: 'Approve',
            StatusDelete: 'Reject',
            ApproveConfirmation: 'Are you sure?',
            ApproveRejection: 'Are you sure?',
            Confirm: 'Confirm',
            SelectPaymentMethod: 'Please select payment method.',
            ManualBankTransfer: 'Manual Bank Transfer',
            Wallet: 'Wallet',
            StripePayment: 'Stripe Payment',
            PaymentMethod: 'Payment method:',
            NoPayment: 'Please select a payment method.',
        },

        MYR: 'MYR',

    },

    DetailedSignUp: {
        DetailedSignUpDesc: 'Please fill in the details for the following categories to complete your account setup.',
        DetailedSignUpTitle: 'Personal Details',
        DetailedSignUpLabelTitle: 'Title',
        DetailedSignUpLabelGender: 'Gender',
        DetailedSignUpLabelCPAddress1: 'Corresponding Address 1',
        DetailedSignUpLabelCPAddress2: 'Corresponding Address 2',
        DetailedSignUpLabelFirstName: 'First Name',
        DetailedSignUpLabelLastName: 'Last Name',
        DetailedSignUpLabelIDNo: 'ID No',
        DetailedSignUpLabelCity: 'City/ Town',
        DetailedSignUpLabelPassport: 'Passport No',
        DetailedSignUpLabelMobile: 'Mobile No',
        DetailedSignUpLabelPostcode: 'Postcode',
        DetailedSignUpLabelCountryOrigin: 'Country of Origin',
        DetailedSignUpLabelHouseTel: 'House Tel.',
        DetailedSignUpLabelState: 'State',
        DetailedSignUpLabelRace: 'Race',
        DetailedSignUpLabelCountryResidence: 'Country of Residence',
        DetailedSignUpLabelCountry: 'Country',
        DetailedSignUpLabelDOB: 'Date of Birth',
        DetailedSignUpLabelBankType: 'Bank Type',
        DetailedSignUpLabelCurrency: 'Currency',
        DetailedSignUpLabelBranchAddress: 'Bank Address',
        DetailedSignUpLabelBranchName: 'Bank Name',
        DetailedSignUpLabelBranchAccNo: 'Bank Acc No',
        DetailedSignUpLabelSwiftCode: 'Swift Code',
        DetailedSignUpLabelCompanyName: 'Company Name',
        DetailedSignUpLabelDesignation: 'Company Designation',
        DetailedSignUpLabelOfficeNo: 'Office No.',
        DetailedSignUpLabelFullName: 'Full Name',
        DetailedSignUpLabelRelationship: 'Relationship',
        DetailedSignUpLabelEmail: 'Email',
        DetailedSignUpButton: 'Save Changes',
        DetailedSignUpErrorEmail: 'Please insert a valid email address',
    },

    ResetPassword: {
        ResetPasswordTitle: 'Change Your Owner Account’s Password',
        ResetPasswordDesc: 'In order to protect your account, make sure your password is:',
        ResetPasswordRule1: 'is longer than 5 characters',
        ResetPasswordRule2: 'contains at least one capital and lowercase letter',
        ResetPasswordRule3: 'contains at least one number',
        ResetPasswordRule4: 'contains at least one special character',
        ResetPasswordLabelNewPassword: 'Your New Password',
        ResetPasswordLabelConfirmNewPassword: 'Confirm Your New Password',
        ResetPasswordPlaceholder: 'enter a password',
        ResetPasswordAgainPlaceholder: 'enter a password again',
        ResetPasswordErrorNewPassword: 'Enter a valid password',
        ResetPasswordErrorConfirmNewPassword: 'Confirm new password does not match with new password',
        ResetPasswordButton: 'Change Password',
        ResetPasswordBCSignup: 'Signup',
        ResetPasswordBCOwnerAccount: 'Owner Account',
        ResetPasswordBCChangePassword: 'Change Password',
        ResetPasswordToastSuccess: 'Your password has been changed!',
        ResetPasswordToastError: 'Something went wrong. Please try again.',
    },

    Unsubscribe: {
        UnsubscribeTitle: 'Unsubscribe',
        UnsubscribeDesc: 'Thank you, you have been successfully removed from this subscriber list and wont receive any further emails from us',
    },

    TopUp: {
        EnterAmount: 'please enter an amount',
        EnterTopUpAmount: 'Enter the amount you would like to top up',
        Confirm: 'Confirm',
    },
    PropertyRpTransactions: {
        PropertyRpTransactionFailed: 'Updating Transaction Status Failed',
        PropertyRpTransactionpropertyName: 'Property Name',
        PropertyRpTransactionpropertyDescription: 'Description',
        PropertyRpTransactionpropertyCreatedAt: 'Created at',
        PropertyRpTransactionpropertyUpdatedAt: 'Updated at',
        PropertyRpTransactionStatus: 'Status',
        PropertyRpTransactionTypes: 'Types',
        PropertyRpTransactionAmount: 'Amount',
        PropertyRpTransactionAction: 'Action',
        PropertyRpTransactionView: 'View',
        PropertyRpTransactionSearchTitle: 'Search by property Name',
        PropertyRpTransactionSearchTitleNameSearch: 'search property name',
        PropertyRpTransactionCreateTransaction: 'Create transaction',
        PropertyRpTransactionShowTransaction: 'show transaction',
        PropertyRpTransactionEnterAmount: 'Enter Amount',
        PropertyRpTransactionEnterRemarks: 'Enter Remarks ',
        PropertyRpTransactionuploadDocument: 'Upload document',
        PropertyRpTransactionSuccess: 'Upload Successful',
        PropertyRpTransactionFail: 'Upload Failed',
        PropertyRpTransactionConfirmCreateTransaction: 'Confirm create transaction for',
        PropertyRpTransactionSelectProperty: 'Select a property',
        PropertyRpTransactionMoreThan0: 'Please enter amount more than 0',
        PropertyRpTransactionOnlyNumbers: 'Please enter only numbers',
    },
};
