import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

interface FilterFirstSignUpRouteProps {
  isFirstSignUp: boolean,
  children: JSX.Element,
  setTabView: (tabView: string) => void;
}

const FilterFirstSignUpRoute = ({ isFirstSignUp, children, setTabView }: FilterFirstSignUpRouteProps): JSX.Element => {
    if (!isFirstSignUp) {
        return children;
    }

    setTabView('');
    return <Navigate to='/newSignIn' replace />;
};

const mapStateToProps = (state: RootState) => ({
    isFirstSignUp: Selectors.getAuthIsFirstSignUp(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterFirstSignUpRoute);
