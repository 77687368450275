import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/Translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { SetCreateImagePayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchImagesProperty(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/propertySetProperyImageAttempt', handleImages, api);
}

function* handleImages(api: PropertyGateway, data: SetCreateImagePayload) {
    const { category, id, name } = data.payload;

    const response = yield* call([api, api.setProperyImage], { category, id, name });

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.propertySetProperyImageSuccess(response.data));

        if (!response.data) {
            yield put(Actions.propertySetProperyImageFailure(Translate.t('GeneralUI.SomethingWrong')));
        }
    } else {
        yield put(Actions.propertySetProperyImageFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralUI.Failed'));
            } else {
                toast.error(Translate.t('GeneralUI.TryAgain'));
            }
        }
    }
}
