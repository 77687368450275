import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Translate from 'lib/Translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { RootState, AppDispatch } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { BreadCrumb, Button, Colors } from '@dm/bigfish';
import { IPackagePrice, IPropertyPackageStatusEnum } from '@dm/types';
import { PackagesiD } from 'entities/packages';
import { TailSpin } from 'react-loader-spinner';

import { IPropertyIndividual } from 'entities/property';
import MainContainer from '../../components/MainContainer';
import PackagesCardLoad from './componentsPackages/PackagesCardLoad';

interface PointsDropdownCompletePackageProps {
    vacantpoints: boolean;
    defectInpectionpoints: boolean;
    designRenovationpoints: boolean;
    propertyManagementpoints: boolean;
    leasepoints:boolean;
    packagesInfo: IPropertyIndividual;

}

const PointsDropdownCompletePackage = (props: PointsDropdownCompletePackageProps) : JSX.Element => {
    const { vacantpoints, defectInpectionpoints, designRenovationpoints, propertyManagementpoints, leasepoints, packagesInfo } = props;

    const [dataVacantPoints] = useState([
        {
            text: Translate.t('Packages.VacantPossessionPoints1'),
        },
        {
            text: Translate.t('Packages.VacantPossessionPoints2'),
        },
        {
            text: Translate.t('Packages.VacantPossessionPoints3'),
        },
        {
            text: Translate.t('Packages.VacantPossessionPoints4'),
        },
        {
            text: Translate.t('Packages.VacantPossessionPoints5'),
        },
    ]);

    const [dataDefectPoints] = useState([
        {
            text: Translate.t('Packages.DefectInspectionPoints1'),
        },
        {
            text: Translate.t('Packages.DefectInspectionPoints2'),
        },
        {
            text: Translate.t('Packages.DefectInspectionPoints3'),
        },
        {
            text: Translate.t('Packages.DefectInspectionPoints4'),
        },
        {
            text: Translate.t('Packages.DefectInspectionPoints5'),
        },
    ]);

    const [dataDesignRenovationPoints] = useState([
        {
            text: Translate.t('Packages.DesignRenoVationPoints1'),
        },
        {
            text: Translate.t('Packages.DesignRenoVationPoints3'),
        },
        {
            text: Translate.t('Packages.DesignRenoVationPoints4'),
        },
        {
            text: Translate.t('Packages.DesignRenoVationPoints5'),
        },

    ]);

    const [dataVacantManagement] = useState([
        {
            text: 'vacant management points',
        },
        {
            text: 'vacant management points',
        },
        {
            text: 'vacant management points',
        },
        {
            text: 'vacant management points',
        },

    ]);

    const [dataPropertyManagement] = useState([
        {
            text: Translate.t('Packages.PropertyManagementPoints1'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints2'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints3'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints4'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints5'),
        },
    ]);

    return (
        <DropdownContainerCompleteEdit vacantpoints={vacantpoints} defectInpectionpoints={defectInpectionpoints} designRenovationpoints={designRenovationpoints} propertyManagementpoints={propertyManagementpoints} leasepoints={leasepoints}>
            {(packagesInfo.packages?.vacantPossession?.price && packagesInfo.packages.vacantPossession.status !== IPropertyPackageStatusEnum.Active && packagesInfo.packages.vacantPossession.status !== IPropertyPackageStatusEnum.PendingSignature) && (

                <div>
                    {
                        dataVacantPoints.map((dropdown, index) => {
                            const uniqueKey = dropdown.text + index;
                            return <DropdownContentHeaderContentCompleteEdit key={uniqueKey}>{dropdown.text}</DropdownContentHeaderContentCompleteEdit>;
                        })
                    }
                </div>
            )}

            {(packagesInfo.packages?.defectInspection?.price && packagesInfo.packages.defectInspection.status !== IPropertyPackageStatusEnum.Active && packagesInfo.packages.defectInspection.status !== IPropertyPackageStatusEnum.PendingSignature) && (

                <div>
                    {
                        dataDefectPoints.map((dropdown, index) => {
                            const uniqueKey = dropdown.text + index;
                            return <DropdownContentHeaderContentCompleteEdit key={uniqueKey}>{dropdown.text}</DropdownContentHeaderContentCompleteEdit>;
                        })
                    }
                </div>
            )}

            {(packagesInfo.packages?.designRenovation?.price && packagesInfo.packages.designRenovation.status !== IPropertyPackageStatusEnum.Active && packagesInfo.packages.designRenovation.status !== IPropertyPackageStatusEnum.PendingSignature) && (
                <div>
                    {
                        dataDesignRenovationPoints.map((dropdown, index) => {
                            const uniqueKey = dropdown.text + index;
                            return <DropdownContentHeaderContentCompleteEdit key={uniqueKey}>{dropdown.text}</DropdownContentHeaderContentCompleteEdit>;
                        })
                    }
                </div>
            )}

            {(packagesInfo.packages?.propertyManagement?.price && packagesInfo.packages.propertyManagement.status !== IPropertyPackageStatusEnum.Active && packagesInfo.packages.propertyManagement.status !== IPropertyPackageStatusEnum.PendingSignature) && (
                <div>
                    {
                        dataPropertyManagement.map((dropdown, index) => {
                            const uniqueKey = dropdown.text + index;
                            return <DropdownContentHeaderContentCompleteEdit key={uniqueKey}>{dropdown.text}</DropdownContentHeaderContentCompleteEdit>;
                        })
                    }
                </div>
            )}

            {(packagesInfo.packages?.vacantManagement?.price && packagesInfo.packages.vacantManagement.status !== IPropertyPackageStatusEnum.Active && packagesInfo.packages.vacantManagement.status !== IPropertyPackageStatusEnum.PendingSignature) && (
                <div>
                    {
                        dataVacantManagement.map((dropdown, index) => {
                            const uniqueKey = dropdown.text + index;
                            return <DropdownContentHeaderContentCompleteEdit key={uniqueKey}>{dropdown.text}</DropdownContentHeaderContentCompleteEdit>;
                        })
                    }
                </div>
            )}

        </DropdownContainerCompleteEdit>
    );
};

interface PackagesProps {
    getPackages(propertyId: string): void;
    packagesList: IPackagePrice;
    packagesInfo: IPropertyIndividual;
    getAllpackagesInfo: (id: string) => void;
    allPackagesBought: boolean;
    setBuyAll: (state: boolean) => void;
}

interface NavParams {
    packageId: string;
}

const Packages = (props: PackagesProps) : JSX.Element => {
    const { packagesList, getPackages, packagesInfo, getAllpackagesInfo, allPackagesBought, setBuyAll } = props;
    const [packagesprice, setPackagesprice] = useState<number>();
    const [balancePrice, setbalancePrice] = useState<number>(0);
    const [balancePriceVacant, setbalancePriceVacant] = useState<number | undefined>(0);
    const [balancePriceDefect, setbalancePriceDefect] = useState<number | undefined>(0);
    const [balancePriceDesign, setbalancePriceDesign] = useState<number | undefined>(0);
    const [balancePriceProperty, setbalancePriceProperty] = useState<number | undefined>(0);
    const [balancePriceVacantManagement, setbalancePriceVacantManagement] = useState<number | undefined>(0);
    const [balancePriceLease, setbalancePriceLease] = useState<number | undefined>(0);

    const [packagesPriceDesignRenovation, setPackagesPriceDesignRenovation] = useState<number>();
    const [packagesPriceDefectInspection, setPackagesPriceDefectInspection] = useState<number>();
    const [packagesPriceVacantPossession, setPackagesPriceVacantPossession] = useState<number>();
    const [packagesPricePropertyManagement, setPackagesPricePropertyManagement] = useState<number>();
    const [packagesPriceLeaseSale, setPackagesLeaseSale] = useState<number>();
    const [packagesPriceVacantManagement, setPackagesPriceVacantManagement] = useState<number>();

    const [vacant, setVacant] = useState(true);
    const [defectinspection, setDefectinspection] = useState(true);
    const [designrenovation, setDesignrenovation] = useState(true);
    const [management, setManagement] = useState(true);
    const [lease, setLease] = useState(true);
    const [vacantManagement, setVacantManagement] = useState(true);
    const [packagesCounter, setPackagesCounter] = useState(5);
    const [buyType, setBuyType] = useState('');

    const [vacantPossessionpoints] = useState([{
        text: Translate.t('Packages.VacantPossessionPoints1'),
    },
    {
        text: Translate.t('Packages.VacantPossessionPoints2'),
    },
    {
        text: Translate.t('Packages.VacantPossessionPoints3'),
    },
    {
        text: Translate.t('Packages.VacantPossessionPoints4'),
    },
    {
        text: Translate.t('Packages.VacantPossessionPoints5'),
    }]);
    const [designnRenovationpoints] = useState([{
        text: Translate.t('Packages.DesignRenoVationPoints1'),
    },
    {
        text: Translate.t('Packages.DesignRenoVationPoints3'),
    },
    {
        text: Translate.t('Packages.DesignRenoVationPoints4'),
    },
    {
        text: Translate.t('Packages.DesignRenoVationPoints5'),
    }]);
    const [defectInspectionpoints] = useState([{
        text: Translate.t('Packages.DefectInspectionPoints1'),
    },
    {
        text: Translate.t('Packages.DefectInspectionPoints2'),
    },
    {
        text: Translate.t('Packages.DefectInspectionPoints3'),
    },
    {
        text: Translate.t('Packages.DefectInspectionPoints4'),
    },
    {
        text: Translate.t('Packages.DefectInspectionPoints5'),
    }]);
    const [propertyManagementpoints] = useState([
        {
            text: Translate.t('Packages.PropertyManagementPoints1'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints2'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints3'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints4'),
        },
        {
            text: Translate.t('Packages.PropertyManagementPoints5'),
        }]);

    const [vacantManagementpoints] = useState([
        {
            text: 'vacant Management points',
        },
        {
            text: 'vacant Management points',
        },
        {
            text: 'vacant Management points',
        },
        {
            text: 'vacant Management points',
        },
    ]);

    const [num] = useState<number>(1);

    const useLocationstate = useLocation();
    const { packageId } = useLocationstate.state as NavParams;

    const [breadCrumb] = useState([
        {
            label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
            onClick: () => { NavActions.navToDashboard(); },
        },
        {
            label: `${(packagesInfo?.name) ? packagesInfo.name : ''}`,
            onClick: () => { return false; },
        },
        {
            label: Translate.t('Packages.BreadcrumblabelServicePackages'),
            onClick: () => { return false; },
        },
    ]);

    useEffect(() => {
        setbalancePriceLease(0);

        getPackages(packageId);
        getAllpackagesInfo(packageId);
    }, []);

    useEffect(() => {
        if ([packagesList!.defectInspection].length) {
            if (typeof (packagesList?.defectInspection) === typeof (num)) {
                setPackagesPriceDefectInspection(packagesList?.defectInspection);
            } else {
                setPackagesPriceDefectInspection(0);
                setDefectinspection(false);
            }
        }
        if ([packagesList!.vacantPossession].length) {
            if (typeof (packagesList?.vacantPossession) === typeof (num)) {
                setPackagesPriceVacantPossession(packagesList?.vacantPossession);
            } else {
                setPackagesPriceVacantPossession(0);
                setVacant(false);
            }
        }

        if ([packagesList!.designRenovation].length) {
            if (typeof (packagesList?.designRenovation) === typeof (num)) {
                setPackagesPriceDesignRenovation(packagesList?.designRenovation);
            } else {
                setPackagesPriceDesignRenovation(0);
                setDesignrenovation(false);
            }
        }

        if ([packagesList!.propertyManagement].length) {
            if (typeof (packagesList?.propertyManagement) === typeof (num)) {
                setPackagesPricePropertyManagement(packagesList?.propertyManagement);
            } else {
                setPackagesPricePropertyManagement(0);
                setManagement(false);
            }
        }

        if ([packagesList!.vacantManagement].length) {
            if (typeof (packagesList?.vacantManagement) === typeof (num)) {
                setPackagesPriceVacantManagement(packagesList?.vacantManagement);
            } else {
                setPackagesPriceVacantManagement(0);
                setVacantManagement(false);
            }
        }

        if ([packagesList!.leaseSale].length) {
            if (typeof (packagesList?.leaseSale) === typeof (num)) {
                setPackagesLeaseSale(packagesList?.leaseSale);
            } else {
                setPackagesLeaseSale(0);
                setLease(false);
            }
        }

        if ((packagesPriceDesignRenovation || packagesPriceDesignRenovation === 0) && (packagesPriceDefectInspection || packagesPriceDefectInspection === 0) && (packagesPriceVacantPossession || packagesPriceVacantPossession === 0) && (packagesPricePropertyManagement || packagesPricePropertyManagement === 0) && (packagesPriceVacantManagement || packagesPriceVacantManagement === 0) && (packagesPriceLeaseSale || packagesPriceLeaseSale === 0)) {
            setPackagesprice(packagesPriceDesignRenovation + packagesPriceDefectInspection + packagesPriceVacantPossession + packagesPricePropertyManagement + packagesPriceVacantManagement + packagesPriceLeaseSale);
            if (packagesInfo.packages?.vacantPossession?.status !== undefined) {
                setbalancePriceVacant(checkPropertyStatusVacantPossesion(packagesInfo.packages?.vacantPossession?.status));
            }
            if (packagesInfo.packages?.defectInspection?.status !== undefined) {
                setbalancePriceDefect(checkPropertyStatusDefectInspection(packagesInfo.packages?.defectInspection?.status));
            }
            if (packagesInfo.packages?.designRenovation?.status !== undefined) {
                setbalancePriceDesign(checkPropertyStatusDesignRenovation(packagesInfo.packages?.designRenovation?.status));
            }
            if (packagesInfo.packages?.propertyManagement?.status !== undefined) {
                setbalancePriceProperty(checkPropertyStatusPropertyManagement(packagesInfo.packages?.propertyManagement?.status));
            }
            if (packagesInfo.packages?.vacantManagement?.status !== undefined) {
                setbalancePriceVacantManagement(checkPropertyStatusVacantManagement(packagesInfo.packages?.vacantManagement?.status));
            }

            if (packagesInfo.packages?.leaseSale?.status !== undefined) {
                setbalancePriceLease(checkPropertyStatusLease(packagesInfo.packages?.leaseSale?.status));
            }
        }
        setHowManyPackages();
    }, [packagesList]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const buyAllOrCancelAllFunction = () => {
        if (allPackagesBought === false) {
            setBuyAll(!allPackagesBought);
            setBuyType('buy all');
        } else {
            setBuyAll(!allPackagesBought);
            setBuyType('cancel all');
        }
    };

    const setHowManyPackages = () => {
        if (packagesList) {
            let buyAllVacantPosession = 0;
            let buyAllDefectInspection = 0;
            let buyAllDesignRenovation = 0;
            let buyAllPropertyManagement = 0;
            let buyAllVacantManagement = 0;

            if (([packagesList?.vacantPossession].length) && (typeof (packagesList?.vacantPossession) === typeof (num))
            && (packagesList?.vacantPossession !== 0) && (packagesInfo.packages?.vacantPossession?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.vacantPossession?.status !== IPropertyPackageStatusEnum.PendingSignature)) {
                buyAllVacantPosession = 1;
            }
            if (([packagesList?.defectInspection].length) && (typeof (packagesList?.defectInspection) === typeof (num))
            && (packagesList?.defectInspection !== 0) && (packagesInfo.packages?.defectInspection?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.defectInspection?.status !== IPropertyPackageStatusEnum.PendingSignature)) {
                buyAllDefectInspection = 1;
            }
            if (([packagesList?.designRenovation].length) && (typeof (packagesList?.designRenovation) === typeof (num))
            && (packagesList?.designRenovation !== 0) && (packagesInfo.packages?.designRenovation?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.designRenovation?.status !== IPropertyPackageStatusEnum.PendingSignature)) {
                buyAllDesignRenovation = 1;
            }
            if (([packagesList?.propertyManagement].length) && (typeof (packagesList?.propertyManagement) === typeof (num))
            && (packagesList?.propertyManagement !== 0) && (packagesInfo.packages?.propertyManagement?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.propertyManagement?.status !== IPropertyPackageStatusEnum.PendingSignature)) {
                buyAllPropertyManagement = 1;
            }
            if (([packagesList?.vacantManagement].length) && (typeof (packagesList?.vacantManagement) === typeof (num))
            && (packagesList?.vacantManagement !== 0) && (packagesInfo.packages?.vacantManagement?.status !== IPropertyPackageStatusEnum.Active) && (packagesInfo.packages?.vacantManagement?.status !== IPropertyPackageStatusEnum.PendingSignature)) {
                buyAllVacantManagement = 1;
            }
            setPackagesCounter(buyAllVacantPosession + buyAllDefectInspection + buyAllDesignRenovation + buyAllPropertyManagement + buyAllVacantManagement);
        }
    };

    const checkPropertyStatusVacantPossesion = (status: number) => {
        switch (status) {
            case IPropertyPackageStatusEnum.PendingSignature:
                return packagesInfo.packages?.vacantPossession?.price;
            case IPropertyPackageStatusEnum.Active:
                return packagesInfo.packages?.vacantPossession?.price;
            default:
                return 0;
        }
    };

    const checkPropertyStatusDefectInspection = (status: number) => {
        switch (status) {
            case IPropertyPackageStatusEnum.PendingSignature:
                return packagesInfo.packages?.defectInspection?.price;
            case IPropertyPackageStatusEnum.Active:
                return packagesInfo.packages?.defectInspection?.price;
            default:
                return 0;
        }
    };
    const checkPropertyStatusDesignRenovation = (status: number) => {
        switch (status) {
            case IPropertyPackageStatusEnum.PendingSignature:
                return packagesInfo.packages?.designRenovation?.price;
            case IPropertyPackageStatusEnum.Active:
                return packagesInfo.packages?.designRenovation?.price;
            default:
                return 0;
        }
    };

    const checkPropertyStatusPropertyManagement = (status: number) => {
        switch (status) {
            case IPropertyPackageStatusEnum.PendingSignature:
                return packagesInfo.packages?.propertyManagement?.price;
            case IPropertyPackageStatusEnum.Active:
                return packagesInfo.packages?.propertyManagement?.price;
            default:
                return 0;
        }
    };

    const checkPropertyStatusVacantManagement = (status: number) => {
        switch (status) {
            case IPropertyPackageStatusEnum.PendingSignature:
                return packagesInfo.packages?.vacantManagement?.price;
            case IPropertyPackageStatusEnum.Active:
                return packagesInfo.packages?.vacantManagement?.price;
            default:
                return 0;
        }
    };

    const checkPropertyStatusLease = (status: number) => {
        switch (status) {
            case IPropertyPackageStatusEnum.PendingSignature:
                return packagesInfo.packages?.leaseSale?.price;
            case IPropertyPackageStatusEnum.Active:
                return packagesInfo.packages?.leaseSale?.price;
            default:
                return 0;
        }
    };

    return (
        <div>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <MainTitleContainer>
                    <DescriptionContainer>
                        {Translate.t('Packages.PackageMainTitle')}
                    </DescriptionContainer>
                    <PropertyTitleContainer>{(packagesInfo.name) ? (packagesInfo.name) : '' }</PropertyTitleContainer>
                </MainTitleContainer>
                <MainBodyContainer>
                    <MainBodyPackageTitle>
                        <MainBodyPackageTitleSub1>
                            {Translate.t('Packages.PackageSubTitle')}
                        </MainBodyPackageTitleSub1>
                        <MainBodyPackageTitleSub2>{`${Translate.t('Packages.Includesall')} ${packagesCounter} ${Translate.t('Packages.services')}`}</MainBodyPackageTitleSub2>
                    </MainBodyPackageTitle>
                    <MainContainerPricing>
                        <div>
                            {(((packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.PendingSignature)
                            && (packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.PendingSignature)
                                    && (packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.PendingSignature)
                                    && (packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.PendingSignature)
                                    && (packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.PendingSignature)
                                    && (packagesInfo.packages?.leaseSale?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.leaseSale?.status === IPropertyPackageStatusEnum.PendingSignature))) ? (
                                        <div>{(packagesprice && packagesprice !== 0) && <PriceTitle>{Translate.t('Packages.AllPackagesBought')}</PriceTitle>}</div>
                                )
                                : (
                                    <div>
                                        {(((packagesPriceDesignRenovation || packagesPriceDesignRenovation === 0)
                                            && (packagesPriceDefectInspection || packagesPriceDefectInspection === 0)
                                            && (packagesPriceVacantPossession || packagesPriceVacantPossession === 0)
                                            && (packagesPricePropertyManagement || packagesPricePropertyManagement === 0)
                                            && (packagesPriceVacantManagement || packagesPriceVacantManagement === 0)

                                        )
                                            && ((packagesPriceDesignRenovation + packagesPriceDefectInspection + packagesPriceVacantPossession + packagesPricePropertyManagement + packagesPriceVacantManagement) - (balancePriceVacant! + balancePriceDefect! + balancePriceDesign! + balancePriceVacantManagement! + balancePriceProperty!)) !== 0)
                                            ? (
                                                <div>
                                                    <PriceTitle>
                                                        Rm
                                                        {((packagesPriceDesignRenovation + packagesPriceDefectInspection + packagesPriceVacantPossession + packagesPricePropertyManagement + packagesPriceVacantManagement) - (balancePriceVacant! + balancePriceDefect! + balancePriceDesign! + balancePriceVacantManagement! + balancePriceProperty!)).toFixed(2)}
                                                    </PriceTitle>
                                                    <PriceTitleSub>{Translate.t('Packages.PackagesPerMonth')}</PriceTitleSub>
                                                </div>

                                            )
                                            : (
                                                <div>
                                                    <TailSpin color={Colors.primary} height={20} width={20} />
                                                    <PriceTitleSub>{Translate.t('Packages.NoPackages')}</PriceTitleSub>
                                                </div>
                                            )}

                                    </div>
                                )}
                        </div>
                    </MainContainerPricing>
                    {(packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantPossession?.status === IPropertyPackageStatusEnum.PendingSignature)
                    && (packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.defectInspection?.status === IPropertyPackageStatusEnum.PendingSignature)
                    && (packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.propertyManagement?.status === IPropertyPackageStatusEnum.PendingSignature)
                    && (packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.designRenovation?.status === IPropertyPackageStatusEnum.PendingSignature)
                    && (packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.vacantManagement?.status === IPropertyPackageStatusEnum.PendingSignature)
                    && (packagesInfo.packages?.leaseSale?.status === IPropertyPackageStatusEnum.Active || packagesInfo.packages?.leaseSale?.status === IPropertyPackageStatusEnum.PendingSignature)
                        ? (
                            <SubscribeButtonUnclickableContainer label={Translate.t('Packages.PackagesCard.PackagesBuy')} />
                        ) : <SubscribeButtonContainer onClick={buyAllOrCancelAllFunction} label={Translate.t('Packages.PackagesCard.PackagesBuy')} />}

                    <PointsDropdownContainer>
                        <PointsDropdownCompletePackage packagesInfo={packagesInfo} vacantpoints={vacant} defectInpectionpoints={defectinspection} designRenovationpoints={designrenovation} propertyManagementpoints={management} leasepoints={lease} />
                    </PointsDropdownContainer>
                </MainBodyContainer>
                <AlternativeTitle>
                    <AlternativeSubTitle1>{Translate.t('Packages.PackagesAlternativeTitle')}</AlternativeSubTitle1>
                    <AlternativeSubTitle2>{Translate.t('Packages.PackageChooseTitle')}</AlternativeSubTitle2>
                </AlternativeTitle>
                <PackagesCardLoad
                    buyType={buyType}
                    setBuyType={setBuyType}
                    packagesPointsVacantPossessionPoints={vacantPossessionpoints}
                    packagesPointsDefectInspection={defectInspectionpoints}
                    packagesPointsDesignRenovation={designnRenovationpoints}
                    packagesPointsPropertyManagement={propertyManagementpoints}
                    packagesPointsVacantManagement={vacantManagementpoints}
                />

            </MainContainer>

        </div>

    );
};

const SubscriberButtonDisabled = styled(Button)`
    margin-left: 20px;
    margin-top: 48px;
    margin-bottom: 48px;
    width: 14%;
    max-width: 14%;
    height: 32px;
    max-height: 32px;
    padding: 2px;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #cccccc;
    color: #fffefe;
    cursor: not-allowed;
    pointer-events: none;
`;

const MainTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    color: black;
    margin-top: 64px;
    width: 100%;
    max-height: 96px;
    height: 96px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
`;

const DescriptionContainer = styled.div`
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    background-color: #ffffff;
    color: #696969;
    width: 100%;
    height: 28px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
`;

const PropertyTitleContainer = styled.div`
    display: flex;
    background-color: #ffffff;
    color: black;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    margin-top: 20px;
    width: 100%;
    max-height: 46px;
    justify-content: center;
    align-items: center;
`;

const MainBodyContainer = styled.div`
    width: 58%;
    height: 30%;
    max-height: 30%;
    margin-left: 20%;
    margin-top: 88px;
    margin-bottom: 80px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-bottom: 45px;
`;

const MainBodyPackageTitle = styled.div`
    display: flex;
    background-color: #F2B47E;
    color: white;
    width: 100%;
    height: 68px;
    max-height: 68px;
    padding: 20px;
    margin-bottom: 48px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

const MainContainerPricing = styled.div`
    width: 100%;
    padding-left: 20px;
`;

const MainBodyPackageTitleSub1 = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;    
`;

const MainBodyPackageTitleSub2 = styled.div`
    margin-left: 8px;
    margin-top: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const PriceTitle = styled.div`
    display: flex;
    color: #000000;
    width: 100%;
    height: 48px;
    max-height: 48px;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    justify-self: flex-end;
`;

const PriceTitleSub = styled.div`
    display: flex;
    background-color: #ffffff;
    color: #000000;
    width: 266px;
    max-width: 266px;
    height: 24px;
    max-height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-top: 8px;
`;

const SubscribeButtonUnclickableContainer = styled(Button)`
    margin-left: 20px;
    margin-top: 48px;
    margin-bottom: 20px;
    width: 14%;
    max-width: 14%;
    height: 32px;
    max-height: 32px;
    padding: 2px;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    @media (max-width: 1600px) {
    margin-top: 48px;
    margin-bottom: 20px;
    width: 14%;
    max-width: 14%;
    height: 32px;
    max-height: 32px;
    padding: 2px;
    align-items: center;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 12px;
    }

    @media (max-width: 750px) {
    align-items: center;
    font-style: normal;
    font-weight: 100;
    font-size: 8px;
    line-height: 8px;
    }

    background-color: #F6F6F6;
    color: black;
    cursor: not-allowed;
    border-color: black;
    border-style: ridge;
`;

const SubscribeButtonContainer = styled(Button)`
    margin-left: 20px;
    margin-top: 48px;
    margin-bottom: 20px;
    width: 14%;
    max-width: 14%;
    height: 32px;
    max-height: 32px;
    padding: 2px;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    @media (max-width: 1600px) {
    margin-top: 48px;
    margin-bottom: 20px;
    width: 14%;
    max-width: 14%;
    height: 32px;
    max-height: 32px;
    padding: 2px;
    align-items: center;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 12px;
    }

    @media (max-width: 750px) {
    align-items: center;
    font-style: normal;
    font-weight: 100;
    font-size: 8px;
    line-height: 8px;
    }
`;

const AlternativeTitle = styled.div`
    background-color: #ffffff;
    color: #000000;
    width: 253px;
    max-width: 253px;
    height: 56px;
    max-height: 56px;
    margin-top: 80px;
    margin-left: 40%;
`;

const AlternativeSubTitle1 = styled.div`
    background-color: #ffffff;
    color: #000000;
    width: 141px;
    max-width: 141px;
    height: 28px;
    max-height: 28px;
    margin-left: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
`;

const AlternativeSubTitle2 = styled.div`
    background-color: #ffffff;
    color: #000000;
    width: 252px;
    max-width: 253px;
    height: 16px;
    max-height: 16px;
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const PointsDropdownContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 0%;
    padding-left: 20px;
    padding-right: 20px;
`;

const DropdownContainerCompleteEdit = styled.div<{vacantpoints?: boolean, defectInpectionpoints?: boolean, designRenovationpoints?:boolean, propertyManagementpoints?:boolean, leasepoints?:boolean, }>`
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    width: 100%;
    height: 100%;
`;

const DropdownContentHeaderContentCompleteEdit = styled.li<{focused?: boolean}>`
    margin-right: 6px;
    color: black;
    margin-top: 20px;
    ${(props) => props.focused && css`
    color: #ffffff;;
    `}
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`;

const mapStateToProps = (state: RootState) => ({
    packagesList: Selectors.getPackagesGetPackagesPricing(state),
    packagesInfo: Selectors.getPropPropertyIndividual(state),
    allPackagesBought: Selectors.packagesSetPackagesBuyAll(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPackages: (propertyId: string) =>
        dispatch(Actions.packagesGetPricingAttempt({ propertyId })),
    getAllpackagesInfo: (id: string) =>
        dispatch(Actions.getPropertyIndividualAttempt({ id })),
    setBuyAll: (state: boolean) => dispatch(Actions.setPackagesBuyAll(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
