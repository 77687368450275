import { IOwnerUser } from '@dm/types';

type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (token: string, expiry: number): void => {
        const now = new Date();
        const authToken = {
            token,
            expiry: now.getTime() + expiry,
        };

        localStorage.setItem('authToken', JSON.stringify(authToken));
    },
    getAuthToken: (): string | null => {
        const authItem = localStorage.getItem('authToken');

        if (!authItem) {
            return null;
        }

        const authToken = JSON.parse(authItem);
        const now = new Date();

        if (now.getTime() > authToken.expiry) {
            localStorage.removeItem('authToken');
            return null;
        }

        return authToken.token;
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
    storeUserInfo: (userInfo: IOwnerUser) : void => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    getUserInfo: () : IOwnerUser | null => {
        const userInfo = localStorage.getItem('userInfo');

        if (!userInfo) {
            return null;
        }

        return JSON.parse(userInfo);
    },
    clearUserInfo: (): void => {
        localStorage.removeItem('userInfo');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

export default {
    Auth,
    LocalStorage,
};
