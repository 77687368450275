import { IFile, IPayment, IPropertyPackagePurchaseTermsEnum, IPropertyPackageStatusEnum, RecentEvents } from '@dm/types';

export interface PackagesDefectInspectionPrice {
    defectPrice: number;
}

export interface PackagesiD{
  id: string;
}

export interface IPropertyPackages {
  status: IPropertyPackageStatusEnum;
  term?: IPropertyPackagePurchaseTermsEnum;
  recentEvents: RecentEvents[];
  price: number;
}

export interface IPaymentId {
  paymentId: string
  clientSecret: string
}

export interface GetPaymentInfoRTO {
  clientSecret: string;
  stripePublicKey: string;
  stripePrivateKey: string;
}

export enum GoogleDriveFolderType {
    folder = 1,
    image = 2,
    otherDocuments = 3,
}
export interface IGoogleDriveContent{
  name: string;
  type: GoogleDriveFolderType;
  folderId?: string;
  webviewLink?:{
    thumbnailLink: string,
    webViewLink: string,
    webContentLink: string,
  }
}

export interface ICreatePMTransaction{
  propertyId: string;
  amount: number;
  description: string;
  transactionDate: string;
  transactionType: number;
  isRecurring?: boolean;
}
