import { Colors } from '@dm/bigfish';
import media from 'lib/Breakpoints';
import React from 'react';
import styled, { keyframes } from 'styled-components';

type ReminderProps = {
    reminderDate: string;
    isActive: boolean;
}

const Reminder: React.FC<ReminderProps> = (props: ReminderProps) => {
    const {
        reminderDate,
        isActive,
    } = props;

    if (!isActive) {
        return null;
    }

    return (
        <ReminderContainer>
            <TextContainer>
                <p>{`Your subscription will expire on  ${reminderDate}`}</p>
            </TextContainer>
        </ReminderContainer>
    );
};

const ReminderContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: ${Colors.danger};
    padding-block: 5px;

    ${media.sm`
        padding-block: 10px;
    `}
`;

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    color: white;

    >p{
        font-size: 24px;

        ${media.sm`
            font-size: 16px;
        `}
    }
`;

export default Reminder;
