import { store } from 'redux/store';
import { push, goBack, replace } from 'redux-first-history';
import { PackageCategory } from '@dm/types';

interface INavActionState {
    [key: string] : any;
}

const navPush = (routeName: string, state?:INavActionState):void => {
    store.dispatch(push({ pathname: routeName }, state));
};

const navBack = ():void => {
    store.dispatch(goBack());
};

const navReplace = (routeName: string) => {
    store.dispatch(replace({ pathname: routeName }));
};

const navReset = (stackName: string) => {
    store.dispatch(replace(stackName));
};

const navToHome = ():void => navPush('/');
const navResetToHome = ():void => navReset('/');

const navToLogin = ():void => navPush('/login');
const navToRegister = ():void => navPush('/register');
const navToVerificationEmailSent = ():void => navPush('/verificationEmail');
const navToRequestResetPassword = () : void => navPush('/requestResetPassword');
const navToVerifySignUp = (uuid: string):void => navPush(`/registerVerify/:${uuid}`);
const navToNewSignIn = (): void => navPush('/newSignIn');

const navToListings = ():void => navPush('/listings');
const navToNotifications = ():void => navPush('/notifications');
const navToReports = ():void => navPush('/reports');
const navToSupport = ():void => navPush('/support');

// To profile
const navToProfile = ():void => navPush('/profile');
const navToPackagesSign = (propertyIndividualId: string, packageCategory:PackageCategory):void => navPush('/packagesSign', { propertyIndividualId, packageCategory });

// To View PropertyDetails
const navToViewPropertyDetails = (propertyIndividualId: string):void => navPush('/viewpropertydetails', { propertyIndividualId });

// To View all Packages Specifically
const navToViewVacantPossessionPackages = (propertyIndividualId: string):void => navPush('/vacantpossesion', { propertyIndividualId });
const navToViewDefectInspectionPackages = (propertyIndividualId: string):void => navPush('/defectInspection', { propertyIndividualId });
const navToViewDesignRenovationPackages = (propertyIndividualId: string):void => navPush('/DesignRenovation', { propertyIndividualId });
const navToViewPropertyManagementPackages = (propertyIndividualId: string):void => navPush('/PropertyManagement', { propertyIndividualId });
const navToViewLeaseAndSalePackages = (propertyIndividualId: string):void => navPush('/leaseAndSale', { propertyIndividualId });
const navToViewVacantManagementPackages = (propertyIndividualId: string):void => navPush('/vacantManagement', { propertyIndividualId });

// To Packages Subscribe Page
const navToPackages = (packageId: string):void => navPush('/subscribe', { packageId });

// To Create New Property
const navToCreateNewProperty = (propertyIndividualId: string):void => navPush('/property/new', { propertyIndividualId });
const navToPaymentOption = (
    packageId: string,
    packagesChoosed: number[],
    priceData: number,
    propertyIndividualId: string,
):void => navPush('/paymentoption', { packageId, packagesChoosed, priceData, propertyIndividualId });
const navToStripeSuccess = ():void => navPush('/PaymentGatewaySuccess');
const navToStripeFailed = ():void => navPush('/PaymentGatewayFailed');
const navToTopupWallet = (propertyIndividualId: string, AmountTopUp: number):void => navPush('/topupWallet', { propertyIndividualId, AmountTopUp });

// To View Documents of pakages From Specific packages
const navToVacantPossesionMain = (propertyIndividualId: string):void => navPush('/VacantPossesionViewDocuments', { propertyIndividualId });
const navToLeaseAndSaleViewDocuments = (propertyIndividualId: string):void => navPush('/LeaseAndSaleViewDocuments', { propertyIndividualId });
const navToDefectInspectionViewDocuments = (propertyIndividualId: string):void => navPush('/defectInspectionViewDocuments', { propertyIndividualId });
const navTopropertyManagementViewDocuments = (propertyIndividualId: string):void => navPush('/propertyManagementViewDocuments', { propertyIndividualId });
const navTopropertyManagementViewDocumentsUploaded = (propertyIndividualId: string):void => navPush('/propertyManagementViewDocumentsUploaded', { propertyIndividualId });
const navToVacantManagementViewDocuments = (propertyIndividualId: string):void => navPush('/vacantManagementViewDocuments', { propertyIndividualId });

const navToDashboard = ():void => navPush('/dashboard');
const navToProperty = ():void => navPush('/property');

export default {
    navBack,

    navToHome,
    navResetToHome,

    navPush,

    navToLogin,
    navToRegister,
    navToVerificationEmailSent,
    navToRequestResetPassword,
    navToVerifySignUp,
    navToNewSignIn,

    navToListings,
    navToNotifications,
    navToReports,
    navToSupport,
    navToProfile,

    navToCreateNewProperty,
    navToPackages,
    navToPaymentOption,

    navToDashboard,
    navToProperty,
    navToViewPropertyDetails,
    navToVacantPossesionMain,

    navToViewVacantPossessionPackages,
    navToViewDefectInspectionPackages,
    navToViewDesignRenovationPackages,
    navToViewPropertyManagementPackages,
    navToViewLeaseAndSalePackages,
    navToViewVacantManagementPackages,
    navToLeaseAndSaleViewDocuments,
    navToDefectInspectionViewDocuments,
    navTopropertyManagementViewDocuments,
    navTopropertyManagementViewDocumentsUploaded,
    navToVacantManagementViewDocuments,

    navToTopupWallet,

    navToPackagesSign,

    navToStripeSuccess,
    navToStripeFailed,
};
