import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOwnerUser } from '@dm/types';

import { PropertyUserInfoPayload, PropReduxState } from './types';

const initialState: PropReduxState = {
    actions: {
        getUserInfo: false,
        setUserInfo: false,
    },
    userInfo: undefined,

    updateUserInfo: '',
    subscriptionTransactionModal: false,

    error: {
        getUserInfo: '',
        setUserInfo: '',
    },
};

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        userGetUserInfoAttempt: (state) => {
            state.actions.getUserInfo = true;
            state.error.getUserInfo = '';
        },

        userGetUserInfoSuccess: (state, action: PayloadAction<IOwnerUser>) => {
            state.actions.getUserInfo = false;
            state.userInfo = action.payload;
        },
        userGetUserInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getUserInfo = false;
            if (action.payload) {
                state.error.getUserInfo = action.payload;
            }
        },

        userSetUserInfoAttempt: (state, _action: PropertyUserInfoPayload) => {
            state.actions.setUserInfo = true;
            state.error.setUserInfo = '';
        },

        updateUserInfo: (state, action: PayloadAction<string>) => {
            state.actions.setUserInfo = false;
            state.updateUserInfo = action.payload;
        },

        clearUserInfo: (state) => {
            state.userInfo = undefined;
        },

        userSetUpdateUserInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setUserInfo = false;
            if (action.payload) {
                state.error.setUserInfo = action.payload;
            }
        },

        setSubscriptionTransactionModal: (state, action: PayloadAction<boolean>) => {
            state.subscriptionTransactionModal = action.payload;
        },

    },
});

export type InitState = typeof initialState;

export default {
    actions: userInfoSlice.actions,
    reducers: userInfoSlice.reducer,
};
