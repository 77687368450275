import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import PackagesGateway from 'api/Packages';
import { GatewayResponseStatus } from 'api/types/types';
import { GetSignDocumentTemplateActionPayload } from 'redux/slices/packages/types';
import Utils from 'lib/Utils';

export default function* watchGetSignDocumentTemplate(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/getPackagesDownloadDocumentTemplateAttempt', handleGetSignDocumentTemplate, api);
}

function* handleGetSignDocumentTemplate(api: PackagesGateway, data: GetSignDocumentTemplateActionPayload) {
    const authToken = Utils.Auth.getAuthToken();
    if (authToken) {
        const { documentTemplate, propertyId } = data.payload;

        const response = yield* call([api, api.getSignDocumentTemplate], { authToken, documentTemplate, propertyId });

        if (response.status === GatewayResponseStatus.Error) {
            yield put(Actions.getPackagesDownloadDocumentTemplateFailure(response.message));
            return;
        }

        yield put(Actions.getPackagesDownloadDocumentTemplateSuccess(response.data));
    }
}
