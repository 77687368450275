import auth from 'redux/slices/auth/Selectors';
import ui from 'redux/slices/ui/Selectors';
import prop from 'redux/slices/prop/Selectors';
import userInfo from 'redux/slices/user/Selectors';
import property from 'redux/slices/property/Selectors';
import packages from 'redux/slices/packages/Selectors';

import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesPoscode, CoordinatesState, CoordinatesCountry, CoordinatesCity, IServerProperty, IPropertyIndividual, WalletBalanceParams, IPropertyPaginator, IAllPropertyDetailDashboards, IRentalAnalytics, IWalletAnalytics } from 'entities/property';
import { IDefectInspection, ILeaseSale, IOwnerUser, IPackagePrice, IVacantPossession, PropertyManagementTransactionResponse, PaymentGatewayResponse, IPropertyManagement, IVacantManagement, ViewTransactionResponse, ViewAllPMTransactionResponse } from '@dm/types';
import { PackagesiD, GetPaymentInfoRTO, IGoogleDriveContent } from 'entities/packages';

import { RootState } from './store';

// Auth
const getAuthLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const getAuthLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const getAuthSignUpAttempting = (state: RootState): boolean => auth.getSignUpAttempting(state.auth);
const getAuthSignUpError = (state: RootState): string => auth.getSignUpError(state.auth);
const getAuthLogout = (state: RootState): boolean => auth.getLogout(state.auth);

const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);
const getAuthStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const getAuthStartupError = (state: RootState): string => auth.getStartupError(state.auth);

const getAuthRequestResetPassword = (state: RootState): boolean => auth.getRequestResetPasswordAttempting(state.auth);
const getAuthRequestResetPasswordError = (state: RootState): string => auth.getRequestResetPasswordError(state.auth);

const getAuthVerifySignUpAttempt = (state: RootState): boolean => auth.getVerifySignUpAttempt(state.auth);
const getAuthVerifySignUpError = (state: RootState): string => auth.getVerifySignUpError(state.auth);
const getAuthVerifySignUp = (state: RootState): boolean => auth.getVerifySignUp(state.auth);

const getAuthDetailedSignUpAttempt = (state: RootState): boolean => auth.getDetailedSignUpAttempt(state.auth);
const getAuthDetailedSignUpError = (state: RootState): string => auth.getDetailedSignUpError(state.auth);

const getAuthIsFirstSignUp = (state: RootState): boolean => auth.getIsFirstSignUp(state.auth);

const getAuthVerifyForgotPasswordEmailAttempt = (state: RootState): boolean => auth.getVerifyForgotPasswordEmailAttempt(state.auth);
const getAuthVerifyForgotPasswordEmailError = (state: RootState): string => auth.getVerifyForgotPasswordEmailError(state.auth);

const getAuthForgotPasswordNewPasswordAttempt = (state: RootState): boolean => auth.getForgotPasswordNewPasswordAttempt(state.auth);
const getAuthForgotPasswordNewPasswordError = (state: RootState): string => auth.getForgotPasswordNewPasswordError(state.auth);

const authGetUnsubscribeAttempt = (state: RootState): boolean => auth.getUnsubscribeAttempt(state.auth);
const authGetUnsubscribeError = (state: RootState): string => auth.getUnsubscribeError(state.auth);

// UI
const getUiSelectedLanguage = (state: RootState): string => ui.getSelectedLanguage(state.ui);
const getUiSelectedTabView = (state: RootState): string => ui.getSelectedTabView(state.ui);
const getUiWelcomeModal = (state: RootState): boolean => ui.getWelcomeModal(state.ui);
const getUiWelcomeModalSuccess = (state: RootState): boolean => ui.getWelcomeModalSuccess(state.ui);
const getUiUploadFileModalOpen = (state: RootState): boolean => ui.getIsUploadFileModalOpen(state.ui);
const getUiFileListModalOpen = (state: RootState): boolean => ui.getIsFileViewModalOpen(state.ui);
const getUiGetFileList = (state: RootState): ViewAllPMTransactionResponse['fileUrl'] => ui.getFileList(state.ui);
const getUiIsPMTransactionModalOpen = (state: RootState): boolean => ui.getIsPMTransactionModalOpen(state.ui);

// Prop
const getPropPropertyAttempt = (state: RootState): boolean => prop.getPropertyAttempt(state.prop);
const getPropPropertyFailure = (state: RootState): string => prop.getPropertyFailure(state.prop);
const getPropProperty = (state: RootState): IServerProperty[] => prop.property(state.prop);

const getPropPropertyPaginatorAttempt = (state: RootState): boolean => prop.getPropertyPaginatorAttempt(state.prop);
const getPropPropertyPaginatorFailure = (state: RootState): string => prop.getPropertyPaginatorFailure(state.prop);
const getPropPropertyPaginator = (state: RootState): IPropertyPaginator => prop.propertyPaginator(state.prop);

const getPropDisplayedProperty = (state: RootState): IPropertyPaginator['data'] => prop.displayedProperty(state.prop);
const getPropCurrentPropertyIndex = (state: RootState): number => prop.currentPropertyIndex(state.prop);
const getPropMenuIsOpen = (state: RootState): boolean => prop.menuIsOpen(state.prop);

const getAllPropertyDetailsAttempt = (state: RootState): boolean => prop.getAllPropertyDetailsAttempt(state.prop);
const getAllPropertyDetailsFailure = (state: RootState): string => prop.getAllPropertyDetailsFailure(state.prop);
const getAllPropertyDetails = (state: RootState): IAllPropertyDetailDashboards => prop.allPropertyDetails(state.prop);

const getRentalAnalyticsAttempt = (state: RootState): boolean => prop.getRentalAnalyticsAttempt(state.prop);
const getRentalAnalyticsFailure = (state: RootState): string => prop.getRentalAnalyticsFailure(state.prop);
const getRentalAnalytics = (state: RootState): IRentalAnalytics => prop.rentalAnalytics(state.prop);

const getWalletAnalyticsAttempt = (state: RootState): boolean => prop.getWalletAnalyticsAttempt(state.prop);
const getWalletAnalyticsFailure = (state: RootState): string => prop.getWalletAnalyticsFailure(state.prop);
const getWalletAnalytics = (state: RootState): IWalletAnalytics[] => prop.walletAnalytics(state.prop);

const getPropPropertyIndividualAttempt = (state: RootState): boolean => prop.getPropertyIndividualAttempt(state.prop);
const getPropPropertyIndividualFailure = (state: RootState): string => prop.getPropertyPropertyIndividualFailure(state.prop);
const getPropPropertyIndividual = (state: RootState): IPropertyIndividual => prop.PropertyIndividual(state.prop);

const getgetPropertyImageDataAttempt = (state: RootState): boolean => prop.getPropertyImageDataAttempt(state.prop);
const getgetPropertyImageDataFailure = (state: RootState): string => prop.getPropertyImageDataFailure(state.prop);
const getPropertyImageData = (state: RootState): IPropertyIndividual => prop.PropertyImageData(state.prop);

// userInfo
const getUserGetUserInfoAttempt = (state: RootState): boolean => userInfo.getUserInfoAttempt(state.userInfo);
const getUserGetUserInfoFailure = (state: RootState): string => userInfo.getUserInfoFailure(state.userInfo);
const getUserGetUserInfo = (state: RootState): IOwnerUser | undefined => userInfo.getUserInfo(state.userInfo);

const setUserSetUserInfoAttempt = (state: RootState): boolean => userInfo.setUserInfoAttempt(state.userInfo);
const setUserSetUserInfoFailure = (state: RootState): string => userInfo.setUserInfoFailure(state.userInfo);
const UpdateUserUpdateUserInfo = (state: RootState): string | undefined => userInfo.updateUserInfo(state.userInfo);

const setProfileSetSubscriptionTransactionModal = (state: RootState): boolean => userInfo.setSubscriptionTransactionModal(state.userInfo);

// Propery Create
const getPropertyGetPropertySetDescriptionAttempting = (state: RootState): boolean => property.getPropertySetDescriptionAttempting(state.property);
const getPropertyGetPropertySetDescriptionError = (state: RootState): string => property.getPropertySetDescriptionError(state.property);
const getPropertyGetPropertySetDescription = (state: RootState): string => property.getPropertySetDescription(state.property);

const getPropertyGetPropertyAmenitiesAttempting = (state: RootState): boolean => property.getPropertySetPropertyAmenitiesAttempting(state.property);
const getPropertyGetPropertyAmenitiesError = (state: RootState): string => property.getPropertySetPropertyAmenitiesError(state.property);

const getPropertyGetPropertyDetailsAttempt = (state: RootState): boolean => property.getPropertySetPropertyDetailsAttempt(state.property);
const getPropertyGetPropertyDetailsError = (state: RootState): string => property.getPropertySetPropertyDetailsError(state.property);
const getPropertyGetPropertyDetailsSuccess = (state: RootState): boolean => property.getPropertySetPropertyDetailsSuccess(state.property);

const getPropertyGetPropertySetCreatePropertAttempting = (state: RootState): boolean => property.getPropertySetCreatePropertAttempting(state.property);
const getPropertyGetPropertySetCreatePropertError = (state: RootState): string => property.getPropertySetCreatePropertError(state.property);
const getPropertyGetPropertySetCreatePropert = (state: RootState): string => property.getPropertySetCreatePropert(state.property);

const getPropertyGetPropertySetTermsAttempting = (state: RootState): boolean => property.getPropertySetTermsAttempting(state.property);
const getPropertyGetPropertySetTermsError = (state: RootState): string => property.getPropertySetTermsError(state.property);
const getPropertyGetPropertySetTerms = (state: RootState): string => property.getPropertySetTerms(state.property);

const getPropertyGetPropertySetImagesAttempting = (state: RootState): boolean => property.getPropertySetImageAttempting(state.property);
const getPropertyGetPropertySetImagesError = (state: RootState): string => property.getPropertySetImageError(state.property);
const getPropertyGetPropertySetImages = (state: RootState): string => property.getPropertySetImage(state.property);

const getPropertyGetPropertySetLocationAttempting = (state: RootState): boolean => property.getPropertySetLocationAttempting(state.property);
const getPropertyGetPropertySetLocationError = (state: RootState): string => property.getPropertySetLocationError(state.property);
const getPropertyGetPropertySetLocation = (state: RootState): string => property.getPropertySetLocation(state.property);

const getPropertyGetCoordinates = (state: RootState): Coordinates => property.getPropertyGetCoordinates(state.property);

const getPropertyGetAddress1 = (state: RootState): CoordinatesAddress1 => property.getPropertyGetAddress1(state.property);

const getPropertyGetAddress2 = (state: RootState): CoordinatesAddress2 => property.getPropertyGetAddress2(state.property);

const getPropertyGetPoscode = (state: RootState): CoordinatesPoscode => property.getPropertyGetPoscode(state.property);

const getPropertyGetState = (state: RootState): CoordinatesState => property.getPropertyGetState(state.property);

const getPropertyGetCountry = (state: RootState): CoordinatesCountry => property.getPropertyGetCountry(state.property);

const getPropertyGetCity = (state: RootState): CoordinatesCity => property.getPropertyGetCity(state.property);

const getPropertyGetPropertySetApprovalAttempting = (state: RootState): boolean => property.getPropertyApprovalTermsAttempting(state.property);
const getPropertyGetPropertySetApprovalError = (state: RootState): string => property.getPropertySetApprovalError(state.property);
const getPropertyGetPropertySetApproval = (state: RootState): string => property.getPropertySetApproval(state.property);

const userGetPropertyUploadUrlAttempting = (state: RootState): boolean => property.getPropertyUploadUrlAttempting(state.property);
const userGetPropertyUploadUrlError = (state: RootState): string => property.getPropertyUploadUrlError(state.property);

const userGetPropertyUploadUrl = (state: RootState): string => property.getPropertyUploadUrl(state.property);

const userUploadPropertyDocumentAttempting = (state: RootState): boolean => property.uploadPropertyImageAttempting(state.property);
const userUploadPropertyDocumentError = (state: RootState): string => property.uploadPropertyImageError(state.property);

const setPropertysetIsDocumentUploadComplete = (state: RootState): boolean => property.setIsPropertyPropertyImageSuccess(state.property);

const setPropertyCreatePropertyModal = (state: RootState): boolean => property.setPropertyCreatePropertyModal(state.property);

const setPropertySetDeleteImageAttempting = (state: RootState): boolean => property.setPropertySetDeleteImageAttempting(state.property);
const setPropertySetDeleteImageError = (state: RootState): string => property.setPropertySetDeleteImageError(state.property);
const setPropertySetDeleteImage = (state: RootState): string => property.setPropertySetDeleteImage(state.property);

const setIsPropertyOpenLocationCollapsible = (state: RootState): boolean => property.setIsPropertyOpenLocationCollapsible(state.property);
const setIsPropertyOpenDetailsCollapsible = (state: RootState): boolean => property.setIsPropertyOpenDetailsCollapsible(state.property);
const setIsPropertyOpenAmenitiesCollapsible = (state: RootState): boolean => property.setIsPropertyOpenAmenitiesCollapsible(state.property);
const setIsPropertyOpenImagesCollapsible = (state: RootState): boolean => property.setIsPropertyOpenImagesCollapsible(state.property);
const setIsPropertyOpenDescriptionCollapsible = (state: RootState): boolean => property.setIsPropertyOpenDescriptionCollapsible(state.property);

const setPropertySetTermsAndConditionSubmitted = (state: RootState): boolean => property.setTermsAndConditionSubmitted(state.property);
const setPropertySetPropertyLocationSubmitted = (state: RootState): boolean => property.setPropertyLocationSubmitted(state.property);
const setPropertySetPropertyDetailsSubmitted = (state: RootState): boolean => property.setPropertyDetailsSubmitted(state.property);
const setPropertySetPropertyAmenitiesSubmitted = (state: RootState): boolean => property.setPropertyAmenitiesSubmitted(state.property);
const setPropertySetPropertyImagesSubmitted = (state: RootState): boolean => property.setPropertyImagesSubmitted(state.property);
const setPropertySetPropertyDescriptionSubmitted = (state: RootState): boolean => property.setPropertyDescriptionSubmitted(state.property);

const getPropertyWalletDetailsAttempting = (state: RootState): boolean => property.getPropertyWalletDetailsAttempting(state.property);
const getPropertyWalletDetailsError = (state: RootState): string => property.getPropertyWalletDetailsError(state.property);
const getPropertyWalletDetails = (state: RootState): WalletBalanceParams | null => property.getPropertyWalletDetails(state.property);

const setPropertySetPropertyImageUploadFileName = (state: RootState): string => property.setPropertyImageUploadFileName(state.property);

const getPropertyLocationCloseCollapsibleAfterSubmitted = (state: RootState): string => property.getPropertyLocationCloseCollapsibleAfterSubmitted(state.property);

const getPropertyCreatePropertyAttempting = (state: RootState): boolean => property.createPropertyAttempting(state.property);
const getPropertyCreatePropertyError = (state: RootState): string => property.createPropertyError(state.property);
const getPropertyPropertyId = (state: RootState): string => property.propertyId(state.property);

const getPropertyEditPropertyDetailsAttempting = (state: RootState): boolean => property.editPropertyDetailsAttempt(state.property);
const getPropertyEditPropertyDetailsError = (state: RootState): string => property.editPropertyDetailsError(state.property);
// packages
const getPackagesGetPackagesPricingAttempt = (state: RootState): boolean => packages.getPackagesPricingAttempt(state.packages);
const getPackagesGetPackagesPricingFailure = (state: RootState): string => packages.getPackagesPricingFailure(state.packages);
const getPackagesGetPackagesPricing = (state: RootState): IPackagePrice => packages.getPackagesPricing(state.packages);
const setPackagesId = (state: RootState): PackagesiD => packages.getPackagesId(state.packages);

const packagessetPackagesPaymentsAttempting = (state: RootState): boolean => packages.setPackagesPaymentsAttempting(state.packages);
const packagessetPackagesPaymentsError = (state: RootState): string => packages.setPackagesPaymentsError(state.packages);
const packagessetPackagesPayments = (state: RootState): PaymentGatewayResponse => packages.setPackagesPayments(state.packages);

const packagesSetPaymentDocumentModal = (state: RootState): boolean => packages.setPaymentDocumentModal(state.packages);
const packagesSetConfirmPaymentMethodModal = (state: RootState): boolean => packages.setConfirmPaymentMethodModal(state.packages);

const packagesgetPackagesUploadUrlAttempting = (state: RootState): boolean => packages.getPackagesUploadUrlAttempting(state.packages);
const packagesgetPackagesUploadUrlError = (state: RootState): string => packages.getPackagesUploadUrlError(state.packages);
const packagesgetPackagesUploadUrl = (state: RootState): string => packages.getPackagesUploadUrl(state.packages);

const packagesUploadPackagesDocumentAttempting = (state: RootState): boolean => packages.uploadPackagesDocumentAttempting(state.packages);
const packagesUploadPackagesDocumentError = (state: RootState): string => packages.uploadPackagesDocumentError(state.packages);
const packagesSetDocumentUploadStatus = (state: RootState): boolean => packages.setDocumentUploadStatus(state.packages);

const packagesSetPackagesDocumentUploadId = (state: RootState): string => packages.setPackagesDocumentUploadId(state.packages);

const packagesSetVacantPossesionModal = (state: RootState): boolean => packages.setVacantPossesionModal(state.packages);

const setpackagesDocumentViewVacantPossesion = (state: RootState): boolean => packages.setpackagesDocumentViewVacantPossesion(state.packages);

const getPackagesDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesDataDetailsSortAttempt(state.packages);
const getPackagesDataDetailsSortFailure = (state: RootState): string => packages.getPackagesDataDetailsSortFailure(state.packages);

const getPackagesDataDetailsSort = (state: RootState): IVacantPossession => packages.getPackagesDataDetailsSort(state.packages);

const packagesSetLeaseSaleModal = (state: RootState): boolean => packages.setLeaseSaleModal(state.packages);

const getPackagesLeaseAndSaleDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesLeaseAndSaleDataDetailsSortAttempt(state.packages);
const getPackagesLeaseAndSaleDataDetailsSortFailure = (state: RootState): string => packages.getPackagesLeaseAndSaleDataDetailsSortFailure(state.packages);
const getPackagesLeaseAndSaleDataDetailsSort = (state: RootState): ILeaseSale => packages.getPackagesLeaseAndSaleDataDetailsSort(state.packages);

const packagesSetPackagesBuyAll = (state: RootState): boolean => packages.setPackagesBuyAll(state.packages);
const packagesSetAllPackageCancel = (state: RootState): boolean => packages.setAllPackageCancel(state.packages);

const getPackagesDefectInspectionDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesDefectInspectionDetailsSortAttempt(state.packages);
const getPackagesDefectInspectionDataDetailsSortFailure = (state: RootState): string => packages.getPackagesDefectInspectionDetailsSortFailure(state.packages);
const getDefectInspectionDataDetailsSort = (state: RootState): IDefectInspection => packages.getPackagesDefectInspectionDetailsSort(state.packages);

const packagesSetIsPackageSigned = (state: RootState): boolean => packages.setIsPackageSigned(state.packages);

const packagesSetsetPropertySignLink = (state: RootState): string => property.setPropertySignLink(state.property);

const getPackagesGetPackagesDownloadSignDocumentsAttempt = (state: RootState): boolean => packages.getPackagesDownloadSignDocumentsAttempting(state.packages);
const getPackagesgetPackagesDownloadSignDocumentsFailure = (state: RootState): string => packages.getPackagesDownloadSignDocumentsError(state.packages);
const getPackagesgetPackagesDownloadSignDocuments = (state: RootState): string => packages.getPackagesDownloadSignDocuments(state.packages);

const packagesGetPackagesSignedUploadUrlAttempting = (state: RootState): boolean => packages.getPackagesSignedUploadUrlAttempting(state.packages);
const packagesGetPackagesSignedUploadUrlError = (state: RootState): string => packages.getPackageSignedsUploadUrlError(state.packages);
const packagesGetPackagesSignedUploadUrl = (state: RootState): string => packages.getPackagesSignedUploadUrl(state.packages);

const packagesUploadPackagesSignedDocumentAttempting = (state: RootState): boolean => packages.uploadPackagesSignedDocumentAttempting(state.packages);
const packagesUploadPackagesSignedDocumentError = (state: RootState): string => packages.uploadPackagesSignedDocumentError(state.packages);
const packagesSetSignCompleted = (state: RootState): boolean => packages.setSignCompleted(state.packages);
const packagesSetPackagesEmailModal = (state: RootState): boolean => packages.setPackagesEmailModal(state.packages);

const packagesSetPackagesUploadedDocumentComplete = (state: RootState): boolean => packages.setAllPackagesUploadedDocumentComplete(state.packages);

const getPackagesGetPackagesPaymentCreditCardAttempt = (state: RootState): boolean => packages.getPackagesPaymentCreditCardAttempting(state.packages);
const getPackagesgetPackagesPaymentCreditCardFailure = (state: RootState): string => packages.getPackagesPaymentCreditCardError(state.packages);
const getPackagesgetPackagesPaymentCreditCard = (state: RootState): GetPaymentInfoRTO | null => packages.getPackagesPaymentCreditCard(state.packages);

const getPackagesIPropertyManagementDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesPropertyManagementDetailsSortAttempt(state.packages);
const getPackagesIPropertyManagementDataDetailsSortFailure = (state: RootState): string => packages.getPackagesPropertyManagementDetailsSortFailure(state.packages);
const getIPropertyManagementDataDetailsSort = (state: RootState): PropertyManagementTransactionResponse[] | null => packages.getPackagesPropertyManagementDetailsSort(state.packages);

const packagesSetPropertyManagementTransactionUpdateSuccessAttempting = (state: RootState): boolean => packages.getPackagesPropertyManagementTransactionUpdateAttempting(state.packages);
const packagesSetPropertyManagementTransactionUpdateSuccessError = (state: RootState): string => packages.getPackagePropertyManagementTransactionUpdateError(state.packages);
const packagesSetPropertyManagementTransactionUpdateSuccess = (state: RootState): string => packages.getPackagesPropertyManagementTransactionUpdateSuccess(state.packages);

const packagesSetPropertyManagementTransactionUpdateSuccessGatewayAttempting = (state: RootState): boolean => packages.getPackagesPropertyManagementTransactionUpdateGatewayAttempting(state.packages);
const packagesSetPropertyManagementTransactionUpdateSuccessGatewayError = (state: RootState): string => packages.getPackagePropertyManagementTransactionUpdateGatewayError(state.packages);
const packagesSetPropertyManagementTransactionUpdateSuccessGateway = (state: RootState): PaymentGatewayResponse | null => packages.getPackagesPropertyManagementTransactionUpdateGatewaySuccess(state.packages);
const packagesSetpackagesPropertyManagementStatusUpdateModal = (state: RootState): boolean => packages.setpackagesPropertyManagementStatusUpdateModal(state.packages);

const packagesGetPackagesSetTopUpPaymentsAttempting = (state: RootState): boolean => packages.getPackagesSetTopUpPaymentsAttempting(state.packages);
const packagesGetPackagesSetTopUpPaymentsError = (state: RootState): string => packages.getPackageSetTopUpPaymentsError(state.packages);
const packagesGetPackagesSetTopUpPayments = (state: RootState): PaymentGatewayResponse | null => packages.getPackagesSetTopUpPaymentsSuccess(state.packages);

const packagesSetTopUpPaymentModal = (state: RootState): boolean => packages.setTopUpPaymentModal(state.packages);

const getPackagesIPropertyManagementDocumentViewDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesPropertyManagementDataDetailsSortAttempt(state.packages);
const getPackagesIPropertyManagementDocumentViewDataDetailsSortFailure = (state: RootState): string => packages.getPackagesPropertyManagementDataDetailsSortFailure(state.packages);
const getIPropertyManagementDataDocumentViewDetailsSort = (state: RootState): IPropertyManagement | null => packages.getPackagesPropertyManagementDataDetailsSort(state.packages);

const getPackagesIVacantManagementViewDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesVacantManagementDataSortAttempt(state.packages);
const getPackagesIVacantManagementViewDataDetailsSortFailure = (state: RootState): string => packages.getPackagesVacantManagementDataSortFailure(state.packages);
const getIVacantManagementDataDocumentViewDetailsSort = (state: RootState): IVacantManagement | null => packages.getPackagesVacantManagementDataSort(state.packages);

const getPackagesPackagesSubscriptionTransactionsAttempt = (state: RootState): boolean => packages.getPackagesSubscriptionTransactionsAttempt(state.packages);
const getPackagesPackagesSubscriptionTransactionsFailure = (state: RootState): string => packages.getPackagesSubscriptionTransactionsFailure(state.packages);
const getIPackagesPackagesSubscriptionTransactions = (state: RootState): ViewTransactionResponse[] | null => packages.getPackagesSubscriptionTransactions(state.packages);

const packagesSetPackagesSubscriptionTransactionsCancelAttempting = (state: RootState): boolean => packages.setPackagesSubscriptionTransactionsCancelAttempting(state.packages);
const packagesSetPackagesSubscriptionTransactionsCancelError = (state: RootState): string => packages.setPackagesSubscriptionTransactionsCancelError(state.packages);
const packagesSetPackagesSubscriptionTransactionsCancel = (state: RootState): string => packages.setPackagesSubscriptionTransactionsCancelSuccess(state.packages);

const packagesSetPackagesSubscriptionTransactionsRenewAttempting = (state: RootState): boolean => packages.setPackagesSubscriptionTransactionsRenewAttempting(state.packages);
const packagesSetPackagesSubscriptionTransactionsRenewError = (state: RootState): string => packages.setPackagesSubscriptionTransactionsRenewError(state.packages);
const packagesSetPackagesSubscriptionTransactionsRenew = (state: RootState): string => packages.setPackagesSubscriptionTransactionsRenewSuccess(state.packages);

const packagesSetTransactionApproveOrRenewLoaderModal = (state: RootState): boolean => packages.setTransactionApproveOrRenewLoaderModal(state.packages);

const packageGetGoogleDriveContentAttempting = (state: RootState): boolean => packages.getGoogleDriveContentAttempting(state.packages);
const packageGetGoogleDriveContentError = (state: RootState): string => packages.getGoogleDriveContentError(state.packages);
const packageGetGoogleDriveContent = (state: RootState): IGoogleDriveContent[] | [] => packages.getGoogleDriveContent(state.packages);

const packageGetCreatePMTransactionAttempting = (state: RootState): boolean => packages.getCreatePMTransactionAttempting(state.packages);
const packageGetCreatePMTransactionError = (state: RootState): string => packages.getCreatePMTransactionError(state.packages);
const packageGetCreatedPMTransactionId = (state: RootState): string => packages.getCreatedPMTransactionId(state.packages);

export default {
    getAuthLoginAttempting,
    getAuthLoginError,

    getAuthSignUpAttempting,
    getAuthSignUpError,
    getAuthLogout,

    getAuthAuthToken,
    getAuthStartupAttempting,
    getAuthStartupError,

    getAuthRequestResetPassword,
    getAuthRequestResetPasswordError,

    getAuthVerifySignUpAttempt,
    getAuthVerifySignUpError,
    getAuthVerifySignUp,

    getAuthDetailedSignUpAttempt,
    getAuthDetailedSignUpError,

    getAuthIsFirstSignUp,

    getAuthVerifyForgotPasswordEmailAttempt,
    getAuthVerifyForgotPasswordEmailError,

    getAuthForgotPasswordNewPasswordAttempt,
    getAuthForgotPasswordNewPasswordError,

    authGetUnsubscribeAttempt,
    authGetUnsubscribeError,

    getUiSelectedLanguage,
    getUiSelectedTabView,
    getUiWelcomeModal,
    getUiWelcomeModalSuccess,
    getUiUploadFileModalOpen,
    getUiFileListModalOpen,
    getUiGetFileList,
    getUiIsPMTransactionModalOpen,

    getPropPropertyAttempt,
    getPropPropertyFailure,
    getPropProperty,

    getPropPropertyIndividualAttempt,
    getPropPropertyIndividualFailure,
    getPropPropertyIndividual,

    getUserGetUserInfoAttempt,
    getUserGetUserInfoFailure,
    getUserGetUserInfo,

    setUserSetUserInfoAttempt,
    setUserSetUserInfoFailure,
    UpdateUserUpdateUserInfo,

    setProfileSetSubscriptionTransactionModal,

    // Property

    getPropPropertyPaginatorAttempt,
    getPropPropertyPaginatorFailure,
    getPropPropertyPaginator,

    getPropDisplayedProperty,
    getPropCurrentPropertyIndex,
    getPropMenuIsOpen,

    getAllPropertyDetailsAttempt,
    getAllPropertyDetailsFailure,
    getAllPropertyDetails,

    getRentalAnalyticsAttempt,
    getRentalAnalyticsFailure,
    getRentalAnalytics,

    getWalletAnalyticsAttempt,
    getWalletAnalyticsFailure,
    getWalletAnalytics,

    getPropertyGetPropertySetDescriptionAttempting,
    getPropertyGetPropertySetDescriptionError,
    getPropertyGetPropertySetDescription,

    getPropertyGetPropertyAmenitiesAttempting,
    getPropertyGetPropertyAmenitiesError,

    getPropertyGetPropertyDetailsAttempt,
    getPropertyGetPropertyDetailsError,
    getPropertyGetPropertyDetailsSuccess,

    getPropertyGetPropertySetCreatePropertAttempting,
    getPropertyGetPropertySetCreatePropertError,
    getPropertyGetPropertySetCreatePropert,

    getPropertyGetPropertySetTermsAttempting,
    getPropertyGetPropertySetTermsError,
    getPropertyGetPropertySetTerms,

    getPropertyGetPropertySetLocationAttempting,
    getPropertyGetPropertySetLocationError,
    getPropertyGetPropertySetLocation,

    getPropertyGetPropertySetImagesAttempting,
    getPropertyGetPropertySetImagesError,
    getPropertyGetPropertySetImages,

    getPropertyGetCoordinates,
    getPropertyGetAddress1,
    getPropertyGetAddress2,
    getPropertyGetPoscode,
    getPropertyGetState,
    getPropertyGetCountry,
    getPropertyGetCity,

    getPropertyGetPropertySetApprovalAttempting,
    getPropertyGetPropertySetApprovalError,
    getPropertyGetPropertySetApproval,

    userGetPropertyUploadUrlAttempting,
    userGetPropertyUploadUrlError,
    userGetPropertyUploadUrl,

    userUploadPropertyDocumentAttempting,
    userUploadPropertyDocumentError,

    setPropertysetIsDocumentUploadComplete,
    setPropertyCreatePropertyModal,

    setPropertySetDeleteImageAttempting,
    setPropertySetDeleteImageError,
    setPropertySetDeleteImage,

    getgetPropertyImageDataAttempt,
    getgetPropertyImageDataFailure,
    getPropertyImageData,

    setIsPropertyOpenLocationCollapsible,
    setIsPropertyOpenDetailsCollapsible,
    setIsPropertyOpenAmenitiesCollapsible,
    setIsPropertyOpenImagesCollapsible,
    setIsPropertyOpenDescriptionCollapsible,

    setPropertySetTermsAndConditionSubmitted,
    setPropertySetPropertyLocationSubmitted,
    setPropertySetPropertyDetailsSubmitted,
    setPropertySetPropertyAmenitiesSubmitted,
    setPropertySetPropertyImagesSubmitted,
    setPropertySetPropertyDescriptionSubmitted,

    getPropertyWalletDetailsAttempting,
    getPropertyWalletDetailsError,
    getPropertyWalletDetails,

    setPropertySetPropertyImageUploadFileName,

    getPropertyLocationCloseCollapsibleAfterSubmitted,

    getPropertyCreatePropertyAttempting,
    getPropertyCreatePropertyError,
    getPropertyPropertyId,

    getPropertyEditPropertyDetailsAttempting,
    getPropertyEditPropertyDetailsError,

    // Packages

    getPackagesGetPackagesPricingAttempt,
    getPackagesGetPackagesPricingFailure,
    getPackagesGetPackagesPricing,

    setPackagesId,

    packagessetPackagesPaymentsAttempting,
    packagessetPackagesPaymentsError,
    packagessetPackagesPayments,

    packagesSetPaymentDocumentModal,
    packagesSetConfirmPaymentMethodModal,

    packagesgetPackagesUploadUrlAttempting,
    packagesgetPackagesUploadUrlError,
    packagesgetPackagesUploadUrl,

    packagesUploadPackagesDocumentAttempting,
    packagesUploadPackagesDocumentError,
    packagesSetDocumentUploadStatus,

    packagesSetPackagesDocumentUploadId,

    packagesSetVacantPossesionModal,

    setpackagesDocumentViewVacantPossesion,

    getPackagesDataDetailsSortAttempt,
    getPackagesDataDetailsSortFailure,
    getPackagesDataDetailsSort,

    packagesSetLeaseSaleModal,

    getPackagesLeaseAndSaleDataDetailsSortAttempt,
    getPackagesLeaseAndSaleDataDetailsSortFailure,
    getPackagesLeaseAndSaleDataDetailsSort,

    packagesSetPackagesBuyAll,
    packagesSetAllPackageCancel,

    getPackagesDefectInspectionDataDetailsSortAttempt,
    getPackagesDefectInspectionDataDetailsSortFailure,
    getDefectInspectionDataDetailsSort,

    packagesSetIsPackageSigned,

    packagesSetsetPropertySignLink,

    getPackagesGetPackagesDownloadSignDocumentsAttempt,
    getPackagesgetPackagesDownloadSignDocumentsFailure,
    getPackagesgetPackagesDownloadSignDocuments,

    packagesGetPackagesSignedUploadUrlAttempting,
    packagesGetPackagesSignedUploadUrlError,
    packagesGetPackagesSignedUploadUrl,

    packagesUploadPackagesSignedDocumentAttempting,
    packagesUploadPackagesSignedDocumentError,
    packagesSetSignCompleted,

    packagesSetPackagesEmailModal,

    packagesSetPackagesUploadedDocumentComplete,

    getPackagesGetPackagesPaymentCreditCardAttempt,
    getPackagesgetPackagesPaymentCreditCardFailure,
    getPackagesgetPackagesPaymentCreditCard,

    getPackagesIPropertyManagementDataDetailsSortAttempt,
    getPackagesIPropertyManagementDataDetailsSortFailure,
    getIPropertyManagementDataDetailsSort,

    packagesSetPropertyManagementTransactionUpdateSuccessAttempting,
    packagesSetPropertyManagementTransactionUpdateSuccessError,
    packagesSetPropertyManagementTransactionUpdateSuccess,

    packagesSetPropertyManagementTransactionUpdateSuccessGatewayAttempting,
    packagesSetPropertyManagementTransactionUpdateSuccessGatewayError,
    packagesSetPropertyManagementTransactionUpdateSuccessGateway,

    packagesSetpackagesPropertyManagementStatusUpdateModal,

    packagesGetPackagesSetTopUpPaymentsAttempting,
    packagesGetPackagesSetTopUpPaymentsError,
    packagesGetPackagesSetTopUpPayments,

    packagesSetTopUpPaymentModal,

    getPackagesIPropertyManagementDocumentViewDataDetailsSortAttempt,
    getPackagesIPropertyManagementDocumentViewDataDetailsSortFailure,
    getIPropertyManagementDataDocumentViewDetailsSort,

    getPackagesIVacantManagementViewDataDetailsSortAttempt,
    getPackagesIVacantManagementViewDataDetailsSortFailure,
    getIVacantManagementDataDocumentViewDetailsSort,

    getPackagesPackagesSubscriptionTransactionsAttempt,
    getPackagesPackagesSubscriptionTransactionsFailure,
    getIPackagesPackagesSubscriptionTransactions,

    packagesSetPackagesSubscriptionTransactionsCancelAttempting,
    packagesSetPackagesSubscriptionTransactionsCancelError,
    packagesSetPackagesSubscriptionTransactionsCancel,

    packagesSetPackagesSubscriptionTransactionsRenewAttempting,
    packagesSetPackagesSubscriptionTransactionsRenewError,
    packagesSetPackagesSubscriptionTransactionsRenew,

    packagesSetTransactionApproveOrRenewLoaderModal,

    packageGetGoogleDriveContentAttempting,
    packageGetGoogleDriveContentError,
    packageGetGoogleDriveContent,

    packageGetCreatePMTransactionAttempting,
    packageGetCreatePMTransactionError,
    packageGetCreatedPMTransactionId,
};
