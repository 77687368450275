import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/Translate';

import { toast } from 'react-toastify';
import Actions from 'redux/Actions';
import { CreatePMTransactionActionPayload } from 'redux/slices/packages/types';

export default function* watchCreatePMTransaction(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/createPMTransactionAttempt', handleCreatePMTransaction, api);
}

function* handleCreatePMTransaction(api: PackagesGateway, data: CreatePMTransactionActionPayload) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        const { propertyId, amount, description, transactionDate, transactionType } = data.payload;

        const response = yield* call([api, api.createPMTransaction], {
            authToken,
            propertyId,
            amount,
            description,
            transactionDate,
            transactionType,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.createPMTransactionFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            yield put(Actions.createPMTransactionSuccess(response.data));
            yield put(Actions.packagesGetIPropertyManagementDataSortAttempt({ propertyId, sort: 1, transactionType: 9999 }));
            yield put(Actions.setPMTransactionModalOpen(false));
            toast.success('Transaction created!');
        } else {
        // handle error
            yield put(Actions.createPMTransactionFailure(response.message));
            toast.error(response.message);
        }
    }
}
